/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState } from 'react';

import { Lens } from 'monocle-ts';

import Grid from '@mui/material/Grid';

import { MemoNumberEdit, NumberEdit } from '~/src/edit/Number';
import BooleanEdit from '~/src/edit/Boolean';
import { ListEdit } from '~/src/edit/List';

import { modifyUpdater } from '~/src/edit/util';
import { LengthConverters } from '~/src/util/Units';
import QButtonGroup from '~/src/ui/input/QButtonGroup';
import { QButtonGroupItem } from '~/src/ui/input/QButtonGroup';
import { RotateLeft, RotateRight } from '@mui/icons-material';
import QTextArea from '~/src/ui/input/QTextArea';

type TestState = {
	a: number,
	b: string,
	c: boolean,
	d: number[],
};

const testA: Lens<TestState,number> = Lens.fromProp<TestState>()('a');
// const testB: Lens<TestState,string> = Lens.fromProp<TestState>()('b');
const testC: Lens<TestState,boolean> = Lens.fromProp<TestState>()('c');
const testD: Lens<TestState,number[]> = Lens.fromProp<TestState>()('d');

type OpeningMode = 'Wall' | 'Panel' | 'Floor Plan';

const modeTransList: QButtonGroupItem<OpeningMode>[] = [
	{ key: 'Wall', names: { eng: 'Wall' }, update: (f: any) => console.log(f('')) },
	{ key: 'Panel', names: { eng: 'Panel' }, update: (f: any) => console.log(f('')) },
	{ key: 'Floor Plan', names: { eng: 'Floor Plan' }, update: (f: any) => console.log(f('')) }
];

type RotateDirection = 'Left' | 'Right';

const rotateTransList: QButtonGroupItem<RotateDirection>[] = [
	{ key: 'Left', names: {}, icon: <RotateLeft></RotateLeft>, update: (f: any) => console.log(f('')) },
	{ key: 'Right', names: {}, icon: <RotateRight></RotateRight>, update: (f: any) => console.log(f('')) }
];

export const TestPage = (): React.ReactElement => {
	const [ test, setTest ] = useState<TestState>({
		a: 1,
		b: 'hello world',
		c: true,
		d: [1, 2, 3],
	});

	return (
		<>
			<Grid container direction="row" justifyContent="space-evenly" sx={{m:1}}>
				<Grid item>
					<MemoNumberEdit value={test.a} update={modifyUpdater(testA, setTest)}/>
				</Grid>
				<Grid item>
					<BooleanEdit value={test.c} update={modifyUpdater(testC, setTest)}/>
				</Grid>
				<Grid item>
					<ListEdit converter={LengthConverters.mm} value={test.d} update={modifyUpdater(testD, setTest)}
						Edit={NumberEdit}
					/>
				</Grid>
			</Grid>
			<Grid container direction="row" justifyContent="space-evenly" sx={{m:1}}>
				<Grid item>
					<QButtonGroup lang='eng' size='small' items={modeTransList} value={['Wall'] as OpeningMode[]} update={(f: any) => console.log('Q1', f(''))}
					/>
				</Grid>
				<Grid item>
					<QButtonGroup lang='eng' size='small' items={modeTransList} value={'Wall' as OpeningMode} update={(f: any) => console.log('Q2:', f(''))}
					/>
				</Grid>
				<Grid item>
					<QButtonGroup lang='eng' size='small' divider={true} label={({eng: 'Rotate'})} items={rotateTransList} update={(f) => console.log('Q3', f('Left'))}
					/>
				</Grid>
			</Grid>
			<Grid container direction="row" justifyContent="space-evenly" sx={{m:1}}>
				<Grid item>
					<QTextArea lang='eng' label={({eng: 'Unit'})} minRows={3} cols={30} />
				</Grid>
			</Grid>
		</>
	);
};

export default TestPage;
