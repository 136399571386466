import * as T from 'io-ts';
import { boolean, keyof, type, intersection, partial } from 'io-ts';

import { MimeFile } from '~/src/base/Mime';
import { Requestor } from '~/src/api/Requestor';
import { NonEmpty } from '~/src/base/Array/NonEmpty';
import { Figure } from '~/src/figure';


/*
 * Possible file types that we can render our figures into.
 */
export type Type = 'png' | 'svg' | 'dxf' | 'pdf';

export const Type : T.Type<Type> = keyof({
	png : true,
	svg : true,
	dxf : true,
	pdf : true,
});


/*
 * The 'Purpose' of requested document 'Format'. This is different from creating
 * a 'Figure' for a specific purpose and should have no influence over figure
 * elements and how rulers, labels and the figure itself is rendered.
 *
 * Intended purpose is to give a hint about what e.g. PDF template and meta data
 * should be used when rendering a 'Figure'.
 *
 * The 'Figure' should look the same no matter which 'Purpose' was selected.
 */
export type Purpose = 'Basic' | 'Quotation' | 'Fabrication';

export const Purpose : T.Type<Purpose> = keyof({
	Basic : true,
	Quotation : true,
	Fabrication : true,
});


/*
 * A simple configuration record used to specify what we expect as a 'Figure'
 * render output.
 *
 * For now, we can specify the file 'Type' and if we want each 'Piece' of a
 * 'Figure' to be in a separate file, or as a single file.
 */
export interface Format {
	Type : Type;
	Frag : boolean;
	Purpose ?: Purpose;
}

export const Format : T.Type<Format> = intersection([
	type({
		Type: Type,
		Frag: boolean,
	}),
	partial({
		Purpose: Purpose
	})
]);


/*
 * A render request object for a single 'Figure'.
 */
export interface FigureReq {
	Figure : Figure;
	Formats : NonEmpty<Format>;
}

export const FigureReq: T.Type<FigureReq> = type({
	Figure : Figure,
	Formats : NonEmpty(Format),
});


/*
 * A record used to request rendering of a 'Figure' or multiple 'Figure's with
 * same or varying 'Format's.
 */
export interface RenderReq {
	FigureReqs : NonEmpty<FigureReq>;
}

export const RenderReq: T.Type<RenderReq> = type({
	FigureReqs : NonEmpty( FigureReq ),
});

/*
 * An 'Output' containing the list of files encoded as either a plain 'Text' or
 * a 'Base64' 'Text'. This depends on the file 'Type'. Things like 'DXF' can be
 * encoded as a plain 'Text' while 'PDF' should be Base64 encoded so it can be
 * successfuly sent back in a JSON object along with some other metadata.
 */
export interface Output {
	Type : Type;
	Files : NonEmpty<MimeFile>;
}

export const Output = type({
	Type : Type,
	Files : NonEmpty(MimeFile),
});

/*
 * A response for a single 'Figure' render containing a list of render outputs
 * for that particular 'Figure'.
 */
export type FigureRes = NonEmpty<Output>;

export const FigureRes = NonEmpty(Output);


/*
 * A response to a 'RenderReq'uest. It's just a list of 'FigureRes'ponses, each
 * of which can contain multiple render results for a single figure. Each
 * 'FigureRes'ponse should have the same index as the corresponding
 * 'FigureReq'uest from the 'RenderReq'uest.
 */
export type RenderRes = NonEmpty<FigureRes>;

export const RenderRes = NonEmpty(FigureRes);


/*
 * A simplified RenderRequest generator for the most basic purpose.
 */
export const figReq = (fig: Figure) => (typ : Type): RenderReq => ({
	FigureReqs: [{
		Figure: fig,
		Formats: [{
			Type: typ,
			Frag: true,
			Purpose: 'Fabrication',
		}]
	}],
});


/*
 * Use the figure render API.
 */
export const render = (req: Requestor) => (renderReq: RenderReq): Promise<RenderRes> =>
	req.request(RenderRes)('figure/render' , 'POST' , renderReq);
