import { dot, get, every, Optic } from '~/src/base/Optic';

import { Type, type, string, intersection, partial } from 'io-ts';
import * as NE from '~/src/base/Array/NonEmpty';
import { P } from '~/src/geometry/point';
import * as Pnt from '~/src/geometry/point';
import * as Piece from './Piece';
import { V3, scale, sub, V2 } from '~/src/geometry/vector';
import { Fn, pipe } from '~/src/base/Function';
import * as PZ from '~/src/ui/PanZoom';
import { Ruler } from './Ruler';
import { pure } from '~/src/base/Array/Two';

export type FigMap<T extends string> = {
	[k in T]?: Figure;
};

export interface Figure {
	Kind?: string;
	Pieces: NE.NonEmpty<Piece.Piece>;
}

export const Figure: Type<Figure> = intersection([partial({
	Kind: string,
}), type({
	Pieces: NE.NonEmpty(Piece.Piece),
})]);

export const _Pieces: Optic<Figure, NE.NonEmpty<Piece.Piece>> = dot('Pieces');

/**
 * An empty Figure.
 *
 * Contains a single empty piece.
 */
export const empty: Figure = ({
	Pieces:[ Piece.empty ]
});

/**
 * Returns all of the points from a figure.
 */
export const points = (figure: Figure): P<V3>[] =>
	figure.Pieces.map(Piece.points).flat(1);

/**
 * Finds a bounding box of a figure by returning the "smallest" and the
 * "largest" point of a figure.
 */
export const bbox = (figure: Figure): [P<V3>, P<V3>] | null => {
	const pts = points(figure);
	return NE.refineNonEmpty(pts) ? Pnt.bbox(pts as NE.NonEmpty<P<V3>>) : null;
};

/**
 * Find a decent pan/zoom for a figure.
 *
 * This assumes that the ruler with the largest layer depth is
 * located on the bounding box of the curves of the figure,
 * and applies the appropriate distance equally around the bounding box.
 *
 * So long as the rulers are not outside the curve bounding box this will
 * ensure that the whole figure is shown. However in some cases it will small.
 */
export const fit = (proj: Fn<P<V3>,P<V2>>, fontsize: number, box: V2) => (figure: Figure): PZ.PanZoom => {
	const maxLayer = get<Figure,Ruler>(pipe(every, Piece._Rulers, every, _Pieces))(figure)
		.map(ruler => ruler.Info.LabelLayer + ruler.Info.Layer)
		.reduce((x,a) => Math.max(x,a), 0);
	const padding: V2 = pure(2 * fontsize * maxLayer);
	const innerBox: V2 = sub(box)(scale(2)(padding));

	const pts = points(figure).map(proj);
	if (!NE.refineNonEmpty(pts) || innerBox.some(a => a < 0)) return PZ.def;
	const bounds = Pnt.bbox(pts);
	const extent = Pnt.sub(bounds[1])(bounds[0]);

	const zoom = Math.min(innerBox[0]/extent[0], innerBox[1]/extent[1]);
	const pan: P<V2> = Pnt.add(scale(-1)(bounds[0]))(scale(1/zoom)(padding));
	return { pan, zoom };
};
