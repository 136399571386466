import React, { memo } from 'react';

import Checkbox from '@mui/material/Checkbox';
import { EditProps } from '~/src/edit/types';

export interface BooleanParams extends EditProps<boolean> {
	size?: 'small' | 'medium';
}

export const BooleanEdit = memo(({value, update, size}: BooleanParams): React.ReactElement =>
	<Checkbox size={size} checked={value} onChange={(ev) => update(() => ev.target.checked)}/>);

export default BooleanEdit;
