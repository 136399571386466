import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';



interface ShareURLProps {
	url?: string; // optional, if not defined use location.href
	size ?: 'small' | 'medium' | 'large';
}

export const ShareURL = (ps: ShareURLProps) => {
	const [ copied, setCopied ] = useState('');
	const [ clicked, setClicked] = React.useState(false);
	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text);
		setCopied('Copied: ' + text );
		setClicked(true);
	};
	const handleTooltipClose = () => {setClicked(false);};

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<Tooltip title={copied} PopperProps={{disablePortal: true }} onClose={handleTooltipClose} open={clicked}
				disableFocusListener disableHoverListener disableTouchListener arrow placement='top'>
				<Box>
					<Tooltip title={clicked ? '' : 'Share URL'}>
						<IconButton size={ ps.size ?? 'large' } component="span" color="info" onClick={() => { copyToClipboard(ps.url ?? window.location.href);}}>
							<ShareIcon fontSize="inherit" />
						</IconButton>
					</Tooltip>
				</Box>
			</Tooltip>
		</ClickAwayListener>

	);
};
