import { interface as iface, Type, string, boolean, array, keyof } from 'io-ts';
import { Translations } from '~/src/model/Language';

export type Group<T = Item> = {
	Fold: boolean,
	Items: T[],
	Label: string,
	Names: Translations,
};

export const Group = <T>(codecT: Type<T>): Type<Group<T>> => iface({
	Fold: boolean,
	Items: array(codecT),
	Label: string,
	Names: Translations,
});

export type ItemType = 'Opt' | 'Var';
const ItemTypes: {[k in ItemType]: true} = {
	Opt: true,
	Var: true,
};

export type Item = OptItem | VarItem;

export interface OptItem {
	type: 'Opt',
	Label: string,
}

export const isOptItem = (ps: Item): ps is OptItem => ps.type === 'Opt';

export interface VarItem {
	type: 'Var',
	Label: string,
}

export const Item: Type<Item> = iface({
	type: keyof(ItemTypes),
	Label: string,
});

