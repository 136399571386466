/**
 * Building Templates and getting information about them.
 *
 * @since 0.4.0
 */
import { array } from 'io-ts';
import { Requestor } from '~/src/api/Requestor';
import { Template } from '~/src/design/template';
import { ClientTemplateMod } from '~/src/admin/template/mod';

/**
 * List mods available on the server.
 *
 * @since 0.4.0
 */
export const listMods = (req: Requestor): Promise<ClientTemplateMod[]> =>
	req.request(array(ClientTemplateMod))('template/mod', 'GET');

/**
 * Make an apply mod request.
 *
 * @since 0.4.0
 */
export const applyMod = (req: Requestor) => (clientTemplateMod: ClientTemplateMod): Promise<Template> =>
	req.request(Template)('template/mod', 'POST', clientTemplateMod);

/**
 * Make a save mod request.
 *
 * @since 0.4.0
 */
export const saveMod = (req: Requestor) => (clientTemplateMod: ClientTemplateMod): Promise<ClientTemplateMod> =>
	req.request(ClientTemplateMod)('template/mod', 'PUT', clientTemplateMod);
