/**
 * Walls.
 *
 * Front-end related wall drawings, checks for updated props and appends XML to the current react reference.
 *
 * @since 0.2.0
 */
import * as React from 'react';

import { Opening } from '~/src/design/opening';
import { Requestor } from '~/src/api/Requestor';

import { Space } from '~/src/ui/SpaceControl';
import { Projection } from '~/src/design/opening/projection';
import { LengthConverter, AngleConverter } from '~/src/util/Units';
import { convertOpeningTextElements, mapTextElements } from '~/src/util/Dom';
import { hash } from '~/src/util/Deep';
import { Box } from '@mui/material';
import { UnitViewProps } from '~/src/view/types';
import { closePie, DRAWING_DIV_ID, WalledState } from '~/src/design/opening/walls/edit';
import { orange } from '@mui/material/colors';
import useDrawingInteractions from '~/src/hooks/useDrawingInteractions';
import { Update } from '~/src/base/Function';
import useTouchAngle from '~/src/hooks/useTouchAngle';
import useDrawingViewer from '~/src/hooks/useDrawingViewer';


/**
 * WallViewer Props.
 *
 * @since 0.2.0
 */
export interface WallProps extends UnitViewProps<Opening> {
	visible: boolean;
	req: Requestor;
	space: Space,
	projection: Projection;
	curbEdges: boolean;
	onDown: (e: React.MouseEvent | React.TouchEvent) => void
	activeElementId: string
	angle: number | null
	updateAngle: Update<number | null>
	trackingAngle: number | null
	updateTrackingAngle: Update<number | null>
	updateWall: Update<WalledState>
}

/**
 * Angle and length unit conversion.
 */
export const convertAngleUnits = (ac: AngleConverter) => (el: number) => (id: string): string | null => {
	if (id.endsWith('-Angle-lbl')) {
		return ac.build(el);
	}

	return null;
};

/**
 * Length conversion
 */
export const convertLengthUnits = (lc: LengthConverter) => (el: number) => (id: string): string | null => {
	if (id.endsWith('-Measure-lbl')
		|| id.endsWith('-Edge-lbl')
		|| id.endsWith('-EdgeMeasuredLine-lbl')
		|| id.endsWith('EdgeBredth-lbl')
		|| id.endsWith('-Minor-lbl')
		|| id.endsWith('-EdgeHeight-lbl')
		|| id.endsWith('-EdgeOuterHeight-lbl'))
	{
		return lc.build(el);
	}

	return null;
};

const WallViewer: React.FC<WallProps> = ({
	req,
	value,
	projection,
	visible,
	lengthConverter,
	angleConverter,
	space,
	activeElementId,
	onDown,
	angle,
	updateAngle,
	updateWall,
	trackingAngle,
	updateTrackingAngle,
	...props
}) => {
	const { ref } = useTouchAngle(updateAngle, angle, updateTrackingAngle, trackingAngle);
	const { viewBox, containerRef, zooming, ...mouseHandlers } = useDrawingInteractions(onDown, activeElementId);
	const { svgInner, hashKey } = useDrawingViewer(req, value, projection, [{
		type: 'Opening',
		Projection: projection,
		RulerDirection: projection.type == 'Flat' ? 'away' : 'out',
		CurbEdges: projection.type === 'FloorPlan',
		DrawFloor: projection.type === 'FloorPlan' // remove?
	}]);

	React.useEffect(() => {
		if (zooming) {
			updateWall(closePie);
		}
	}, [zooming, updateWall]);

	React.useEffect(() => {
		if (containerRef?.current) {
			mapTextElements(convertOpeningTextElements(value)(convertLengthUnits(lengthConverter)))(containerRef?.current);
		}
	}, [lengthConverter, svgInner, value, containerRef]);

	React.useEffect(() => {
		if (containerRef?.current) {
			mapTextElements(convertOpeningTextElements(value)(convertAngleUnits(angleConverter)))(containerRef?.current);
		}
	}, [angleConverter, svgInner, value, containerRef]);

	const propHash = hash(hashKey)({
		req,
		value,
		projection,
		visible,
		onDown,
		...props
	});

	return (
		<Box
			id={DRAWING_DIV_ID}
			ref={containerRef}
			className={`hash-${propHash} drawing`}
			sx={{ width: '100%', flexGrow: 1 }}
			{...mouseHandlers}
		>
			<Box
				ref={ref}
				component='svg'
				dangerouslySetInnerHTML={{
					__html: svgInner
				}}
				sx={{
					[`& #${activeElementId}`]: {
						stroke: orange[300],
						strokeOpacity: 0.5,
						fill: orange[300]
					}
				}}
				viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`}
			/>
		</Box>
	);
};

export default WallViewer;
