import { pipe } from 'fp-ts/lib/function';
import React from 'react';
import * as R from 'fp-ts/lib/Record';
import * as O from 'fp-ts/lib/Option';
import { Draw, getDrawings } from '~/src/design/opening/api/drawings';
import { hash } from '~/src/util/Deep';
import { Projection } from '~/src/design/opening/projection';
import { Requestor } from '~/src/api/Requestor';
import { Opening } from '~/src/design/opening';


const useDrawingViewer = (req: Requestor, value: Opening, projection: Projection, drawingsArray: Draw[]) => {
	const [lastHash, setLastHash] = React.useState('');
	const [lastDrawing, setLastDrawing] = React.useState('');
	const [drawings, setDrawings] = React.useState<{[k in string]?: string}>({});
	const [svgInner, setSvgInner] = React.useState('');

	const hashKey = Math.random();

	const getDrawing: (hash: string) => Promise<string> = React.useCallback((hash: string): Promise<string> => pipe(
		drawings,
		R.lookup(hash),
		O.chain(O.fromNullable),
		O.fold(() => getDrawings(req)(value, drawingsArray).then((silicaDrawings) => {
			const drawing = silicaDrawings[0];
			if (drawing === undefined)
				return Promise.reject(new Error('No drawings.'));
			setDrawings(prev => ({
				...prev,
				[hash]: drawing
			}));
			return drawing;
		}),
		(s) => Promise.resolve(s)
		)
	), [drawings, req, value, drawingsArray]);

	React.useEffect(() => {
		// This should hold everything that the drawing returned by the server depends on.
		const drawingDependant = {
			projection: projection,
			opening: value,
		};

		const drawHash = hash(hashKey)(drawingDependant);

		if (drawHash === lastHash) return;


		getDrawing(drawHash).then((drawing) => {
			setLastHash(drawHash);
			setLastDrawing(drawing);
		});
	}, [getDrawing, lastHash, projection, value, hashKey]);

	React.useEffect(() => {
		if (lastDrawing) {
			const parser = new DOMParser();
			const doc = parser.parseFromString(lastDrawing, 'application/xml');
			const svg = doc.querySelector('svg');
			setSvgInner(svg?.innerHTML || '');
		}
	}, [lastDrawing]);

	return {
		svgInner,
		hashKey
	};
};

export default useDrawingViewer;
