import { useContext, useState, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import QButton from '~/src/ui/input/QButton';
import { EditProps } from '~/src/edit/types';
import {
	Option,
	Template,
	Variable,
	templateTransEntry,
} from '~/src/design/template';
import ResizeHandle from '~/src/components/ResizeHandle';
import { Group } from '~/src/design/template/groups';
import { isSelectedOption, isSelectedVariable } from '~/src/design/template/edit';
import { templateGroups, templateOpts, templateVars } from '~/src/design/template/optics';
import NumberEdit from '~/src/edit/Number';
import DataGroup from './DataGroup';
import { comp } from '~/src/base/Function';
import { index } from '~/src/base/Optic';
import SelectEnum from '~/src/edit/SelectEnum';
import { BulkDesignContext, SHOW_ALERT } from '~/src/design/bulk/store';
import { mmap } from '~/src/base/Array';
import { BulkDesign, BulkDesignLeaf } from '~/src/design/bulk';
import { Mod } from '~/src/base/Function';

const DEFAULT_WIDTH = 300;
const MINIMUM_WIDTH = 300;
const MAXIMUM_WIDTH = 500;

type NewVarType = Variable & { Type: 'Vars'; ArrIndex: number };
type NewOptType = Option & { Type: 'Opts'; ArrIndex: number };

enum ActionStep {
	SAVE = 'SAVE',
	CLONE = 'CLONE'
}

type TemplateLayoutGroupType = Group<NewVarType | NewOptType>;

type TemplateDataPanelProps = EditProps<Template> & {
	selectedNode?: BulkDesign | BulkDesignLeaf;
	setFieldBasedOnPath: (field: 'Name' | 'Count', newValue: string | number) => void;
	handleDuplication: () => void;
};

const TemplateDataPanel = (props: TemplateDataPanelProps) => {
	const { update, value, selectedNode, setFieldBasedOnPath, handleDuplication } = props;
	const { Opts, Vars, Groups } = value;

	const { serverTemplates, dispatch } = useContext(BulkDesignContext);
	const [selfWidth, setSelfWidth] = useState(DEFAULT_WIDTH);
	const [amount, setAmount] = useState(selectedNode?.Count ?? 0);
	const [actionStep, setActionStep] = useState<ActionStep>(ActionStep.SAVE);

	const handleSaveQuote = () => {
		// Firstly, Update amount.
		setFieldBasedOnPath('Count', amount);
		dispatch({
			type: SHOW_ALERT,
			data: {
				open: true,
				title: 'Quote submitted',
				description: 'You can now continue',
				autoDuration: 3000,
			}
		});
		setActionStep(ActionStep.CLONE);
	};

	const handleClose = () => {
		setSelfWidth(0);
	};

	const handleClone = () => {
		setSelfWidth(0);
		handleDuplication();
		dispatch({
			type: SHOW_ALERT,
			data: {
				open: true,
				title: 'Item cloned',
				description: 'You are now editing the cloned item',
				autoDuration: 3000,
			}
		});
		setTimeout(() => {
			setActionStep(ActionStep.SAVE);
			setSelfWidth(DEFAULT_WIDTH);
		}, 500);
	};

	const layoutData: TemplateLayoutGroupType[] = useMemo(() => {
		const newVars: NewVarType[] = Vars.map(
			(variable, ArrIndex): NewVarType => ({
				...variable,
				Type: 'Vars',
				ArrIndex,
			})
		).filter((variable): variable is NewVarType =>
			isSelectedVariable(value)(variable)
		);

		const newOpts: NewOptType[] = Opts.map(
			(opt, ArrIndex): NewOptType => ({ ...opt, Type: 'Opts', ArrIndex })
		).filter((opt): opt is NewOptType => isSelectedOption(value)(opt));

		return Groups.map((group) => {
			const newItems = group.Items.map((item) => {
				if (item.type === 'Opt') {
					const opt = newOpts.find((opt) => opt.Label === item.Label);
					if (opt) return { ...opt, Type: 'Opts' };
				} else if (item.type === 'Var') {
					const varb = newVars.find((varb) => varb.Label === item.Label);
					if (varb) return { ...varb, Type: 'Vars' };
				}
				return null;
			}).filter((item): item is NewVarType | NewOptType => item !== null);
			return {
				...group,
				Items: newItems,
			};
		});
	}, [Groups, Opts, Vars, value]);

	const handleUpdate = (a: Mod<Template>) => {
		update(a);
		setActionStep(ActionStep.SAVE);
	};

	useEffect(() => {
		setAmount(selectedNode?.Count || 0);
	}, [selectedNode]);

	useEffect(() => {
		if (actionStep === ActionStep.CLONE) {
			setActionStep(ActionStep.SAVE);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [amount]);

	return (
		<Box className="template-datapanel">
			<ResizeHandle
				defaultValue={DEFAULT_WIDTH}
				minValue={MINIMUM_WIDTH}
				maxValue={MAXIMUM_WIDTH}
				position="left"
				onChange={setSelfWidth}
			/>
			<Box
				className="template-datapanel__content"
				style={{ width: `${selfWidth}px`, transition: 'width 0.3s ease-in-out' }}
			>
				<Box className="template-datapanel__content-main">
					{/* Select Glass */}
					<Box className="template-datapanel__selector">
						<Box className="template-datapanel__selector-top">
							<Typography className="template-datapanel__selector-title">
									Select glass
							</Typography>
						</Box>
						<Box className="template-datapanel__selector-content">
							<FormControl sx={{ my: 1, width: '100%' }}>
								<SelectEnum
									value={value?.Id || ''}
									label={{ eng: 'Template' }}
									size="small"
									items={mmap(templateTransEntry)(serverTemplates)}
									update={() => {}}
								/>
							</FormControl>
						</Box>
					</Box>
					{layoutData.map((elm, i) => (
						<DataGroup
							key={elm.Label}
							data={elm}
							update={comp(update, templateGroups, index(i))}
							updateOpts={comp(handleUpdate, templateOpts)}
							updateVars={comp(handleUpdate, templateVars)}
						/>
					))}
				</Box>
				<Box className={`template-datapanel__content-footer ${actionStep === ActionStep.CLONE ? 'gap-2' : 'gap-8'}`}>
					<NumberEdit tlabel={{ eng: 'Quantity' }} update={setAmount} value={amount} />
					{actionStep === ActionStep.SAVE && (
						<QButton variant="text" onClick={handleSaveQuote}>Save Quote</QButton>
					)}
					{actionStep === ActionStep.CLONE && (
						<>
							<QButton variant="text" onClick={handleClose} style={{ backgroundColor: '#822727' }}>Close</QButton>
							<QButton variant="text" onClick={handleClone} style={{ backgroundColor: '#1966D8' }}>Clone</QButton>
						</>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default TemplateDataPanel;
