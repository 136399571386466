/**
 * Units.
 *
 * @since 0.2.0
 */

import { flow } from 'fp-ts/lib/function';
import { keyof, Type } from 'io-ts';

/**
 * The converter interface.
 *
 * @since 0.2.0
 */
export interface Converter<T,S> {
	name: string;
	build: (x: T) => S;
	parse: (str: S) => Promise<T>;
	defaultProduct: (x: number) => number
}

const noProductFactor = (x: number) => x;

export const plainConverter: Converter<number,string> = {
	name: 'plain',
	build: (x) => x.toString(),
	parse: (str) => notNaN(parseFloat(str)),
	defaultProduct: noProductFactor,
};

export const piecesConverter: Converter<number,string> = {
	name: 'pieces',
	build: (x) => Math.round(x).toString() + ' pc',
	parse: (str) => notNaN(Math.round(parseFloat(str))),
	defaultProduct: noProductFactor,
};

/**
 * Greatest common divisor.
 *
 * @since 0.2.0
 */
const gcd = (a: number, b: number): number =>
	isNaN(a) || isNaN(b) ? NaN :
		a === 0 ? b :
			b === 0 ? a :
				a > b ? gcd(a % b, b) : gcd(b % a, a);

export type Length = number;

export type LengthUnit = 'inch' | 'mm';

const lengthUnits: {[k in LengthUnit]: true} = {
	inch: true,
	mm: true,
};

export const LengthUnit: Type<LengthUnit> = keyof(lengthUnits);

/**
 * LengthConverter, from number to string and back.
 *
 * @since 0.2.0
 */
export type LengthConverter = Converter<Length, string>;

const decimalFromInchString = (str: string) => str.split(/(?!^)-|\s/) // Split on space or dash
	.map(e => e.split('/') // Split fraction
		.map(parseFloat) // Turn everything into a float
		.reduce((a, b) => a/b)) // Get fraction as decimal
	.reduce((a, b) => a >= 0 ? a+b : a-b); // Add whole number and decimal

const notNaN = (num: number): Promise<number> => {
	if (isNaN(num)) {
		return Promise.reject(new Error('Value is not a number.'));
	} else {
		return Promise.resolve(num);
	}
};

const nonNaN: Converter<number, number> = {
	name: 'non-nan',
	build: (x) => isNaN(x) ? 0 : x,
	parse: notNaN,
	defaultProduct: noProductFactor
};

export const composeConverter = <A, B, C>(
	f: Converter<A, B>,
	g: Converter<B, C>,
	name?: string
): Converter<A, C> => ({
		name: name ? name : f.name + ' ' + g.name,
		build: (x: A) => g.build(f.build(x)),
		parse: (a: C) => g.parse(a).then(f.parse),
		defaultProduct: g.defaultProduct ? g.defaultProduct : f.defaultProduct ? f.defaultProduct : noProductFactor
	});

export const nonNegative: Converter<number, number> = {
	name: 'non-negative',
	build: (x) => Number(x),
	parse: (x) => x >= 0
		? Promise.resolve(x)
		: Promise.reject(new Error('Negative Number Entered')),
	defaultProduct: noProductFactor
};

const fractionalInch = (inches: number) => {
	if (inches > 0 && inches < 1) {
		return true;
	}
	if (inches > -1 && inches < 0) {
		return true;
	}
	return false;
};

const numberIsZero = (inches: number) => inches === 0;

const digitsSuper = {
	'0': '⁰',
	'1': '¹',
	'2': '²',
	'3': '³',
	'4': '⁴',
	'5': '⁵',
	'6': '⁶',
	'7': '⁷',
	'8': '⁸',
	'9': '⁹',
};

const digitsSub = {
	'0': '₀',
	'1': '₁',
	'2': '₂',
	'3': '₃',
	'4': '₄',
	'5': '₅',
	'6': '₆',
	'7': '₇',
	'8': '₈',
	'9': '₉',
};

const fromSubSuperDigits = {
	'⁰': '0',
	'¹': '1',
	'²': '2',
	'³': '3',
	'⁴': '4',
	'⁵': '5',
	'⁶': '6',
	'⁷': '7',
	'⁸': '8',
	'⁹': '9',
	'₀': '0',
	'₁': '1',
	'₂': '2',
	'₃': '3',
	'₄': '4',
	'₅': '5',
	'₆': '6',
	'₇': '7',
	'₈': '8',
	'₉': '9',
};

const mapChars = (chars: {[k in string]?: string}) => (x: string): string =>
	x.split('').map(d => chars[d] ?? d).join('');

const toDigits = (digits: {[k in string]?: string}) => (n: number) =>
	mapChars(digits)(n.toFixed(0));

const toSuper = toDigits(digitsSuper);
const toSub = toDigits(digitsSub);
const fromSuper = mapChars(fromSubSuperDigits);
const splitSuper = (x: string): string => x.replace(/([0-9])([¹²³⁰-⁹])/, '$1 $2');

const inches: Converter<number, string> = {
	name: 'inches',
	build: (x) => {
		const len = Math.round(x * 16);
		const inches = len / 16;
		const sixteenths = Math.abs(len % 16);
		const factor = gcd(sixteenths, 16);
		const wholeInches = !fractionalInch(inches) ?
			`${String(inches).replace(/(\..+)/, '')}` :
			fractionalInch(inches) && inches < 0 ? '-' :
				'';
		const fraction = !numberIsZero(sixteenths) ? toSuper(sixteenths/factor)+ '/' + toSub(16/factor) : '';
		return wholeInches+fraction+'"';
	},
	// parse inch string to millimeters number
	parse: flow(splitSuper, fromSuper, decimalFromInchString, notNaN),
	defaultProduct: (x) => x,
};

const millimeters: Converter<number, string> = {
	name: 'millimeters',
	// build mm to mm string
	build: (length) => `${Math.round(length)} mm`,
	// parse millimeters string to inch number
	parse: (num) => Promise.resolve(Math.round(parseFloat(num))),
	defaultProduct: (x) => x * 25
};

const inchToMM: Converter<number, number> = {
	name: 'inch to mm',
	build: (length) => length * 25.4,
	parse: (length) => Promise.resolve(length / 25.4),
	defaultProduct: (x) => x * 25,
};

const mmToInch: Converter<number, number> = {
	name: 'mm to inch',
	build: (length) => length / 25.4,
	parse: (length) => Promise.resolve(length * 25.4),
	defaultProduct: (x) => x / 25,
};

const inchesFromMillimeters: Converter<number, string> =
	composeConverter(mmToInch, inches, 'Inches');

export const inchConverter = composeConverter(nonNaN, inchesFromMillimeters, 'Inches');
export const nonNaNMillimeters = composeConverter(nonNaN, millimeters, 'Millimeters');

export const lengthUnitConverters: {[a in LengthUnit]: {[b in LengthUnit]: LengthConverter}} = {
	inch: {
		inch: inches,
		mm: composeConverter(inchToMM, millimeters, 'Millimeters')
	},
	mm: {
		inch: inchConverter,
		mm: nonNaNMillimeters,
	}
};

/**
 * LengthConverters, containing length related conversions.
 *
 * @since 0.2.0
 */
export const LengthConverters: {[k in LengthUnit]: LengthConverter} = {
	inch: inchConverter,
	mm: nonNaNMillimeters
};

/**
 * Units of area.
 */
export type AreaUnit = 'ft²' | 'in²'| 'm²' | 'mm²';

const AreaUnits: {[k in AreaUnit]: true} = {
	'ft²': true,
	'in²': true,
	'm²': true,
	'mm²': true,
};

export const AreaUnit: Type<AreaUnit> = keyof(AreaUnits);

const fmtSqft: Converter<number,string> = {
	build: (x: number) => x.toFixed(1) + ' ft²',
	parse: (str) => notNaN(parseFloat(str)),
	name: 'square feet',
	defaultProduct: noProductFactor,
};

const fmtSqin: Converter<number,string> = {
	build: (x: number) => x.toFixed(0) + ' in²',
	parse: (str) => notNaN(parseFloat(str)),
	name: 'square inch',
	defaultProduct: noProductFactor,
};

const fmtSqm: Converter<number,string> = {
	build: (x: number) => x.toFixed(2) + ' m²',
	parse: (str) => notNaN(parseFloat(str)),
	name: 'square meter',
	defaultProduct: noProductFactor,
};

const fmtSqmm: Converter<number,string> = {
	build: (x: number) => x.toFixed(0) + ' mm²',
	parse: (str) => notNaN(parseFloat(str)),
	name: 'square millimeter',
	defaultProduct: noProductFactor,
};

const scale = (factor: number): Converter<number,number> => ({
	build: (x: number) => x * factor,
	parse: (x) => Promise.resolve(x/factor),
	name: `times ${factor.toString()}`,
	defaultProduct: noProductFactor,
});

export const AreaUnitConverters: {[a in AreaUnit]: {[b in AreaUnit]: Converter<number,string>}} = {
	'ft²': {
		'ft²': fmtSqft,
		'in²': composeConverter(scale(144), fmtSqin),
		'm²': composeConverter(scale(144/(39.37**2)), fmtSqm),
		'mm²': composeConverter(scale(144*25.4**2), fmtSqmm),
	},
	'in²': {
		'ft²': composeConverter(scale(1/144), fmtSqft),
		'in²': fmtSqin,
		'm²': composeConverter(scale(1/(39.37**2)), fmtSqm),
		'mm²': composeConverter(scale(25.4**2), fmtSqmm),
	},
	'm²': {
		'ft²': composeConverter(scale(39.37**2/144), fmtSqft),
		'in²': composeConverter(scale(39.37**2), fmtSqin),
		'm²': fmtSqm,
		'mm²': composeConverter(scale(1000**2), fmtSqmm),
	},
	'mm²': {
		'ft²': composeConverter(scale(1/144/25.4**2), fmtSqft),
		'in²': composeConverter(scale(1/25.4**2), fmtSqin),
		'm²': composeConverter(scale(1/1000**2), fmtSqm),
		'mm²': fmtSqmm,
	},
};

export type Angle = number;

export type AngleLocation = 'interior' | 'exterior';

/**
 * AngleConverter, from number to string and back.
 *
 * @since 0.2.0
 */
export type AngleConverter = Converter<Angle, string>;

const validateAngle = (x: number): Promise<Angle> =>
	isNaN(x) ? PromiseErr('Angle must be a number.') :
		x < -180 ? PromiseErr('Angle is too small.') :
			x > 180 ? PromiseErr('Angle is too large.') :
				Promise.resolve(x);

/**
 * AngleConverters, containing angle related conversions.
 *
 * @since 0.2.0
 */
export const AngleConverters: {[k in AngleLocation]: AngleConverter} = {
	interior: {
		name: 'Interior',
		/*
		 * Currently, Silica model uses exterior angles in the opening design
		 * so for consistently we treat the argument in `build` as an exterior angle
		 */
		build: (angle) => `${Math.round(180 - angle)}°`,
		parse: (str) => Promise.resolve(Math.round(180 - parseFloat(str))).then(validateAngle),
		defaultProduct: noProductFactor
	},
	exterior: {
		name: 'Exterior',
		/*
		 * Currently, Silica model uses exterior angles in the opening design
		 * so for consistently we treat the argument in `build` as an exterior angle
		 */
		build: (angle) => `${Math.round((angle))}°`,
		parse: (str) => Promise.resolve(Math.round(parseFloat(str))).then(validateAngle),
		defaultProduct: noProductFactor
	},
};

/**
 * PromiseErr, helper.
 *
 * @since 0.2.0
 */
const PromiseErr = <T>(err: string): Promise<T> => Promise.reject(new Error(err));
