import * as React from 'react';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { Divider } from '@mui/material';

import Undo from '@mui/icons-material/Undo';
import RotateLeft from '@mui/icons-material/RotateLeft';
import RotateRight from '@mui/icons-material/RotateRight';

import { Mod } from '~/src/base/Function';
import { AngleLocation, LengthUnit } from '~/src/util/Units';
import { TranslationList } from '~/src/model/Language';
import SelectEnum from '~/src/edit/SelectEnum';
import { modifyUpdater } from '~/src/edit/util';
import { OpeningMode } from '~/src/design/opening/edit';
import { projectionTag } from '~/src/design/opening/optics';
import { Projection, ProjectionTag } from '~/src/design/opening/projection';
import QButtonGroup, { QButtonGroupItem } from '~/src/ui/input/QButtonGroup';

export interface ProjectionControlProps {
	projection: Projection;
	onProjectionChange: (p: Mod<Projection>) => void;
}

export interface LengthUnitProps {
	lengthUnit: LengthUnit;
	onLengthUnitChange: (p: Mod<LengthUnit>) => void;
}

export interface AngleLocationProps {
	angleLocation: AngleLocation;
	onAngleLocationChange: (p: Mod<AngleLocation>) => void;
}

export interface OpeningModeProps {
	mode: OpeningMode;
	onOpeningModeChange: (p: Mod<OpeningMode>) => void;
}

export interface UndoButtonProps {
	onUndo: Function;
}

export type ControlProps = ProjectionControlProps & LengthUnitProps & AngleLocationProps & OpeningModeProps & UndoButtonProps;

export const Controls = (ps: ControlProps) =>
	<>
		<OpeningModeControl mode={ps.mode} onOpeningModeChange={ps.onOpeningModeChange}></OpeningModeControl>
		<ProjectionControl projection={ps.projection} onProjectionChange={ps.onProjectionChange}></ProjectionControl>
		<LengthUnitControl lengthUnit={ps.lengthUnit} onLengthUnitChange={ps.onLengthUnitChange}></LengthUnitControl>
		<AngleLocationControl angleLocation={ps.angleLocation} onAngleLocationChange={ps.onAngleLocationChange}></AngleLocationControl>
		<Divider orientation='vertical' variant='middle' sx={{ margin: '12px 0' }} flexItem />
		<UndoButton onUndo={ps.onUndo}></UndoButton>
	</>
;

export interface ProjectionControlState {
	rotation: number;
}

const projectionTransList: TranslationList<ProjectionTag> = [{key: 'Flat', names: { eng: 'Flat' }}, {key: 'FloorPlan', names: { eng: 'FloorPlan' }}, {key: 'Isometric', names: { eng: 'Isometric' }}];

type RotateDirection = 'Left' | 'Right';

const ProjectionControl: React.FC<ProjectionControlProps> = ({
	projection,
	onProjectionChange,
}) => {
	const handleRotationChange = (amount: number) => onProjectionChange((prev) => prev.type === 'Isometric' ? ({ ...prev, Rotate: (prev.Rotate || 0) + amount }) : prev);
	const rotateTransList: QButtonGroupItem<RotateDirection>[] = [
		{ key: 'Left', names: {}, icon: <RotateLeft></RotateLeft>, update: () => handleRotationChange(15) },
		{ key: 'Right', names: {}, icon: <RotateRight></RotateRight>, update: () => handleRotationChange(-15) }
	];
	return <>
		{projection.type !== 'Isometric' ? null :
			<FormControl>
				<QButtonGroup lang='eng'
					size='small'
					divider={true}
					label={({eng: 'Rotate'})}
					items={rotateTransList}
				/>
			</FormControl>}
		<FormControl>
			<SelectEnum
				size='small'
				id="projection"
				value={projection.type}
				label={({eng: 'View Mode'})}
				items={projectionTransList}
				update={modifyUpdater(projectionTag, onProjectionChange)}
			/>
		</FormControl>
	</>;
};

const lengthUnitTransList: TranslationList<LengthUnit> = [{key: 'inch', names: { eng: 'inch' }}, {key: 'mm', names: { eng: 'mm' }}];
const angleTransList: TranslationList<AngleLocation> = [{key: 'interior', names: { eng: 'interior' }}, {key: 'exterior', names: { eng: 'exterior' }}];

export const LengthUnitControl = (ps: LengthUnitProps) =>
	<FormControl>
		<SelectEnum
			size='small'
			value={ps.lengthUnit}
			label={({eng: 'Unit'})}
			items={lengthUnitTransList}
			update={ps.onLengthUnitChange}
		/>
	</FormControl>;

export const AngleLocationControl = (ps: AngleLocationProps) =>
	<FormControl>
		<SelectEnum
			size='small'
			id="control-angle-location-label"
			value={ps.angleLocation}
			label={({eng: 'Angles'})}
			items={angleTransList}
			update={ps.onAngleLocationChange}
		/>
	</FormControl>;


export const OpeningModeControl = (ps: OpeningModeProps) => {
	const modeTransList: QButtonGroupItem<OpeningMode>[] = [
		{ key: 'Wall', names: { eng: 'Wall' }, update: () => ps.onOpeningModeChange(() => 'Wall') },
		{ key: 'Panel', names: { eng: 'Panel' }, update:() => ps.onOpeningModeChange(() => 'Panel') }
	];
	return (<FormControl>
		<QButtonGroup lang='eng'
			size='small'
			items={modeTransList}
			value={ps.mode}
		/>
	</FormControl>);
};

export const UndoButton = (ps: UndoButtonProps) =>
	<Button variant='outlined'
		onClick={() => ps.onUndo()}
		sx={{ margin: '12px 10px', fontSize: '16px', textTransform: 'none' }}>
		<Undo sx={{ marginRight: '8px' }}/>Undo
	</Button>;

export default ProjectionControl;
