import { Box, Button, Collapse, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { constant, flow, pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import * as A from 'fp-ts/lib/Array';
import * as React from 'react';
import { Circular, Curvature, Directed, Major, MajorMeasure, Measurement, Minor, MinorMeasure, Angle, Mag } from '~/src/design/opening/measurement';
import { measureMajor, majorValue, measureMinor, curvatureValue, major, measureMag, definedCurvature, definedMinorMeasurement, measureAngle, minorPri, majorPri, magValue, angleValue, minorValue, majorMajorMeasure, magMajorMeasure, angleMinorMeasure, minorMinorMeasure, minorMeasure } from '~/src/design/opening/measurement/optics';
import ToggleButtonLayout from '~/src/ui/input/ToggleButtonLayout';
import { Update } from '~/src/base/Function';
import { NumberEdit } from '~/src/edit/Number';
import { checkStringEquality, getClearPriority } from '~/src/edit/util';
import * as B from 'fp-ts/lib/boolean';
import useToggle from '~/src/hooks/useToggle';
import { QAccordionTitleValueSummary } from '~/src/ui/QAccordion';

import { modifyUpdater, updateUpdater } from '~/src/edit/util';
import { ViewProps } from '~/src/view/types';
import { EditProps, UpdateProps } from '~/src/edit/types';
import { useConverterFor } from '~/src/user/units';

const QCollapse = styled(Collapse)({
	minHeight: 'unset!important',
});

export interface MeasurementViewProps<S extends keyof Directed> extends ViewProps<Measurement<S>> { }

export interface MeasurementEditProps<S extends keyof Directed> extends EditProps<Measurement<S>> { }

// Curvature can be either `Circular` or `Sinusoidal`
const handleCurvatureType = <S extends keyof Directed>(update: Update<Measurement<S>>) => (
	_: React.MouseEvent<HTMLElement>,
	newType: 'None' | 'Circular' | 'Sinusoidal',
) => {
	switch (newType) {
	case ('Circular'): {
		// update circular
		update((prev) => pipe(
			definedCurvature<S>().getOption(prev),
			O.fold(
				() => ({ ...prev, Curvature: { type: 'Circular', Deflection: 0 } }),
				(a) => ({ ...prev, Curvature: { type: 'Circular', Deflection: curvatureValue.get(a) } }),
			)
		)
		);
		break;
	}
	case 'Sinusoidal': {
		// update Sinusoidal
		update((prev) => pipe(
			definedCurvature<S>().getOption(prev),
			O.fold(
				() => ({ ...prev, Curvature: { type: 'Sinusoidal', Deflection: 0 } }),
				(a) => ({ ...prev, Curvature: { type: 'Sinusoidal', Deflection: curvatureValue.get(a) } }),
			)
		)
		);
		break;
	}
	case 'None': {
		update((prev) => ({ ...prev, Curvature: undefined }));
	}
	}
};

// Measurement can be either `Major`, `Mag`, `undefined`
const handleMajorType = <S extends keyof Directed>(measurement: Measurement<S>) => (update: Update<MajorMeasure>) => (
	_: React.MouseEvent<HTMLElement>,
	newType: 'Major' | 'Magnitude',
) => {
	switch (newType) {
	case 'Major': {
		// update Major
		update(() => measureMajor<S>().get(measurement));
		break;
	}
	case 'Magnitude': {
		// update Magnitude
		update(() => measureMag<S>().get(measurement));
	}
	}
};

// Measurement can be either `Major`, `Mag`, `undefined`
const handleMinorType = <S extends keyof Directed>(measurement: Measurement<S>) => (update: Update<MinorMeasure>) => (
	_: React.MouseEvent<HTMLElement>,
	newType: 'Minor' | 'Angle',
) => {
	switch (newType) {
	case 'Minor': {
		// update Minor
		update(() => measureMinor<S>().get(measurement));
		break;
	}
	case 'Angle': {
		// update Angle
		update(() => measureAngle<S>().get(measurement));
		break;
	}
	}
};

const handleOutageType = (update: Update<MinorMeasure>) => (
	_: React.MouseEvent<HTMLElement>,
	newType: 0 | -1 | 1,
) => {
	switch (newType) {
	case 0: {
		update(minorMeasure.set(0));
		break;
	}
	case -1: {
		update(minorMeasure.modify((a) => a >= 0 ? a * -1 : a));
		break;
	}
	case 1: {
		update(minorMeasure.modify((a) => a <= 0 ? a * -1 : a));
	}
	}
};

const curvatureOption = (curvature: Curvature | undefined): O.Option<Curvature> => typeof curvature === 'undefined' ? O.none : O.some(curvature);

const handleClearMajorPriority = <S extends keyof Directed>(update: Update<Measurement<S>>) => () => pipe(
	majorPri<S>().modify(constant(getClearPriority())),
	update
);
const handleClearMinorPriority = <S extends keyof Directed>(update: Update<Measurement<S>>) => pipe(
	minorPri<S>().modify(constant(getClearPriority())),
	update
);

interface MajorEditProps extends EditProps<Major> {
}

function MajorEdit({
	update,
	value,
}: MajorEditProps) {
	return (
		<NumberEdit
			autoFocus
			unit='mm'
			min={0}
			value={majorValue.get(value)}
			update={modifyUpdater(majorValue, update)}
			tlabel={{eng: 'Major Value'}}
		/>
	);
}

interface MagnitudeEditProps extends EditProps<Mag> {
}

function MagnitudeEdit ({
	update,
	value,
}: MagnitudeEditProps) {
	return (
		<NumberEdit
			autoFocus
			unit='mm'
			min={0}
			value={magValue.get(value)}
			update={modifyUpdater(magValue, update)}
			tlabel={{eng: 'Magnitude Value'}}
		/>
	);
}

interface MajorMeasureEditProps extends EditProps<MajorMeasure> {
}

function MajorMeasureEdit({
	value,
	update,
}: MajorMeasureEditProps) {
	return Major.is(value) ? (
		<MajorEdit
			update={modifyUpdater(majorMajorMeasure, update)}
			value={value}
		/>
	) : (
		<MagnitudeEdit
			update={modifyUpdater(magMajorMeasure, update)}
			value={value}
		/>
	);
}

interface MeasurePickerProps<S extends keyof Directed> extends EditProps<Measurement<S>> { }

function MajorMeasurePicker<S extends keyof Directed>({
	update,
	value,
}: MeasurePickerProps<S>) {
	return (
		<ToggleButtonLayout htmlFor="major measurement type" label='Major Type'>
			<ToggleButtonGroup
				value={Major.is(major<S>().get(value)) ? 'Major' : 'Magnitude'}
				exclusive
				onChange={handleMajorType<S>(value)(modifyUpdater(major<S>(), update))}
				aria-label="major measurement type"
				id="major measurement type"
			>
				<ToggleButton value="Major" aria-label="Major">
		Major
				</ToggleButton>
				<ToggleButton value="Magnitude" aria-label="Magnitude">
		Magnitude
				</ToggleButton>
			</ToggleButtonGroup>
		</ToggleButtonLayout>
	);
}

function ClearMajorPriorityButton<S extends keyof Directed>({
	update,
}: UpdateProps<Measurement<S>>) {
	return (
		<Button variant='text' onClick={flow(handleClearMajorPriority(update))}>Clear Major Priority</Button>
	);
}

function ClearMinorPriorityButton<S extends keyof Directed>({
	update,
}: UpdateProps<Measurement<S>>) {
	return (
		<Button variant='text' onClick={() => handleClearMinorPriority(update)}>Clear Minor Priority</Button>
	);
}

interface MinorAngleEdit extends EditProps<Angle> {
}

function MinorAngleEdit<S extends keyof Directed> ({
	update,
	value
}: MinorAngleEdit) {
	return (
		<NumberEdit
			unit='deg'
			value={angleValue.get(value)}
			update={modifyUpdater(angleValue, update)}
			tlabel={{eng: 'Angle Value'}}
			sx={{ width: '180px', mr: 3.5 }}
		/>
	);
}

interface MinorEditProps extends EditProps<Minor> {
}

function MinorEdit({
	update,
	value: minor
}: MinorEditProps) {
	return (
		<NumberEdit
			unit='mm'
			value={minorValue.get(minor)}
			update={modifyUpdater(minorValue, update)}
			tlabel={{eng: 'Outage'}}
			sx={{ width: '180px', mr: 3.5 }}
		/>
	);
}

interface MinorMeasureEditProps<S extends keyof Directed> extends EditProps<MinorMeasure> {
	measurementDir: Directed[S]
}

function
MinorMeasureEdit<S extends keyof Directed>({
	value,
	update,
	measurementDir
}: MinorMeasureEditProps<S>) {
	return <Box display='flex' alignItems='flex-start'>
		{ Angle.is(value) ? (
			<MinorAngleEdit
				value={value}
				update={modifyUpdater(angleMinorMeasure, update)}
			/>
		) : (
			<MinorEdit
				value={value}
				update={modifyUpdater(minorMinorMeasure, update)}
			/>
		) }
		<MinorOutagePicker update={update} value={value} measurementDir={measurementDir} />
	</Box>;
}

function MinorMeasurePicker<S extends keyof Directed>({
	update,
	value,
}: MeasurePickerProps<S>) {
	return <ToggleButtonLayout htmlFor="minor measurement outage direction" label='Minor Type'>
		<ToggleButtonGroup
			value={pipe(
				definedMinorMeasurement<S>().get(value),
				(a) => Minor.is(a) ? 'Minor' : 'Angle'
			)}
			exclusive
			onChange={handleMinorType<S>(value)(modifyUpdater(definedMinorMeasurement<S>(), update))}
			aria-label="minor measurement outage direction"
			id="minor measurement outage direction"
		>
			<ToggleButton value="Minor" aria-label="Minor">
	Minor
			</ToggleButton>
			<ToggleButton value="Angle" aria-label="Angle">
	Angle
			</ToggleButton>
		</ToggleButtonGroup>
	</ToggleButtonLayout>;
}

interface MinorOutagePickerProps<S extends keyof Directed> extends EditProps<MinorMeasure> {
	measurementDir: Directed[S]
}

const isVerticalCurb: <S extends keyof Directed>(dir: Directed[S]) => boolean = <S extends keyof Directed>(dir: Directed[S]) => pipe(
	['up', 'down'],
	A.some(
		(verticalDir) => checkStringEquality(O.some(dir), O.some(verticalDir))
	)
);

const OutageIcon = ({ src }: { src: string }) => <img src={src} height='22px' width='30px' />;

function MinorOutagePicker<S extends keyof Directed>({
	update,
	value,
	measurementDir
}: MinorOutagePickerProps<S>) {
	return <ToggleButtonLayout htmlFor="minor measurement outage direction" label='Outage Direction'>
		<ToggleButtonGroup
			value={pipe(
				minorMeasure.get(value),
				(a) => a === 0 ? a : a > 0 ? 1 : -1
			)}
			exclusive
			onChange={handleOutageType(update)}
			aria-label="minor measurement outage direction"
			id="minor measurement outage direction"
		>
			<ToggleButton sx={{ px: 1 }} value={0} aria-label='zero'>
				{pipe(
					measurementDir,
					isVerticalCurb,
					B.match(
						constant(<OutageIcon src='/img/opening/outage-horizontal-0.svg' />),
						constant(<OutageIcon src='/img/opening/outage-vertical-0.svg' />)
					)
				)}
			</ToggleButton>
			<ToggleButton sx={{ px: 1 }} value={-1} aria-label="negative">
				{pipe(
					measurementDir,
					isVerticalCurb,
					B.match(
						constant(<OutageIcon src='/img/opening/outage-horizontal-1.svg' />),
						constant(<OutageIcon src='/img/opening/outage-vertical-2.svg' />)
					)
				)}
			</ToggleButton>
			<ToggleButton sx={{ px: 1 }} value={1} aria-label="positive">
				{pipe(
					measurementDir,
					isVerticalCurb,
					B.match(
						constant(<OutageIcon src='/img/opening/outage-horizontal-2.svg' />),
						constant(<OutageIcon src='/img/opening/outage-vertical-1.svg' />)
					)
				)}
			</ToggleButton>
		</ToggleButtonGroup>
	</ToggleButtonLayout>;
}

function CurvatureEdit<S extends keyof Directed>({
	update,
	value,
}: MeasurementEditProps<S>) {
	return pipe(
		definedCurvature<S>().getOption(value),
		O.fold(
			() => null,
			(a) => (
				<NumberEdit
					unit='mm'
					value={curvatureValue.get(a)}
					update={modifyUpdater(definedCurvature<S>().composeLens(curvatureValue), update)}
					tlabel={{eng: 'Curvature Value'}}
				/>
			)
		)
	);
}

function CurvaturePicker<S extends keyof Directed>({
	value,
	update,
}: EditProps<Measurement<S>>) {
	return (
		<ToggleButtonLayout htmlFor="type of curvature" label='Curvature'>
			<ToggleButtonGroup
				value={pipe(
					value.Curvature,
					curvatureOption,
					O.fold(
						() => 'None',
						(a) => Circular.is(a) ? 'Circular' : 'Sinusoidal')
				)}
				exclusive
				onChange={handleCurvatureType<S>(update)}
				aria-label="type of curvature"
				id="type of curvature"
			>
				<ToggleButton value="None" aria-label="None">
			None
				</ToggleButton>
				<ToggleButton value="Circular" aria-label="Circular">
			Circular
				</ToggleButton>
				<ToggleButton value="Sinusoidal" aria-label="Sinusoidal">
			Sinusoidal
				</ToggleButton>
			</ToggleButtonGroup>
		</ToggleButtonLayout>
	);
}

const ToggleAdvancedButton = ({
	show,
	toggle
}: { show: boolean, toggle: () => void }) => {
	const handleClick = () => toggle();
	return (
		<Box textAlign='right'>
			<Button
				sx={{ textDecoration: 'underline', display: 'inline-flex', fontSize: '0.75rem'}}
				onClick={handleClick}>
				{show ? 'Hide' : 'Show'} Advanced
			</Button>
		</Box>
	);
};

export function MeasurementEdit<S extends keyof Directed>({
	value,
	update,
}: MeasurementEditProps<S>) {
	const { toggle: toggleAdvanced, value: showAdvanced } = useToggle();
	return <>
		<ToggleAdvancedButton show={showAdvanced} toggle={toggleAdvanced} />
		<QCollapse orientation="vertical" in={showAdvanced}>
			<MajorMeasurePicker
				update={update}
				value={value}
			/>
		</QCollapse>
		<MajorMeasureEdit
			update={modifyUpdater(major<S>(), updateUpdater(majorPri<S>().set(1), update))}
			value={major<S>().get(value)}
		/>
		<QCollapse orientation="vertical" in={showAdvanced}>
			<ClearMajorPriorityButton update={update} />
			<MinorMeasurePicker
				update={update}
				value={value}
			/>
		</QCollapse>
		<MinorMeasureEdit
			value={definedMinorMeasurement<S>().get(value)}
			update={modifyUpdater(definedMinorMeasurement<S>(), updateUpdater(minorPri<S>().set(1), update))}
			measurementDir={value.Direction}
		/>
		<QCollapse orientation="vertical" in={showAdvanced}>
			<ClearMinorPriorityButton update={update} />
			<CurvaturePicker
				update={update}
				value={value}
			/>
			<CurvatureEdit
				update={update}
				value={value}
			/>
		</QCollapse>
	</>;
}

export const MeasurementSummary = <S extends keyof Directed>({
	value,
}: MeasurementViewProps<S>): React.ReactElement => {
	const convert = useConverterFor('mm');
	return <QAccordionTitleValueSummary
		title='Measurement'
		value={pipe(
			value,
			(m) => measureMajor<S>().composeLens(majorValue).get(m),
			convert.build,
		)} />;
};
