import { flow, constant, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as Id from 'fp-ts/lib/Identity';
import { Translations } from '~/src/model/Language';

import { Template, Variable } from '~/src/design/template';
import { ClientTemplateMod, Mode, modeNames } from '~/src/admin/template/mod';

import { variableRangeRangeMin, variableRangeRangeMax } from '~/src/design/template/variable/optics';
import { optionalSpaceRank, optionalTemplateModRange, optionalVariableRangeMax, optionalVariableRangeMin, optionalVariableRangeStep, spaceName, spaceNameTranslation, spaceRank, spaceRestrictSpace, variableNames, variableNameTranslation, variableRange, variableSelection } from '~/src/admin/template/mod/inner-optics';
import { id, modBaseId, mode, imageChange, namesChange, modTemplate, modSpaceEntry, modSpaceSelection, idId, clientModVariableRange } from '~/src/admin/template/mod/optics';
import { transTo, nameChangeTranslations } from '~/src/optics/Language';

import * as React from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import SelectEnum from '~/src/edit/SelectEnum';

import { Mod, Update } from '~/src/base/Function';

// Styles

import './edit.css';
import { VariableNames, VariableRange } from '~/src/admin/template/mod/inner';
import { isModifiedNumber, isModifiedString, showModifiedMessage, sortNonEmptyNumberAsc, textValue } from '~/src/edit/util';
import { optionalRangeMax, optionalRangeMin, optionalRangeStep, rangeRangeOptional } from '~/src/design/template/variable/optics';
import SetOptions from '~/src/admin/template/mod/option/edit-set';
import SpaceEntry from '~/src/admin/template/mod/space/edit';
import ModTextField from '~/src/ui/input/ModTextField';
import { RangeRange, RangeSet } from '~/src/design/template/variable';
import { NonEmpty } from '~/src/model/Boxes';
import ModVariableRank from '~/src/admin/template/mod/variable/edit-rank';
import { optionTranslations } from '~/src/design/template/option/optics';
import QAccordion from '~/src/ui/QAccordion';

export interface ClientTemplateModProps {
	templates: Template[];
	modTemplates: Template[];
	mod: ClientTemplateMod;
	update: Update<ClientTemplateMod>;
}

const findTemplate = (ps: ClientTemplateModProps): Template|undefined => {
	const base = modBaseId.get(ps.mod);
	return (base._ === 'Template' ? ps.templates : ps.modTemplates).find(t => t.Id === base.Id);
};

const checkValue = (ev: React.ChangeEvent<HTMLInputElement>): {v: string, c: boolean} =>
	({v: ev.target.value, c: ev.target.checked});

const sliderValue = (_ev: React.SyntheticEvent | Event, newValue: number | number[]): number =>
	typeof newValue === 'number' ? newValue : Math.min(...newValue);

/**
 * Encodes a RangeRange with a default `Step` value
 * using NonEmpty<number> to fill in `Max` and `Min`
 *
 * @param arr NonEmpty<number>, ideally sorted with length > 1
 * @returns RangeRange
 */
const rangeRangeFromNonEmpty = (arr: NonEmpty<number>) => RangeRange.encode({
	type: 'Range',
	Min: NEA.head(arr),
	Max: NEA.last(arr),
	Step: 0.025
});

/**
 * Encode a RangeSet using the `Min` and `Max` values of
 * a RangeRange as only two entries of Set
 *
 * @param range RangeRange to convert to RangeSet
 */
const rangeSetFromRangeRange = (range: RangeRange) => RangeSet.encode({
	type: 'Set',
	Set: [variableRangeRangeMin.get(range), variableRangeRangeMax.get(range)]
});

/**
 * Sorts a NonEmpty<number> then encodes `RangeRange` object
 */
const unsortedNeaToRangeRange = flow(
	sortNonEmptyNumberAsc,
	rangeRangeFromNonEmpty
);

export const Options = (ps: ClientTemplateModProps): React.ReactElement[] | null => {
	const { ModTemplate } = ps.mod;
	const template = findTemplate(ps);

	const handleSpaceNameChange = (label: string) => (value: string): (s: ClientTemplateMod) => ClientTemplateMod =>
		modTemplate.modify(spaceName.modify(constant(({ ...spaceName.get(ModTemplate), [label]: {'eng': value} }))));

	const handleSpaceRankChange = (space: string) => (rank: string): (s: ClientTemplateMod) => ClientTemplateMod =>
		modTemplate.modify(spaceRank.modify(constant(({ ...spaceRank.get(ModTemplate), [space]: rank ? Number(rank) : undefined }))));

	return template === undefined ? null : template.Opts.map((space) =>
		<Grid item key={space.Label} xs={12}>
			<Paper elevation={2} sx={{ p: 2, m: 1 }}>
				<FormControlLabel
					control={<Checkbox
						checked={modTemplate.compose(spaceRestrictSpace(space.Label)).get(ps.mod)}
						onChange={(ev) => ps.update(modTemplate.compose(spaceRestrictSpace(space.Label)).set(ev.target.checked))}
					/>}
					label={space.Names.eng ?? space.Label}
				/>
				<ModTextField
					value={spaceNameTranslation(space.Label).compose(transTo('eng')).get(spaceName.get(ModTemplate))}
					onChange={flow(textValue, (x) => handleSpaceNameChange(space.Label)(x), ps.update)}
					helperText={pipe(
						isModifiedString,
						Id.ap(O.some(optionTranslations.compose(transTo('eng')).get(space))),
						Id.ap(spaceName.composeOptional(nameChangeTranslations(space.Label)).composeLens(transTo('eng')).getOption(ModTemplate)),
						showModifiedMessage
					)}
					label={`Name: ${space.Names.eng || space.Label}`} variant="outlined" />
				<ModTextField
					value={pipe(optionalSpaceRank(space.Label).getOption(ModTemplate), O.toNullable)}
					label={`Display Rank: ${space.Rank || 'none'}`}
					inputProps={{ inputMode: 'numeric' }}
					helperText={pipe(
						isModifiedNumber,
						Id.ap(O.fromNullable(space.Rank)),
						Id.ap(optionalSpaceRank(space.Label).getOption(ModTemplate)),
						showModifiedMessage
					)}
					onChange={flow(textValue, (x) => handleSpaceRankChange(space.Label)(x), ps.update)}
				/>
				<Box my={2}>
					<Typography color='GrayText' variant='caption'>Entry Values</Typography>
					<Stack pl={0} mt={0} flexWrap='wrap' component='ul' direction="row">
						{space.Range.map((entry) => (
							<SpaceEntry
								key={entry.Names.eng}
								entry={entry}
								spaceName={space.Label}
								templateMod={ModTemplate}
								update={ps.update}
							/>
						))}
					</Stack>
				</Box>
				<QAccordion
					panelName={space.Label}
					summary={(
						<Grid container spacing={1} direction="row">
							{ space.Range.filter(opt => modSpaceEntry(space.Label)(opt.Label).get(ps.mod)).map((opt,i) =>
								<Grid item key={i} xs="auto">
									<Typography key={opt.Label} sx={{ paddingRight: '1ex', color: modSpaceSelection(space.Label).get(ps.mod) === opt.Label ? 'text.primary' : 'text.secondary'}}> {opt.Names.eng ?? opt.Label} </Typography>
								</Grid>
							) }
						</Grid>
					)}
					details={(
						<RadioGroup
							key={space.Label}
							value={modSpaceSelection(space.Label).get(ps.mod) ?? ''}
						>
							{space.Range.map((opt) =>
								<Grid container key={opt.Label}>
									<Grid item xs={12}>
										<Radio value={opt.Label} onClick={() => ps.update(modSpaceSelection(space.Label).modify(a => a === opt.Label ? undefined : opt.Label))} />
										<Checkbox checked={modSpaceEntry(space.Label)(opt.Label).get(ps.mod)} value={opt.Label} onChange={flow(checkValue, (r) => modSpaceEntry(space.Label)(opt.Label).modify(constant(r.c)), ps.update)} />
										<span>{opt.Names.eng ?? opt.Label}</span>
									</Grid>
								</Grid>)}
						</RadioGroup>
					)}
				/>
			</Paper>
		</Grid>);
};

export const Variables = (ps: ClientTemplateModProps): React.ReactElement[] | null => {
	const { update } = ps;
	const { ModTemplate } = ps.mod;

	const variableUpdate = (label: string) => (val: number): (s: ClientTemplateMod) => ClientTemplateMod =>
		modTemplate.modify(variableSelection.modify(constant(({ ...variableSelection.get(ModTemplate), [label]: val }))));

	const handleVariableNameChange = (label: string) => (value: string): (s: ClientTemplateMod) => ClientTemplateMod =>
		modTemplate.modify(variableNames.modify(constant(({ ...variableNames.get(ModTemplate), [label]: {'eng': value} }))));

	const handleVariableRangeMaxChange = (variable: Variable) => (value: string): (s: ClientTemplateMod) => ClientTemplateMod =>
		modTemplate.modify(
			variableRange.modify(constant({
				...O.toUndefined(variableRange.getOption(ModTemplate)),
				[variable.Label]: {
					type: 'Range', Min: 0, Step: 0, // Default values that shouldn't end up in object
					...O.toUndefined(rangeRangeOptional.getOption(variable.Range)), // Existing variable range
					...O.toUndefined(optionalTemplateModRange(variable.Label).getOption(ModTemplate)), // Existing range mods
					Max: Number(value),
				}
			}))
		);

	const handleVariableRangeMinChange = (variable: Variable) => (value: string): (s: ClientTemplateMod) => ClientTemplateMod =>
		clientModVariableRange.modify(constant({
			...O.toUndefined(variableRange.getOption(ModTemplate)),
			[variable.Label]: {
				type: 'Range', Max: 0, Step: 0, // Default values that shouldn't end up in object
				...O.toUndefined(rangeRangeOptional.getOption(variable.Range)), // Existing variable range
				...O.toUndefined(optionalTemplateModRange(variable.Label).getOption(ModTemplate)), // Existing range mods
				Min: Number(value),
			}
		}));


	const handleVariableRangeStepChange: (variable: Variable) => (value: string) => VariableRange = (variable: Variable) => (value: string) => ({
		...O.toUndefined(variableRange.getOption(ModTemplate)),
		[variable.Label]: {
			type: 'Range', Max: 0, Min: 0, // Default values that shouldn't end up in object
			...O.toUndefined(rangeRangeOptional.getOption(variable.Range)), // Existing variable range
			...O.toUndefined(optionalTemplateModRange(variable.Label).getOption(ModTemplate)), // Existing range mods
			Step: Number(value),
		}
	});

	const convertToSet = (variableLabel: string) => (range: RangeRange) => clientModVariableRange.modify(constant(({
		...O.toUndefined(variableRange.getOption(ModTemplate)),
		[variableLabel]: pipe(range, rangeSetFromRangeRange)
	})));

	const convertToRange = (variableLabel: string) => (nea: NonEmpty<number>) => clientModVariableRange.modify(constant(({
		...O.toUndefined(variableRange.getOption(ModTemplate)),
		[variableLabel]: pipe(nea, unsortedNeaToRangeRange)
	})));

	const Range = (variable: Variable) => {
		const range = variable.Range;

		const possibleModifiedRange = optionalTemplateModRange(variable.Label).getOption(ModTemplate);

		const relevantRange = pipe(
			possibleModifiedRange,
			O.getOrElse(constant(range))
		);

		switch (relevantRange.type) {
		case 'Set':
			return <Grid item xs={11}>
				<Slider
					defaultValue={(ModTemplate.VariableSelection ?? {})[variable.Label]}
					step={null}
					size="small"
					marks={relevantRange.Set.map((x) => ({ value: x }))}
					onChangeCommitted={flow(sliderValue, (x) => variableUpdate(variable.Label)(x), ps.update)} />
				<SetOptions
					variable={variable}
					range={relevantRange}
					templateMod={ModTemplate}
					update={ps.update}
				/>
				<Button size='small' onClick={flow(
					() => convertToRange(variable.Label)(relevantRange.Set),
					ps.update)
				} variant='text'>Convert to Range</Button>
			</Grid>;
		case 'Range':
			return <Grid item xs={11}>
				<Slider
					defaultValue={(ModTemplate.VariableSelection ?? {})[variable.Label]}
					valueLabelFormat={(val: number) => val.toString()}
					valueLabelDisplay="auto"
					marks={[relevantRange.Min, relevantRange.Max].map((x) => ({ value: x, label: x.toString() }))}
					min={relevantRange.Min}
					max={relevantRange.Max}
					step={relevantRange.Step}
					onChangeCommitted={flow(sliderValue, (x) => variableUpdate(variable.Label)(x), ps.update)} />
				<ModTextField
					value={variableNameTranslation(variable.Label).compose(transTo('eng')).get(variableNames.get(ModTemplate))}
					onChange={flow(textValue, (x) => handleVariableNameChange(variable.Label)(x), update)}
					label={`Name: ${variable.Names.eng || variable.Label}`} variant="outlined" />
				<ModVariableRank
					variableLabel={variable.Label}
					currentRank={variable.Rank}
					ModTemplate={ModTemplate}
					update={ps.update}
				/>
				<Box>
					<ModTextField
						value={pipe(optionalVariableRangeMax(variable.Label).getOption(ModTemplate), O.toUndefined)}
						label={`Max: ${pipe(optionalRangeMax.getOption(variable.Range), O.toNullable)}`}
						inputProps={{ inputMode: 'numeric' }}
						helperText={pipe(
							isModifiedNumber,
							Id.ap(optionalRangeMax.getOption(variable.Range)),
							Id.ap(optionalVariableRangeMax(variable.Label).getOption(ModTemplate)),
							showModifiedMessage
						)}
						onChange={flow(textValue, (x) => handleVariableRangeMaxChange(variable)(x), ps.update)}
					/>
					<ModTextField
						value={pipe(optionalVariableRangeMin(variable.Label).getOption(ModTemplate), O.toUndefined)}
						label={`Min: ${pipe(optionalRangeMin.getOption(variable.Range), O.toNullable)}`}
						inputProps={{ inputMode: 'numeric' }}
						helperText={pipe(
							isModifiedNumber,
							Id.ap(optionalRangeMin.getOption(variable.Range)),
							Id.ap(optionalVariableRangeMin(variable.Label).getOption(ModTemplate)),
							showModifiedMessage
						)}
						onChange={flow(textValue, (x) => handleVariableRangeMinChange(variable)(x), ps.update)}
					/>
					<ModTextField
						value={pipe(optionalVariableRangeStep(variable.Label).getOption(ModTemplate), O.toUndefined)}
						label={`Step: ${pipe(optionalRangeStep.getOption(variable.Range), O.toNullable)}`}
						type='number'
						inputProps={{
							step: 0.0025,
							min: 0
						}}
						helperText={pipe(
							isModifiedNumber,
							Id.ap(optionalRangeStep.getOption(variable.Range)),
							Id.ap(optionalVariableRangeStep(variable.Label).getOption(ModTemplate)),
							showModifiedMessage
						)}
						onChange={flow(textValue, (x) => handleVariableRangeStepChange(variable)(x), clientModVariableRange.set, ps.update)}
					/>
				</Box>
				<Button size='small' onClick={flow(
					() => convertToSet(variable.Label)(relevantRange),
					ps.update)} variant='text'>Convert to Set</Button>
			</Grid>;
		}
	};

	interface VariableParams {
		variable: Variable;
	}

	const Variable = (ps: VariableParams): React.ReactElement => <Paper elevation={2} sx={{ p: 2, m: 1 }}>
		<Grid item>
			<Box>{ps.variable.Names.eng ?? ps.variable.Label}</Box>
		</Grid>
		<Grid item>
			{Range(ps.variable)}
		</Grid>
	</Paper>;

	const template = findTemplate(ps);
	return template === undefined ? null : template.Vars.map((opt) => <Variable key={opt.Label} variable={opt} />);
};

export const getVariableName: (vn: VariableNames) => O.Option<Translations> = (modNames: VariableNames) => modNames ? O.some(modNames) : O.none;

export const FormFields = (ps: ClientTemplateModProps): React.ReactElement => {
	const { Id, Mode, ImageChange } = ps.mod;

	return <Paper elevation={2} sx={{ p: 2, m: 1 }}>
		<Grid container direction="row" justifyContent="space-evenly" spacing={1}>
			<Grid item xs={12} sm={4} md={2} lg={12}>
				<TextField value={ps.mod.NamesChange.eng ?? ''} fullWidth label="Template Name" variant="outlined"
					onChange={flow(textValue, constant, (x) => namesChange.compose(transTo('eng')).modify(x), ps.update)} />
			</Grid>
			<Grid item xs={12} sm={4} md={2} lg={12}>
				<TextField value={Id} fullWidth label="Template Id" variant="outlined"
					onChange={flow(textValue, constant, (x) => id.modify(x), ps.update)} />
			</Grid>
			<Grid item xs={12} sm={4} md={2} lg={12}>
				<FormControl fullWidth>
					<SelectEnum
						id="BaseId"
						value={modBaseId.compose(idId()).get(ps.mod)}
						label={({eng: 'Base Template'})}
						items={[...ps.templates, ...ps.modTemplates].map((t) => ({key: t.Id, names: t.Names}))}
						update={(f: Mod<string>) => {
							const Id = f('');
							const _ = ps.templates.find(t => t.Id === Id) !== undefined ? 'Template' : 'Mod';
							ps.update(modBaseId.set({_, Id}));
						}} />
				</FormControl>
			</Grid>
			<Grid item xs={12} sm={4} md={2} lg={12}>
				<FormControl fullWidth>
					<SelectEnum
						id="Mode"
						value={Mode}
						label={({eng: 'Design Mode'})}
						items={modeNames}
						update={(f: Mod<Mode>) => ps.update(mode.modify(f))} />
				</FormControl>
			</Grid>
			<Grid item xs={12} sm={4} md={2} lg={12}>
				<TextField value={ImageChange ?? ''} fullWidth label="Override Image URL" variant="outlined"
					onChange={flow(textValue, constant, (x) => imageChange.modify(x), ps.update)} />
			</Grid>
		</Grid>
	</Paper>;
};

export const ClientTemplateModEditor = (ps: ClientTemplateModProps): React.ReactElement => {
	return (
		<Grid justifyContent="space-evenly" container spacing={1}>
			<Grid item xs={12} lg={3}>
				{FormFields(ps)}
			</Grid>
			<Grid item xs={12} lg={6}>
				<Grid container direction="row" spacing={1}>
					{Options(ps)}
				</Grid>
			</Grid>
			<Grid item xs={12} lg={3}>
				<Grid container direction="column" justifyContent="center">
					{Variables(ps)}
				</Grid>
			</Grid>
		</Grid>
	);
};
