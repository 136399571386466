/**
 * Check whether something is a boolean.
 */
export const isBool = (x: unknown): x is boolean => typeof x === 'boolean';

/**
 * Check whether something is null.
 */
export const isNull = (x: unknown): x is null => x === null;

/**
 * Check whether something is undefined.
 */
export const isUndefined = (x: unknown): x is undefined => x === undefined;

/**
 * Check whether something is a plain array.
 */
export const isArray = (x: unknown): x is unknown[] => Object.getPrototypeOf(x) === Array.prototype;

/**
 * Check whether something is a plain object.
 */
export const isObject = (x: unknown): x is {[k in string]?: unknown} => {
	const pt = Object.getPrototypeOf(x);
	return pt === Object.prototype || pt === null;
};

/**
 * Check whether something is a Date object.
 */
export const isDate = (x: unknown): x is Date => Object.getPrototypeOf(x) === Date.prototype;
