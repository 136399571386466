import { LabelOrient, LineStyle, RulerInfo } from '~/src/figure/RulerInfo';
import { number, boolean, partial } from 'io-ts';
import { Optic, pdot } from '~/src/base/Optic';

export type RulerInfoMod = Partial<RulerInfo>;

export const RulerInfoMod = partial({
	Visible: boolean,
	BarStyle: LineStyle,
	ExtendWhisker: boolean,
	WhiskerStyle: LineStyle,
	Layer: number,
	LabelLayer: number,
	LabelOrient: LabelOrient,
});

export const _Visible: Optic<RulerInfoMod, boolean|undefined> =
	pdot('Visible');

export const _ExtendWhisker: Optic<RulerInfoMod, boolean|undefined> =
	pdot('ExtendWhisker');

export const _Layer: Optic<RulerInfoMod, number|undefined> =
	pdot('Layer');

export const _LabelLayer: Optic<RulerInfoMod, number|undefined> =
	pdot('LabelLayer');

export const defRulerInfoMod = (): RulerInfoMod => ({});

export const applyRulerInfoMod = (rim: RulerInfoMod) => (ri : RulerInfo) : RulerInfo => ({
	Visible: rim.Visible ?? ri.Visible,
	BarStyle: rim.BarStyle ?? ri.BarStyle,
	ExtendWhisker: rim.ExtendWhisker ?? ri.ExtendWhisker,
	WhiskerStyle: rim.WhiskerStyle ?? ri.WhiskerStyle,
	Layer: rim.Layer ?? ri.Layer,
	LabelLayer: rim.LabelLayer ?? ri.LabelLayer,
	LabelOrient: rim.LabelOrient ?? ri.LabelOrient,
});
