import { InputAdornment, TextFieldProps } from '@mui/material';
import { flow } from 'fp-ts/lib/function';
import * as React from 'react';
import { textValue } from '~/src/edit/util';
import { modTemplate } from '~/src/admin/template/mod/optics';
import { entryRank, modOptionRank, spaceEntryRanks } from '~/src/admin/template/mod/inner-optics';
import ModTextField from '~/src/ui/input/ModTextField';
import { EntryTextFieldProps } from '~/src/admin/template/mod/space/edit-name';
import CheckIconButton from '~/src/ui/input/CheckIconButton';

interface EntryRankTextFieldProps extends EntryTextFieldProps<number> {
	value: number
}

const submitMod = (spaceName: string, entryLabel: string, newValue: number) => modTemplate
	.composeOptional(modOptionRank)
	.composeLens(spaceEntryRanks(spaceName))
	.composeLens(entryRank(entryLabel))
	.set(newValue);

const handleClick = (spaceName: string, entryLabel: string, temporaryValue: number) => () => submitMod(spaceName, entryLabel, temporaryValue);

const EntryRankTextField: React.FC<EntryRankTextFieldProps & TextFieldProps> = ({
	value,
	update,
	spaceName,
	entryLabel,
	templateMod,
	...textFieldProps
}) => {
	const [temporaryValue, setTemporaryValue] = React.useState<number>(value);

	return (
		<ModTextField
			size='small'
			inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
			onChange={flow(textValue, Number, setTemporaryValue)}
			{...textFieldProps}
			value={temporaryValue} // Component value overrides passed in value
			InputProps={{
				endAdornment: <InputAdornment position='end'>
					<CheckIconButton
						onClick={flow(handleClick(spaceName, entryLabel, temporaryValue), update)}
						disabled={!temporaryValue}
					/>
				</InputAdornment>
			}}
		/>
	);
};

export default EntryRankTextField;
