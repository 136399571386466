
/**
 * Optics for Templates.
 *
 */
import { dot, each, Optic } from '~/src/base/Optic';

import { Template, Option, Variable, Group } from '~/src/design/template';
import { transLang } from '~/src/optics/Language';
import { Language, Translations } from '~/src/model/Language';
import { comp, Fn } from '~/src/base/Function';
import { mem } from '~/src/base/Memoize';

/**
 * Focus on the Names of a Template.
 *
 * @category Template
 */
export const templateNames: Optic<Template,Translations> = dot('Names');

/**
 * Focus on a particular Name Translation.
 *
 * @category Template
 */
export const templateName = (lang: Language): Optic<Template,string> => comp(templateNames, transLang(lang));

/**
 * Focus on the Options in a Template.
 *
 * @category Template
 */
export const templateOpts: Optic<Template,Option[]> = dot('Opts');

/**
 * Modify a particular option on a template.
 */
export const templateOpt: Fn<string,Optic<Template,Option>> = (label: string) => comp(templateOpts, each(matchesLabel(label)));

/**
 * Modify a particular variable on a template.
 */
export const templateVar: Fn<string,Optic<Template,Variable>> = (label: string) => comp(templateVars, each(matchesLabel(label)));

const matchesLabel = mem((label: string) => <T extends {Label: string}>(o: T): boolean => o.Label === label);

/**
 * Focus on the Variables in a Template.
 *
 * @category Template
 */
export const templateVars: Optic<Template,Variable[]> = dot('Vars');

/**
 * Focus on selection of an `Option`;
 *
 * @category Template
 */
export const optionSelection: Optic<Option,string> = dot('Value');

/**
 * Focus on the value of an `Variable`;
 *
 * @category Template
 */
export const variableValue: Optic<Variable,number> = dot('Value');

/**
 * Focus on the Names of a `Variable`;
 *
 * @category Template
 */
export const templateVariableNames: Optic<Variable,Translations> = dot('Names');

/**
 * Modify the Groups of a Template.
 */
export const templateGroups: Optic<Template,Group[]> = dot('Groups');

/**
 * Modify the Fold inside a Group.
 */
export const groupFold: Optic<Group,boolean> = dot('Fold');
