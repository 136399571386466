import Check from '@mui/icons-material/Check';
import { IconButton, IconButtonProps } from '@mui/material';

const CheckIconButton = (props: IconButtonProps) => (
	<IconButton
		sx={{ ml: '9px' }}
		size='small'
		color='primary'
		aria-label="update value"
		{...props}
	>
		<Check />
	</IconButton>

);

export default CheckIconButton;
