import * as React from 'react';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { TextEdit } from '~/src/edit/Text';
import { ViewProps } from '~/src/view/types';
import { Requestor } from '~/src/api/Requestor';
import { postPriceList } from '~/src/api/Pricing';

import { PriceListEdit } from '~/src/edit/PriceList';
import { ListId , PriceList } from '~/src/model/Pricing';

export const PriceListListPage = ( { value , edit } : ViewProps<Record<ListId, PriceList>> & { edit : ( lid : ListId ) => () => void } ) => {
	return (
		<>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>List Name</TableCell>
							<TableCell>Edit</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{ Object.entries( value )
							.map( ([_ , l] : [string, PriceList]) =>
								<TableRow key={l.Id}>
									<TableCell component='th' scope='row'>
										{l.Name}
									</TableCell>

									<TableCell>
										<Button variant='text' onClick={ edit( l.Id ) }>Edit</Button>
									</TableCell>
								</TableRow>
							)
						}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export const PriceListEditPage = ( { req , list } : { req : Requestor , list : PriceList } ) => {
	const [ priceList , setPriceList ] = React.useState( list );

	return <PriceListEdit req={req} value={ priceList } update={ f => setPriceList( f( priceList ) ) } />;
};

export const PriceListMakePage = ( { req , aok } : { req : Requestor , aok : () => void } ) => {
	const [ zone , setZone ] = React.useState<string>('');
	const [ name , setName ] = React.useState<string>('');
	const [ errs , setErrs ] = React.useState<string[]>([]);

	const alert =
		<Alert severity='error'>
			<AlertTitle>Errors</AlertTitle>
			<ul>
				{ errs.map( err => <li key={err}>{err}</li> ) }
			</ul>
		</Alert>;

	const handleCreate = () => {
		const errors : string[] = [];

		const hasSpace = (s : string) : boolean => /\s/.test(s);

		const newPriceList : PriceList = {
			Id : '',
			Zone : zone,
			Name : name,
			Code : '',
			Elems : [],
		};

		if (hasSpace(zone) || zone.length === 0) {
			errors.push('Invalid zone: "' + zone + '"');
		}

		if (hasSpace(name) || name.length === 0) {
			errors.push('Invalid name: "' + name + '"');
		}

		if (errors.length !== 0) {
			setErrs([...errors]);
			return;
		}

		postPriceList( req )( newPriceList )
			.then( () => {
				setErrs([]);
				aok();
			} )
			.catch( err => {
				setErrs( ['Unable to create new PriceList'] );
				console.error( err );
			} );
	};

	return (
		<Grid container spacing={2}>
			<Grid item>
				<Stack spacing={2}>
					{ !errs.length ? null : alert }
					<TextEdit value={ zone } update={ f => setZone( f( zone ) ) } label='Zone' />
					<TextEdit value={ name } update={ f => setName( f( name ) ) } label='Name' />
					<Button variant='outlined' onClick={handleCreate} >Create</Button>
				</Stack>
			</Grid>
		</Grid>
	);
};

