import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import { View } from '~/src/model/Tableau';
import { EditProps } from '~/src/edit/types';

export const ViewEdit = ( { value , update } : EditProps<View> ) => {
	const swapCols = () =>
		update( () => ({ Cols : value.Rows , Rows : value.Cols }) as View );

	const addToRows = (col : string) => {
		const newRows = [...value.Rows, col];
		const newCols = value.Cols.filter( c => c !== col);
		update( () => ({ Rows : newRows , Cols : newCols }) as View );
	};

	const addToCols = (row : string) => {
		const newRows = value.Rows.filter( r => r !== row);
		const newCols = [...value.Cols, row];
		update( () => ({ Rows : newRows , Cols : newCols }) as View );
	};

	return (
		<Box sx={{ flexGrow : 1 }}>
			<Grid container spacing={2}>
				<Grid item>
					<ButtonGroup size='small'>
						<Button key='rows' disabled>Rows:</Button>
						{ value.Rows.map( r => <Button key={r} onClick={ () => addToCols(r) }>{r}</Button> ) }
					</ButtonGroup>
				</Grid>

				<Grid item>
					<SwapHorizIcon onClick={swapCols} />
				</Grid>

				<Grid item>
					<ButtonGroup size='small'>
						<Button key='cols' disabled>Cols:</Button>
						{ value.Cols.map( c => <Button key={c} onClick={ () => addToRows(c) }>{c}</Button> ) }
					</ButtonGroup>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ViewEdit;
