import { useContext, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import { NamedTree } from '~/src/model/NamedTree';
import {
	BulkDesignContext,
	BULK_DESIGN_VIEW_MODE,
} from '~/src/design/bulk/store';
import TreeViewColumn from './TreeViewColumn';
import type { ColumnProp } from './TreeViewColumn';

const columns: ColumnProp[] = [
	{
		id: 'measurements',
		width: '141px',
		label: 'Measurements',
	},
	{
		id: 'shape',
		width: '87px',
		label: 'Shape',
	},
	{
		id: 'edge-finish',
		width: '120px',
		label: 'Edge Finish',
	},
	{
		id: 'tempered',
		width: '115px',
		label: 'Tempered',
	},
	{
		id: 'corners',
		width: '98px',
		label: 'Corners',
	},
	{
		id: 'radius',
		width: '90px',
		label: 'Radius',
	},
	{
		id: 'sku',
		width: '70px',
		label: 'SKU',
	},
	{
		id: 'price',
		width: '90px',
		label: 'Price',
	},
];

interface LeftBarProps {
	toggleEditMode: () => void;
	addTemplate: () => void;
	treeData: NamedTree<unknown>;
	duplicateItem: () => void;
}

function LeftBarHeader({
	toggleEditMode,
	duplicateItem,
	addTemplate,
	treeData,
}: LeftBarProps) {
	const { mainViewMode } = useContext(BulkDesignContext);

	const gridTemplateColumns = useMemo(() => {
		if (mainViewMode === BULK_DESIGN_VIEW_MODE.LIST_VIEW) {
			return `minmax(12px, 12px) 1fr repeat(${columns.length}, auto) 176px`;
		}
		return '12px 1fr auto';
	}, [mainViewMode]);

	return (
		<Box
			className="tree-view__head"
			style={{
				gridTemplateColumns,
			}}
		>
			{/* empty space with 12px width */}
			<Box className="index"></Box>
			{/* Project Name */}
			<Box className="tree-view__head-title">
				<Box
					component="span"
					className="title-icon"
				>
					<img src="/img/treeview/checklist.svg" alt="" />
				</Box>
				<Box component="span" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{treeData.Name}</Box>
			</Box>
			{/* Columns - visible only in itemized list view */}
			{mainViewMode === BULK_DESIGN_VIEW_MODE.LIST_VIEW && (
				<>
					{columns.map((column) => (
						<TreeViewColumn key={column.id} column={column} />
					))}
				</>
			)}
			{/* button group */}
			<Box className="tree-view__head-actions">
				<Box className="tree-view__head-btn">
					<Button onClick={() => addTemplate()}>
						<img src="/img/treeview/plus.svg" alt="" />
					</Button>
				</Box>
				<Box className="tree-view__head-btn">
					<Button onClick={() => toggleEditMode()}>
						<img src="/img/treeview/tick.svg" alt="" />
					</Button>
				</Box>
				<Box className="tree-view__head-btn">
					<Button>
						<img src="/img/treeview/disk.svg" alt="" />
					</Button>
				</Box>
				<Box className="tree-view__head-btn">
					<Button>
						<img src="/img/treeview/disk-with-arrow.svg" alt="" />
					</Button>
				</Box>
				<Box className="tree-view__head-btn">
					<Button onClick={() => duplicateItem()}>
						<img src="/img/treeview/copy.svg" alt="" />
					</Button>
				</Box>
				<Box className="tree-view__head-btn">
					<Button>
						<img src="/img/treeview/lock-icon.svg" alt="" />
					</Button>
				</Box>
			</Box>
		</Box>
	);
}

export default LeftBarHeader;
