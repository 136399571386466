import { useCallback, useRef, useState } from 'react';
import { Requestor } from '~/src/api/Requestor';
import BulkDesignPage from './bulk-design';
import type { BulkDesignRefProps } from './bulk-design';

import { literal, type, Type, union, unknown } from 'io-ts';
import { useMessage } from '~/src/hooks/useMessage';
import { Quote } from '~/src/pricing/quote';
import { NamedTree } from '~/src/model/NamedTree';
import { TokenSecret, isTokenSecret } from '~/src/model/Authentication';
import { CreateRequestor as GlassdRequestor } from '~/src/api/Requestor/Glassd';
import { Config } from '~/src/model/Config';

interface EmbedBulkDesignPageProps {
	config?: Config;
}

interface BulkDesignMessage {
	type: 'QUOTE' | 'BULK_DESIGN' | 'INIT_AUTH';
	data: Quote | NamedTree<unknown> | TokenSecret;
}

const BulkDesignMessage: Type<BulkDesignMessage> = type({
	type: union([literal('QUOTE'), literal('BULK_DESIGN'), literal('INIT_AUTH')]),
	data: union([Quote, NamedTree(unknown), TokenSecret]),
});

const EmbedBulkDesignPage = ({ config }: EmbedBulkDesignPageProps) => {
	const [req, setReq] = useState<Requestor | undefined>(undefined);
	const bulkDesignRef = useRef<BulkDesignRefProps>(null);

	const handleMessage = useCallback(
		({ data }: MessageEvent<BulkDesignMessage>) => {
			console.log('****** bulk message ', data);
			if (data.type === 'INIT_AUTH') {
				const auth = data.data as TokenSecret;
				if (isTokenSecret(auth) && config) {
					setReq(GlassdRequestor(config.SilicaEndpoint, auth));
				}
			} else if (data.type === 'QUOTE') {
				if (bulkDesignRef.current) {
					bulkDesignRef.current.updateQuote(data.data as Quote);
				}
			} else if (data.type === 'BULK_DESIGN') {
				// Do something here.
				if (bulkDesignRef.current) {
					bulkDesignRef.current.updateTreeData(data.data as NamedTree<unknown>);
				}
			}
		},
		[config]
	);

	useMessage(BulkDesignMessage, handleMessage);

	const onQuoteChange = useCallback((quote: Quote) => {
		const payload = {
			type: 'Save-Quote',
			data: quote,
		};
		if (window.top) {
			window.top.postMessage(payload, '*');
		} else if (window.parent) {
			window.parent.postMessage(payload, '*');
		}
	}, []);

	const onTreeDataChange = useCallback((tree: NamedTree<unknown>) => {
		const payload = {
			type: 'Save-Bulk-Design',
			data: tree,
		};
		if (window.top) {
			window.top.postMessage(payload, '*');
		} else if (window.parent) {
			window.parent.postMessage(payload, '*');
		}
	}, []);

	const onSaveChange = useCallback((tree: NamedTree<unknown>, quote: Quote) => {
		const payload = {
			type: 'Save-CE',
			data: {
				tree,
				quote,
			},
		};
		if (window.top) {
			window.top.postMessage(payload, '*');
		} else if (window.parent) {
			window.parent.postMessage(payload, '*');
		}
	}, []);

	const onCancel = useCallback(() => {
		// Do something here.
		const payload = {
			type: 'No-Save-CE',
		};
		if (window.top) {
			window.top.postMessage(payload, '*');
		} else if (window.parent) {
			window.parent.postMessage(payload, '*');
		}
	}, []);

	if (req) {
		return (
			<BulkDesignPage
				ref={bulkDesignRef}
				req={req}
				onQuoteChange={onQuoteChange}
				onTreeDataChange={onTreeDataChange}
				onSaveChange={onSaveChange}
				onCancel={onCancel}
			/>
		);
	}

	return <div> Loading… </div>;
};

export default EmbedBulkDesignPage;
