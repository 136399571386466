.selections {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.search {
  display: flex;
  flex-grow: 1;
  align-content: center;
}

.search, .edit {
  margin: auto 0 !important;
}
