/**
 * Optics for Range.
 *
 */
import { Lens, Optional, Traversal } from 'monocle-ts';
import { Range, RangeRange, RangeSet } from '~/src/design/template/variable';
import * as O from 'fp-ts/lib/Option';
import { getNumberPrism, nonEmptyNumberArrayTraversal } from '~/src/optics/Common';
import { NonEmpty } from '~/src/model/Boxes';

/**
 * Option for Range of type `Range`
 *
 * @category Range
 */
const getOptionRangeRange: (rs: Range) => O.Option<RangeRange> = (variableRange) => (variableRange.type === 'Range' ? O.some(variableRange) : O.none);
/**
 * Return RangeRange if the type is `Range`
 *
 * @category Range
 */
const setRangeRange: (rr: RangeRange) => (vr: Range) => Range = (rangeRange: RangeRange) => (range: Range) => (rangeRange.type === 'Range' ? rangeRange : range);
/**
 * Focus on optional RangeRange in Range
 *
 * @category Range
 */
export const rangeRangeOptional: Optional<Range, RangeRange> = new Optional<
	Range,
	RangeRange>(getOptionRangeRange, setRangeRange);

/**
 * Option for Range of type `Set`
 *
 * @category Range
 */
const getOptionRangeSet: (rs: Range) => O.Option<RangeSet> = (variableRange) => (variableRange.type === 'Set' ? O.some(variableRange) : O.none);
/**
 * Return RangeSet if the type is `Range`
 *
 * @category Range
 */
const setRangeSet: (rs: RangeSet) => (vr: Range) => Range = (rangeSet: RangeSet) => (range: Range) => (rangeSet.type === 'Set' ? rangeSet : range);
/**
 * Focus on optional RangeSet in Range
 *
 * @category Range
 */
export const rangeSetOptional: Optional<Range, RangeSet> = new Optional<
	Range,
	RangeSet>(getOptionRangeSet, setRangeSet);

/**
 * Focus on Set in RangeSet
 *
 * @category Range
 */
export const variableRangeSetSet: Lens<RangeSet, NonEmpty<number>> = Lens.fromProp<RangeSet>()('Set');

/**
 * Focus on Step in RangeRange
 *
 * @category Range
 */
export const variableRangeRangeStep: Lens<RangeRange, number> = Lens.fromProp<RangeRange>()('Step');

/**
 * Focus on optional minimum value in Range
 *
 * @category Range
 */
export const optionalRangeStep: Optional<Range, number> = rangeRangeOptional.composeLens(variableRangeRangeStep);

/**
 * Focus on Max in RangeRange
 *
 * @category Range
 */
export const variableRangeRangeMax: Lens<RangeRange, number> = Lens.fromProp<RangeRange>()('Max');

/**
 * Focus on Min in RangeRange
 *
 * @category Range
 */
export const variableRangeRangeMin: Lens<RangeRange, number> = Lens.fromProp<RangeRange>()('Min');

/**
 * Focus on optional minimum value in Range
 *
 * @category Range
 */
export const optionalRangeMin: Optional<Range, number> = rangeRangeOptional.composeLens(variableRangeRangeMin);

/**
 * Focus on optional maximum value in Range
 *
 * @category Range
 */
export const optionalRangeMax: Optional<Range, number> = rangeRangeOptional.composeLens(variableRangeRangeMax);

export const getNumberTraversal = (value: number): Traversal<RangeSet, number> =>
	variableRangeSetSet
		.composeTraversal(nonEmptyNumberArrayTraversal)
		.composePrism(getNumberPrism(value));
