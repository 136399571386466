import { useEffect, useState } from 'react';

import { Config } from '~/src/model/Config';
import { decodePromise, jsonPromise, nullablePromise } from '~/src/model/Util';
import { JWT, decode } from '~/src/model/Authentication';

import { Requestor } from '~/src/api/Requestor';
import { CreateRequestor } from '~/src/api/Requestor/Silica';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import './Auth0.css';
import { Paper, Typography } from '@mui/material';
import { xhr } from '~/src/api/xhr';
import { Fn } from '~/src/base/Function';

export interface LoginProps {
	config: Config;
	updateReq: (req: Requestor) => void;
}

export interface LoginState {
	user: string;
	password: string;
}

type OauthResponse = { access_token: string, refresh_token: string, expires_in: number, token_type: 'Bearer' };

const setReq = (config: Config, updateReq: Fn<Requestor,void>) => (jwt: JWT) => {
	sessionStorage.setItem('login', JSON.stringify(jwt));
	updateReq(CreateRequestor(config.SilicaEndpoint,jwt));
};

export default function LoginAuth0({config, updateReq}: LoginProps) {
	const [user, setUser] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		nullablePromise(sessionStorage.getItem('login'))
			.then(jsonPromise)
			.then(decodePromise(JWT))
			.then((jwt: JWT) => {
				const { Auth0 } = config;
				const jwtDecode = decode(jwt);

				const expt = jwtDecode?.exp ?? 0;
				const ctime = new Date().getTime() / 1000;
				const exp = expt - (ctime + (expt - ctime) / 2);

				if (exp < 0) {
					xhr('POST', `${Auth0.domain}/oauth/token`, {}, JSON.stringify({
						grant_type: 'refresh_token',
						client_id: Auth0.clientId,
						client_secret: Auth0.clientSecret,
						refresh_token: jwt.refreshToken,
					})).then(jsonPromise).then((o: object) => {
						const res = o as OauthResponse;
						setReq(config,updateReq)({ accessToken: res.access_token, refreshToken: res.refresh_token });
					});
				} else {
					setReq(config,updateReq)(jwt);
				}
			}).catch(_fail => {
				console.log('Login information out of date or unreadable.');
			});
	});
	function attemptLogin () {
		const { Auth0 } = config;

		xhr('POST', `${Auth0.domain}/oauth/token`, {
			'Content-Type': 'application/json'
		}, JSON.stringify({
			grant_type: 'password',
			username: user,
			password: password,
			client_id: Auth0.clientId,
			client_secret: Auth0.clientSecret,
			audience: Auth0.audience,
			scope: 'offline_access' // refresh token
		})).then(jsonPromise).then((o: object) => {
			const res = o as OauthResponse;
			setReq(config, updateReq)({ accessToken: res.access_token, refreshToken: res.refresh_token });
		}).catch((e) => {
			console.log(e);
			alert('Auth failed');
		});
	}

	return <Paper component='form' name='Login' id='Login' sx={{
		p: 3,
		width: '33%',
		margin: 'auto',
		mt: 3,
		['@media (max-width: 600px)']: {
			width: '80%',
		}
	}}>
		<Typography pb={2} variant='h2'>Login</Typography>
		<TextField
			required
			label="Username"
			fullWidth
			onChange={(ev)=>setUser(ev.target.value)}
			sx={{ mb: 2 }}
		/>
		<TextField
			required
			type="password"
			label="Password"
			fullWidth
			onChange={(ev)=>setPassword(ev.target.value)}
			sx={{ mb: 2 }}
		/>
		<Button
			type="submit"
			variant="contained"
			color="primary"
			fullWidth
			onClick={(e) => { e.preventDefault(); attemptLogin(); }}
		>Login</Button>
	</Paper>;
}
