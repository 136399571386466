/**
 * Generic Dialog.
 *
 * @since 0.2.0
 */
import * as React from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

/**
 * Dialog props.
 *
 * @since 0.2.0
 */
export interface DialogProps {
	onSave: (str: string) => void;
	onClose: () => void;
	message?: string;
	initialValue?: string;
	open: boolean;
	children?: React.ReactNode;
}

/**
 * Dialog state.
 *
 * @since 0.2.0
 */
interface DialogState {
	value: string;
}

export class GenericDialog<T extends DialogProps, S extends DialogState> extends React.Component<T, S> {
	constructor(ps: T) {
		super(ps);
		this.state = {
			...this.state,
			value: ps.initialValue ?? ''
		};
	}

	render() {
		const {message, onSave, onClose, children, open} = this.props;
		return <Dialog onClose={onClose} open={open}>
			<DialogTitle>{message}</DialogTitle>

			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				p={2}
			>
				<form onSubmit={(e) => {
					e.preventDefault();
					onSave(this.state.value);
					onClose();
				}}>
					<Grid>
						{children}
					</Grid>

					<Box sx={{
						display: 'flex',
						justifyContent: 'center',
					}}>
						<Box sx={{p: 1}}>
							<Button type='submit' variant="outlined">Ok</Button>
						</Box>
						<Box sx={{p: 1}}>
							<Button variant="outlined"
								onClick={(e) => {
									e.preventDefault();
									onClose();
								}}
							>Close</Button>
						</Box>
					</Box>
				</form>
			</Grid>

		</Dialog>;
	}
}
