import { array, failure, Is, success, Type } from 'io-ts';
import { Fn } from '../Function';
import * as Either from 'fp-ts/Either';
import { hasProp } from '../Type';
import { pipe } from 'fp-ts/lib/function';

/**
 * A pair of elements.
 */
export type Two<T> = [T,T];

/**
 * Check whether a value is Two of something.
 */
export const is = <T>(isT: Is<T>) => (x: unknown): x is Two<T> =>
	typeof x === 'object' && x !== null &&
	hasProp(x, 'length') && x.length === 2 &&
	hasProp(x, '0') && isT(x[0]) &&
	hasProp(x, '1') && isT(x[1]);

/**
 * Reverse a container.
 */
export const reverse = <T>(xs: Two<T>): Two<T> => xs;

/**
 * Clone a container, but not the items within it.
 */
export const clone = <T>(xs: Two<T>): Two<T> => [ xs[0], xs[1] ];

/**
 * Apply a function to every element of the container.
 */
export const map = <A,B>(f: Fn<A,B>) => (o: Two<A>): Two<B> => [ f(o[0]), f(o[1]) ];

/**
 * Zip two containers together with a function.
 */
export const zip = <A,B,C>(f: (x: A, y: B) => C) => (xs: Two<A>, ys: Two<B>): Two<C> =>
	[ f(xs[0],ys[0]), f(xs[1],ys[1]) ];

/**
 * Generate a container of As from a single example.
 */
export const pure = <A>(x: A): Two<A> => [x, x];

/**
 * Make a pair of elements.
 */
export const mk = <A>(x: A) => (y: A): Two<A> => [x, y];

export default mk;

/**
 * Encode or decode Two of something.
 */
export const Two = <T,S>(codecT: Type<T,S>) => new Type<Two<T>,Two<S>>(
	`Two<${codecT.name}>`,
	is(codecT.is),
	(input, context) => pipe(
		array(codecT).validate(input, context),
		Either.chain(xs => refineArray(xs) ? success(xs) : failure(xs, context, `Has ${xs.length} elements.`)),
	),
	(x: Two<T>) => [ codecT.encode(x[0]), codecT.encode(x[1]) ],
);

/**
 * Refine a list into a Two, if it has exactly two elements.
 */
export const refineArray = <T>(x: T[]): x is [T,T] => x.length === 2;
