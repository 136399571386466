.price-grid
{
	width: 100%;
	font-family: sans-serif;
	border-spacing: 0;
	border: 1px solid rgba(120, 120, 120, .5);
	overflow-y: auto;
	overflow-x: auto;
}

.price-grid thead
{
	top: 0;
	z-index: 2;
	position: sticky;
}

.price-grid tbody th
{
	left: 0;
	z-index: 2;
	position: sticky;
}

.price-grid tr,
.price-grid th,
.price-grid td
{
	height: 100%;
}

.price-grid th,
.price-grid td
{
	text-align: left;
	border: 1px solid rgba(120, 120, 120, .5);
	white-space: nowrap;
}

.price-grid th
{
	padding: 5px;
	font-weight: bold;
}

.price-grid tbody th,
.price-grid thead tr
{
	background: rgb(185, 188, 189);
}

.price-grid td
{
	padding: 0;
	position: relative;
}

.price-grid td:hover
{
	background: rgba(0, 0, 0, .1);
}

.price-grid .cell-editor
{
	height: 100%;
	display: flex;
}

.price-grid .cell-editor > input
{
	margin: 0;
	border: none;
	height: 100%;
	box-sizing: border-box;
	vertical-align: middle;
	background: transparent;
	font-weight: bold;
	width: 8em;
	padding: 0 .5em;
}

.price-grid .cell-editor .call-checkbox
{
	width: 2em;
	height: 100%;
	display: flex;
	justify-content: center;
}

.price-grid .cell-editor > *,
.price-grid .cell-editor > input
{
	flex-grow: 1;
	border-right: 2px dashed rgba(120, 120, 120, .5);
}

.price-grid .cell-editor > *:last-child
{
	border-right: none;
}


@media ( prefers-color-scheme : dark ) {
	.price-grid tbody th,
	.price-grid thead tr
	{
		background : rgb(34, 41, 54);
	}

	.price-grid input
	{
		color: #fff;
	}
}

.price-grid input[type=number]::-webkit-outer-spin-button,
.price-grid input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.price-grid input[type=number] {
	-moz-appearance:textfield;
}

.price-grid .clicker {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-weight: bold;
}
