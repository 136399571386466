/**
 * Optics for pure manipulation of BulkDesign
 *
 * @since 0.1.0
 */
import { BulkDesign, BulkItem } from '~/src/design/bulk';
import { NamedLeaf, NamedTree } from '~/src/model/NamedTree';
import { Lens } from 'monocle-ts';
import { Quote } from '~/src/pricing/quote';
import { chargeItemQuote } from './Quote';
import { NamedLeafWithChargeItem, Path } from '~/src/pages/bulk-design';
import { NamedItem, selectionModelLeaf, selectionModelTree } from '~/src/edit/TreeView/TemplateTreeView';
import { Template } from '~/src/design/template';
import { Entry } from '~/src/design/template/option';

export interface NamedLeafWithPath<A> {
	leaf: NamedLeaf<A>;
	path: Path;
}

type SelectionItem<A> = selectionModelTree<A> | selectionModelLeaf<A>;


/**
 * Focus on Items field inside NamedTree
 *
 * @since 0.1.0
 * @category Division
 */
export const namedTreeItemsLens = Lens.fromProp<NamedTree<BulkItem>>()('Items');


/**
 * Focus on Items field inside NamedLeaf
 *
 * @since 0.1.0
 * @category Division
 */
export const namedLeafItemLens: Lens<NamedLeaf<BulkItem>, BulkItem> = Lens.fromProp<NamedLeaf<BulkItem>>()('Item');


/**
 * Focus on a child Item of NamedLeaf, given it's path in array of indexes.
 *
 * @since 0.1.0
 */
export const itemNamedLeaf = <BulkItem>(
	indices: Path
): Lens<NamedTree<unknown> | NamedLeaf<BulkItem>, BulkItem | undefined> =>
		new Lens<NamedTree<unknown> | NamedLeaf<BulkItem>, BulkItem | undefined>(
			(s) => {
				let currentNode: NamedTree<unknown> | NamedLeaf<BulkItem> = s;
				for (const index of indices) {
					if (
						currentNode &&
                    'Items' in currentNode &&
                    index >= 0 &&
                    index < currentNode.Items.length
					) {
						currentNode = currentNode.Items[index] as NamedTree<unknown> | NamedLeaf<BulkItem>;
					} else {
						return undefined;
					}
				}
				return 'Item' in currentNode ? currentNode.Item : undefined;
			},
			() => (s) => s
		);


/**
 * Focus on a child Items of NamedTree, given it's path in array of indexes.
 *
 * @since 0.1.0
 */
export const itemsNamedTree = (indices: Path): Lens<BulkDesign, (BulkDesign | NamedLeaf<BulkItem>)[]> => {
	return new Lens<BulkDesign, (BulkDesign | NamedLeaf<BulkItem>)[]>(
		(s) => {
			let currentNode: BulkDesign | NamedLeaf<BulkItem> = s;
			for (const index of indices) {
				if (currentNode && 'Items' in currentNode && index >= 0 && index < currentNode.Items.length) {
					currentNode = currentNode.Items[index] as BulkDesign | NamedLeaf<BulkItem>;
				} else {
					return [];
				}
			}
			return 'Items' in currentNode ? currentNode.Items : [];
		},
	() => (s) => s
	);
};


/**
 * Focus on a child Items or Item, given it's path in array of indexes.
 *
 * @since 0.1.0
 */
export const itemsOrItemNamedTree = <A>(indices: Path): Lens<NamedItem<A>, A | A[] | undefined> =>
	new Lens<NamedItem<A>, A | A[] | undefined>(
		(s) => {
			let currentNode: NamedItem<A> = s;
			for (const index of indices) {
				if (
					currentNode &&
					'Items' in currentNode &&
					index >= 0 &&
					index < currentNode.Items.length
				) {
					currentNode = currentNode.Items[index] as NamedItem<A>;
				} else {
					return undefined;
				}
			}
			if ('Item' in currentNode) {
				return currentNode.Item as A;
			} else if ('Items' in currentNode) {
				return (currentNode.Items as unknown[]) as A[];
			}
			return undefined;
		},
		() => (s) => s
	);


export const itemsOrItemNamedTreeParent = <A>(indices: Path): Lens<NamedItem<A>, NamedItem<A> | undefined> =>
	new Lens<NamedItem<A>, NamedItem<A> | undefined>(
		(s) => {
			let currentNode: NamedItem<A> | undefined = s;
			for (const index of indices) {
				if (currentNode && 'Items' in currentNode && index >= 0 && index < currentNode.Items.length) {
					currentNode = currentNode.Items[index] as NamedItem<A>;
				} else {
					return undefined;
				}
			}
			return currentNode;
		},
		() => (s) => s
	);


/**
 * Add an item inside the `Items` array, just after its own index.
 *
 * @since 0.1.0
 */
export const addItemToNamedTree = <A>(
	path: Path,
	itemToAdd: NamedItem<A>
): ((data: NamedTree<A> | undefined) => NamedTree<A> | undefined) => {
	return (data: NamedTree<A> | undefined): NamedTree<A> | undefined => {
		if (!data) {
			return undefined;
		}

		let currentField: NamedTree<A> | NamedLeaf<A> = data;

		// Copy the path array so that it's not mutated
		const pathCopy = [...path];

		while (pathCopy.length > 0) {
			const index = pathCopy.shift();

			if (
				currentField &&
				'Items' in currentField &&
				index !== undefined &&
				index >= 0 &&
				index < currentField.Items.length
			) {
				// If we reach the last index, add the new item just after it
				if (pathCopy.length === 0) {
					currentField.Items.splice(index + 1, 0, { ...itemToAdd });
				}
				currentField = currentField.Items[index] as NamedTree<A>;
			} else {
				// Invalid path, return unchanged data
				return data;
			}
		}

		// Ensure currentField is defined
		if (currentField) {
			return data; // Return the modified data
		}

		// Invalid path, return unchanged data
		return data;
	};
};

/**
 * Add an item inside the `Items` array, just after its own index.
 *
 * @since 0.1.0
 */
export const addItemToNamedTreeClone = <A>(
	path: Path,
	itemToAdd: NamedItem<A>
): ((data: NamedTree<A> | undefined) => NamedTree<A> | undefined) => {
	return (data: NamedTree<A> | undefined): NamedTree<A> | undefined => {
		if (!data) {
			return undefined;
		}

		const clonedData: NamedTree<A> = deepClone(data); // Deep clone the entire tree to avoid modifying the original data

		let currentField: NamedTree<A> | NamedLeaf<A> = clonedData;

		// Copy the path array so that it's not mutated
		const pathCopy = [...path];

		while (pathCopy.length > 0) {
			const index = pathCopy.shift();

			if (
				currentField &&
				'Items' in currentField &&
				index !== undefined &&
				index >= 0 &&
				index < currentField.Items.length
			) {
				// If we reach the last index, add the new cloned item just after it
				if (pathCopy.length === 0) {
					const newItem: NamedItem<A> = deepClone(itemToAdd); // Clone the item to ensure immutability
					currentField.Items.splice(index + 1, 0, newItem);
				}
				currentField = currentField.Items[index] as NamedTree<A>;
			} else if (
				currentField &&
				'Items' in currentField &&
				index == 0 &&
				currentField.Items.length == 0
			) {
				if (pathCopy.length === 0) {
					const newItem: NamedItem<A> = deepClone(itemToAdd); // Clone the item to ensure immutability
					currentField.Items.splice(index, 0, newItem);
				}
			} else {
				// Invalid path, return unchanged data
				return data;
			}
		}

		// Ensure currentField is defined
		if (currentField) {
			return clonedData; // Return the modified cloned data
		}

		// Invalid path, return unchanged data
		return data;
	};
};



/**
 * Move an item inside the `Items` array to a new position after a specific index.
 *
 * @since 0.1.0
 */
export const moveItemInNamedTree = <A>(
	pathToMove: Path,
	pathToReplace: Path
): ((data: NamedTree<A> | undefined) => NamedTree<A> | undefined) => {
	return (data: NamedTree<A> | undefined): NamedTree<A> | undefined => {
		if (!data) {
			return undefined;
		}

		const clonedData: NamedTree<A> = JSON.parse(JSON.stringify(data));

		// Helper function to find the item based on the path
		const findItemAtPath = (path: Path, node: NamedTree<A> | NamedLeaf<A>): NamedTree<A> | NamedLeaf<A> => {
			if (path.length === 1) {
				if ('Items' in node) {
					return node.Items[path[0] ?? 0] as NamedTree<A> | NamedLeaf<A>;
				}
			} else {
				const [index, ...restPath] = path;
				if ('Items' in node && index !== undefined && index >= 0 && index < node.Items.length) {
					const item = node.Items[index];
					if (item && 'Items' in item) {
						return findItemAtPath(restPath, item);
					}
				}
			}
			return node;
		};

		const itemToMove = findItemAtPath(pathToMove, clonedData);
		const itemToMoveParent = findItemAtPath(pathToMove.slice(0, -1), clonedData);
		const itemToMoveReplaceParent = findItemAtPath(pathToReplace.slice(0, -1), clonedData);

		if (itemToMove && itemToMoveParent && itemToMoveReplaceParent && 'Items' in itemToMoveParent && 'Items' in itemToMoveReplaceParent) {
			const indexToMove = pathToMove[pathToMove.length - 1];
			const indexToReplace = pathToReplace[pathToReplace.length - 1];

			if (indexToMove !== undefined && indexToReplace !== undefined) {
				// Remove the item from its original position
				itemToMoveParent.Items.splice(indexToMove, 1);

				// Insert the item into the desired position
				itemToMoveReplaceParent.Items.splice(indexToReplace, 0, itemToMove);

				return clonedData;
			}
		}

		// Invalid paths, return unchanged data
		return data;
	};
};


/**
 * Replace an item inside the `Items` array to a with a new position.
 *
 * @since 0.1.0
 */
export const replaceItemInNamedTree = <A>(
	pathToMove: Path,
	pathToReplace: Path
): ((data: NamedTree<A> | undefined) => NamedTree<A> | undefined) => {
	return (data: NamedTree<A> | undefined): NamedTree<A> | undefined => {
		if (!data) {
			return undefined;
		}

		const clonedData: NamedTree<A> = JSON.parse(JSON.stringify(data));

		const findItemAtPath = (path: Path, node: NamedTree<A> | NamedLeaf<A>): NamedTree<A> | NamedLeaf<A> | undefined => {
			if (path.length === 1) {
				const newPath = path[0] ?? 0;
				if ('Items' in node && newPath >= 0 && newPath < node.Items.length) {
					return node.Items[newPath] as NamedTree<A> | NamedLeaf<A>;
				}
			} else {
				const [index, ...restPath] = path;
				if ('Items' in node && index !== undefined && index >= 0 && index < node.Items.length) {
					const item = node.Items[index];
					if (item && 'Items' in item) {
						return findItemAtPath(restPath, item);
					}
				}
			}
			return undefined;
		};

		const itemToMove = findItemAtPath(pathToMove, clonedData);
		const itemToReplace = findItemAtPath(pathToReplace, clonedData);

		if (itemToMove && itemToReplace) {
			const parentPathToMove = pathToMove.slice(0, -1);
			const parentPathToReplace = pathToReplace.slice(0, -1);

			const parentToMove = parentPathToMove.length === 0 ? clonedData : findItemAtPath(parentPathToMove, clonedData);
			const parentToReplace =
				parentPathToReplace.length === 0 ? clonedData : findItemAtPath(parentPathToReplace, clonedData);

			if (parentToMove && parentToReplace){
				if ('Items' in parentToMove && 'Items' in parentToReplace) {
					const indexToMove = pathToMove[pathToMove.length - 1];
					const indexToReplace = pathToReplace[pathToReplace.length - 1];

					if (indexToMove !== undefined && indexToReplace !== undefined) {
						// Replace the item in the desired position
						parentToReplace.Items[indexToReplace] = itemToMove;

						return clonedData;
					}
				}
			}
		}

		// Invalid paths, return unchanged data
		return data;
	};
};


/**
 * Swap an item inside the `Items` array to a with a new position.
 *
 * @since 0.1.0
 */
export const swapItemsInNamedTree = <A>(
	pathToMove: Path,
	pathToReplace: Path
): ((data: NamedTree<A> | undefined) => NamedTree<A> | undefined) => {
	return (data: NamedTree<A> | undefined): NamedTree<A> | undefined => {
		if (!data) {
			return undefined;
		}

		const clonedData: NamedTree<A> = JSON.parse(JSON.stringify(data));

		const findItemAtPath = (path: Path, node: NamedTree<A> | NamedLeaf<A>): NamedTree<A> | NamedLeaf<A> | undefined => {
			if (path.length === 1) {
				const newPath = path[0] ?? 0;
				if ('Items' in node && newPath >= 0 && newPath < node.Items.length) {
					return node.Items[newPath] as NamedTree<A> | NamedLeaf<A>;
				}
			} else {
				const [index, ...restPath] = path;
				if ('Items' in node && index !== undefined && index >= 0 && index < node.Items.length) {
					const item = node.Items[index];
					if (item && 'Items' in item) {
						return findItemAtPath(restPath, item);
					}
				}
			}
			return undefined;
		};

		const itemToMove = findItemAtPath(pathToMove, clonedData);
		const itemToReplace = findItemAtPath(pathToReplace, clonedData);

		if (itemToMove && itemToReplace) {
			const parentPathToMove = pathToMove.slice(0, -1);
			const parentPathToReplace = pathToReplace.slice(0, -1);

			const parentToMove = parentPathToMove.length === 0 ? clonedData : findItemAtPath(parentPathToMove, clonedData);
			const parentToReplace =
				parentPathToReplace.length === 0 ? clonedData : findItemAtPath(parentPathToReplace, clonedData);
			if (parentToMove && parentToReplace){
				if ('Items' in parentToMove && 'Items' in parentToReplace) {
					const indexToMove = pathToMove[pathToMove.length - 1];
					const indexToReplace = pathToReplace[pathToReplace.length - 1];

					if (indexToMove !== undefined && indexToReplace !== undefined) {
						// Swap the items in the desired positions
						parentToMove.Items[indexToMove] = itemToReplace;
						parentToReplace.Items[indexToReplace] = itemToMove;

						return clonedData;
					}
				}
			}
		}

		// Invalid paths, return unchanged data
		return data;
	};
};


export const swapItemsInTreeData = (
	indexToMove: number,
	indexToReplace: number
): ((data: TreeDataItem[] | undefined) => TreeDataItem[] | undefined) => {
	return (data: TreeDataItem[] | undefined): TreeDataItem[] | undefined => {
		if (!data) {
			return undefined;
		}

		const clonedData: TreeDataItem[] = JSON.parse(JSON.stringify(data));

		if (indexToMove >= 0 && indexToMove < clonedData.length && indexToReplace >= 0 && indexToReplace < clonedData.length) {
			// Swap the items in the desired positions
			const temp = clonedData[indexToMove];
			clonedData[indexToMove] = clonedData[indexToReplace] as TreeDataItem;
			clonedData[indexToReplace] = temp as TreeDataItem;

			return clonedData;
		}

		// Invalid indices, return unchanged data
		return data;
	};
};


type TreeDataItem = NamedTree<unknown> | NamedLeaf<unknown>;

export const replaceItemsInNamedTree = (
	path: Path,
	newItems: TreeDataItem[]
): ((data: TreeDataItem | undefined) => TreeDataItem | undefined) => {
	return (data: TreeDataItem | undefined): TreeDataItem | undefined => {
		if (!data) {
			return undefined;
		}

		// Deep clone the entire tree to avoid modifying the original data
		const clonedData: TreeDataItem = deepClone(data);

		// Use a reference to traverse the cloned tree
		let currentField: TreeDataItem | undefined = clonedData;

		// Traverse the path to find the target object
		for (const index of path) {
			if (currentField && 'Items' in currentField && index >= 0 && index < currentField.Items.length) {
				currentField = currentField.Items[index];
			} else {
				// Invalid path, return unchanged data
				return data;
			}
		}

		// Ensure currentField is defined and is a NamedTree or NamedLeaf
		if (currentField && ('Items' in currentField || 'Item' in currentField)) {
			// Modify the cloned tree
			(currentField as NamedTree<unknown>).Items = newItems as NamedItem<unknown>[];
		}

		// Return the modified cloned tree
		return clonedData;
	};
};

function deepClone<T>(obj: T): T {
	if (obj === null || typeof obj !== 'object') {
		return obj;
	}

	if (Array.isArray(obj)) {
		const newArray: unknown[] = [];
		for (let i = 0; i < obj.length; i++) {
			newArray[i] = deepClone(obj[i]);
		}
		return newArray as unknown as T;
	}

	if (typeof obj === 'object') {
		const newObj: Record<string, unknown> = {};
		for (const key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				newObj[key] = deepClone((obj as Record<string, unknown>)[key]);
			}
		}
		return newObj as unknown as T;
	}

	// If obj is neither null, nor an object, nor an array, return as is
	return obj;
}

export function deepEqual<T>(obj1: T, obj2: T): boolean {
	if (obj1 === obj2) {
		return true;
	}

	if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
		return false;
	}

	const keys1 = Object.keys(obj1) as (keyof T)[];
	const keys2 = Object.keys(obj2) as (keyof T)[];

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
			return false;
		}
	}

	return true;
}

/**
 * Update Name field inside the NamedTree or NamedLeaf based on the provided path.
 *
 * @since 0.1.0
 */
export const updateTemplateInNameTree = <A>(
	path: Path,
	value: Template
) => (data: NamedItem<A>): NamedTree<unknown> | NamedLeaf<unknown> => {
		let currentField: NamedItem<unknown> | undefined = data;
		let parent: NamedItem<unknown> | undefined = undefined;

		for (const index of path) {
			if (
				currentField &&
			'Items' in currentField &&
			index >= 0 &&
			index < currentField.Items.length
			) {
				parent = currentField;
				currentField = currentField.Items[index] as NamedItem<A>;
			} else {
			// Invalid path, return unchanged data
				return data;
			}
		}

		// Ensure currentField is defined
		if (currentField) {
		// Use type assertion to specify the expected type of currentField
			const updatedField: NamedItem<unknown> = {
				...(currentField as NamedItem<unknown>), // Type assertion
				Item: value,
			};

			// If parent is a NamedTree, update the Items array with the updated child
			if (parent && 'Items' in parent) {
				parent.Items = parent.Items.map((item, index) =>
					index === path[path.length - 1] ? updatedField : item
				);
				return data; // Return the top-level parent object with the modified child
			}

			// If currentField is a NamedLeaf, return the updated NamedLeaf
			return updatedField;
		}

		// Invalid path, return unchanged data
		return data;
	};

/**
 * Update Name field inside the NamedTree or NamedLeaf based on the provided path.
 *
 * @since 0.1.0
 */
export const updateFieldNameTree = <A>(
	path: Path,
	field: keyof NamedItem<A>,
	value: string | number
) => (data: NamedItem<A>): NamedTree<unknown> | NamedLeaf<unknown> => {
		let currentField: NamedItem<A> | undefined = data;
		let parent: NamedItem<A> | undefined = undefined;

		for (const index of path) {
			if (
				currentField &&
			'Items' in currentField &&
			index >= 0 &&
			index < currentField.Items.length
			) {
				parent = currentField;
				currentField = currentField.Items[index] as NamedItem<A>;
			} else {
			// Invalid path, return unchanged data
				return data;
			}
		}

		// Ensure currentField is defined
		if (currentField) {
		// Use type assertion to specify the expected type of currentField
			const updatedField: NamedItem<A> = {
				...(currentField as NamedItem<A>), // Type assertion
				[field]: value,
			};

			// If parent is a NamedTree, update the Items array with the updated child
			if (parent && 'Items' in parent) {
				parent.Items = parent.Items.map((item, index) =>
					index === path[path.length - 1] ? updatedField : item
				);
				return data; // Return the top-level parent object with the modified child
			}

			// If currentField is a NamedLeaf, return the updated NamedLeaf
			return updatedField;
		}

		// Invalid path, return unchanged data
		return data;
	};

/**
 * Focus on a child Items or Item, given its path in an array of indexes.
 *
 * @since 0.1.0
 */
export const itemsOrItemSelectionTree = <A>(indices: Path): Lens<SelectionItem<A>, SelectionItem<A> | SelectionItem<A>[] | undefined> =>
	new Lens<SelectionItem<A>, SelectionItem<A> | SelectionItem<A>[] | undefined>(
		(s) => {
			let currentNode: SelectionItem<A> = s;
			for (const index of indices) {
				if (
					currentNode &&
					'Items' in currentNode &&
					index >= 0 &&
					index < currentNode.Items.length
				) {
					currentNode = currentNode.Items[index] as SelectionItem<A>;
				} else {
					return undefined;
				}
			}
			if ('Item' in currentNode) {
				// Return the entire object for leaf nodes
				return currentNode as SelectionItem<A>;
			} else if ('Items' in currentNode) {
				// Return the entire array of objects for nodes with child items
				return (currentNode.Items as SelectionItem<A>[]) as SelectionItem<A>[];
			}
			return undefined;
		},
		() => (s) => s
	);


export function findSelectedPaths<A>(tree: selectionModelTree<A>, currentPath: Path = []): Path[] {
	if (tree.selected) {
		// If the current tree is selected, add the current path to the result
		return [currentPath];
	}

	let selectedPaths: Path[] = [];

	// Iterate through the items in the tree
	if (tree.Items){
		for (let i = 0; i < tree.Items.length; i++) {
			const item = tree.Items[i];

			// Recursively search for selected paths in the child items
			const childPaths = findSelectedPaths(item as selectionModelTree<A>, [...currentPath, i]);

			// Merge the selected paths found in the child items with the current selected paths
			selectedPaths = [...selectedPaths, ...childPaths];
		}
	}

	return selectedPaths;
}


/**
 * Focus on all Item of NamedLeaf inside NamedTree<A>.
 *
 * @since 0.1.0
 */
export function allNamedLeafItem<A>(tree: NamedTree<A>): A[] {
	const items: A[] = [];

	function traverse(node: NamedTree<A> | NamedLeaf<A>) {
		if ('Item' in node) {
			items.push(node.Item);
		}
		if ('Items' in node) {
			node.Items.forEach((childNode) => traverse(childNode));
		}
	}

	traverse(tree);
	return items;
}


/**
 * Focus on all NamedLeaf inside NamedTree<A>.
 *
 * @since 0.1.0
 */
export function allNamedLeafs<A>(tree: NamedTree<A>): NamedLeaf<A>[] {
	const namedLeafs: NamedLeaf<A>[] = [];

	function traverse(node: NamedTree<A> | NamedLeaf<A>) {
		if ('Item' in node) {
			// Skip NamedLeaf's Item properties, we want the parent NamedLeaf object
			return;
		}
		if ('Items' in node) {
			node.Items.forEach((childNode) => {
				if ('Item' in childNode) {
					namedLeafs.push(childNode);
				}
				traverse(childNode);
			});
		}
	}

	traverse(tree);
	return namedLeafs;
}


/**
 * Focus on all NamedLeaf inside NamedTree<A> with corresponding path.
 *
 * @since 0.1.0
 */
export function allNamedLeafsWithPaths<A>(tree: NamedTree<A>, path: Path = []): NamedLeafWithPath<A>[] {
	const namedLeafs: NamedLeafWithPath<A>[] = [];

	function traverse(node: NamedTree<A> | NamedLeaf<A>, currentPath: Path) {
		if ('Item' in node) {
			// Skip NamedLeaf's Item properties, we want the parent NamedLeaf object
			return;
		}
		if ('Items' in node) {
			node.Items.forEach((childNode, index) => {
				const newPath = [...currentPath, index];
				if ('Item' in childNode) {
					namedLeafs.push({ leaf: childNode, path: newPath });
				}
				traverse(childNode, newPath);
			});
		}
	}

	traverse(tree, path);
	return namedLeafs;
}


/**
 * Combined optic to get NamedLeafs with paths and corresponding ChargeItem from Quote.
 *
 * @since 0.1.0
 */
export function allNamedLeafsWithChargeItems(
	treeData: NamedTree<BulkItem>, // Your specific type for NamedTree
	quoteData: Quote
): NamedLeafWithChargeItem[] {
	const namedLeafs = allNamedLeafsWithPaths(treeData);
	return namedLeafs.map((namedLeafWithPath) => {
		const chargeItem = chargeItemQuote(namedLeafWithPath.path).get(quoteData);
		return { namedLeafs: namedLeafWithPath, chargeItem };
	});
}



export const checkArrayExceptLast = (arr1: number[], arr2: number[]): boolean => {
	if (arr1.length !== arr2.length) {
		return false;
	}

	for (let i = 0; i < arr1.length - 1; i++) {
		if (arr1[i] !== arr2[i]) {
			return false;
		}
	}

	return true;
};

/**
 * Remove an item from a named tree
 * @since 0.1.0
 */
export const removeItemFromNamedTree = <A>(
	path: Path
): ((data: NamedTree<A> | undefined) => NamedTree<A> | undefined) => {
	return (data: NamedTree<A> | undefined): NamedTree<A> | undefined => {
		if (!data) {
			return undefined;
		}

		const clonedData: NamedTree<A> = deepClone(data); // Deep clone the entire tree to avoid modifying the original data

		let currentField: NamedTree<A> | NamedLeaf<A> = clonedData;

		// Copy the path array so that it's not mutated
		const pathCopy = [...path];

		while (pathCopy.length > 0) {
			const index = pathCopy.shift();

			if (
				currentField &&
				'Items' in currentField &&
				index !== undefined &&
				index >= 0 &&
				index < currentField.Items.length
			) {
				// If we reach the last index, remove the item.
				if (pathCopy.length === 0) {
					currentField.Items.splice(index, 1);
				} else {
					currentField = currentField.Items[index] as NamedTree<A>;
				}
			} else {
				// Invalid path, return unchanged data
				return data;
			}
		}

		// Ensure currentField is defined
		if (currentField) {
			return clonedData; // Return the modified cloned data
		}

		// Invalid path, return unchanged data
		return data;
	};
};

/**
 * Get values from the original template.
 */
export const extractValuesFromTemplate = (template: Template, fields: string[]) => {
	if (!template) {
		return null;
	}
	const templateValues = [
		...template.Opts.map((variable) => ({
			...variable,
			Type: 'Opts',
		})),
		...template.Vars.map((variable) => ({
			...variable,
			Type: 'Vars',
		})),
	];

	const getValueFromTemplate = (label: string): string | number => {
		if (templateValues) {
			const searchLabel = templateValues.find((item) => item.Label === label);
			if (searchLabel) {
				switch (searchLabel.Type) {
				case 'Opts':
					return (
						(searchLabel.Range as Entry<string>[]).find(
							(r) => r.Label === searchLabel.Value
						)?.Names?.eng ?? ''
					);
				case 'Vars':
					return searchLabel.Value;
				}
			}
		}
		return '';
	};

	return fields.reduce((res, field) => ({
		...res,
		[field]: getValueFromTemplate(field)
	}), {});
};

/**
 * Populate the name from the template
 */
export const generateNameFromTemplate = (template: Template): string => {
	if (!template) return '';

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const values: any = extractValuesFromTemplate(template, ['width', 'height', 'Material', 'Shape']);

	return `${values.width}x${values.height} ${values.Shape} - ${values.Material}`;
};
