import { interface as iface, string, Type, number, array, union, recursion } from 'io-ts';
import { hasProp } from '~/src/base/Type';
/**
 * A tree with Ts and the leaves with names and counts at each node.
 */

export interface NamedTree<A> {
  /** Name of this branch of the tree. */
  Name: string;
  /** How many duplicates of this branch there should be. */
  Count: number;
  /** The leaves and branches below this one. */
  Items: NamedItem<A>[];
}

export type NamedItem<A> = NamedTree<A> | NamedLeaf<A>;

/**
 * Codec for `NamedTree`.
 *
 * @since 0.1.0
 */
export const NamedTree = <A>(codecA: Type<A>): Type<NamedTree<A>> =>
	recursion('NamedTree', () => iface({
		Name: string,
		Count: number,
		Items: array(NamedItem(codecA)),
	}));

export const NamedItem = <A>(codecA: Type<A>): Type<NamedItem<A>> =>
	recursion('NamedItem', () => union([NamedTree(codecA), NamedLeaf(codecA)]));

export const isTree = <A>(x: NamedItem<A>): x is NamedTree<A> => hasProp(x, 'Items');
export const isLeaf = <A>(x: NamedItem<A>): x is NamedLeaf<A> => hasProp(x, 'Item');

export interface NamedLeaf<A> {
  /** Name of this branch of the tree. */
  Name: string;
  /**
   * How many copies of this value there should be.
   * This must be a positive integer.
   */
  Count: number;
  /** A value. */
  Item: A;
}

/**
 * Codec for `NamedLeaf`.
 *
 * @since 0.1.0
 */
export const NamedLeaf = <A>(codecA: Type<A>): Type<NamedLeaf<A>> => {
	return iface({
		Name: string,
		Count: number,
		Item: codecA,
	});
};

/** An Empty Named Tree */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emptyTree: NamedTree<any> = {
	Name: '',
	Count: 1,
	Items: [],
};
