import React from 'react';

import { Requestor } from '~/src/api/Requestor';

export interface LogoutProps {
	updateReq: (req: Requestor|undefined) => void;
}

export interface LogoutState {
}

export default class Logout extends React.Component<LogoutProps, LogoutState> {
	constructor(props: LogoutProps) {
		super(props);
		this.state = {};
		sessionStorage.removeItem('login');
		this.props.updateReq(undefined);
	}

	render = () =>
		<div className="logout-wrapper">
			<h1>Thank you for logging out.</h1>
		</div>;
}
