.template-viewer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.controls {
  display: flex;
  gap: 10px;
}

.template-viewer > svg {
  height: auto !important;
  flex-grow: 1;
}
