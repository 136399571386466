import { styled } from '@mui/material';
import TextareaAutosize, { TextareaAutosizeProps } from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography';
import { Language, Translations } from '~/src/model/Language';
import type {} from '@mui/material/themeCssVarsAugmentation';

interface QTextareaAutosizeProps extends TextareaAutosizeProps {
	lang: Language;
	label: Translations;
}

const StyledTextareaAutosize = styled(TextareaAutosize)<TextareaAutosizeProps>(({ theme }) => ({
	border: `1px solid ${theme.vars.palette.app?.choreBorder}`,
	borderRadius: '6px',
	padding: '12px',
	backgroundColor: 'transparent',
	color: theme.vars.palette.text.primary,
	fontFamily: '"Poppins", sans-serif',
	fontSize: '16px',
	lineHeight: 1.5,
	'&:hover': {
		borderColor: theme.vars.palette.app?.border
	},
	'&:focus': {
		outline: `2px solid ${theme.vars.palette.app?.outline}`,
		borderColor: 'transparent'
	}
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
	fontSize: '12px',
	lineHeight: '24px',
	color: theme.vars.palette.text.secondary
}));

/**
 *  TextField from MUI library but with default props and styling
 *  common to the TemplateMod page
 */
const QTextArea = ({lang, label, ...ps}: QTextareaAutosizeProps) => (
	<>
		<StyledLabel>
			{label[lang]}
		</StyledLabel>
		<StyledTextareaAutosize {...ps} />
	</>
);

export default QTextArea;
