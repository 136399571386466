/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';

import { Requestor } from '~/src/api/Requestor';
import { Opening, SectionTree, Curb, Wall, Anomaly, SectionStep, Stretch } from '~/src/design/opening';
import { Directed } from '~/src/design/opening/measurement';
import { Projection } from '~/src/design/opening/projection';
import { OneOrUptoThree } from '~/src/model/Boxes';
import { Space } from '~/src/ui/SpaceControl';
import { Angle, Converter } from '~/src/util/Units';
import { LensMaybe, trace } from '~/src/util/Trace';

import { Lens, Optional } from 'monocle-ts';
import * as A from 'fp-ts/lib/Array';
import * as O from 'fp-ts/lib/Option';
import * as B from 'fp-ts/lib/boolean';
import * as E from 'fp-ts/lib/Either';

import {
	openingLeft,
	stretchCurb,
	opticRest,
	opticRight,
	opticSection,
	opticFloor,
	opticCeiling,
	ceilingCurbsOptic,
	floorCurbsOptic,
	curbAnomalies,
	stretchCurbs,
	indexCurb,
	curbDirection,
} from '~/src/design/opening/optics';

import WallViewer from '~/src/design/opening/walls/view';
import PieMenu, { PieEntry, PieMenuProps, XY } from '~/src/ui/PieMenu';
import TextModal, { TextModalProps } from '~/src/ui/TextModal';
import { EdgeBredth as EdgeBredthModal, EdgeBredthProps as EdgeBredthModalProps, } from '~/src/ui/modal/EdgeBredth';

import { Mod, Update } from '~/src/base/Function';

import './edit.css';
import { Path, UnitEditProps } from '~/src/edit/types';
import { addCurbs, lengthOfCeilingCurbs, lengthOfFloorCurbs, parseSectionPath, refineSectionPath, removeCurb } from '~/src/edit/util';
import { constant, flow, pipe } from 'fp-ts/lib/function';
import QRightDrawer, { DrawerContent, QRightDrawerProps } from '~/src/ui/drawer/QRightDrawer';
import AnomalyForm from '~/src/design/opening/anomaly/edit';
import CurbForm from '~/src/design/opening/curb/edit';
import SectionForm from '~/src/design/opening/section/edit';
import useDrawingDragAngles from '~/src/hooks/useDrawingDragAngles';
import { createPossibleFloorPlanOptics, getFloorPlanFocus } from '~/src/util/Dom';
import FloorForm from '~/src/design/opening/floor/edit';

export interface WalledState {
	pie: PieMenuProps | undefined;
	modal?: TextModalProps;
	edgeBredthModal?: EdgeBredthModalProps;
	rightDrawer?: QRightDrawerProps;
}

/**
 * Focus on the open toggle in UI props
 *
 * @category WalledState
 */
export const openProp: <T extends { open: boolean }>() => Lens<T, boolean> = <T extends { open: boolean }>() => Lens.fromProp<T>()('open');

/**
 * Focus on right drawer in WalledState
 *
 * @category WalledState
 */
export const rightDrawer: Lens<WalledState, QRightDrawerProps> = Lens.fromNullableProp<WalledState>()('rightDrawer', {});

/**
 * Focus on text modal in WalledState
 *
 * @category WalledState
 */
export const modal: Lens<WalledState, TextModalProps> = Lens.fromNullableProp<WalledState>()('modal', {
	onSave: () => {},
	onClose: () => {},
	open: false,
});
/**
 * Focus on edge bredth props in WalledState
 *
 * @category WalledState
 */
export const edgeBredthModalOptic: Lens<WalledState, EdgeBredthModalProps> = Lens.fromNullableProp<WalledState>()('edgeBredthModal', {
	onClose: () => {},
	onSave: () => {},
	open: false,
	location: 'center'
});
/**
 * Focus on pie menu props in WalledState
 *
 * @category WalledState
 */
export const pieOptic: Lens<WalledState, PieMenuProps> = Lens.fromNullableProp<WalledState>()('pie', {
	onClose: () => {},
	clicked: false,
	x: 0,
	y: 0
});

export interface WalledProps extends UnitEditProps<Opening> {
	visible: boolean;
	req: Requestor;
	projection: Projection;
	space: Space;
	curbEdges: boolean;
	spaceControl: (space: Space) => void;
}

export const parseCurbs: (path: Path) => number = (path: Path): number => {
	const i = path.indexOf('Curbs');

	const next = path[i + 1];
	if (next === undefined) return -1;

	const nextI = parseInt(next);
	if (Number.isInteger(nextI)) return nextI;

	return -1;
};

const defaultAnomaly = Anomaly.encode({ Offset: { Location: 'start', Offset: 1 }, Size: 1, Depth: 1 });

const addAnomalySlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (anomalyOptic: Optional<Opening, Anomaly[]>): PieEntry => ({
	icon: 'Add Anomaly',
	onSelect: (_: XY) => {
		// Add new anomaly to end of array
		updateOpening(anomalyOptic.modify(A.append(defaultAnomaly)));
		updateWall(flow(openDrawer({
			title: 'Anomaly',
			Content: AnomalyForm(anomalyOptic),
		}), closePie));
	}
});

const getMinimalSection = (minimalOpening: Opening) => (angle: Angle): SectionTree => {
	const opticAngle = Lens.fromPath<SectionTree>()(['Angle', 'Angle']);
	return opticAngle.set(angle)(minimalOpening.Section);
};



const addSection = (minimalOpening: Opening) => (sectionPath: SectionStep[], angle: Angle = 90) => (op: Opening) => {
	const newSection = getMinimalSection(minimalOpening)(angle);
	const rest = O.toNullable(opticRest(sectionPath).getOption(op));

	if (Array.isArray(rest)) {
		if (rest.length < 3) {
			return opticRest(sectionPath).modify((_old) => ([...rest, newSection] as OneOrUptoThree<Wall, SectionTree>))(op);
		}
	}

	return opticRest(sectionPath).modify((_old) => [newSection])(op);
};

const addSectionSlice = (minimalOpening: Opening) => (updateWall: Update<WalledState>, sectionPath: SectionStep[]) => (updateOpening: Update<Opening>): PieEntry => ({
	icon: 'Add sect.',
	onSelect: (xy: XY) => {
		updateWall(openPie( { ...xy, radius: 110, entries: [
			{
				icon: '90°',
				onSelect: () => {
					updateOpening(addSection(minimalOpening)(sectionPath, 90));
					updateWall(closePie);
				}
			},
			null,
			{
				icon: '135°',
				onSelect: () => {
					updateOpening(addSection(minimalOpening)(sectionPath, 45));
					updateWall(closePie);
				}
			},
			null,
			{
				icon: '225°',
				onSelect: () => {
					updateOpening(addSection(minimalOpening)(sectionPath, -45));
					updateWall(closePie);
				}
			},
			null,
			{
				icon: '270°',
				onSelect: () => {
					updateOpening(addSection(minimalOpening)(sectionPath, -90));
					updateWall(closePie);
				}
			},
		], onClose: () => updateWall(closePie)}));
	}
});

const delSlice: (sectionPath: SectionStep[]) => Mod<Opening> = (sectionPath: SectionStep[]) => (op: Opening) => {
	if (sectionPath.length == 0) return op;

	const opticPrev = opticRest(sectionPath.slice(0, -1));

	const wall = O.toNullable(opticRest(sectionPath).getOption(op));

	const rest = (O.toNullable(opticPrev.getOption(op)) as SectionTree[] ?? [])
		.filter((_e, i: number) => i !== sectionPath.slice(-1)[0]);

	if (!wall) return op;

	return opticPrev.modify(() => rest.length > 0 ? rest as OneOrUptoThree<Wall, SectionTree> : wall)(op);
};

const delSectionSlice: (updateWall: Update<WalledState>) => (sectionPath: SectionStep[]) => (updateOpening: Update<Opening>) => PieEntry = (updateWall: Update<WalledState>) => (sectionPath: SectionStep[]) => (updateOpening: Update<Opening>) => ({
	icon: 'Del sec.',
	onSelect: () => {
		updateOpening(delSlice(sectionPath));
		updateWall(closePie);
	}
});

const editSectionSlice = (updateWall: Update<WalledState>) => (sectionPath: SectionStep[]): PieEntry => ({
	icon: 'Spec',
	onSelect: (_XY) => updateWall(flow(openDrawer({
		title: 'Section',
		Content: SectionForm(opticSection(sectionPath))
	}), closePie))
});

const sectionActions = (minimalOpening: Opening) => (updateWall: Update<WalledState>) => (sectionPath: SectionStep[]) => (updateOpening: Update<Opening>) => (isJoint: boolean): (PieEntry | null)[] => [
	addSectionSlice(minimalOpening)(updateWall, refineSectionPath(sectionPath)(isJoint))(updateOpening),
	editSectionSlice(updateWall)(sectionPath),
	null, null,
	delSectionSlice(updateWall)(sectionPath)(updateOpening)
];

const sectionSlice = (minimalOpening: Opening) => (updateWall: Update<WalledState>) => (sectionPath: SectionStep[]) => (updateOpening: Update<Opening>) => (isJoint: boolean): PieEntry => ({
	icon: 'Section',
	onSelect: (xy: XY) => {
		updateWall(openPie({
			entries: sectionActions(minimalOpening)(updateWall)(sectionPath)(updateOpening)(isJoint),
			onClose: () => updateWall(closePie),
			...xy,
		}));
	}
});

const editMeasurementSlice = <S extends keyof Directed>(updateWall: Update<WalledState>) => (curbOptic: Optional<Opening, Curb<S>>): PieEntry => ({
	icon: 'Spec',
	onSelect: (_XY) => updateWall(flow(openDrawer({
		title: 'Curb',
		Content: CurbForm<S>(curbOptic, 'Measurement'),
	}), closePie))
});

const leftCurbActions = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>): (PieEntry | null)[] => [
	null,
	editMeasurementSlice<'Left'>(updateWall)(openingLeft.compose(stretchCurbs<'Left'>()).composeOptional(indexCurb(0))),
	addAnomalySlice(updateWall)(updateOpening)(openingLeft.compose(stretchCurbs<'Left'>()).composeOptional(indexCurb(0)).composeLens(curbAnomalies<'Left'>())),
];

const rightCurbActions = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (minimalOpening: Opening) => (sectionPath: SectionStep[]): (PieEntry | null)[] => [
	sectionSlice(minimalOpening)(updateWall)(sectionPath)(updateOpening)(false),
	editMeasurementSlice<'Right'>(updateWall)(opticRight(sectionPath).composeLens(stretchCurbs<'Right'>()).composeOptional(indexCurb(0))),
	addAnomalySlice(updateWall)(updateOpening)(opticRight(sectionPath).composeLens(stretchCurbs<'Right'>()).composeOptional(indexCurb(0)).composeLens(curbAnomalies<'Right'>()))
];

const upperLeftNotchSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => ({
	icon: image('notch-ul.svg'),
	title: 'Notch',
	onSelect: () => {
		// Add default notch to end of ceiling stretch
		pipe(
			addCurbs(ceilingCurbsOptic(sectionPath))(curbsPath)('Top')(['down', 'left'])('after')()(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const upperRightNotchSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => (			{
	icon: image('notch-ur.svg'),
	title: 'Notch',
	onSelect: () => {
		// Add default notch to start of ceiling stretch
		pipe(
			addCurbs(ceilingCurbsOptic(sectionPath))(curbsPath)('Top')(['left', 'up'])()()(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const ceilingNotchActions = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): (PieEntry | null)[] => [
	upperLeftNotchSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath),
	null,null,null,
	upperRightNotchSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath),
];

const addNotch = (updateWall: Update<WalledState>) => (entries: (PieEntry | null)[]): PieEntry => ({
	icon: 'Notch',
	onSelect: (xy: XY) => updateWall(openPie({ ...xy, entries, onClose: () => updateWall(closePie)}))
});

const upperLeftRakeSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => ({
	icon: image('rake-ul-up.svg'),
	onSelect: () => {
		// Add default ceiling rake to end
		pipe(
			addCurbs(ceilingCurbsOptic(sectionPath))(curbsPath)('Top')(['left'])('after')({type: 'Minor'})(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});
const upperRightRakeSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => ({
	icon: image('rake-ur-down.svg'),
	onSelect: () => {
		// Add default ceiling rake to start of stretch
		pipe(
			addCurbs(ceilingCurbsOptic(sectionPath))(curbsPath)('Top')(['left'])()({type: 'Minor'})(-lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const ceilingRakeActions = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): (PieEntry | null)[] => [
	upperLeftRakeSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath),
	null,null,null,
	upperRightRakeSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath),
];

const addRake = (updateWall: Update<WalledState>) => (entries: (PieEntry | null)[]): PieEntry => ({
	icon: 'Rake',
	onSelect: (xy: XY) => updateWall(openPie( { ...xy, entries, onClose: () => updateWall(closePie)}))
});

const ceilingLeftDownNibSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => (			{
	icon: 'Down',
	onSelect: (_xy: XY) => {
		// Add default ceiling nib to end
		pipe(
			addCurbs(ceilingCurbsOptic(sectionPath))(curbsPath)('Top')(['down'])('after')({ type: 'Major' })(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const ceilingLeftUpNibSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => (			{
	icon: 'Up',
	onSelect: (_xy: XY) => {
		// Add default ceiling nib to end
		pipe(
			addCurbs(ceilingCurbsOptic(sectionPath))(curbsPath)('Top')(['up'])('after')({ type: 'Major' })(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const ceilingRightUpNibSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => (			{
	icon: 'Down',
	onSelect: (_xy: XY) => {
		// Add default ceiling nib to start
		pipe(
			addCurbs(ceilingCurbsOptic(sectionPath))(curbsPath)('Top')(['up'])('before')({ type: 'Major' })(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const ceilingRightDownNibSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => (			{
	icon: 'Down',
	onSelect: (_xy: XY) => {
		// Add default ceiling nib to start
		pipe(
			addCurbs(ceilingCurbsOptic(sectionPath))(curbsPath)('Top')(['down'])('before')({ type: 'Major' })(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const showNibSlice = (slice: PieEntry) => (conditions: (() => boolean)[]) => pipe(slice, oPieEntry(conditions), O.toNullable);

const ceilingNibActions = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number) => (stretchLength: number): (PieEntry | null)[] => [
	null,
	ceilingLeftDownNibSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath),
	null,
	ceilingRightUpNibSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath),
	null,
	showNibSlice(ceilingRightDownNibSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath))([isNotStartCurb(curbsPath)]),
	null,
	showNibSlice(ceilingLeftUpNibSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath))([isNotEndCurb(stretchLength)(curbsPath)])
];

const addNib = (updateWall: Update<WalledState>) => (entries: (PieEntry | null)[]): PieEntry => ({
	icon: 'Nib',
	onSelect: (xy: XY) => updateWall(openPie( { ...xy, entries, onClose: () => updateWall(closePie)}))
});

const lowerLeftNotchSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => ({
	icon: image('notch-ll.svg'),
	title: 'Notch',
	onSelect: () => {
		// Add default notch to end of ceiling stretch
		pipe(
			addCurbs(floorCurbsOptic(sectionPath))(curbsPath)('Bottom')(['right', 'down'])()()(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const lowerRightNotchSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => (			{
	icon: image('notch-lr.svg'),
	title: 'Notch',
	onSelect: () => {
		// Add default notch to start of ceiling stretch
		pipe(
			addCurbs(floorCurbsOptic(sectionPath))(curbsPath)('Bottom')(['up', 'right'])('after')()(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const floorNotchActions = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): (PieEntry | null)[] => [
	lowerLeftNotchSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath),
	null,null,null,
	lowerRightNotchSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath),
];

const lowerLeftRakeSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => ({
	icon: image('rake-ll-down.svg'),
	onSelect: () => {
		// Add default floor rake to end
		pipe(
			addCurbs(floorCurbsOptic(sectionPath))(curbsPath)('Bottom')(['right'])()({type: 'Minor'})(-lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});
const lowerRightRakeSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => ({
	icon: image('rake-lr-up.svg'),
	onSelect: () => {
		// Add default floor rake to start of stretch
		pipe(
			addCurbs(floorCurbsOptic(sectionPath))(curbsPath)('Bottom')(['right'])('after')({type: 'Minor'})(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const floorRakeActions = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): (PieEntry | null)[] => [
	lowerLeftRakeSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath),
	null,null,null,
	lowerRightRakeSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath),
];

const floorLeftDownNibSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => (			{
	icon: 'Down',
	onSelect: (_xy: XY) => {
		// Add default floor nib to end
		pipe(
			addCurbs(floorCurbsOptic(sectionPath))(curbsPath)('Bottom')(['down'])('before')()(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const floorLeftUpNibSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => (			{
	icon: 'Up',
	onSelect: (_xy: XY) => {
		// Add default floor nib to end
		pipe(
			addCurbs(floorCurbsOptic(sectionPath))(curbsPath)('Bottom')(['up'])('before')()(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const floorRightUpNibSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => (			{
	icon: 'Up',
	onSelect: (_xy: XY) => {
		// Add default floor nib to start
		pipe(
			addCurbs(floorCurbsOptic(sectionPath))(curbsPath)('Bottom')(['up'])('after')()(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const isNotStartCurb: (curbPath: number) => () => boolean = (curbPath: number) => () => curbPath != 0;
const isNotEndCurb: (stretchLength: number) => (curbPath: number) => () => boolean = (stretchLength: number) => (curbPath: number) => () => curbPath != stretchLength - 1;
const isNotVerticalCurb: <S extends keyof Directed>(curb: O.Option<Curb<S>>) => () => boolean = <S extends keyof Directed>(curb: O.Option<Curb<S>>) => () => pipe(curb, O.fold(constant(true), (a) => !['up', 'down'].includes(curbDirection<S>().get(a))));

const floorRightDownNibSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => (			{
	icon: 'Down',
	onSelect: (_xy: XY) => {
		// Add default floor nib to start
		pipe(
			addCurbs(floorCurbsOptic(sectionPath))(curbsPath)('Bottom')(['down'])('after')()(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const oPieEntry = (conditions: (() => boolean)[]) => (a: PieEntry | null) => pipe(
	conditions,
	A.reduce(O.fromNullable(a), (oPrev, condition) => pipe(
		oPrev,
		O.chain(O.fromPredicate(condition))
	))
);

const floorNibActions = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number) => (stretchLength: number): (PieEntry | null)[] => [
	null,
	floorLeftDownNibSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath),
	null,
	showNibSlice(floorRightDownNibSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath))([isNotEndCurb(stretchLength)(curbsPath)]),
	null,
	floorRightUpNibSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath),
	null,
	showNibSlice(floorLeftUpNibSlice(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath))([isNotStartCurb(curbsPath)])
];

const removeCurbSlice = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (update: Mod<Opening>): PieEntry => ({
	icon: 'Remove',
	onSelect: () => {
		pipe(update, updateOpening);
		updateWall(closePie);
	}
});

const splitCeilingCurb = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => (			{
	icon: 'Split',
	onSelect: () => {
		pipe(
			addCurbs(ceilingCurbsOptic(sectionPath))(curbsPath)('Top')(['left'])('split')()(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const splitFloorCurb = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number): PieEntry => (			{
	icon: 'Split',
	onSelect: () => {
		pipe(
			addCurbs(floorCurbsOptic(sectionPath))(curbsPath)('Bottom')(['right'])('split')()(lengthConverter.defaultProduct(18)),
			updateOpening
		);
		updateWall(closePie);
	}
});

const ceilingCurbActions = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number) => (stretchLength: number) => (opening: Opening): (PieEntry | null)[] => [
	null, // Section slice
	editMeasurementSlice<'Top'>(updateWall)(opticCeiling(sectionPath).composeLens(stretchCurbs<'Top'>()).composeOptional(indexCurb(curbsPath))),
	addAnomalySlice(updateWall)(updateOpening)(opticCeiling(sectionPath).compose(stretchCurb(curbsPath)).composeLens(curbAnomalies<'Top'>())),
	addNotch(updateWall)(ceilingNotchActions(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath)),
	addRake(updateWall)(ceilingRakeActions(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath)),
	pipe(addNib(updateWall)(ceilingNibActions(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath)(stretchLength)), O.fromPredicate(isNotVerticalCurb(ceilingCurbsOptic(sectionPath).compose(indexCurb<'Top'>(curbsPath)).getOption(opening))), O.toNullable),
	stretchLength > 1 ? removeCurbSlice(updateWall)(updateOpening)(ceilingCurbsOptic(sectionPath).modify(removeCurb(curbsPath))) : null,
	splitCeilingCurb(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath)
];

const floorCurbActions = (updateWall: Update<WalledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number,string>) => (sectionPath: SectionStep[]) => (curbsPath: number) => (stretchLength: number) => (opening: Opening): (PieEntry | null)[] => [
	null, // Section slice
	editMeasurementSlice<'Bottom'>(updateWall)(opticFloor(sectionPath).composeLens(stretchCurbs<'Bottom'>()).composeOptional(indexCurb(curbsPath))),
	addAnomalySlice(updateWall)(updateOpening)(opticFloor(sectionPath).compose(stretchCurb(curbsPath)).composeLens(curbAnomalies<'Bottom'>())),
	addNotch(updateWall)(floorNotchActions(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath)),
	addRake(updateWall)(floorRakeActions(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath)),
	pipe(addNib(updateWall)(floorNibActions(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath)(stretchLength)), O.fromPredicate(isNotVerticalCurb(floorCurbsOptic(sectionPath).compose(indexCurb<'Bottom'>(curbsPath)).getOption(opening))), O.toNullable),
	stretchLength > 1 ? removeCurbSlice(updateWall)(updateOpening)(floorCurbsOptic(sectionPath).modify(removeCurb(curbsPath))) : null,
	splitFloorCurb(updateWall)(updateOpening)(lengthConverter)(sectionPath)(curbsPath)
];

const editFloorSlice = (updateWall: Update<WalledState>) => (stretchOptic: Optional<Opening, Stretch<'Bottom'>>) => (focus: 'Bredth' | 'OuterHeight' | 'Offset'): PieEntry => ({
	icon: 'Spec',
	onSelect: (_XY) => updateWall(flow(openDrawer({
		title: 'Floor',
		Content: FloorForm(stretchOptic, focus),
	}), closePie))
});

const editFloorCurbSlice = <S extends keyof Directed>(updateWall: Update<WalledState>) => (curbOptic: Optional<Opening, Curb<S>>) => (focus: 'Measurement' | 'Bredth' | 'OuterHeight' | 'Offset'): PieEntry => ({
	icon: 'Spec',
	onSelect: () => updateWall(flow(openDrawer({
		title: 'Curb',
		Content: CurbForm(curbOptic, focus),
	}), closePie))
});

const floorPlanStretchActions = (updateWall: Update<WalledState>) => (stretchOptic: Optional<Opening, Stretch<'Bottom'>>) => (focus: 'Bredth' | 'OuterHeight' | 'Offset'): (PieEntry | null)[] => ([
	null,
	editFloorSlice(updateWall)(stretchOptic)(focus),
]);

const floorPlanCurbActions = <S extends keyof Directed>(updateWall: Update<WalledState>) => (curbOptic: Optional<Opening, Curb<S>>) => (focus: 'Measurement' | 'Bredth' | 'OuterHeight' | 'Offset'): (PieEntry | null)[] => ([
	null,
	editFloorCurbSlice<S>(updateWall)(curbOptic)(focus),
]);

const image = (href: string, x = -15, y = -15, w = 30, h = 30) =>
	<image x={x} y={y} width={w} height={h} href={'/img/opening/' + href} />;

export const DRAWING_DIV_ID = 'Drawing';

const onMouseDown = (minimalOpening: Opening) => (updateWall: Update<WalledState>) => (props: React.PropsWithChildren<WalledProps>) => (updateElId: Update<string>) => (e: React.MouseEvent | React.TouchEvent) => {
	const target = e.target;
	let xy = { x: 0, y: 0 };

	if ('clientX' in e) {
		xy = { x: e.clientX, y: e.clientY };
	}
	if ('targetTouches' in e){
		const firstTouch = e.targetTouches[0];
		if (firstTouch) {
			xy = { x: firstTouch.clientX, y: firstTouch.clientY };
		}
	}

	if (!(target && target instanceof Node)) return;
	const ele = target.parentElement;
	if (!ele) return;

	if (ele.id === DRAWING_DIV_ID) {
		/*
		 * User is trying to click away
		 * inside of drawing
		 */
		updateWall(closePie);
	}


	const path = ele.id.split('-').slice(0, -1);
	const lens = trace(props.value, props.value)(path);


	const classList = Array.from(ele.classList);
	const isLabel = classList.includes('figure-label'),
		isBox = classList.includes('figure-box'),
		isCorner = path.includes('Corner');

	if (isBox || isLabel) {
		updateElId(() => (ele.id));
		handle(minimalOpening)(updateWall)(props)(isBox, isLabel, isCorner)(xy)(path, lens)(ele.id);
	}
};

/* Main */

const handle = (minimalOpening: Opening) => (updateWall: Update<WalledState>) => (props: React.PropsWithChildren<WalledProps>) => (isBox: boolean, isLabel: boolean, isCorner: boolean) =>
	(xy: XY) => (path: Path, lens: LensMaybe<any>) => (id: string) => {
		const sectionPath = parseSectionPath(path);

		if (path.includes('Left')) {
			handleLeft(updateWall)(props)(isBox, isCorner)(xy);
		} else if (path.includes('Right') && !isCorner) {
			handleRight(minimalOpening)(updateWall)(props, sectionPath)(isBox, isLabel, isCorner)(xy)(path, lens);
		} else {
			handleSection(minimalOpening)(updateWall)(props, sectionPath)(isBox, isLabel, isCorner)(xy)(path, lens)(id);
		}
	};

const handleLeft = (updateWall: Update<WalledState>) => (props: React.PropsWithChildren<WalledProps>) =>
	(_isBox: boolean, isCorner: boolean) =>
		(xy: {x: number, y: number}) => {
			// User clicks on left curb

			// TODO: Handle corner click
			if (isCorner)
				return;

			// Open pie with single curb options
			updateWall(openPie({
				...xy,
				radius: 120,
				entries: leftCurbActions(updateWall)(props.update),
				onClose: () => updateWall(closePie)
			}));
		};

const handleSection = (minimalOpening: Opening) =>(updateWall: Update<WalledState>) => (props: React.PropsWithChildren<WalledProps>, sectionPath: SectionStep[]) =>
	(isBox: boolean, _isLabel: boolean, isCorner: boolean) =>
		(xy: {x: number, y: number}) =>
			(path: Path, lens: LensMaybe<any>) => (id: string) => {

				const
					isAngle = path.includes('Angle'),
					isCeiling = path.includes('Ceiling'),
					isEdge = path.includes('Edge'),
					isEdgeBredth = path.includes('EdgeBredth'),
					isEdgeOuterHeight = path.includes('EdgeOuterHeight'),
					isFloor = path.includes('Floor'),
					isJoint = path.includes('Joint');

				if (isEdge || isEdgeOuterHeight || isEdgeBredth) {
					handleFloorPlan(updateWall)(id)(xy);
					return;
				}

				if (isJoint) {
					handleJoint(minimalOpening)(updateWall)(props, sectionPath)(xy);
				}

				if (isCeiling) {
					handleCeiling(updateWall)(props, sectionPath)(isBox, isCorner)(xy)(path, lens);
				} else if (isFloor) {
					handleFloor(updateWall)(props, sectionPath)(isCorner)(xy)(path);
				} else if (isAngle) {
					handleAngle(updateWall)(sectionPath)(path);
				}
			};

const handleAngle = (updateWall: Update<WalledState>) => (sectionPath: SectionStep[]) =>
	(path: Path) => {
		const sectionOptic: Optional<Opening, SectionTree> = opticSection(sectionPath);
		const focus: 'Right' | 'Angle' = pipe(path.includes('Right'), B.fold(constant('Angle'), constant('Right')));

		updateWall(openDrawer({
			title: 'Section',
			Content: SectionForm(sectionOptic, focus)
		}));
	};

const handleCeiling = (updateWall: Update<WalledState>) => (props: React.PropsWithChildren<WalledProps>, sectionPath: SectionStep[]) =>
	(_isBox: boolean, isCorner: boolean) =>
		(xy: {x: number, y: number}) =>
			(path: Path, _lens: LensMaybe<any>) => {
				const curbsPath = pipe(
					path,
					parseCurbs,
				);
				const curbLength = lengthOfCeilingCurbs(props.value)(sectionPath);

				// TODO: Handle corner click
				if (isCorner) {
					return;
				}

				updateWall(openPie({
					...xy,
					radius: 120,
					entries: ceilingCurbActions(updateWall)(props.update)(props.lengthConverter)(sectionPath)(curbsPath)(curbLength)(props.value),
					onClose: () => updateWall(closePie)
				}));
			};

const handleFloor = (updateWall: Update<WalledState>) => (props: React.PropsWithChildren<WalledProps>, sectionPath: SectionStep[]) =>
	(isCorner: boolean) =>
		(xy: {x: number, y: number}) =>
			(path: Path) => {
				const curbsPath = parseCurbs(path);
				const curbLength = lengthOfFloorCurbs(props.value)(sectionPath);

				// TODO: Handle corner click
				if (isCorner) {
					return;
				}

				updateWall(openPie({
					...xy,
					radius: 120,
					entries: floorCurbActions(updateWall)(props.update)(props.lengthConverter)(sectionPath)(curbsPath)(curbLength)(props.value),
					onClose: () => updateWall(closePie)
				}));
			};

const handleFloorPlan = (updateWall: Update<WalledState>) => (elementId: string) => (xy: XY) => {
	pipe(
		elementId,
		createPossibleFloorPlanOptics,
		E.fold(
			/*
			 * TODO: A curb bredth, offset, and outer height may be null when the stretch bredth,
			 * offset, and outer height are defined in the Opening design. The drawing will
			 * fill in the amount from the stretch but the optic will return zero and won't use the
			 * equivalent stretch value
			 */
			(curbOptic) => updateWall(openPie({
				...xy,
				radius: 120,
				entries: floorPlanCurbActions<'Bottom'>(updateWall)(curbOptic)(getFloorPlanFocus(elementId)),
				onClose: () => updateWall(closePie),
			})),
			(stretchOptic) => updateWall(openPie({
				...xy,
				radius: 120,
				entries: floorPlanStretchActions(updateWall)(stretchOptic)(getFloorPlanFocus(elementId)),
				onClose: () => updateWall(closePie),
			})))
	);
};

const handleJoint = (minimalOpening: Opening) => (updateWall: Update<WalledState>) => (props: React.PropsWithChildren<WalledProps>, sectionPath: SectionStep[]) => (xy: {x: number, y: number}) => {
	updateWall(openPie({
		...xy, radius: 120,
		entries: [sectionSlice(minimalOpening)(updateWall)(sectionPath)(props.update)(true)],
		onClose: () => updateWall(closePie)
	}));
};

const handleRight = (minimalOpening: Opening) => (updateWall: Update<WalledState>) => (props: React.PropsWithChildren<WalledProps>, sectionPath: SectionStep[]) =>
	(_isBox: boolean, _isLabel: boolean, _isCorner: boolean) =>
		(xy: {x: number, y: number}) => (path: Path, _lens: LensMaybe<any>) => {
			if (path.includes('Angle')) {
				handleAngle(updateWall)(sectionPath)(path);
				return;
			}

			updateWall(openPie( {
				...xy, radius: 120,
				entries: rightCurbActions(updateWall)(props.update)(minimalOpening)(sectionPath),
				onClose: () => updateWall(closePie)
			}));
		};


/**
 *
 * `Update` actions for `WalledState`
 *
 */
const openDrawer: (drawer: { title: string, Content: DrawerContent }) => Mod<WalledState> = (drawer: { title: string, Content: DrawerContent }) => rightDrawer.set({ open: true, ...drawer });
const closeDrawer: Mod<WalledState> = rightDrawer.set({ open: false });
const openPie: (pieProps: PieMenuProps) => Mod<WalledState> = (pieProps) => pieOptic.set(pieProps);
export const closePie: Mod<WalledState> = pieOptic.modify((prevState) => ({...prevState, entries: undefined, radius: 0}));

const WallEditor: React.FC<WalledProps> = (props) => {
	// Minimal opening data object for creating new sections
	const [minimalOpening, setMinimalOpening] = React.useState<Opening|null>(null);

	// Main state control object for WallEditor
	const [walledState, setWalledState] = React.useState<WalledState>({
		rightDrawer: undefined,
		modal: undefined,
		edgeBredthModal: undefined,
		pie: undefined
	});


	// State for managing pie slice selection and highlighting on touch screens
	const { angle, setAngle, trackingAngle, setTrackingAngle } = useDrawingDragAngles();

	// Reassign setWalledState to help with Updater pattern
	const updateWall: Update<WalledState> = setWalledState;

	// Network call for the latest minimal opening data
	const fetchMinimalOpening = React.useCallback(() => {
		props.req.request(Opening)('minimal/silica/opening', 'GET')
			.then(opening => setMinimalOpening(opening))
			.catch(failure => console.warn('Failed to fetch minimal opening.', failure));
	}, [props.req]);

	// Fetch the minimal opening after component mounts
	React.useEffect(() => fetchMinimalOpening(), [fetchMinimalOpening]);

	// Destructure state object to pass props to corresponding components
	const {pie, modal, edgeBredthModal, rightDrawer} = walledState;

	const exit = () => updateWall(closeDrawer);

	const [elId, setElId] = React.useState('');

	React.useEffect(() => {
		if (!pie?.entries) {
			setElId('');
		}
	}, [pie?.entries]);

	return <>
		{minimalOpening && (
			<WallViewer {...props}
				angle={angle}
				updateAngle={setAngle}
				trackingAngle={trackingAngle}
				updateTrackingAngle={setTrackingAngle}
				updateWall={updateWall}
				activeElementId={elId}
				onDown={onMouseDown(minimalOpening)(updateWall)(props)(setElId)}
			/>
		)}
		{pie ? <PieMenu
			trackingAngle={trackingAngle}
			updateTrackingAngle={setTrackingAngle}
			angle={angle}
			updateAngle={setAngle}
			drawingRotation={props.projection.type === 'Isometric' ? props.projection.Rotate: undefined}
			updateOpening={props.update}
			angleConverter={props.angleConverter}
			lengthConverter={props.lengthConverter}
			{...pie} />
			: null}
		{modal ? <TextModal {...modal} /> : null}
		{edgeBredthModal ? <EdgeBredthModal {...edgeBredthModal} /> : null}
		{rightDrawer?.open
			? (
				<QRightDrawer
					{...rightDrawer}
					exit={exit}
					value={props.value}
					lengthConverter={props.lengthConverter}
					angleConverter={props.angleConverter}
					update={props.update}
				/>
			)
			: null}
	</>;

};

export default WallEditor;
