import { Optic, pdot, withDefault } from '~/src/base/Optic';
import { Type, string, number, partial } from 'io-ts';
import { P } from '~/src/geometry/point';
import { V3 } from '~/src/geometry/vector';
import { NonEmpty } from '~/src/base/Array/NonEmpty';
import { RulerInfoMod, applyRulerInfoMod } from '~/src/figure/mod/ruler-info';
import { comp } from '~/src/base/Function';
import { Ruler } from '~/src/figure/Ruler';

export interface RulerMod {
	Info?: RulerInfoMod;
	Class?: NonEmpty<string>;
	Guide?: P<V3>;
	Unit?: string;
	Value?: number;
}

export const RulerMod: Type<RulerMod> = partial({
	Info: RulerInfoMod,
	Class: NonEmpty(string),
	Guide: P(V3),
	Unit: string,
	Value: number,
});

export const _Info = (): Optic<RulerMod, RulerInfoMod> =>
	comp(pdot('Info'), withDefault({}));

export const _Class = (): Optic<RulerMod, NonEmpty<string> | undefined> =>
	pdot('Class');

export const _Unit = (): Optic<RulerMod, string | undefined> =>
	pdot('Unit');

export const _Value = (): Optic<RulerMod, number | undefined> =>
	pdot('Value');

export const defRulerMod = (): RulerMod => ({});

export const applyRulerMod = (mod : RulerMod) => (val : Ruler) : Ruler => ({
	...val,
	Info : mod.Info ? applyRulerInfoMod(mod.Info)(val.Info) : val.Info,
	Class : mod.Class ?? val.Class,
	Guide : mod.Guide ?? val.Guide,
	Unit : mod.Unit ?? val.Unit,
	Value : mod.Value ?? val.Value,
});
