/**
 * Optics for TemplateMod.
 *
 */
import { Lens, Optional } from 'monocle-ts';
import { optionalT, optionalNonEmpty, presentInList } from '~/src/optics/Common';

import { TemplateMod, Specialization, OptionSelection, CollateChange, VariableSelection, VariableNames, SpaceNames, SpaceRank, VariableRank, VariableRange, OptionNamesChange, NameChange, OptionRank } from '~/src/admin/template/mod/inner';
import { nameChangeTranslations } from '~/src/optics/Language';
import { Range } from '~/src/design/template/variable';
import { getNumberTraversal, optionalRangeMax, optionalRangeMin, optionalRangeStep, rangeSetOptional, variableRangeSetSet } from '~/src/design/template/variable/optics';
import { NonEmpty } from '~/src/model/Boxes';
import { Translations } from '~/src/model/Language';

/**
 * Focus on the Specialization in a TemplateMod.
 *
 * @category TemplateMod
 */
export const specialization: Lens<TemplateMod,Specialization> = Lens.fromProp<TemplateMod>()('Specialization').compose(optionalT({}));

/**
 * Focus on the Space Collation in a TemplateMod.
 *
 * @category TemplateMod
 */
export const spaceCollate: Lens<TemplateMod,CollateChange> = Lens.fromProp<TemplateMod>()('SpaceCollateChange').compose(optionalT({}));

/**
 * Focus on the Space Restrictions in a TemplateMod.
 *
 * @category TemplateMod
 */
export const spaceRestrict: Lens<TemplateMod,string[]> = Lens.fromProp<TemplateMod>()('SpaceRestrict').compose(optionalT<string[]>([]));

/**
 * Focus on a whether a particular space is restricted on a TemplateMod.
 *
 * @category TemplateMod
 */
export const spaceRestrictSpace = (space: string): Lens<TemplateMod,boolean> => spaceRestrict.compose(presentInList(space));

/**
 * Focus on a particular space in a `Specialization`.
 *
 * @category TemplateMod
 */
export const specSpace = (space: string): Lens<Specialization,Array<string>> => Lens.fromProp<Specialization>()(space).compose(optionalNonEmpty());

/**
 * Focus on the OptionSelection in a TemplateMod.
 *
 * @category TemplateMod
 */
export const optionSelection: Lens<TemplateMod,OptionSelection> = Lens.fromProp<TemplateMod>()('OptionSelection').compose(optionalT({}));

/**
 * Focus on the Variable Collation in a TemplateMod.
 *
 * @category TemplateMod
 */
export const variableCollate: Lens<TemplateMod,CollateChange> = Lens.fromProp<TemplateMod>()('VariableCollateChange').compose(optionalT({}));

/**
 * Focus on the VariableSelection in a TemplateMod.
 *
 * @category TemplateMod
 */
export const variableSelection: Lens<TemplateMod,VariableSelection> = Lens.fromProp<TemplateMod>()('VariableSelection').compose(optionalT({}));

/**
 * Focus on Variable Name in TemplateMod
 *
 * @category TemplateMod
 */
export const variableNames: Lens<TemplateMod, VariableNames> = Lens.fromProp<TemplateMod>()('VariableNames').compose(optionalT({}));
/**
 * Focus on Optional English Variable Name in TemplateMod
 *
 * @category TemplateMod
 */
export const variableNameTranslation: (l: string) => Lens<VariableNames, Translations> = (label: string) => Lens.fromProp<VariableNames>()(label).compose(optionalT({}));

/**
 * Focus on SpaceNames in TemplateMod
 *
 * @category TemplateMod
 */
export const spaceName: Lens<TemplateMod, SpaceNames> = Lens.fromProp<TemplateMod>()('SpaceNames').compose(optionalT({}));

/**
 * Focus on Optional English Space Name in TemplateMod
 *
 * @category TemplateMod
 */
export const spaceNameTranslation: (l: string) => Lens<SpaceNames, Translations> = (label: string) => Lens.fromProp<SpaceNames>()(label).compose(optionalT({}));

/**
 * Focus on SpaceRank in TemplateMod
 *
 * @category TemplateMod
 */
export const spaceRank: Lens<TemplateMod, SpaceRank> = Lens.fromProp<TemplateMod>()('SpaceRank').compose(optionalT({}));

export const rank: (s: string) => Optional<SpaceRank | VariableRank, number> = (key: keyof SpaceRank | keyof VariableRank) => Optional.fromNullableProp<SpaceRank | VariableRank>()(key);

/**
 * Focus on rank for a space in TemplateMod
 *
 * @category TemplateMod
 */
export const optionalSpaceRank: (s: string) => Optional<TemplateMod, number> = (space: string) => spaceRank.composeOptional(rank(space));

/**
 * Focus on VariableRank in TemplateMod
 *
 * @category TemplateMod
 */
export const variableRank: Lens<TemplateMod, VariableRank> = Lens.fromProp<TemplateMod>()('VariableRank').compose(optionalT({}));

/**
 * Focus on rank for a space in TemplateMod
 *
 * @category TemplateMod
 */
export const optionalVariableRank: (s: string) => Optional<TemplateMod, number> = (variable: string) => variableRank.composeOptional(rank(variable));

/**
 * Focus on optional range in VariableRange
 *
 * @category TemplateMod
 */
export const optionalRange: (s: string) => Optional<VariableRange, Range> = (key: keyof VariableRange) => Optional.fromNullableProp<VariableRange>()(key);
/**
 * Focus on optional VariableRange in TemplateMod
 *
 * @category TemplateMod
 */
export const variableRange: Optional<TemplateMod, VariableRange> = Optional.fromNullableProp<TemplateMod>()('VariableRange');

/**
 * Focus on a variable range in TemplateMod
 *
 * @category TemplateMod
 */
export const optionalTemplateModRange: (vn: string) => Optional<TemplateMod, Range> = (variableName: string) => variableRange.compose(optionalRange(variableName));

/**
 * Focus on optional maximum value in variable range in TemplateMod
 *
 * @category TemplateMod
 */
export const optionalVariableRangeMax: (vn: string) => Optional<TemplateMod, number> = (variableName: string) => optionalTemplateModRange(variableName).compose(optionalRangeMax);

/**
 * Focus on optional maximum value in variable range in TemplateMod
 *
 * @category TemplateMod
 */
export const optionalVariableRangeMin: (vn: string) => Optional<TemplateMod, number> = (variableName: string) => optionalTemplateModRange(variableName).compose(optionalRangeMin);


/**
 * Focus on optional step value in variable range in TemplateMod
 *
 * @category TemplateMod
 */
export const optionalVariableRangeStep: (vn: string) => Optional<TemplateMod, number> = (variableName: string) => optionalTemplateModRange(variableName).compose(optionalRangeStep);

/**
 * Focus on optional step array in variable range in TemplateMod
 *
 * @category TemplateMod
 */
export const modRangeSet: (vn: string) => Optional<TemplateMod, NonEmpty<number>> = (variableName: string) =>
	optionalTemplateModRange(variableName)
		.compose(rangeSetOptional)
		.composeLens(variableRangeSetSet);

/**
 * Focus on optional RangeSet in TemplateMod
 *
 * @category TemplateMod
 */
export const rangeSet = (variableName: string) => variableRange.compose(optionalRange(variableName)).compose(rangeSetOptional);

/**
 * Focus on number in a RangeSet variable in TemplateMod
 *
 * @category TemplateMod
 */
export const updateRangeSet = (variableName: string) => (numberToMatch: number) => optionalRange(variableName).compose(rangeSetOptional).composeTraversal(getNumberTraversal(numberToMatch));

/**
 * Focus on OptionNamesChange in TemplateMod
 *
 * @category TemplateMod
 */
export const modOptionNamesChange: Optional<TemplateMod, OptionNamesChange> = Optional.fromNullableProp<TemplateMod>()('OptionNamesChange');

/**
 * Focus on optional name change in OptionNamesChange
 *
 * @category TemplateMod
 */
export const optionNameChange: (spaceName: string) => Lens<OptionNamesChange, NameChange> = (spaceName: string) => Lens.fromNullableProp<OptionNamesChange>()(spaceName, {});

/**
 * Focus on optional translation for Entry in OptionNamesChange
 *
 * @category TemplateMod
 */
export const entryTranslation = (spaceName: string) => (entryLabel: string) =>
	optionNameChange(spaceName)
		.composeOptional(nameChangeTranslations(entryLabel));

/**
 * Focus on OptionRank in TemplateMod
 *
 * @category TemplateMod
 */
export const modOptionRank: Optional<TemplateMod, OptionRank> = Optional.fromNullableProp<TemplateMod>()('OptionRank');

/**
 * Focus on a space's modified entry ranks in OptionRank
 *
 * @category TemplateMod
 */
export const spaceEntryRanks: (spaceName: string) => Lens<OptionRank, { [k in string]?: number }> = (spaceName: string) =>
	Lens.fromNullableProp<OptionRank>()(spaceName, {});

/**
 * Focus on an entry's modified rank in OptionRank
 *
 * @category TemplateMod
 */
export const entryRank: (labelName: string) => Lens<{ [k in string]?: number }, number> = (labelName: string) =>
	Lens.fromNullableProp<{ [k in string]?: number }>()(labelName, 0);

/**
 * Focus on optional Rank in TemplateMod
 *
 * @category TemplateMod
 */
export const optionalModEntryRank: (spaceName: string) => (label: string) => Optional<TemplateMod, number> = (spaceName: string) => (label: string) =>
	modOptionRank
		.composeLens(spaceEntryRanks(spaceName)).composeLens(entryRank(label));
