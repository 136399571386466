import React from 'react';


import { Import } from '~/src/ui/input/Import';
import { Export } from '~/src/ui/input/Export';

import { AngleConverters, inchConverter } from '~/src/util/Units';
import { literal, type, Type } from 'io-ts';
import { useMessage } from '~/src/hooks/useMessage';
import { Optic , set , dot } from '~/src/base/Optic';
import { FigureRender } from '~/src/ui/input/FigureRender';
import { Requestor } from '~/src/api/Requestor';
import { ToolBar } from '~/src/ui/toolbar/ToolBar';

import { Figure } from '~/src/figure';
import { defFigureMod , applyFigureMod } from '~/src/figure/FigureMod';
import { EditableFigure , FigureEditor } from '~/src/figure/Edit';


export interface FigurePageProps {
	req : Requestor
}

export interface SetFigure {
	type: 'SetFigure';
	figure: Figure;
}

export const SetFigure: Type<SetFigure> = type({
	type: literal('SetFigure'),
	figure: Figure,
});

const _fig: Optic<EditableFigure, Figure> = dot('figure');

const FigurePage = ( { req } : FigurePageProps) => {
	const [eFigure, updateEFigure] = React.useState<EditableFigure | null>(null);

	const handleFigureMessage = React.useCallback( (msg: MessageEvent<SetFigure>) =>
		updateEFigure( (eFigure : EditableFigure | null) => !eFigure ? null : set(_fig)(msg.data.figure)(eFigure))
	, [updateEFigure]);

	const figure : Figure | undefined = React.useMemo(() =>
		eFigure ? applyFigureMod(eFigure.modifier)(eFigure.figure) : undefined
	, [eFigure]);

	useMessage(SetFigure, handleFigureMessage);

	return (
		<div className="page figure">
			<ToolBar>
				<Import
					name="Figure"
					codec={Figure}
					failure={e => {
						alert('Import failed, check console for details.');
						console.warn(e);
					}}
					success={fig => updateEFigure( { figure : fig , modifier : defFigureMod() } )}
				/>
				<Export
					name="Figure"
					onError={e => {
						alert('Export failed, check console for details.');
						console.warn(e);
					}}
					getContent={() => eFigure?.figure}
				/>
				<FigureRender req={req} name='figure' data={ figure } />
			</ToolBar>

			{ !eFigure ? null
				: <FigureEditor
					value={eFigure}
					update={f => updateEFigure(f(eFigure))}
					width="100%"
					height="100%"
					angleConverter={AngleConverters.exterior}
					lengthConverter={inchConverter} />
			}
		</div>
	);
};

export default FigurePage;
