import { constant, pipe } from 'fp-ts/lib/function';
import { Optional } from 'monocle-ts';
import React from 'react';
import { Joint, Opening, SectionTree, Wall } from '~/src/design/opening';
import { sectionAngle, angleAngle, restRightAngle, sectionRest } from '~/src/design/opening/optics';
import * as O from 'fp-ts/lib/Option';
import QDrawerError from '~/src/ui/drawer/QDrawerError';
import QAccordion, { QAccordionTitleValueSummary } from '~/src/ui/QAccordion';

import { modifyUpdater } from '~/src/edit/util';
import { FormProps, UnitEditProps } from '~/src/edit/types';
import { UnitViewProps } from '~/src/view/types';
import NumberEdit from '~/src/edit/Number';
import { one } from '~/src/optics/Boxes';

export interface JointViewProps extends UnitViewProps<Joint> { }

export const JointSummary = ({
	value,
	angleConverter,
}: JointViewProps): React.ReactElement =>
	<QAccordionTitleValueSummary
		title='Angle'
		value={pipe(
			value,
			(m) => angleAngle.get(m),
			angleConverter.build,
		)} />;

export interface JointEditProps extends UnitEditProps<Joint> { }

const JointEdit: React.FC<JointEditProps> = ({
	value,
	update,
}) => {
	return <>
		<NumberEdit
			unit='deg'
			value={angleAngle.get(value)}
			update={modifyUpdater(angleAngle, update)}
			tlabel={{eng: 'Angle Value'}}
			sx={{ width: '180px', mr: 3.5 }}
		/>
	</>;
};

interface SectionEditProps extends UnitEditProps<SectionTree> { }

export const SectionEdit = (_: SectionEditProps) =>
	<>
	</>;

const SectionForm = (
	optic: Optional<Opening, SectionTree>,
	focus?: 'Angle' | 'Right',
) => ({
	value,
	lengthConverter,
	angleConverter,
	update,
}: FormProps<Opening>) =>
	O.fold(
		constant(<QDrawerError>Section Not Found</QDrawerError>),
		(section: SectionTree) => {
			const joint = sectionAngle.get(section);
			const updateSectionTree = modifyUpdater(optic, update);
			const updateSectionJoint = modifyUpdater(sectionAngle, updateSectionTree);

			const rightWallJoint = sectionRest.composePrism(one<Wall,SectionTree>()).composeLens(restRightAngle).getOption(section);
			const updateRightJoint = modifyUpdater(sectionRest.composePrism(one<Wall,SectionTree>()).composeLens(restRightAngle), updateSectionTree);
			return <>
				<QAccordion
					initialExpanded={focus === 'Angle'}
					panelName='Angle'
					summary={<JointSummary
						lengthConverter={lengthConverter}
						angleConverter={angleConverter}
						value={joint}
					/>}
					details={<JointEdit
						lengthConverter={lengthConverter}
						angleConverter={angleConverter}
						update={updateSectionJoint}
						value={joint}
					/>}
				/>
				{pipe(rightWallJoint, O.fold(constant(null), (rightJoint) => (
					<QAccordion
						initialExpanded={focus === 'Right'}
						panelName='Right Wall Angle'
						summary={	<QAccordionTitleValueSummary
							title='Right Wall Angle'
							value={pipe(
								rightJoint,
								(m) => angleAngle.get(m),
								angleConverter.build,
							)} />}
						details={<JointEdit
							lengthConverter={lengthConverter}
							angleConverter={angleConverter}
							update={updateRightJoint}
							value={rightJoint}
						/>}
					/>
				)))}
			</>;
		})(optic.getOption(value));

export default SectionForm;
