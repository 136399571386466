import { failure, OutputOfProps, Props, success, Type, TypeOfProps, Validation } from 'io-ts';

/**
 * Codec like `type`, but with default values for fields.
 */
export const typeWithDefaults = <P extends Props>(props: P, def: TypeOfProps<P>, name?: string): Type<TypeOfProps<P>,OutputOfProps<P>> => new Type(
	name ?? 'type with defaults',
	(u: unknown): u is TypeOfProps<P> => {
		if (typeof u !== 'object' || u === null) return false;
		const hasProp = Object.prototype.hasOwnProperty.bind(u);
		return Object.entries(props).every(
			([k,v]) => hasProp(k) && v.is((u as {[k in string]: unknown})[k])
		);
	},
	(u, context): Validation<TypeOfProps<P>> => {
		if (typeof u !== 'object') return failure(u, context, 'Object Required');
		if (u === null) return failure(u, context, 'Non-Null Required');
		const r = {} as TypeOfProps<P>;
		const hasProp = Object.prototype.hasOwnProperty.bind(u);
		for (const k in props) {
			if (hasProp(k)) {
				const codec = props[k];
				if (!codec) return failure(u, context, 'No Codec???');
				const valid = codec.validate((u as {[k in string]: unknown})[k], context);
				if (valid._tag === 'Left') return valid;
				r[k] = valid.right;
			} else {
				r[k] = def[k];
			}
		}
		return success(r);
	},
	(x: TypeOfProps<P>): OutputOfProps<P> => {
		const r = {} as OutputOfProps<P>;
		for (const k in props) {
			const codec = props[k];
			if (!codec) throw 'No Codec???';
			r[k] = codec.encode(x[k]);
		}
		return r;
	},
);
