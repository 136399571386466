import * as React from 'react';
import { ForwardedRef , MutableRefObject , useRef , useState , useEffect , useCallback } from 'react';

import { Fn } from '~/src/base/Function';

import { UnitViewProps } from '~/src/view/types';
import { mapWithPrior } from '~/src/base/Array';
import { Angle } from '~/src/geometry/angle';
import * as Ang from '~/src/geometry/angle';

import { P, P2, P3 } from '~/src/geometry/point';
import * as Pnt from '~/src/geometry/point';
import { V2, V3 } from '~/src/geometry/vector';
import * as Vec from '~/src/geometry/vector';
import { Figure , FigMap, fit } from '~/src/figure';
import { Piece } from '~/src/figure/Piece';
import { Curve, splitAtParam } from '~/src/figure/Curve';
import { Ruler, Measure, rulerPositions } from '~/src/figure/Ruler';
import { Fixed, transform, isFixedLinear, fixed_end } from '~/src/geometry/segment';
import { IId } from '~/src/figure/Item';
import { LineStyle } from '~/src/figure/RulerInfo';
import * as css from './view.module.css';

export interface Touch {
	cntr : P2;
	angl : Angle;
	dist : number;
}

const touchBlend = (pnts: V2[]): Touch => {
	const cntr = Pnt.p(Vec.v2_scale(1/pnts.length)(pnts.reduce((a,b) => Vec.v2_add(a)(b), [0, 0])));
	const offs = pnts.map(Vec.v2_sub(cntr));
	const dist = offs.map(Vec.norm).reduce((a,b) => a + b, 0);
	const angl = offs.map(Ang.atan2).reduce((a,b) => a + b, 0) / offs.length;

	return {cntr, dist, angl};
};

const touchSpots = (trgt: EventTarget, tchs: React.TouchList): V2[] => {
	const rect = (trgt as HTMLDivElement).getBoundingClientRect();

	return Array
		.from(tchs)
		.filter(tch => tch)
		.map(tch => [tch.clientX - rect.left, tch.clientY - rect.top]);
};

export const useForwardedRef = <T extends unknown>(ref: ForwardedRef<T> | null): MutableRefObject<T | null> => {
	const iref = useRef<T>(null);

	useEffect(() => {
		if (!ref)
			return;

		if (typeof ref === 'function') {
			ref(iref.current);
		} else {
			ref.current = iref.current;
		}
	});

	return iref;
};

export type ItemType = 'Label' | 'Ruler' | 'RulerLabel' | 'EdgeBox' | 'Other';

export type FigureProps<T> = UnitViewProps<T> & FigureProj & FigureZoom & FigureItemActions;

export interface ViewFigure<V> extends FigurePan, FigureZoom, FigureSize, FigureViewActions, FigureItemActions {
	pref : string;
}

export interface FigurePan {
	pan : V2;
}

export interface FigureZoom {
	zoom : number;
}

export interface FigureProj {
	proj : Fn<P<V3>, P2>;
}

export interface FigureSize {
	width ?: string;
	height ?: string;
}

export interface FigureViewActions {
	onPan ?: (dta: V2) => void;
	onZoom ?: (dta: number, pnt: P2) => void;
	onRotate ?: (dta: number, pnt: P2) => void;
	onCenter ?: (size: V2) => void;
}

export interface FigureItemActions {
	actionables ?: ItemType[];
	onItemMove ?: (typ: ItemType, iid: IId, pnt: P2) => void; // TODO: add starting point
	onItemPrimary ?: (typ: ItemType, iid: IId, pnt: P2) => void;
	onItemContext ?: (typ: ItemType, iid: IId, pnt: P2) => void;
}

export interface FigureChildren {
	children : (props: ItemHandlers) => React.ReactNode;
}

export interface ItemHandlers {
	aid ?: IId;
	onItemMouseDown : (typ: ItemType, iid: IId) => (evt: React.MouseEvent) => void;
}

const mkDashing = (style: LineStyle) => {
	switch (style) {
	case 'Dashed': return '8';
	case 'Dotted': return '1 7';
	default: return undefined;
	}
};

const mkWeight = (style: LineStyle) => {
	switch (style) {
	case 'Dotted': return '2px';
	case 'Heavy': return '2px';
	default: return '1px';
	}
};

export const FigureViewer = ({width, height, ...ps}: UnitViewProps<Figure> & FigureSize) => {
	const [pan, setPan] = React.useState<V2>([0, 0]);
	const [zoom, setZoom] = React.useState<number>(0.5);
	const [rotAngle, setRotAngle] = React.useState<number>(0);

	const toFigP = useCallback((p: V2) =>
		Vec.v2_sub(Vec.v2_scale(1/zoom)(p))(pan)
	, [pan, zoom]);

	const proj = useCallback((p: P3) =>
		Pnt.lowerXZ3(Pnt.map(Vec.aboutZ(rotAngle))(p))
	, [rotAngle]);

	const doPan = useCallback((dxy: V2) =>
		setPan(Vec.v2_add(Vec.v2_scale(1/zoom)(dxy)))
	, [zoom]);

	const doZoom = useCallback((dzf: number, pnt: V2) => {
		const fpt = toFigP(pnt);

		if (dzf < 0.1 || dzf > 10)
			return;

		setPan(p => Vec.v2_add(p)(Vec.v2_scale(1 / dzf - 1)(Vec.v2_add(fpt)(p))));
		setZoom(z => z * dzf);
	}, [toFigP, setPan, setZoom]);

	const onCenter = useCallback((size: V2) => {
		const { pan, zoom } = fit(proj, 16, size)(ps.value);
		setPan(pan);
		setZoom(zoom);
	}, [proj, setPan, setZoom, ps.value]);

	const doRotate = useCallback((a: number, _p: P2) =>
		setRotAngle(b => a + b)
	, [setRotAngle]);

	return (
		<ViewFigureWrap
			pref="Figure"

			pan={pan}
			zoom={zoom}
			width={width ?? '100%'}
			height={height ?? '100%'}

			onPan={doPan}
			onZoom={doZoom}
			onRotate={doRotate}
			onCenter={onCenter}
		>
			{ (hs: ItemHandlers) => <FragFig proj={proj} zoom={zoom} {...hs} {...ps} /> }
		</ViewFigureWrap>
	);
};

export const ViewFigureWrapRef = <V extends number[]>({ref, ...ps}: ViewFigure<V> & FigureChildren & {ref ?: React.ForwardedRef<SVGSVGElement>}) => {
	const iref = useForwardedRef(ref ?? null);

	useEffect(() => {
		const elm = iref.current;
		const def = (evt: Event) => { evt.preventDefault(); };

		if (!elm)
			return;

		elm.addEventListener('wheel', def);
		elm.addEventListener('touchend', def);
		elm.addEventListener('touchmove', def);
		elm.addEventListener('touchstart', def);

		return () => {
			elm.removeEventListener('wheel', def);
			elm.removeEventListener('touchend', def);
			elm.removeEventListener('touchmove', def);
			elm.removeEventListener('touchstart', def);
		};
	}, [iref]);

	return (
		<ViewFigure ref={iref} {...ps} />
	);
};

export const ViewFigureWrap = React.forwardRef((ps, ref) => ViewFigureWrapRef({ref, ...ps})) as <V>(ps: ViewFigure<V> & FigureChildren & {ref ?: React.ForwardedRef<SVGSVGElement>}) => ReturnType<typeof ViewFigureWrapRef>;

export const ViewFigureRef = <V extends number[]>({ref, pan, zoom, width, height, children, ...ps}: ViewFigure<V> & FigureChildren & {ref ?: React.ForwardedRef<SVGSVGElement>}) => {
	const iref = useForwardedRef(ref ?? null);

	const [btnMid, setBtnMid] = useState<boolean>(false);
	const [touch, setTouch] = useState<Touch>();
	const [activeType, setActiveType] = useState<ItemType>();
	const [activeItem, setActiveItem] = useState<IId>();

	const {onPan: doPan, onZoom: doZoom, onRotate: doRotate, onCenter: doCenter} = ps;
	const {onItemMove: doItemMove} = ps;

	// +[OPEN: MOUSE]
	const onWheel = useCallback((evt: React.WheelEvent) => {
		const rect = (evt.target as HTMLDivElement).getBoundingClientRect();
		const spot = Pnt.p(Vec.v2_sub([evt.clientX, evt.clientY])([rect.left, rect.top]));

		if (evt.shiftKey || evt.metaKey)
			return;

		if (evt.ctrlKey) {
			doZoom?.(Math.exp(-evt.deltaY / 1000), spot);
			doRotate?.(evt.deltaX / 10, spot);
			return;
		}

		doPan?.(Vec.v2_scale(-0.5)([evt.altKey ? evt.deltaY : evt.deltaX , evt.altKey ? evt.deltaX : evt.deltaY]));
	}, [doPan, doZoom, doRotate] );

	const onMouseUp = useCallback((evt: React.MouseEvent) => {
		if (evt.button === 1)
			setBtnMid(false);

		setActiveType(undefined);
		setActiveItem(undefined);
	}, [setBtnMid, setActiveType, setActiveItem]);

	const onMouseMove = useCallback((evt: React.MouseEvent) => {
		if (btnMid) {
			doPan?.([evt.movementX, evt.movementY] as V2);
			return;
		}

		if (!iref || !iref.current)
			return;

		const rect = iref.current.getBoundingClientRect();
		const spot = [evt.clientX - rect.left, evt.clientY - rect.top] as P2;

		if (activeItem && activeType)
			doItemMove?.(activeType, activeItem, spot);
	}, [iref, doPan , btnMid, doItemMove, activeType, activeItem]);

	const onMouseDown = useCallback((evt: React.MouseEvent) => {

		if (evt.button === 1)
			setBtnMid(true);

		evt.preventDefault();
	}, [setBtnMid]);

	const onItemMouseDown = useCallback((typ: ItemType, iid: IId) => (evt: React.MouseEvent) => {
		if (activeItem || evt.button !== 0)
			return;

		setActiveType(typ);
		setActiveItem(iid);
	}, [activeItem, setActiveType, setActiveItem]);

	const onClick = useCallback(() => {
		iref?.current?.focus();
	}, [iref]);
	// -[SHUT: MOUSE]

	// +[OPEN: TOUCH]
	const touchEnds = useCallback((evt: React.TouchEvent) => {
		if (evt.touches.length <= 1)
			return;

		setTouch(touchBlend(touchSpots(evt.target, evt.touches)));
	}, [setTouch]);

	const onTouchEnd = touchEnds;

	const onTouchMove = useCallback((evt: React.TouchEvent) => {
		const ntch = touchBlend(touchSpots(evt.target, evt.touches));
		if (!touch) {
			setTouch(ntch);
			return;
		}

		if (evt.touches.length <= 1)
			return;

		doPan?.(Vec.v2_sub(ntch.cntr)(touch.cntr));
		doZoom?.(ntch.dist / touch.dist, touch.cntr);

		setTouch(ntch);
	}, [touch, doPan, doZoom, setTouch]);

	const onTouchStart = touchEnds;
	// -[SHUT: TOUCH]

	// -[OPEN: KEYBOARD]
	const onKeyDown = useCallback((evt: React.KeyboardEvent) => {
		console.log('KeyDown');
		if (evt.key === 'c') {
			const svg = iref.current;
			if (svg === null || doCenter === undefined) return;
			doCenter([svg.clientWidth, svg.clientHeight]);
		}
	}, [doCenter, iref]);
	// -[SHUT: KEYBOARD]

	return (
		<svg
			ref={iref}
			tabIndex={0}

			width={width}
			height={height}

			onWheel={onWheel}

			onTouchEnd={onTouchEnd}
			onTouchMove={onTouchMove}
			onTouchStart={onTouchStart}

			onMouseUp={onMouseUp}
			onMouseMove={onMouseMove}
			onMouseDown={onMouseDown}

			onClick={onClick}
			onKeyDown={onKeyDown}

			style={{'--font-size' : `${16/zoom}px`} as React.CSSProperties }
		>
			<g transform={`scale(${zoom}) translate(${pan[0]} ${pan[1]})`}>
				{children({aid : activeItem, onItemMouseDown: onItemMouseDown, ...ps})}
			</g>
		</svg>
	);
};

export const ViewFigure = React.forwardRef((ps, ref) => ViewFigureRef({ref, ...ps})) as <V>(ps: ViewFigure<V> & FigureChildren & {ref ?: React.ForwardedRef<SVGSVGElement>}) => ReturnType<typeof ViewFigureRef>;

export const FragFig = ({value: figure, ...ps}: FigureProps<Figure> & ItemHandlers) =>
	<g className='Figure'>
		{figure.Pieces.map((piece, i) => <FragPiece key={i} value={piece} {...ps} />)}
	</g>
;

export const FragPiece = ({value: piece, ...ps}: FigureProps<Piece> & ItemHandlers) =>
	<g className='Piece'>
		<g className='Curves'>
			{piece.Curves.map(curve => <FragCurve key={curve.Id.join('-')} value={curve} {...ps} />)}
		</g>

		<g className='CBoxes'>
			{piece.Curves.map(curve => <FragBoxes key={curve.Id.join('-')} value={curve} {...ps} />)}
		</g>

		<g className='Rulers'>
			{piece.Rulers.map(ruler => <FragRuler key={ruler.Id.join('-')} value={ruler} {...ps} />)}
			{piece.Rulers.map(ruler => <FragAngular key={ruler.Id.join('-')} value={ruler} {...ps} />)}
		</g>
	</g>
;

export const FragCurve = ({value: curve, proj}: FigureProps<Curve>) => {
	const curveId = curve.Id.join('-');
	const curveClass = curve.Class.join(' ');

	return (
		<path
			key={curveId}
			id={`curve-${curveId}`}
			className={`${css.Curve} ${css[curveClass] ?? ''}`}
			d={segsPath(proj)(curve.Segments)}
		/>
	);
};

export const FragBoxes = ({value: curve, proj, actionables}: FigureProps<Curve>) => {
	if (actionables && !actionables.includes('EdgeBox'))
		return null;

	const curveId = curve.Id.join('-');
	const curveClass = curve.Class.join(' ');

	const [fst, rst] = splitAtParam(1/3)(curve.Segments);
	const [mid, lst] = splitAtParam(1/2)(rst);

	return (
		<>
			<path id={`hitbox-mid-${curveId}`} className={`${css.Hitbox} ${css.Mid} ${css[curveClass] ?? ''}`} d={segsPath(proj)(mid)}/>
			<path id={`hitbox-fst-${curveId}`} className={`${css.Hitbox} ${css.Fst} ${css[curveClass] ?? ''}`} d={segsPath(proj)(fst)}/>
			<path id={`hitbox-lst-${curveId}`} className={`${css.Hitbox} ${css.Lst} ${css[curveClass] ?? ''}`} d={segsPath(proj)(lst)}/>
		</>
	);
};

export const FragAngular = ({value : ruler, zoom, proj}: FigureProps<Ruler> & ItemHandlers) => {
	const rlr : Measure = ruler.For;

	if (rlr.type !== 'Angular')
		return null;

	const sze: number = 10 / zoom;
	const left: V2 = Vec.scale(sze)(Vec.unit(Pnt.sub(proj(rlr.Left))(proj(rlr.Middle))));
	const rght: V2 = Vec.scale(sze)(Vec.unit(Pnt.sub(proj(rlr.Right))(proj(rlr.Middle))));
	const midl: V2 = proj(rlr.Middle);

	const mpt: P2 = Pnt.p(midl);
	const lpt: P2 = Pnt.p(Vec.add(midl)(left));
	const rpt: P2 = Pnt.p(Vec.add(midl)(rght));
	const cpt: P2 = Pnt.p(Vec.add(Vec.add(midl)(left))(rght));

	const angl: number = Math.acos(Vec.dot(left)(rght) / (Vec.norm(left) * Vec.norm(rght))) / (2 * Math.PI) * 360;

	if (!(Math.abs(angl - 90) <= 0.00001))
		return null;

	return <polygon points={`${mpt[0]},${mpt[1]} ${lpt[0]},${lpt[1]} ${cpt[0]},${cpt[1]} ${rpt[0]},${rpt[1]}`} className={css['Ruler-angle']} />;
};

export const FragRuler = ({value: ruler, zoom, proj, lengthConverter, angleConverter, actionables, ...ps}: FigureProps<Ruler> & ItemHandlers) => {
	if (!ruler.Info.Visible) return null;

	const len = Vec.norm(Pnt.sub(ruler.For.Left)(ruler.For.Right));

	const actRlr = actionables && actionables.includes('Ruler');
	const actLbl = actionables && actionables.includes('RulerLabel');

	if (ruler.For.type === 'Angular' || len < 0.00001 || !ruler.Info.Visible)
		return null;

	const rulerId = ruler.Id.join('-');
	const rulerClass = ruler.Class.join(' ');

	const wsk = ruler.Info.ExtendWhisker;

	const val = ruler.Value === '+inf' ? 0 : Number(ruler.Value);

	const lbl =
		ruler.Unit === 'mm' ? lengthConverter.build(val) :
			ruler.Unit === 'deg' ? angleConverter.build(val) :
				ruler.Value.toString();

	const { dir, label, left, right } = rulerPositions(32/zoom)(ruler);
	const label_position = proj(label);

	const active = (ps.aid === ruler.Id) ? 'active' : '';

	const ruler_left = proj(left);
	const ruler_right = proj(right);
	const obj_left = proj(ruler.Object.Start);
	const obj_right = proj(ruler.Object.End);

	const wisker_offset = Vec.scale(5/32)(dir);
	const wp1 = proj(Pnt.add(left)(wisker_offset));
	const wps1 = proj(Pnt.subV(left)(wisker_offset));
	const wp2 = proj(Pnt.add(right)(wisker_offset));
	const wps2 = proj(Pnt.subV(right)(wisker_offset));

	const vec = (() => {
		switch (ruler.Info.LabelOrient) {
		case 'ObjectAcross': return Vec.v2_perp(Pnt.sub(obj_right)(obj_left));
		case 'ObjectAlong': return Pnt.sub(obj_right)(obj_left);
		case 'RulerAcross': return Vec.v2_perp(Pnt.sub(ruler_right)(ruler_left));
		case 'RulerAlong': return Pnt.sub(ruler_right)(ruler_left);
		}})();
	const angle = Math.atan2(vec[1], vec[0]) * 180 / Math.PI;
	const rot = Math.abs(angle) > 90 ? angle + 180 : angle;

	const whiskerDashing = mkDashing(ruler.Info.WhiskerStyle);
	const whiskerWeight = mkWeight(ruler.Info.WhiskerStyle);
	const dashing = mkDashing(ruler.Info.BarStyle);
	const weight = mkWeight(ruler.Info.BarStyle);

	return (
		<g id={`Ruler-${rulerId}`} className={`${css.Ruler} ${css[rulerClass] ?? ''}`} >
			<line x1={ruler_left[0]} y1={ruler_left[1]} x2={ruler_right[0]} y2={ruler_right[1]} strokeDasharray={dashing} strokeWidth={weight} className={css['Ruler-line']} />
			<line x1={wps1[0]} y1={wps1[1]} x2={wp1[0]} y2={wp1[1]} className={css['Ruler-whisker']} />
			<line x1={wps2[0]} y1={wps2[1]} x2={wp2[0]} y2={wp2[1]} className={css['Ruler-whisker']} />
			<text transform={`translate(${label_position[0]} ${label_position[1]}) rotate(${rot})`} >{lbl}</text>

			{ wsk ? <line x1={obj_left[0]} y1={obj_left[1]} x2={ruler_left[0]} y2={ruler_left[1]} strokeDasharray={whiskerDashing} strokeWidth={whiskerWeight} className={css['Ruler-whisker-extended']} /> : null }
			{ wsk ? <line x1={obj_right[0]} y1={obj_right[1]} x2={ruler_right[0]} y2={ruler_right[1]} strokeDasharray={whiskerDashing} strokeWidth={whiskerWeight} className={css['Ruler-whisker-extended']} /> : null }

			{ actRlr ? <line x1={ruler_left[0]} y1={ruler_left[1]} x2={ruler_right[0]} y2={ruler_right[1]} className={`${css['Ruler-handle']} ${css[active]}`} onMouseDown={ps.onItemMouseDown('Ruler', ruler.Id)} /> : null }
			{ actLbl ? <circle cx={label_position[0]} cy={label_position[1]} r={20/zoom} className={`${css['RulerLabel-handle']} ${css[active]}`} onMouseDown={ps.onItemMouseDown('RulerLabel', ruler.Id)} /> : null }
		</g>
	);
};

export const isClosed = <V extends number[]>(segs: Fixed<V>[]): boolean => {
	const b = segs[0];
	const e = segs[segs.length - 1];

	if (b === undefined || e === undefined)
		return false;

	return Pnt.near(b[0])(fixed_end(e));
};

export const segsPath = <V extends number[]>(proj: Fn<P<V>, P2>) => (segs: Fixed<V>[]): string =>
	mapWithPrior(segPathFull(proj), segPathCont(proj))(segs).join(' ')
;

export const segPathFull = <V extends number[]>(proj: Fn<P<V>, P2>) => (seg: Fixed<V>): string =>
	pathAbs(transform(proj)(seg));


export const segPathCont = <V extends number[]>(proj: Fn<P<V>, P2>) => (prev: Fixed<V>, curr: Fixed<V>): string =>
	(Pnt.near(fixed_end(prev))(curr[0]) ? pathRel : pathAbs)(transform(proj)(curr))
;

export const pathAbs = <V extends number[]>(s: Fixed<V>): string =>
	`M ${s[0][0]} ${s[0][1]} ${pathRel(s)}`
;

export const pathRel = <V extends number[]>(s: Fixed<V>): string =>
	isFixedLinear(s) ?
		`L ${s[1][0]} ${s[1][1]}` :
		`C ${s[1][0]} ${s[1][1]} ${s[2][0]} ${s[2][1]} ${s[3][0]} ${s[3][1]}`
;

export interface WatchFiguresProps<T> extends FigureProps<T> , ItemHandlers {
	/**
	 * Render our value into a collection of figures.
	 *
	 * This must be memoized!
	 */
	render: Fn<T,Promise<FigMap<string>>>;
}

type Rendered = boolean | string;

export const WatchFigures = <T extends unknown>({render, value, ...ps}: WatchFiguresProps<T>) => {
	const [ figures, setFigures ] = React.useState<FigMap<string>>({});
	const [ rendered, setRendered ] = React.useState<Rendered>(false);

	useEffect(() => {
		setRendered(false);

		render(value).then(figs => {
			setFigures(figs);
			setRendered(true);
		}).catch(e => {
			console.warn(e);
			setRendered('Error!');
		});
	}, [render, value]);
	return (
		<>
			{ Object.entries(figures).map(([k,v]) => v ? <FragFig key={k} value={v} {...ps} /> : null) }
			{ rendered === true ? null : rendered === false ? <WatchSpinner/> : <WatchError message={rendered}/> }
		</>
	);
};

export const WatchSpinner = () => <></>;
export const WatchError = ({message}: {message: string}) => <foreignObject width="100%" height="100%"> <div style={{margin: '40%'}}> {message} </div> </foreignObject>;
