import React from 'react';
import { ToolBar } from '~/src/ui/toolbar/ToolBar';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
// import TextField from '@mui/material/TextField';
import EditOffIcon from '@mui/icons-material/EditOff';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { ConfigEntryMode, configEntryModes, modeAbbr, organizations, OrgName, orgNameAbbr } from '~/src/admin/glassd-config';

import * as css from './edit-search.module.css';

interface ConfigModeFilterProps {
	selectedMode: ConfigEntryMode[],
	onFilterModeChange: (v: ConfigEntryMode, c: boolean) => void,
}
const ConfigModeFilter = (ps: ConfigModeFilterProps) =>
	<FormControl component="fieldset" className='ConfigModeFilter'>
		<FormGroup aria-label="position" row>
			{configEntryModes.map(mode => {
				return (
					<FormControlLabel
						key={mode}
						value={mode}
						control={<Checkbox
							checked={ps.selectedMode.includes(mode)}
							color="success"
							onChange={(e) => {ps.onFilterModeChange(e.target.value as ConfigEntryMode, e.target.checked);}}
						/>}
						label={`${mode}(${modeAbbr[mode]})`}
						labelPlacement="start"
					/>
				);
			})}
		</FormGroup>
	</FormControl>;

interface ConfigOrgFilterProps {
	selectedOrg: OrgName[],
	onFilterOrgChange: (v: OrgName, c: boolean) => void,
}

const OrganizationFilter = (ps: ConfigOrgFilterProps) =>
	<FormControl component="fieldset" className='OrganizationFilter'>
		<FormGroup aria-label="position" row>
			{organizations.map(org => {
				return (
					<FormControlLabel
						key={org}
						value={org}
						control={<Checkbox
							checked={ps.selectedOrg.includes(org)}
							color="success"
							onChange={(e) => {ps.onFilterOrgChange(e.target.value as OrgName, e.target.checked);}}
						/>}
						label={`${org}(${orgNameAbbr[org]})`}
						labelPlacement="start"
					/>
				);
			})}
		</FormGroup>
	</FormControl>;

interface SearchControlProps {
	filterText: string,
	editMode: boolean,
	hasEdited: boolean;
	filterMode: ConfigEntryMode[],
	filterOrganization: OrgName[],
	onFilterTextChange: (t: string) => void,
	onFilterModeChange: (v: ConfigEntryMode, c: boolean) => void,
	onFilterOrgChange: (v: OrgName, c: boolean) => void,
	onEditModeChange: (e:boolean) => void,
	onSaveConfigs: () => void;
}

export default class SearchControl extends React.Component<SearchControlProps, {}> {
	render() {
		const { hasEdited, filterText, editMode, filterMode, filterOrganization,
			onEditModeChange, onFilterModeChange, onFilterOrgChange, onFilterTextChange, onSaveConfigs } = this.props;
		return (
			<ToolBar className="SearchControl">
				<div className={ css.selections }>
					<OrganizationFilter selectedOrg={filterOrganization} onFilterOrgChange={onFilterOrgChange}></OrganizationFilter>
					<ConfigModeFilter selectedMode={filterMode} onFilterModeChange={onFilterModeChange}></ConfigModeFilter>
				</div>

				<Input
					id="standard-error"
					fullWidth={true}
					placeholder="Search build settings..."
					value={filterText}
					className={ css.search }
					onChange={(e) => { onFilterTextChange(e.target.value);}}
					startAdornment={ <InputAdornment position="start"><SearchIcon /></InputAdornment> }
				/>

				<Tooltip title={editMode ? 'Cancel Edit' : 'Edit'} placement="top">
					<Checkbox
						checked={ editMode }
						onChange={(e) => { onEditModeChange(e.target.checked);}}
						inputProps={{ 'aria-label': 'controlled' }}
						icon={<EditIcon />}
						checkedIcon={<EditOffIcon />}
						className={ css.edit }
					/>
				</Tooltip>

				{hasEdited ?
					(<Tooltip title={'Save'} placement="top">
						<IconButton onClick={() => { onSaveConfigs();}} size="large" component="span" color="info"
							style={{ padding:'inherit'}}>
							<SaveIcon fontSize="inherit" />
						</IconButton>
					</Tooltip>) : null
				}
			</ToolBar>
		);
	}
}
