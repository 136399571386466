import { MimeFile , b64decode } from '~/src/base/Mime';

export type File = 'png' | 'svg' | 'dxf' | 'pdf' | 'json';

export const mime : { [ k in File ] : string } = {
	png : 'image/png',
	svg : 'image/svg+xml',
	dxf : 'application/dxf',
	pdf : 'application/pdf',
	json: 'application/json',
};

export const base : { [ k in File ] : boolean } = {
	png : true,
	svg : true,
	dxf : false,
	pdf : true,
	json: false,
};

export const download = ( name : string , file : File , data : string ) : void => {
	if ( !base[ file ] ) {
		downloadBlob( `${name}.${file}` )( new Blob( [ data ] , { type : mime[ file ] } ) );
	}

	else {
		fetch( `data:${mime[file]};base64,${data}` )
			.then( resp => resp.blob() )
			.then( downloadBlob( `${name}.${file}` ) )
			.catch( err => console.error( `Error downloading '${name}.${file}'`, err))
		;
	}
};

export const downloadBlob = ( name : string ) => ( blob : Blob ) : void => {
	const a = document.createElement( 'a' );
	a.href = URL.createObjectURL( blob );
	a.download = name;

	document.body.appendChild( a );
	a.click();
	document.body.removeChild( a );
};

export const downloadMimeFile = ( file : MimeFile ) : void =>
	downloadBlob( file.Name )( b64decode( file.Data, file.Mime ) )
;
