/**
 * Number types and guards.
 *
 * @since 0.1.0
 */

import { number, Type } from 'io-ts';
import { Two } from '~/src/base/Array/Two';

/**
 * An angle, in degrees, from a negative full turn to a positive full turn.
 *
 * Unfortunately we cannot express this as an actual type in current typescript.
 *
 * @since 0.1.0
 */
export type Angle = number;

/**
 * Codec for Angle.
 *
 * @since 0.1.0
 */
export const Angle: Type<Angle> = number;

export const sin = (theta: Angle): number => Math.sin(theta * Math.PI / 180);
export const cos = (theta: Angle): number => Math.cos(theta * Math.PI / 180);
export const tan = (theta: Angle): number => Math.tan(theta * Math.PI / 180);
export const csc = (theta: Angle): number => 1/Math.sin(theta * Math.PI / 180);
export const sec = (theta: Angle): number => 1/Math.cos(theta * Math.PI / 180);
export const cot = (theta: Angle): number => 1/Math.tan(theta * Math.PI / 180);

export const asin = (x: number): Angle => Math.asin(x) * 180 / Math.PI;
export const acos = (x: number): Angle => Math.acos(x) * 180 / Math.PI;
export const atan = (x: number): Angle => Math.atan(x) * 180 / Math.PI;
export const acsc = (x: number): Angle => Math.asin(1/x) * 180 / Math.PI;
export const asec = (x: number): Angle => Math.acos(1/x) * 180 / Math.PI;
export const acot = (x: number): Angle => Math.atan(1/x) * 180 / Math.PI;

export const atan2 = (v: Two<number>): Angle => Math.atan2(v[1], v[0]) * 180/Math.PI;
