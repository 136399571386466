import * as React from 'react';

import SelectEnum from '~/src/edit/SelectEnum';
import { EditProps } from '~/src/edit/types';

import { PartialOrdering, partialOrderingTrans } from '~/src/model/PartialOrdering';

export interface PartialOrderingParams extends EditProps<PartialOrdering> {
	size?: 'small' | 'medium';
}

export const PartialOrderingEditor = ({value, update, size}: PartialOrderingParams): React.ReactElement =>
	<SelectEnum size={size} items={partialOrderingTrans} value={value} update={update} />;

export default PartialOrderingEditor;
