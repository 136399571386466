import { record , string } from 'io-ts';

import { Requestor } from '~/src/api/Requestor';
import { ListId , PriceList , GridId , PriceGrid , ItemId , PriceItem } from '~/src/model/Pricing';


// GET
export const getPriceListList = ( req : Requestor ) : Promise<Record<string, PriceList>> =>
	req.request(record(string , PriceList))('pricing/list/all', 'GET');

export const getPriceGridList = ( req : Requestor ) : Promise<Record<string, PriceGrid>> =>
	req.request(record(string , PriceGrid))('pricing/grid/all', 'GET');

export const getPriceGrid = ( req : Requestor ) => ( gid : GridId ) : Promise<PriceGrid> =>
	req.request(PriceGrid)(`pricing/grid/${gid}`, 'GET');

export const getPriceList = ( req : Requestor ) => ( lid : ListId ) : Promise<PriceList> =>
	req.request(PriceList)(`pricing/list/${lid}`, 'GET');

export const getPriceItems = ( req : Requestor ) => ( gid : GridId ) : Promise<Record<ItemId, PriceItem>> =>
	req.request(record(string , PriceItem))(`pricing/item/all/${gid}`, 'GET');

export const getPriceItem = ( req : Requestor ) => ( iid : ItemId ) : Promise<PriceItem> =>
	req.request(PriceItem)(`pricing/item/${iid}` , 'GET');


// POST
export const postPriceItem = ( req : Requestor ) => ( itm : PriceItem ) : Promise<PriceItem> =>
	req.request(PriceItem)('pricing/item/' , 'POST' , itm );

export const postPriceGrid = ( req : Requestor ) => ( grd : PriceGrid ) : Promise<PriceGrid> =>
	req.request(PriceGrid)('pricing/grid/' , 'POST' , grd );

export const postPriceList = ( req : Requestor ) => ( lst : PriceList ) : Promise<PriceList> =>
	req.request(PriceList)('pricing/list/' , 'POST' , lst );


// DELETE
export const deletePriceGrid = ( req : Requestor ) => ( gid : GridId ) : Promise<PriceGrid> =>
	req.request(PriceGrid)( `pricing/grid/${gid}` , 'DELETE' );

export const deletePriceItem = ( req : Requestor ) => ( iid : ItemId ) : Promise<PriceItem> =>
	req.request(PriceItem)( `pricing/item/${iid}` , 'DELETE' );
