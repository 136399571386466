import FormControl from '@mui/material/FormControl';
import { createContext, useContext } from 'react';
import { SelectEnum } from '~/src/edit/SelectEnum';
import { EditProps } from '~/src/edit/types';
import { TranslationList, Translations } from '~/src/model/Language';
import { AngleConverters, AreaUnit, AreaUnitConverters, Converter, LengthUnit, lengthUnitConverters, piecesConverter, plainConverter } from '~/src/util/Units';

export type Unit = LengthUnit | AreaUnit | 'deg' | 'pc';

const LengthUnitContext = createContext<LengthUnit>('mm');

export const LengthUnitProvider = LengthUnitContext.Provider;

export const useLengthUnit = () => useContext(LengthUnitContext);

const AreaUnitContext = createContext<AreaUnit>('m²');

export const AreaUnitProvider = AreaUnitContext.Provider;

export const useAreaUnit = () => useContext(AreaUnitContext);

const lengthUnitTransList: TranslationList<LengthUnit> = [
	{key: 'mm', names: { eng: 'mm' }},
	{key: 'inch', names: { eng: 'Inch' }}
];

const lengthUnitTrans: Translations = {
	eng: 'Length Unit',
};

export const LengthUnitEdit = ({value, update}: EditProps<LengthUnit>) =>
	<FormControl style={{ padding: '12px 4px' }}>
		<SelectEnum
			size='small'
			value={value}
			label={lengthUnitTrans}
			items={lengthUnitTransList}
			update={update}
		/>
	</FormControl>;

export const useConverterFor = (unit?: Unit): Converter<number,string> => {
	const lengthUnit = useLengthUnit();
	const areaUnit = useAreaUnit();
	if (LengthUnit.is(unit)) {
		return lengthUnitConverters[unit][lengthUnit];
	} else if (AreaUnit.is(unit)) {
		return AreaUnitConverters[unit][areaUnit];
	} else if (unit == 'deg') {
		return AngleConverters.interior;
	} else if (unit == 'pc') {
		return piecesConverter;
	}
	return plainConverter;
};
