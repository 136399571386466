import * as React from 'react';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { QToolbar } from './QToolbar';
import { Opening } from '~/src/design/opening';
import QDrawerContainer from './QDrawerContainer';
import GhostButton from '~/src/ui/input/GhostButton';
import QDrawerTitle from './QDrawerTitle';
import { Box, IconButton } from '@mui/material';
import { Close, DragHandle } from '@mui/icons-material';
import useResize from '~/src/hooks/useResize';
import { FormProps, Form } from '~/src/edit/types';

export type DrawerContent = Form<Opening>;

export interface QRightDrawerProps extends DrawerProps {
	children?: React.ReactNode,
	Content?: DrawerContent,
	open?: boolean,
	minWidth?: number
	exit?: () => void
	title?: string
}

const QRightDrawer: React.FC<QRightDrawerProps & FormProps<Opening>> = ({
	children,
	minWidth,
	Content,
	lengthConverter,
	angleConverter,
	update,
	value,
	exit,
	title,
	...props
}) => {
	const {
		width,
		enableResize,
	} = useResize({
		minWidth: minWidth || 300
	});

	const handleClose = React.useCallback(() => {
		exit && exit();
	}, [exit]);

	const preventDefault: (e: React.FormEvent<HTMLFormElement>) => void = (e) => e.preventDefault();

	return (
		<Drawer
			variant='persistent'
			anchor='right'
			sx={{
				width: width,
				flexShrink: 0,
				['& .MuiDrawer-paper']: { width: width, boxSizing: 'border-box' },
			}}
			PaperProps={{
				sx: {
					overflow: 'hidden'
				}
			}}
			{...props}
		>
			<QToolbar />
			<IconButton sx={{
				position: 'absolute',
				top: '73px',
				left: '10px'
			}} aria-label='close drawer' onClick={handleClose}>
				<Close />
			</IconButton>
			<Box
				onMouseDown={enableResize}
				onTouchStart={enableResize}
				sx={{
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					left: '0px',
					top: 0,
					bottom: 0,
					cursor: 'col-resize'
				}}><DragHandle sx={{ transform: 'rotate(90deg)' }} /></Box>
			<QDrawerTitle>{title}</QDrawerTitle>
			<QDrawerContainer
				FormProps={{
					onSubmit: preventDefault
				}}
				actions={(
					<GhostButton onClick={handleClose} sx={{ mx: '1' }}>Close</GhostButton>
				)}
			>
				{Content && <Content angleConverter={angleConverter} lengthConverter={lengthConverter} value={value} update={update} />}
			</QDrawerContainer>
		</Drawer>
	);
};

export default QRightDrawer;
