import * as React from 'react';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { ClientTemplateMod } from '~/src/admin/template/mod';
import { TemplateMod } from '~/src/admin/template/mod/inner';
import { Update } from '~/src/base/Function';
import { modOptionNamesChange, optionNameChange } from '~/src/admin/template/mod/inner-optics';
import { flow } from 'fp-ts/lib/function';
import ModTextField from '~/src/ui/input/ModTextField';
import { textValue } from '~/src/edit/util';
import CheckIconButton from '~/src/ui/input/CheckIconButton';
import { modTemplate } from '~/src/admin/template/mod/optics';

export interface EntryTextFieldProps<T> {
	value: T,
	spaceName: string,
	entryLabel: string
	update: Update<ClientTemplateMod>
	templateMod: TemplateMod
}

const modSpaceName = (spaceName: string, entryLabel: string, newValue: string) => optionNameChange(spaceName).modify((prev) => ({ ...prev, [entryLabel]: { eng: newValue }}));

const handleClick = (spaceName: string, entryLabel: string, newValue: string) => () => modTemplate.composeOptional(modOptionNamesChange).modify(modSpaceName(spaceName, entryLabel, newValue));

const EntryNameTextField: React.FC<EntryTextFieldProps<string> & TextFieldProps> = ({
	value,
	spaceName,
	entryLabel,
	update,
	templateMod,
	...textFieldProps
}) => {
	const [temporaryValue, setTemporaryValue] = React.useState<string>(value);

	return (
		<>
			<ModTextField
				size='small'
				onChange={flow(textValue, setTemporaryValue)}
				{...textFieldProps}
				value={temporaryValue} // Component value overrides passed in value
				InputProps={{
					endAdornment: <InputAdornment position='end'>
						<CheckIconButton
							onClick={flow(handleClick(spaceName, entryLabel, temporaryValue), update)}
							disabled={!temporaryValue}
						/>
					</InputAdornment>
				}}
			/>
		</>
	);
};

export default EntryNameTextField;
