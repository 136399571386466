import { interface as iface, keyof, number, string, Type, unknown as unk, partial, intersection, recursion, union, array } from 'io-ts';
import { Price, PriceMap, add, sub, scale, sum, prices } from '~/src/pricing/price';

/** Charges. */
export type Charge = ChargeGroup | ChargeItem;

/** Recalculate the derived fields on a Charge. */
export const normalizeCharge = (c: Charge): Charge =>
	isChargeGroup(c) ? normalizeGroup(c) : normalizeItem(c);

export const isChargeGroup = (x: Charge): x is ChargeGroup =>
	x.hasOwnProperty('Items');

/** A number of ChargeItems collected together into a group. */
export interface ChargeGroup extends ChargeItem {
	/** Regular charges on this Quote. */
	Items: Charge[],
}

/** Recalculate the derived fields on a ChargeGroup. */
export const normalizeGroup = (cg: ChargeGroup): ChargeGroup => {
	const Items = cg.Items.map(normalizeCharge);
	// assume this group is correct if all its items are unchanged
	if (Items.every((v, i) => v === cg.Items[i])) return cg;
	const Price = sum(Items.map(c => c.Extended));
	const ci = normalizeItem(Object.assign({}, cg, { Unit: piece, Price}));
	return Object.assign({}, ci, {Items});
};

/**
 * A single line item in a Quote.
 */
export interface ChargeItem {
	/** Display name of this Quote. */
	Name: string,
	/** Number of Units being sold. */
	Count: number,
	/** Price per Unit. */
	Price: Price,
	/** Units in which an item is sold. */
	Unit: ChargeUnit,
	/** Discounts collected from all Charges (excluding Shipping) grouped by name. */
	Discounts: PriceMap,
	/** Taxes collected from all Charges (excluding Shipping) grouped by name. */
	Taxes: PriceMap,
	/** Additional debugging information. */
	Unveil?: unknown,

	/** Total extended cost of all Charges (excluding Shipping). */
	Extended: Price,
	/** Total of Discounts. */
	Discount: Price,
	/** Extended Price - Discounts. */
	SubTotal: Price,
	/** Total of Taxes. */
	Tax: Price,
	/** Subtotal + Taxes + Shipping Total. */
	Total: Price,
}

/** Recalculate the derived fields on a ChargeItem. */
export const normalizeItem = (item: ChargeItem): ChargeItem => {
	const Extended = scale(item.Count, item.Price);
	const Discount = sum(prices(item.Discounts));
	const SubTotal = sub(Extended, Discount);
	const Tax = sum(prices(item.Taxes));
	const Total = add(SubTotal, Tax);
	// avoid allocating a new item if everything is correct.
	return item.Extended == Extended && item.Discount == Discount && item.SubTotal == SubTotal && item.Tax == Tax && item.Total == Total
		? item : Object.assign({}, item, { Extended, Discount, Tax, SubTotal, Total });
};

/** Charge Units */
export interface ChargeUnit {
	/** Number of Base Units. */
	Count: number,
	/** Base Unit. */
	Unit: DimUnit;
}

/** One Piece */
export const piece: ChargeUnit = { Count: 1, Unit: 'pc' };

/** Dimensional Base Units */
export type DimUnit =
	/** Pieces */
	'pc' |
	/** Length */
	'mm' |
	/** Area */
	'mm²';

export const DimUnit: Type<DimUnit> = keyof({
	pc: true,
	mm: true,
	'mm²': true,
});

export const ChargeUnit: Type<ChargeUnit> = iface({
	Count: number,
	Unit: DimUnit,
});

export const ChargeItem: Type<ChargeItem> = intersection([iface({
	Name: string,
	Count: number,
	Price: Price,
	Unit: ChargeUnit,
	Discounts: PriceMap,
	Taxes: PriceMap,

	Extended: Price,
	Discount: Price,
	SubTotal: Price,
	Tax: Price,
	Total: Price,
}), partial({
	Unveil: unk,
})]);

export const Charge: Type<Charge> = recursion('Charge', () => union([ChargeItem, ChargeGroup]));

export const ChargeGroup: Type<ChargeGroup> = intersection([ChargeItem, iface({
	Items: array(Charge),
})]);
