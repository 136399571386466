import { Chip, ListItem } from '@mui/material';
import { constant, flow } from 'fp-ts/lib/function';
import * as React from 'react';
import { ClientTemplateMod } from '~/src/admin/template/mod';
import { variableRange } from '~/src/admin/template/mod/inner-optics';
import { Update } from '~/src/base/Function';
import { modTemplate } from '~/src/admin/template/mod/optics';
import { RangeSet } from '~/src/design/template/variable';
import { variableRangeSetSet } from '~/src/design/template/variable/optics';
import { NonEmpty, refineNonEmpty } from '~/src/model/Boxes';
import { filterWithIndex } from 'fp-ts/Array';
import SetOptionTextField from '~/src/admin/template/mod/option/edit-text';

const indexFilter = (filterIndex: number) => (i: number, _: number) =>
	i !== filterIndex;

const filterByIndex = (i: number) => filterWithIndex(indexFilter(i));

const refineAndReturnNonEmpty = (filteredArray: number[]) => (arr: NonEmpty<number>) => refineNonEmpty(filteredArray) ? filteredArray : arr;

interface SetOptionProps {
	range: RangeSet
	index: number
	value: number
	label: string
	update: Update<ClientTemplateMod>
}

const SetOption: React.FC<SetOptionProps> = ({ index, value, label, update, range }) => {
	const [editMode, setEditMode] = React.useState(false);

	/*
	 * Assumes that a set value will never need to stay 0
	 * And that the user should edit it immediately
	 */
	React.useEffect(() => {
		if (value === 0) {
			setEditMode(true);
		}
	}, [value, setEditMode]);

	const handleClick = () => setEditMode(oldMode => !oldMode); // Toggles text field

	const handleDelete = (index: number) => (label: string) => modTemplate
		.modify(
			variableRange.modify(
				constant({
					[label]: {
						type: 'Set',
						Set: refineAndReturnNonEmpty(
							filterByIndex(index)(variableRangeSetSet.get(range))
						)(variableRangeSetSet.get(range))
					}
				}))
		);


	return (
		<ListItem sx={{ p: 0.5, width: 'auto', }}>
			{!editMode ?
				<Chip
					label={value}
					variant='outlined'
					onClick={handleClick}
					onDelete={flow(() => handleDelete(index)(label), update)}
				/>
				: <SetOptionTextField range={range} toggleVisibility={handleClick} value={value} update={update} label={label} />
			}
		</ListItem>
	);
};

export default SetOption;
