/**
 * Generic file downloader.
 *
 * @since 0.4.0
 */
import * as React from 'react';
import './FileUpload.css';

import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';

/**
 * FileDownload Props.
 *
 * @since 0.2.0
 */
export interface FileDownloadProps {
	onError?: (e: string) => void;
	file: FileInfo;
	title?: string;
}

export interface FileInfo {
	name: string;
	type: FileType;
	getContent: () => string;
	base64?: true;
}

export type FileType = 'json' | 'svg' | 'dxf' | 'pdf' | 'png';

const mimetype: {[k in FileType]: string} = {
	json: 'application/json',
	svg: 'image/svg+xml',
	dxf: 'image/vnd.dxf',
	pdf: 'application/pdf',
	png: 'image/png',
};

export const download = (file: FileInfo, onError?: (e: string) => void) => {
	try {
		const a = window.document.createElement('a');
		a.target = '_blank';
		const type = mimetype[file.type];
		const content = file.getContent();
		a.href = file.base64
			? `data:${type};base64,` + content
			: window.URL.createObjectURL(new Blob([content], {type}));
		a.download = `${file.name}.${file.type}`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	} catch (_e) {
		onError ? onError('File Download failed') : null;
	}
};

const onClick = (fd: FileDownload) => () => download(fd.props.file, fd.props.onError);

export default class FileDownload extends React.Component<FileDownloadProps, {}> {
	constructor(ps: FileDownloadProps) {
		super(ps);

		this.state = {
		};
	}

	render() {
		const { title } = this.props;
		return <Tooltip title={title ?? ''}>
			<IconButton onClick={onClick(this)} size="medium" component="span" color="info">
				<DownloadIcon fontSize="inherit" />
			</IconButton>
		</Tooltip>;
	}
}
