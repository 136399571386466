import React from 'react';
import { ConfigEntry, ConfigEntryMode, OrganizationConfig, getAllOrgSelectedConfigEntries, OrgSelectedConfig, OrgName} from '~/src/admin/glassd-config';
import ConfigTable from '~/src/admin/glassd-config/edit-table';
import SearchControl from '~/src/admin/glassd-config/edit-search';
import { Requestor } from '~/src/api/Requestor';
import { list, updateConfig } from '~/src/admin/glassd-config/api';
import { Toast, ToastType } from '~/src/view/Toast';

// import * as css from './configs.css';


export interface ConfigTablePageProps {
	req: Requestor;
}
export interface ConfigTablePageState {
	allOrgConfigs: OrganizationConfig[];
	editMode: boolean;
	filterText: string;
	filterMode: ConfigEntryMode[];
	filterOrganization: OrgName[];
	allOrgSelectedConfigEntries: OrgSelectedConfig[];
	hasEdited: boolean;
	editedUsers: string[];
	toastOpen: boolean;
	toastMessage: string;
	toastType: ToastType;
}

const saveConfigs = (page: ConfigTablePage) => {
	const toUpdateConfigs: OrganizationConfig[] = [];
	page.state.editedUsers.forEach(userId => {
		const userConfig = page.state.allOrgConfigs.find(c => c.UserId == userId);
		if (userConfig) toUpdateConfigs.push(userConfig);
	});

	Promise.all(toUpdateConfigs.map(o => updateConfig(page.props.req)(o))).then((updatedConfigs) => {
		page.setState({toastOpen: true, toastType: 'success',
			toastMessage: `Updated config for ${updatedConfigs.map(c => c.Name).join(', ')} sucessfully`,
			hasEdited: false, editedUsers:[]});
	}).catch(() => {
		page.setState({toastOpen: true, toastType: 'error', toastMessage: `Error updating config ${toUpdateConfigs.map(c => c.Name).join(', ')}`});
	});

};

export default class ConfigTablePage extends React.Component<ConfigTablePageProps, ConfigTablePageState> {
	constructor(props: ConfigTablePageProps) {
		super(props);
		this.state = {
			filterText: '',
			editMode: false,
			filterMode: ['shower'],
			filterOrganization: ['Retail'],
			allOrgSelectedConfigEntries: [],
			hasEdited: false,
			editedUsers:[],
			allOrgConfigs: [],
			toastOpen: false,
			toastMessage: '',
			toastType: 'success'
		};

		list(this.props.req).then((allOrgConfigs) => {
			const allOrgSelectedConfigEntries: OrgSelectedConfig[] = getAllOrgSelectedConfigEntries(allOrgConfigs);
			if (allOrgConfigs && allOrgSelectedConfigEntries) {
				this.setState({allOrgSelectedConfigEntries: allOrgSelectedConfigEntries , allOrgConfigs: allOrgConfigs});
			}
		}).catch(console.warn);
	}

	render() {
		const handleConfigEntryUpdate = (value: string | number | boolean, rowId: string, dataKey: string) => {
			const editedUsers = this.state.editedUsers;
			const updatedAllOrgConfigs = this.state.allOrgConfigs;
			const updatedConfigs = this.state.allOrgSelectedConfigEntries.map((row) => {
				if (row.Id === rowId) {
					const updatedEntry: OrgSelectedConfig = { ...row, [dataKey]: value };
					const {UserId, description, key, name, mode, SelectedVendor } = updatedEntry;
					if (!editedUsers.includes(UserId)) editedUsers.push(UserId);
					const updatedOrgConfig: OrganizationConfig | undefined = this.state.allOrgConfigs.find(oc => oc.UserId == UserId);
					if (updatedOrgConfig && updatedOrgConfig.Vendors && SelectedVendor in updatedOrgConfig.Vendors) {
						if (updatedOrgConfig.Vendors[SelectedVendor] != undefined) {
							(updatedOrgConfig.Vendors[SelectedVendor] ?? {})[key] = {key, name, mode, description, value} as ConfigEntry;
							updatedAllOrgConfigs.filter(oc => oc.UserId != UserId).concat(updatedOrgConfig);
						}
					}
					return updatedEntry;
				} else return { ...row };
			} );
			this.setState({allOrgConfigs: updatedAllOrgConfigs, allOrgSelectedConfigEntries: updatedConfigs, hasEdited: true, editedUsers: editedUsers });

		};
		return (
			<div className="screen">
				{this.state.toastOpen ?
					<Toast
						open={this.state.toastOpen}
						message={this.state.toastMessage}
						type={this.state.toastType}
						vertical='top' horizontal='right'
						handleClose={() => { this.setState({toastOpen: false, toastMessage: '', toastType: 'info'}); }}
					></Toast> : <></> }

				<SearchControl
					filterText={this.state.filterText}
					filterMode={this.state.filterMode}
					filterOrganization={this.state.filterOrganization}
					editMode={this.state.editMode}
					hasEdited={this.state.hasEdited}
					onFilterTextChange={(t) => {
						this.setState({ filterText: t });
					}}
					onFilterModeChange={(m,checked) => {
						this.setState({ filterMode: checked ? this.state.filterMode.concat(m) : this.state.filterMode.filter(cm => cm != m)});
					}}
					onFilterOrgChange={(m,checked) => {
						this.setState({ filterOrganization: checked ? this.state.filterOrganization.concat(m) : this.state.filterOrganization.filter(cm => cm != m)});
					}}
					onEditModeChange={(em) => {
						this.setState({ editMode: em });
					}}
					onSaveConfigs={() =>{ saveConfigs(this); }}
				/>

				<ConfigTable
					allOrgSelectedConfigEntries={this.state.allOrgSelectedConfigEntries}
					filterText={this.state.filterText}
					filterOrganization={this.state.filterOrganization}
					editMode={this.state.editMode}
					filterMode={this.state.filterMode}
					onConfigEntryUpdate={handleConfigEntryUpdate}
				/>
			</div>
		);
	}
}
