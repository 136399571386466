import { copy } from './Deep';

export type Lens<T> = { lens: T, path: Array<string> };
export type LensMaybe<T> = Lens<T> | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trace = (root: any = {}, lens: any = {}) => (path: Array<string>, i = 0): Lens<any> | undefined => {
	const p = path[i];
	if (p !== undefined && lens && i < path.length) {
		return trace(root, lens[p])(path, ++i);
	} else {
		if (lens === null || Array.isArray(lens) || Object.keys(lens).length > 0) {
			return { lens: copy(lens), path };
		}

		if (path.length)
			return trace(root, root)(path.slice(0, -1), 0);
		else
			return undefined;
	}
};
