/**
 * ClientTemplateMod.
 *
 * @since 0.1.0
 */
import { Type, keyof, literal, interface as iface, intersection, partial, string, union } from 'io-ts';
import { TranslationMap, TranslationList, Translations } from '~/src/model/Language';
import { TemplateMod } from '~/src/admin/template/mod/inner';

/**
 * Type for Mode.
 */
export type Mode = 'backsplash'
	| 'mirror'
	| 'misc'
	| 'multimirror'
	| 'panel'
	| 'shelf'
	| 'shower'
	| 'tabletop';

/**
 * Translations for modes.
 */
export const modeTranslations: TranslationMap<Mode> = {
	backsplash: {
		eng: 'Backsplash',
	},
	mirror: {
		eng: 'Mirror',
	},
	misc: {
		eng: 'Miscellaneous',
	},
	multimirror: {
		eng: 'Multiple Mirrors',
	},
	panel: {
		eng: 'Panel',
	},
	shelf: {
		eng: 'Shelf',
	},
	shower: {
		eng: 'Shower',
	},
	tabletop: {
		eng: 'Tabletop',
	},
};

/**
 * List of Modes and their names.
 */
export const modeNames = Object.entries(modeTranslations).map(([key, names]) => ({key, names})) as TranslationList<Mode>;

/**
 *
 * Codec for Mode.
 *
 * @since 0.4.0
 */
export const Mode: Type<Mode> = keyof(modeTranslations);

/**
 * Internal type describing what sort of object this mod is based on.
 *
 * @category Id
 */
export type BaseId = Id<'Template'> | Id <'Mod'>;

/**
 * Tagged Identifier type.
 *
 * @category Id
 */
export type Id<T extends string> = Readonly<{
	_: T,
	Id: string,
}>;

/**
 * Tagged Identifier codec.
 *
 * @category Id
 */
export const Id = <T extends string>(x: T): Type<Id<T>> => iface({
	_: literal(x),
	Id: string,
});

/**
 * Specify the template that this mod is based on.
 *
 * @category ModBaseId
 */
export type ModBaseTemplateId = { ClientTemplateId: string };

export const ModBaseTemplateId = iface({ ClientTemplateId: string });

/**
 * Specify the mod that this mod is based on.
 *
 * @category ModBaseId
 */
export type ModBaseModId = { ModId: string };

export const ModBaseModId = iface({ ModId: string });

/**
 * Options for specifying the base template of a template mod.
 *
 * Either the mod modifies a template or a template generated by another mod.
 *
 * @category ModBaseId
 */
export type ModBaseId = ModBaseTemplateId | ModBaseModId;

export const ModBaseId: Type<ModBaseId> = union([ModBaseTemplateId, ModBaseModId]);

export type ModSansId = {
	Id: string;
	UserId: string;
	Mode: Mode;
	ModTemplate: TemplateMod;
	ImageChange?: string;
	NamesChange: Translations;
};

/**
 * Type for ClientTemplateMod.
 *
 * @since 0.1.0
 */
export type ClientTemplateMod = ModSansId & ModBaseId;

/**
 * Codec for ClientTemplateMod.
 */
export const ClientTemplateMod: Type<ClientTemplateMod> = intersection([
	iface({
		Id: string,
		UserId: string,
		Mode: Mode,
		ModTemplate: TemplateMod,
		NamesChange: Translations,
	}),
	partial({
		ImageChange: string,
	}),
	ModBaseId,
]);
