/**
 * Optics for TemplateMod.
 *
 */
import { Lens, Optional, Prism } from 'monocle-ts';
import { optionalString, presentInList } from '~/src/optics/Common';
import { specialization, specSpace, optionSelection, variableRange } from '~/src/admin/template/mod/inner-optics';

import { ClientTemplateMod, Mode, BaseId, ModBaseTemplateId, Id, ModSansId } from '~/src/admin/template/mod';
import { TemplateMod, OptionSelection, VariableRange } from '~/src/admin/template/mod/inner';
import { Translations } from '~/src/model/Language';

/**
 * Focus on the Id in a ClientTemplateMod.
 *
 * @category ClientTemplateMod
 */
export const id: Lens<ClientTemplateMod,string> = Lens.fromProp<ClientTemplateMod>()('Id');

/**
 * Focus on the baseId in a ClientTemplateMod.
 *
 * @category ClientTemplateMod
 */
export const modBaseId: Lens<ClientTemplateMod,BaseId> = new Lens(
	(t: ClientTemplateMod) => ModBaseTemplateId.is(t) ? {_: 'Template', Id: t.ClientTemplateId} : {_: 'Mod', Id: t.ModId},
	(a: BaseId) => (t: ClientTemplateMod): ClientTemplateMod => Object.assign({}, modSansId.get(t),
		Id('Mod').is(a) ? {ModId: a.Id} : {ClientTemplateId: a.Id}
	),
);

export const modSansId: Lens<ClientTemplateMod,ModSansId> = new Lens(
	(t: ClientTemplateMod): ModSansId => {
		if (ModBaseTemplateId.is(t)) {
			const { ClientTemplateId, ...rest } = t;
			return rest;
		}
		const { ModId, ...rest } = t;
		return rest;
	},
	(a: ModSansId) => (t: ClientTemplateMod) => Object.assign({}, t, a),
);

export const idOfType = <S extends string, T extends S>(x: T) => Prism.fromPredicate<Id<S>,Id<T>>(Id(x).is);

export const idId = <T extends string>() => Lens.fromProp<Id<T>>()('Id');


/**
 * Focus on the UserId in a ClientTemplateMod.
 *
 * @category ClientTemplateMod
 */
export const userId: Lens<ClientTemplateMod,string> = Lens.fromProp<ClientTemplateMod>()('UserId');

/**
 * Focus on the Mode in a ClientTemplateMod.
 *
 * @category ClientTemplateMod
 */
export const mode: Lens<ClientTemplateMod,Mode> = Lens.fromProp<ClientTemplateMod>()('Mode');

/**
 * Focus on the ModTemplate in a ClientTemplateMod.
 *
 * @category ClientTemplateMod
 */
export const modTemplate: Lens<ClientTemplateMod,TemplateMod> = Lens.fromProp<ClientTemplateMod>()('ModTemplate');

/**
 * Focus on the presence/absense of a particular specialization entry in a `ClientTemplateMod`.
 *
 * @category TemplateMod
 */
export const modSpaceEntry = (space: string) => (entry: string): Lens<ClientTemplateMod,boolean> => modTemplate.compose(specialization).compose(specSpace(space)).compose(presentInList(entry));

/**
 * Focus on a change in selection of a `ClientTemplateMod`.
 *
 * @category TemplateMod
 */
export const modSpaceSelection = (space: string): Lens<ClientTemplateMod,string|undefined> => modTemplate.compose(optionSelection).compose(Lens.fromProp<OptionSelection>()(space));

/**
 * Focus on the ImageChange in a ClientTemplateMod.
 *
 * @category ClientTemplateMod
 */
export const imageChange: Lens<ClientTemplateMod,string> = Lens.fromProp<ClientTemplateMod>()('ImageChange').compose(optionalString);

/**
 * Focus on the Translations in a ClientTemplateMod.
 *
 * @category ClientTemplateMod
 */
export const namesChange: Lens<ClientTemplateMod,Translations> = Lens.fromProp<ClientTemplateMod>()('NamesChange');

/**
 * Focus on the VariableRange in a ClientTemplateMod.
 *
 * @category ClientTemplateMod
 */
export const clientModVariableRange: Optional<ClientTemplateMod, VariableRange> = modTemplate.composeOptional(variableRange);
