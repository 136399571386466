import { array, failure, Is, success, Type } from 'io-ts';
import { Fn } from '../Function';
import * as Either from 'fp-ts/Either';
import { hasProp } from '../Type';
import { pipe } from 'fp-ts/lib/function';

/**
 * A single element.
 */
export type One<T> = [T];

/**
 * Check whether a value is One of something.
 */
export const is = <T>(isT: Is<T>) => (x: unknown): x is One<T> =>
	typeof x === 'object' && x !== null &&
	hasProp(x, 'length') && x.length === 1 &&
	hasProp(x, '0') && isT(x[0]);

/**
 * Reverse a container.
 */
export const reverse = <T>(xs: One<T>): One<T> => xs;

/**
 * Clone a container, but not the items within it.
 */
export const clone = <T>(xs: One<T>): One<T> => [ xs[0] ];

/**
 * Apply a function to every element of the container.
 */
export const map = <A,B>(f: Fn<A,B>) => (o: One<A>): One<B> => [ f(o[0]) ];

/**
 * Zip two containers together with a function.
 */
export const zip = <A,B,C>(f: (x: A, y: B) => C) => (xs: One<A>, ys: One<B>): One<C> =>
	[ f(xs[0],ys[0]) ];

/**
 * Generate a container of As from a single example.
 */
export const pure = <A>(x: A): One<A> => [x];

/**
 * Make a single contained element.
 */
export const mk = pure;

/**
 * Encode or decode One of something.
 */
export const One = <T,S>(codecT: Type<T,S>) => new Type<One<T>,[S]>(
	`One<${codecT.name}>`,
	is(codecT.is),
	(input, context) => pipe(
		array(codecT).validate(input, context),
		Either.chain(xs => refineArray(xs) ? success(xs) : failure(xs, context, `Has ${xs.length} elements.`)),
	),
	(x: One<T>) => [ codecT.encode(x[0]) ],
);

/**
 * Refine a list into a Two, if it has exactly two elements.
 */
export const refineArray = <T>(x: T[]): x is [T] => x.length === 1;

export default One;
