import { Type, type, string } from 'io-ts';

import { NonEmpty } from '~/src/base/Array/NonEmpty';

import { P } from '~/src/geometry/point';
import { Item } from './Item';
import { LabelInfo } from './LabelInfo';
import { V3 } from '~/src/geometry/vector';

export interface Label extends Item {
	Info: LabelInfo;
	For: P<V3>;
	Guide: P<V3>;
	Text: string;
}

export const Label: Type<Label> = type({
	Id: NonEmpty(string),
	Class: NonEmpty(string),
	Info: LabelInfo,
	For: P(V3),
	Guide: P(V3),
	Text: string,
});
