/**
 * Building Raw Templates and getting information about them.
 *
 * @since 0.4.0
 */
import { array } from 'io-ts';
import { Template } from '~/src/design/template';
import { Requestor } from '~/src/api/Requestor';

/**
 * Get raw template by id.
 *
 * @since 0.4.0
 */
export const list = (req: Requestor): Promise<Template[]> =>
	req.request(array(Template))('template/list/raw', 'GET');

/**
 * Get raw template by id.
 *
 * @since 0.4.0
 */
export const listById = (req: Requestor) => (id: string): Promise<Template> =>
	req.request(Template)(`template/list/raw/${id}`, 'GET');
