import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';

export interface ColumnProp {
	id: string;
	width: string;
	label: string;
}

interface TreeViewColumnProps {
	column: ColumnProp;
}

const TreeViewColumn = ({ column }: TreeViewColumnProps) => {
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

	const handleChangeOrder = () => {
		setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
	};

	return (
		<Box
			className="tree-view-column"
			style={{
				width: column.width,
				minWidth: column.width,
				maxWidth: column.width,
			}}
			onClick={handleChangeOrder}
		>
			<Typography className="tree-view-column__title">
				{column.label}
			</Typography>
			<ExpandMoreIcon
				className={classNames('tree-view-column__icon', {
					desc: sortOrder === 'desc',
				})}
			/>
		</Box>
	);
};

export default TreeViewColumn;
