import { Template, Option, Variable, LabelValueObj } from '~/src/design/template';
import { Requestor } from '~/src/api/Requestor';

import Box from '@mui/material/Box';

import { TemplateViewer } from '~/src/design/template/view';
import { isMet } from '~/src/design/template/dependency';
import { Update } from '~/src/base/Function';

import TemplateDataPanel from './TemplateDataPanel';

// Styles

import './TreeViewTemplate.css';
import { BulkDesign, BulkDesignLeaf } from '~/src/design/bulk';

export interface TemplateParams {
	req: Requestor;
	limits?: string;
	template: Template;
	update: Update<Template>;
	selectedNode?: BulkDesign | BulkDesignLeaf;
	handleDuplication: () => void;
	setFieldBasedOnPath: (field: 'Name' | 'Count', newValue: string | number) => void;
}
export interface TemplateState {}

const isSelected =
	(opts: Option[]) =>
		(optLabel: string) =>
			(entLabel: string): boolean => {
				const opt = opts.find((opt) => opt.Label === optLabel);
				if (opt === undefined) return false;
				return opt.Value === entLabel;
			};

export const isSelectedOption =
	(tmpl: Template) =>
		(opt: Option): boolean =>
			opt.Range.length > 1 && isMet(isSelected(tmpl.Opts))(opt.Depends);

export const isSelectedVariable =
	(tmpl: Template) =>
		(v: Variable): boolean =>
			isMet(isSelected(tmpl.Opts))(v.Depends);

export const getTemplateSelections = (tmpl: Template): LabelValueObj => {
	const sOpts = tmpl.Opts.filter((opt) => isSelectedOption(tmpl)(opt)).map(
		(o) => [o.Label, o.Value]
	);
	const sVars = tmpl.Vars.filter((opt) => isSelectedVariable(tmpl)(opt)).map(
		(o) => [o.Label, o.Value]
	);
	return Object.assign(
		{},
		Object.fromEntries(sOpts),
		Object.fromEntries(sVars)
	);
};

export const TreeViewTemplateEditor = (props: TemplateParams) => {
	const { req, template, update, selectedNode, setFieldBasedOnPath, handleDuplication } = props;

	return (
		<Box className="treeview-template">
			<TemplateViewer req={req} template={template} />
			<TemplateDataPanel
				value={template}
				selectedNode={selectedNode}
				setFieldBasedOnPath={setFieldBasedOnPath}
				update={update}
				handleDuplication={handleDuplication}
			/>
		</Box>
	);
};
