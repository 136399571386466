/**
 * Generic file uploader.
 *
 * @since 0.4.0
 */
import * as React from 'react';
import './FileUpload.css';

import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '@mui/icons-material/Upload';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

/**
 * FileUpload props.
 *
 * @since 0.4.0
 */
export interface FileUploadProps {
	onSuccess?: (e: string) => void;
	onError?: (e: ProgressEvent<FileReader>) => void;
	accept?: string; // for now
	title?: string;
}

/**
 * FileUpload state.
 *
 * @since 0.4.0
 */
export interface FileUploadState {
		files?: FileList;
}

const Input = styled('input')({
	display: 'none',
});

const onChange = (fu: FileUpload) => (e: React.ChangeEvent<HTMLInputElement>) => {
	e.preventDefault();

	const files = e.target.files;

	if (files) {
		const reader = new FileReader();

		reader.onload = (e: ProgressEvent<FileReader>) => {
			if (fu.props.onSuccess) {
				/* ... for now skip ArrayBuffer */
				if (e.target && e.target.result && typeof e.target.result === 'string') {
					fu.props.onSuccess(e.target.result);
				}
			}
		};
		reader.onerror = (e: ProgressEvent<FileReader>) => {
			if (fu.props.onError) fu.props.onError(e);
		};

		if (files[0]) {
			reader.readAsText(files[0], 'UTF-8');
		}

		fu.setState({ files });
	}
};

export default class FileUpload extends React.Component<FileUploadProps, FileUploadState> {
	constructor(ps: FileUploadProps) {
		super(ps);

		this.state = {
		};
	}

	render() {
		const { title, accept } = this.props;

		return <Tooltip title={title ?? ''}>
			<label htmlFor="contained-button-file">
				<Input accept={accept ?? '*'}
					id="contained-button-file"
					type="file" onChange={onChange(this)} />
				<IconButton size="medium" component="span" color="info">
					<UploadIcon fontSize="inherit" />
				</IconButton>
			</label>
		</Tooltip>;
	}
}
