import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
	Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ErrorIcon from '@mui/icons-material/Error';
import { Option, Variable } from '~/src/design/template';
import { Group } from '~/src/design/template/groups';
import { Update } from '~/src/base/Function';
import { OptionEditor } from '~/src/design/template/option/edit';
import { VariableEditor } from '~/src/design/template/variable/edit';
import { groupFold } from '~/src/design/template/optics';
import { comp } from '~/src/base/Function';
import { index } from '~/src/base/Optic';
import { toggle } from '~/src/base/Boolean';
import { sus } from '~/src/base/Memoize';

import './DataGroup.css';

type NewVarType = Variable & { Type: 'Vars'; ArrIndex: number };
type NewOptType = Option & { Type: 'Opts'; ArrIndex: number };

type TemplateLayoutGroupType = Group<NewVarType | NewOptType>;

interface VarOptGroupProps {
	data: TemplateLayoutGroupType;
	updateOpts: Update<Option[]>;
	updateVars: Update<Variable[]>;
	update: Update<Group>;
}

const DataGroup = (props: VarOptGroupProps) => {
	const { data, updateOpts, updateVars, update } = props;
	const { Items, Label, Names, Fold } = data;
	const isOption = (ps: NewOptType | NewVarType): ps is NewOptType =>
		ps.Type === 'Opts';

	return (
		<Accordion expanded={!Fold} style={{ margin: 0 }}>
			<AccordionSummary
				aria-controls="panel1d-content"
				className="q-datagroup__summary"
				onClick={sus(comp(update, groupFold), toggle)}
				expandIcon={
					<Box>
						<AddIcon
							className="q-datagroup__summary-addicon"
							sx={{ color: '#E2E8F0' }}
						/>
						<RemoveIcon
							className="q-datagroup__summary-removeicon"
							sx={{ color: '#E2E8F0' }}
						/>
					</Box>
				}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: '10px',
					}}
				>
					<Typography className="q-datagroup__summar-title">
						{Names.eng ?? Label}
					</Typography>
					<ErrorIcon sx={{ color: '#718096', width: '20px', height: '20px' }} />
				</Box>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					padding: '20px 6px 8px',
					backgroundColor: 'var(--gray-800, #1A202C)'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						rowGap: '20px'
					}}
				>
					{Items.map((ps) => {
						return isOption(ps) ? (
							<OptionEditor
								update={comp(updateOpts, index(ps.ArrIndex))}
								value={ps}
								key={ps.Label}
							/>
						) : (
							<VariableEditor
								update={comp(updateVars, index(ps.ArrIndex))}
								value={ps}
								key={ps.Label}
							/>
						);
					})}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

export default DataGroup;
