import { number, Type, type, literal, union, string } from 'io-ts';
import { Item } from './Item';
import { RulerInfo } from './RulerInfo';
import { RulerObject } from './RulerObject';
import { P, lerp, add, sub } from '~/src/geometry/point';
import * as Vec from '~/src/geometry/vector';
import { NonEmpty } from '~/src/base/Array/NonEmpty';
import { V3 } from '~/src/geometry/vector';

/**
 * Length of a line segment.
 */
export interface Distance {
	type: 'Distance';
	Left: P<V3>,
	Right: P<V3>,
}

export const Distance: Type<Distance> = type({
	type: literal('Distance'),
	Left: P(V3),
	Right: P(V3),
});

/**
 * Angle between two line segments.
 */
export interface Angular {
	type: 'Angular';
	Left: P<V3>,
	Middle: P<V3>,
	Right: P<V3>,
}

export const Angular: Type<Angular> = type({
	type: literal('Angular'),
	Left: P(V3),
	Middle: P(V3),
	Right: P(V3),
});

/**
 * What is being measured by this ruler.
 */
export type Measure = Distance | Angular

export const Measure: Type<Measure,Measure> = union([Distance, Angular]);

/**
 * Locate the midpoint of the measurement.
 */
export const measureMidpoint = (measure: Measure): P<V3> =>
	measure.type === 'Distance' ? lerp(0.5)(measure.Left)(measure.Right) :
		measure.Middle;

/**
 * Find the position of the ruler and label.
 */
export type RulerPositions = {
	/** The direction from the guide to the ruler. */
	dir: V3,
	/** The offset to the ruler. */
	offset: V3,
	/** The left side/start of the ruler. */
	left: P<V3>,
	/** The right side/end of the ruler. */
	right: P<V3>,
	/** The position of the label */
	label: P<V3>,
};

export const rulerPositions = (scale: number) => (ruler: Ruler): RulerPositions => {
	const dir = direction(scale)(ruler);
	const offset = Vec.scale(ruler.Info.Layer)(dir);
	const label_offset = Vec.scale(ruler.Info.Layer + ruler.Info.LabelLayer)(dir);
	const midpoint = measureMidpoint(ruler.For);

	const label = add(midpoint)(label_offset);
	const left = add(ruler.For.Left)(offset);
	const right = add(ruler.For.Right)(offset);
	return {dir, offset, left,right,label};
};

/**
 * Extract the offset to the ruler, assuming layer 1, relative to its object.
 */
export const direction = (len: number) => (ruler: Ruler): V3 =>
	Vec.size(len)(sub(ruler.Guide)(ruler.For.Left));

/**
 * A measurement of something on a figure.
 */
export interface Ruler extends Item {
	Info: RulerInfo;
	For: Measure;
	Object: RulerObject;
	Guide: P<V3>;
	Unit: string;
	Value: number | string;
}

export const Ruler: Type<Ruler> = type({
	Id: NonEmpty(string),
	Class: NonEmpty(string),
	Info: RulerInfo,
	For: Measure,
	Object: RulerObject,
	Guide: P(V3),
	Unit: string,
	Value: union([number, string]),
});
