import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import useAccordion from '~/src/hooks/useAccordion';
import RemoveIcon from '@mui/icons-material/Remove';

const QAccordionSummary = styled(AccordionSummary)(({
	background: 'transparent',
	'&.Mui-expanded': {
		minHeight: 'unset'
	},
	'& .Mui-expanded': {
		margin: '12px 0px'
	}
}));

interface QAccordionProps {
		summary: React.ReactNode
		details: React.ReactNode
		panelName: string,
		initialExpanded?: boolean
}

export const QAccordionTitleValueSummary = ({
	title,
	value
}: { title?: React.ReactNode, value?: React.ReactNode }) => {
	return (
		<Box
			display='flex'
			flexWrap='wrap'
			justifyContent='space-between'
			flexGrow={1}
		>
			<Typography pr={3}>{title}</Typography>
			<Typography color='primary' pr={2}>{value}</Typography>
		</Box>
	);
};

const QAccordion: React.FC<QAccordionProps> = ({
	summary,
	details,
	panelName,
	initialExpanded
}) => {
	const { expanded, handleChange } = useAccordion(initialExpanded ? panelName : false);

	const open = expanded === panelName;

	return (
		<Accordion
			sx={{
				'&.Mui-expanded': {
					margin: 0
				}
			}}
			onChange={handleChange(panelName)}
			expanded={open} TransitionProps={{ unmountOnExit: true }}>
			<QAccordionSummary expandIcon={open ? <RemoveIcon /> : <AddIcon/>}>
				{summary}
			</QAccordionSummary>
			<AccordionDetails sx={{ textAlign: 'left' }}>
				{details}
			</AccordionDetails>
		</Accordion>
	);
};

export default QAccordion;
