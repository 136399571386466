type Envs = 'qa' | 'dev' | 'live' | 'local';

const envs : Envs[] = ['qa', 'dev', 'live', 'local'];

export const isEnv = (value : string | undefined): value is Envs =>
	envs.includes(value as Envs);

interface Config {
	SilicaEndpoint: string;
	Auth0: Auth0;
}

interface Auth0 {
	domain: string;
	clientId: string;
	clientSecret: string;
	audience: string;
}

export const getConfig = (stage : string | undefined) => (def : Envs) : Config =>
	isEnv(stage) ? configs[stage] : configs[def];

export const configs : Record<Envs, Config> = {
	'qa' : {
		'SilicaEndpoint': 'https://silica-qa.commonedge.com',
		'Auth0': {
			'domain': 'https://commonedge.auth0.com',
			'clientId': 'TpSBsKIIsOdqsqZLUwK0AVsI0ijvu3ZT',
			'clientSecret': 'nrr03uxqV8nMCA7aOBdGY1pdLC0hPXgj5yhieKJJ9nV2pcu-Go_UtTkM7QBNiUfM',
			'audience': 'https://silica.commonedge.com/auth0/v0'
		}
	},

	'dev' : {
		'SilicaEndpoint': 'https://silica-dev.commonedge.com',
		'Auth0': {
			'domain': 'https://commonedge.auth0.com',
			'clientId': 'TpSBsKIIsOdqsqZLUwK0AVsI0ijvu3ZT',
			'clientSecret': 'nrr03uxqV8nMCA7aOBdGY1pdLC0hPXgj5yhieKJJ9nV2pcu-Go_UtTkM7QBNiUfM',
			'audience': 'https://silica.commonedge.com/auth0/v0'
		}
	},

	'live' : {
		'SilicaEndpoint': 'https://silica.commonedge.com',
		'Auth0': {
			'domain': 'https://commonedge.auth0.com',
			'clientId': 'TpSBsKIIsOdqsqZLUwK0AVsI0ijvu3ZT',
			'clientSecret': 'nrr03uxqV8nMCA7aOBdGY1pdLC0hPXgj5yhieKJJ9nV2pcu-Go_UtTkM7QBNiUfM',
			'audience': 'https://silica.commonedge.com/auth0/v0'
		}
	},

	'local' : {
		'SilicaEndpoint': 'http://localhost:8088',
		'Auth0': {
			'domain': 'https://commonedge.auth0.com',
			'clientId': 'TpSBsKIIsOdqsqZLUwK0AVsI0ijvu3ZT',
			'clientSecret': 'nrr03uxqV8nMCA7aOBdGY1pdLC0hPXgj5yhieKJJ9nV2pcu-Go_UtTkM7QBNiUfM',
			'audience': 'https://silica.commonedge.com/auth0/v0'
		}
	}

};
