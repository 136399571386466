import * as React from 'react';
import * as A from 'fp-ts/lib/Array';
import * as O from 'fp-ts/lib/Option';
import { Update } from '~/src/base/Function';
import { constant, pipe } from 'fp-ts/lib/function';
import { sequenceTOption } from '~/src/edit/util';

type TouchListArray = TouchList | React.TouchList

const changedTouches: (a: TouchEvent) => TouchList = (a: TouchEvent) => a.changedTouches;
export const touchListToArray = (a: TouchListArray) => {
	const touches = [];

	for (let i = 0; i < a.length; i++) {
		const touch = a.item(i);
		if (touch) {
			touches.push(touch);
		}
	}
	return touches;
};
const deltaClient: (a: Touch | React.Touch, b: Touch | React.Touch) => { dx: number, dy: number } = (a: Touch | React.Touch, b: Touch | React.Touch) => {
	return {
		dx: b.clientX - a.clientX,
		dy: b.clientY - a.clientY
	};
};
const angleFromTouchDelta: (deltas: { dx: number, dy: number }) => number = (deltas: { dx: number, dy: number }) => Math.atan2(deltas.dy, deltas.dx) * (180/Math.PI);
const angleFromTouches = (initialTouch: TouchEvent | null, lastTouch: TouchEvent | null) => pipe(
	sequenceTOption(O.fromNullable(initialTouch), O.fromNullable(lastTouch)),
	O.map(A.map(changedTouches)),
	O.map(A.map(touchListToArray)),
	O.map(A.map(A.head)),
	O.chain(A.sequence(O.Applicative)),
	O.chain((a) => sequenceTOption(O.fromNullable(a[0]), O.fromNullable(a[1]))),
	O.map((a) => deltaClient(...a)),
	O.map(angleFromTouchDelta),
);

const useTouchAngle = (updateNumberOrNull: Update<number | null>, value: number | null, updateTrackingAngle: Update<number | null>, trackingAngle: number | null) => {
	const ref = React.useRef<SVGCircleElement>(null);

	const [initialTouch, setInitialTouch] = React.useState<TouchEvent | null>(null);
	const [lastTouch, setLastTouch] = React.useState<TouchEvent | null>(null);
	const [movingTouch, setMovingTouch] = React.useState<TouchEvent | null>(null);


	React.useEffect(() => {
		function handleTouchEnd(e: TouchEvent) {
			/*
			 * If the user zooms, this gets set
			 * because the event still (?) fires with one touch despite being
			 * the end of a zoom event, Therefore, down on the start listener
			 * we clear the last as a reset for the new touch angle calculation
			 */
			setLastTouch(e);
			setMovingTouch(null);
			updateTrackingAngle(() => null);
		}

		function handleTouchStart(e: TouchEvent) {
			/*
			 * If the user presses down, activating the pie menu and then release
			 * a zero angle is calculated and set. This defined angle then prevents other angles from
			 * being set because the user decided to tap instead of drag.
			 *
			 * Therefore, set the angle to null when the user first taps to make sure their movement
			 * always sets a new angle
			 */
			if (e.touches.length === 1) {
				updateNumberOrNull(() => null);
				setLastTouch(null);
				setMovingTouch(null);
				setInitialTouch(e);
				updateTrackingAngle(() => null);
			}
		}

		function handleTouchMove(e: TouchEvent) {
			if (e.touches.length === 1) {
				setMovingTouch(e);
			}
		}

		const el = ref?.current;

		if (el) {
			el.addEventListener('touchstart', handleTouchStart);
			el.addEventListener('touchend', handleTouchEnd);
			el.addEventListener('touchmove', handleTouchMove);

			return () => {
				el.removeEventListener('touchstart', handleTouchStart);
				el.removeEventListener('touchend', handleTouchEnd);
				el.removeEventListener('touchmove', handleTouchMove);
			};
		}
		return;
	}, [updateNumberOrNull, updateTrackingAngle]);

	React.useEffect(() => {
		const oAngle = angleFromTouches(initialTouch, lastTouch);
		const oValue = O.fromNullable(value);

		pipe(
			oAngle,
			O.chain(O.fromPredicate(() => O.isNone(oValue))),
			O.fold(constant(null), (a) => {
				updateNumberOrNull(() => a);
				setLastTouch(null);
				setInitialTouch(null);
			})
		);

	}, [initialTouch, lastTouch, updateNumberOrNull, value]);


	React.useEffect(() => {
		const oAngle = angleFromTouches(initialTouch, movingTouch);

		pipe(
			oAngle,
			O.fold(constant(null), (a) => updateTrackingAngle(() => a))
		);

	}, [initialTouch, movingTouch, updateTrackingAngle, trackingAngle]);

	return {
		ref
	};
};

export default useTouchAngle;
