import React, { memo, useMemo } from 'react';

import { Mod, Update } from '~/src/base/Function';

import {
	Limit,
	Limits,
	LimitType,
	If,
	All,
	Any,
	Opt,
	Var,
	Var2,
	limitTranslations,
	defAll,
	defAny,
	defIf,
	defOpt,
	defVar,
	defVar2,
} from '~/src/admin/template/limits';
import {
	allOf,
	anyOf,
	ifIf,
	ifThen,
	limitAll,
	limitAny,
	limitIf,
	limitOpt,
	limitsId,
	limitsLimits,
	limitsName,
	limitVar,
	limitVar2,
	optMember,
	optSpace,
	optOption,
	varVariable,
	varRelation,
	varThreshold,
	var2Variable,
	var2Relation,
	var2Threshold,
} from '~/src/admin/template/limits/optics';

import Grid from '@mui/material/Grid';

import BooleanEdit from '~/src/edit/Boolean';
import { ListEdit } from '~/src/edit/List';
import NumberEdit from '~/src/edit/Number';
import PartialOrderingEditor from '~/src/edit/PartialOrdering';
import { SelectEnum, SelectEnumSet } from '~/src/edit/SelectEnum';

import { Template } from '~/src/design/template';

import { EditProps } from '~/src/edit/types';
import { LengthConverters } from '~/src/util/Units';
import { comp, pipe } from '~/src/base/Function';
import { TextEdit } from '~/src/edit/Text';
import { get1, set } from '~/src/base/Optic';
import { useLanguage } from '~/src/user/language';

export interface LimitsParams extends EditProps<Limits> {
	template?: Template;
}

export const LimitsEditor = memo(({update, value, template}: LimitsParams): React.ReactElement => {
	const lang = useLanguage();
	return <Grid container direction="row" spacing={1}>
		<Grid item container direction="column" spacing={1} xs={1}>
			<Grid item>
				<TextEdit value={get1(limitsName(lang))(value)} fullWidth label="Limits Name" variant="outlined" update={comp(update,limitsName(lang))} />
			</Grid>
			<Grid item>
				<TextEdit value={get1(limitsId)(value)} fullWidth label="Limits Id" variant="outlined" update={comp(update,limitsId)} />
			</Grid>
		</Grid>
		<Grid item xs={10}>
			<ListEdit newItem={defIf} value={value.Limits ?? []}
				update={comp(update,limitsLimits)}
				converter={LengthConverters.mm}
				Edit={useMemo(() => (ps: EditProps<Limit>) => <LimitEditor {...ps} template={template}/>, [template])}
			/>
		</Grid>
	</Grid>;
});

export interface LimitParams<T> {
	value: T;
	update: Update<Limit>;
	template?: Template;
}

export const LimitEditor = ({value, ...ps}: LimitParams<Limit>): React.ReactElement =>
	value.type === 'If' ? <IfEditor value={value} {...ps}/>
		: value.type === 'All' ? <AllEditor value={value} {...ps}/>
			: value.type === 'Any' ? <AnyEditor value={value} {...ps}/>
				: value.type === 'Opt' ? <OptEditor value={value} {...ps}/>
					: value.type === 'Var' ? <VarEditor value={value} {...ps}/>
						: value.type === 'Var2' ? <Var2Editor value={value} {...ps}/>
							: <p>impossible</p>;

export const AllEditor = memo(({value, update, template}: LimitParams<All>): React.ReactElement =>
	<ListEdit converter={LengthConverters.mm} newItem={defOpt} value={value.Of} update={comp(update, limitAll, allOf)}
		Edit={(ps) => <LimitEditor {...ps} template={template}/>}
	>
		<LimitPicker value={value} update={update}/>
	</ListEdit>);

export const AnyEditor = memo(({value, update, template}: LimitParams<Any>): React.ReactElement =>
	<ListEdit converter={LengthConverters.mm} newItem={defOpt} value={value.Of} update={comp(update,limitAny,anyOf)}
		Edit={(ps) => <LimitEditor {...ps} template={template}/>}
	>
		<LimitPicker value={value} update={update}/>
	</ListEdit>);

export const IfEditor = memo(({value, update, ...ps}: LimitParams<If>): React.ReactElement =>
	<Grid item container direction="row" spacing={1}>
		<LimitPicker value={value} update={update}/>
		<Grid item><LimitEditor {...ps} value={value.If} update={comp(update,limitIf,ifIf)}/></Grid>
		<Grid item><LimitEditor {...ps} value={value.Then} update={comp(update,limitIf,ifThen)}/></Grid>
	</Grid>);

/** Change what space an option limit applies to, then clear the options selected in that option limit. */
const updateOptionSpaceAndClear = (f: Mod<string>) => pipe(comp(limitOpt,optSpace)(f), set(comp(limitOpt,optOption))([]));

export const OptEditor = memo(({value, update, template}: LimitParams<Opt>): React.ReactElement => {
	const spaces = template === undefined ? [] : template.Opts;
	const space = spaces.find(s => s.Label === value.Space);
	const entries = space === undefined ? [] : space.Range;

	const setSpace = comp(update, updateOptionSpaceAndClear);

	return <Grid item container direction="row" spacing={1}>
		<LimitPicker value={value} update={update}/>
		<Grid item>
			<SelectEnum size="small"
				items={spaces.map(space => ({ key: space.Label, names: space.Names }))}
				value={value.Space} update={setSpace}
			/>
		</Grid>
		<Grid item>
			<BooleanEdit value={value.Member} update={comp(update,limitOpt,optMember)}/>
		</Grid>
		<Grid item>
			<SelectEnumSet size="small"
				items={entries.map(entry => ({ key: entry.Label, names: entry.Names }))}
				value={value.Option} update={comp(update,limitOpt,optOption)}
			/>
		</Grid>
	</Grid>;
});

const VarPicker = memo(({value, update, template}: {value: string, update: Update<string>, template?: Template}) => {
	const vars = template === undefined ? [] : template.Vars;
	return <SelectEnum size="small"
		items={vars.map(v => ({ key: v.Label, names: {} }))}
		value={value} update={update}
	/>;
});

export const VarEditor = memo(({value, update, template}: LimitParams<Var>): React.ReactElement =>
	<Grid item container direction="row" spacing={1}>
		<LimitPicker value={value} update={update}/>
		<Grid item>
			<VarPicker value={value.Variable} update={comp(update,limitVar,varVariable)} template={template}/>
		</Grid>
		<Grid item>
			<PartialOrderingEditor size="small" value={value.Relation} update={comp(update,limitVar,varRelation)} />
		</Grid>
		<Grid item>
			<NumberEdit size="small" unit='inch' value={value.Threshold} update={comp(update,limitVar,varThreshold)} />
		</Grid>
	</Grid>);

export const Var2Editor = memo(({value, update, template}: LimitParams<Var2>): React.ReactElement =>
	<Grid item container direction="row" spacing={1}>
		<LimitPicker value={value} update={update}/>
		<Grid item>
			<Grid container direction="column">
				<VarPicker value={value.Variables[0]} update={comp(update,limitVar2,var2Variable(0))} template={template}/>
				<VarPicker value={value.Variables[1]} update={comp(update,limitVar2,var2Variable(1))} template={template}/>
			</Grid>
		</Grid>
		<Grid item>
			<PartialOrderingEditor size="small" value={value.Relation} update={comp(update,limitVar2,var2Relation)} />
		</Grid>
		<Grid item>
			<Grid container direction="column">
				<NumberEdit size="small" unit='inch' value={value.Thresholds[0]} update={comp(update,limitVar2,var2Threshold(0))} />
				<NumberEdit size="small" unit='inch' value={value.Thresholds[1]} update={comp(update,limitVar2,var2Threshold(1))} />
			</Grid>
		</Grid>
	</Grid>);

const pickType = (type: LimitType) => (lim: Limit): Limit => {
	if (type === lim.type) return lim;
	if (type === 'Any') {
		if (lim.type === 'All') return set(anyOf)(lim.Of)(defAny);
		return defAny;
	}
	if (type === 'All') {
		if (lim.type === 'Any') return set(allOf)(lim.Of)(defAll);
		return defAll;
	}
	if (type === 'If') {
		return defIf;
	}
	if (type === 'Opt') return defOpt;
	if (type === 'Var') return defVar;
	if (type === 'Var2') return defVar2;
	return lim;
};

const LimitPicker = ({value, update: update}: LimitParams<Limit>): React.ReactElement =>
	<Grid item>
		<SelectEnum size="small" items={limitTranslations}
			value={value.type} update={(f) => update(pickType(f(value.type)))}
		/>
	</Grid>;
