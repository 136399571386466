import { Fn } from './Function';

/**
 * Add two numbers.
 */
export const plus = (x: number) => (y: number): number => x + y;

/**
 * Subtract the first number from the second number.
 */
export const minus = (x: number) => (y: number): number => y - x;

/**
 * Subtract the second number from the first number.
 */
export const subtract = (x: number) => (y: number): number => x - y;

/**
 * Multiply two numbers.
 */
export const times = (x: number) => (y: number): number => x * y;

/**
 * Linear interpolation between two numbers.
 *
 * lerp(0)(x)(y) returns y;
 * lerp(1)(x)(y) return x;
 */
export const lerp = (a: number): Fn<number,Fn<number,number>> => {
	const b = 1-a;
	return (x: number) => (y: number): number => a*x + b*y;
};
