/**
 * EdgeBredth dialog.
 *
 * @since 0.2.0
 */
import * as React from 'react';
import { ShiftLocation } from '~/src/design/opening/measurement';
import { DialogProps, GenericDialog } from './Dialog';
import { Cell } from '~/src/ui/util/Cell';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import './EdgeBredth.css';

/**
 * EdgeBredth props.
 *
 * @since 0.2.0
 */
export interface EdgeBredthProps extends Omit<DialogProps, 'onSave'> {
	onSave: (str: string, location: ShiftLocation) => void;
	location: ShiftLocation;
}

/**
 * EdgeBredth state.
 *
 * @since 0.2.0
 */
interface EdgeBredthState {
	value: string;
	selected: ShiftLocation;
}

export class EdgeBredth extends React.Component<EdgeBredthProps, EdgeBredthState> {
	constructor(ps: EdgeBredthProps) {
		super(ps);

		this.state = {
			value: this.props.initialValue ?? '',
			selected: this.props.location
		};
	}

	select (location: ShiftLocation) {
		this.setState({ selected: location });
	}

	render() {
		const {message, onSave, onClose, open} = this.props;
		const {value, selected} = this.state;

		const cells: ShiftLocation[] = [
			'inside',
			'center',
			'outside'
		];

		return <GenericDialog
			open={open}
			onClose={onClose}
			onSave={() => { onSave(value, selected); }}
			message={message ?? 'Please make a selection'}
		>
			<div className="EdgeBredth">
				<div className="Cells">
					{cells.map((e, i) => <Cell
						key={i}
						selected={(selected === e)}
						onClick={() => { this.select(e); }}
						onDragStart={(e) => { e.preventDefault(); }}
						style={{border: '1px solid', margin: '4px auto',
							padding: '4px', width: '50%'}}
					>{<span>{ e }</span>}</Cell>)}
				</div>
				<Box sx={{p: 1}}>
					<TextField required id="outlined-required" label="Input" value={value}
						onChange={e => this.setState({ value: e.target.value }) }
						autoFocus />
				</Box>
			</div>
		</GenericDialog>;
	}
}
