import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

export default App;

if (typeof document !== 'undefined') {
	const target = document.getElementById('app');
	if (target === null)
		throw new Error('Cannot find the \'app\' DOM node.');

	createRoot(target).render(<BrowserRouter><App/></BrowserRouter>);
}
