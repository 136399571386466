import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { MimeFile } from '~/src/base/Mime';
import { Figure } from '~/src/figure';
import { Requestor } from '~/src/api/Requestor';
import { Type , Output , RenderRes , figReq , render } from '~/src/figure/api';
import { downloadMimeFile } from '~/src/base/Download';

export interface FigureRenderProps {
	req ?: Requestor;
	name : string;
	data ?: Figure;
}

export const FigureRender = ( { req , name , data } : FigureRenderProps ) => {
	const active = !!req && !!data;

	const downloadRender = ( typ : Type ) => () => {
		if (!req || !data)
			return;

		render(req)(figReq( data )( typ ))
			.then( ( res : RenderRes ) => {
				const out : Output = res[0][0];
				const fle : MimeFile = out.Files[0];
				fle.Name = `${name}.${typ}`;
				downloadMimeFile( fle );
				// download( name , out.Type , out.Datas[0] );
			});
	};

	return (
		<ButtonGroup variant="outlined" aria-label="Basic button group">
			<Button disabled={!active} onClick={downloadRender('png')}>PNG</Button>
			<Button disabled={!active} onClick={downloadRender('svg')}>SVG</Button>
			<Button disabled={!active} onClick={downloadRender('dxf')}>DXF</Button>
			<Button disabled={!active} onClick={downloadRender('pdf')}>PDF</Button>
		</ButtonGroup>
	);
};
