.Hitbox {
  fill: none;
  stroke: transparent;
  stroke-width: 3em;
  stroke-linecap: round;
  vector-effect: non-scaling-stroke;
}

.Hitbox:hover {
  stroke: #80000060;
}

.Ruler-handle {
  fill: transparent;
  stroke: transparent;
  stroke-width: 10px;
  stroke-linecap: round;
  vector-effect: non-scaling-stroke;
}

.RulerLabel-handle {
  fill: transparent;
  stroke: transparent;
  stroke-width: 0;
}

.RulerLabel-handle.active,
.RulerLabel-handle:hover {
  fill: #80000060;
}

.Ruler-line {
  shape-rendering: crispEdges;
}

.Ruler-line,
.Ruler-angle,
.Ruler-whisker {
  fill: none;
  stroke: currentColor;
  vector-effect: non-scaling-stroke;
}

.Ruler-whisker {
  stroke-width: 1px;
  stroke-linecap: round;
}

.Ruler-whisker-extended {
  shape-rendering: crispEdges;
  stroke: currentColor;
  stroke-width: 1px;
  vector-effect: non-scaling-stroke;
}

.Ruler-handle.active ,
.Ruler-handle:hover {
  stroke: #00000022;
}

.Ruler > text {
  fill: currentColor;
  font-size: var(--font-size);
  text-anchor: middle;
  dominant-baseline: middle;
}

.Ruler-angle, .Curve {
  fill: none;
  stroke: currentColor;
  stroke-width: 2px;
  vector-effect: non-scaling-stroke;
}
