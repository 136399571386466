import { useEffect, useState, useCallback, useRef } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import './style.css';

interface ResizeHandle {
	defaultValue: number;
	minValue: number;
	maxValue: number;
	position?: 'left' | 'right';
	onChange: (width: number) => void;
}

const ResizeHandle = ({ defaultValue = 330, minValue, maxValue, position = 'right', onChange }: ResizeHandle) => {
	const [isResizing, setIsResizing] = useState(false);
	const [initialPosition, setInitialPosition] = useState(0);
	const resizeHandleRef = useRef<HTMLDivElement>();
	const [leftSideBarWidth, setLeftSideBarWidth] = useState(defaultValue);

	const handleDoubleClick = () => {
		setLeftSideBarWidth((prev) => (prev === 0 ? defaultValue : 0));
	};

	const handleMouseDown = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setIsResizing(true);
	};

	const handleMouseUp = () => {
		setIsResizing(false);
	};

	const handleMouseMove = useCallback((e: { pageX: number }) => {
		if (isResizing) {
			const offset = e.pageX - initialPosition;
			const newWidth = defaultValue + offset * (position === 'left' ? -1 : 1);
			if (newWidth <= maxValue - 5 && newWidth > minValue) {
				setLeftSideBarWidth(newWidth + 5);
			}
		}
	}, [isResizing, maxValue, minValue, initialPosition, defaultValue, position]);

	const handleResizeHandler = () => {
		const pageX = resizeHandleRef.current?.getBoundingClientRect().left || 0;
		setInitialPosition(pageX);
	};

	useEffect(() => {
		onChange(leftSideBarWidth);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leftSideBarWidth]);

	useEffect(() => {
		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);

		return () => {
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
		};
	}, [handleMouseMove]);

	useEffect(() => {
		window.addEventListener('resize', handleResizeHandler);

		return () => {
			window.removeEventListener('resize', handleResizeHandler);
		};
	}, []);

	useEffect(() => {
		const pageX = resizeHandleRef.current?.getBoundingClientRect().left || 0;
		setInitialPosition(pageX);
	}, []);

	return (
		<Box
			className={classNames('q-resize-handle', `q-resize-handle__${position}`)}
			onDoubleClick={handleDoubleClick}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			ref={resizeHandleRef}
		>
			<div className="q-resize-handle-div">
				<Box className="q-resize-handle-item">
					<img src="/img/treeview/drag-handle-dotted.svg" className="d-none" />
				</Box>
				<Box className="q-resize-handle-item q-resize-handle-item-middle">
					<img src="/img/treeview/drag-handle-lines.png" />
				</Box>
				<Box className="q-resize-handle-item q-resize-handle-item-last">
					<img src="/img/treeview/drag-handle-dotted.svg" />
				</Box>
			</div>
		</Box>
	);
};

export default ResizeHandle;
