import React from 'react';

const useDrawingDragAngles = () => {
	const [angle, setAngle] = React.useState<number | null>(null);
	/*
	 * The tracking angle is used to
	 * activate the slices "hover" styling since the element's
	 * touch move handler is not trigged when the touch moves over
	 * the element IF the touch started on a different element
	 */
	const [trackingAngle, setTrackingAngle] = React.useState<number | null>(null);

	return {
		angle,
		setAngle,
		trackingAngle,
		setTrackingAngle
	};
};

export default useDrawingDragAngles;
