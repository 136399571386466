/**
 * Templates for simplified interaction with any model.
 *
 * @since 0.1.0
 */
import { array, interface as iface, string, Type, unknown } from 'io-ts';
import { Option as PolyOption } from '~/src/design/template/option';
import { Variable as PolyVariable } from '~/src/design/template/variable';
import { Group as PolyGroup, Item } from '~/src/design/template/groups';
import { Translations, TranslationEntry } from '~/src/model/Language';
import { StartsWith } from '~/src/model/type-helpers';

/**
 * Monomorphic Option.
 */
export type Option = PolyOption<string,string>;
/**
 * Codec for 'Option'.
 */
export const Option: Type<Option> = PolyOption(string, string);

/**
 * Monomorphic Variable.
 */
export type Variable = PolyVariable<string>;
/**
 * Codec for 'Variable'.
 */
export const Variable: Type<Variable> = PolyVariable(string);

/**
 * Monomorphic Group.
 */
export type Group = PolyGroup<Item>;
/**
 * Codec for 'Group'.
 */
export const Group: Type<Group> = PolyGroup(Item);

export type LabelValueObj = { [label in string]: string|number }

/**
 *  A template for simplified interaction with any model.
 *
 * - `Data` holds the templated model - the client doesn't need to interact with it at all.
 *
 * - `Opts` holds options that can be updated by the client, these are enumerated (suitable
 *   for a select field or similar) options where each option has custom presentation.
 *
 * - 'Vars' holds numeric variables, these are real numeric values either taken from a range
 *   (sutiable for an number input element or similar) or a enumerated list. Prime examples
 *   are measurements.
 *
 * @since 0.1.0
 */
export type Template = {
  /** We don't care what type of template it is (that is, what's in the data), just that it is one. */
  type: StartsWith<'Templated'>;
	Data: unknown,
	Id: string,
	Names: Translations,
	Opts: Option[],
	Vars: Variable[],
	Groups: Group[],
};
/**
 * Codec for `Template`.
 *
 * @since 0.1.0
 */
export const Template: Type<Template> = iface({
	type: StartsWith('Templated'),
	Data: unknown,
	Id: string,
	Names: Translations,
	Opts: array(Option),
	Vars: array(Variable),
	Groups: array(Group),
});

/**
 * Get a translation entry for this template.
 */
export const templateTransEntry = (t: Template): TranslationEntry<string> =>
	({ key: t.Id, names: t.Names });
