import { AuthInfo as LoginState } from '~/src/model/Authentication';
import { Config } from '~/src/model/Config';
import { Requestor } from '~/src/api/Requestor';

import LoginAuth0 from '~/src/edit/login/Auth0';

import './login.css';

export interface LoginProps {
	config?: Config;
	updateReq: (req: Requestor) => void;
}

export { LoginState };

const Login = ({ config, updateReq }: LoginProps) => {
	if (config === undefined) {
		return null;
	}

	return (
		<LoginAuth0
			config={config}
			updateReq={(req: Requestor) => {
				updateReq(req);
			}}
		/>
	);
};

export default Login;
