{
    "Charges": [
        {
            "Count": 1,
            "Discount": {},
            "Discounts": {},
            "Extended": {
                "USD": "700.00"
            },
            "Items": [
                {
                    "Count": 2,
                    "Discount": {},
                    "Discounts": {},
                    "Extended": {
                        "USD": "400.00"
                    },
                    "Name": "South Wall Mirror",
                    "Price": {
                        "USD": "200.00"
                    },
                    "SubTotal": {
                        "USD": "400.00"
                    },
                    "Tax": {},
                    "Taxes": {},
                    "Total": {
                        "USD": "400.00"
                    },
                    "Unit": {
                        "Count": 1,
                        "Unit": "pc"
                    }
                },
                {
                    "Count": 1,
                    "Discount": {},
                    "Discounts": {},
                    "Extended": {
                        "USD": "300.00"
                    },
                    "Name": "Bathroom Mirror",
                    "Price": {
                        "USD": "300.00"
                    },
                    "SubTotal": {
                        "USD": "300.00"
                    },
                    "Tax": {},
                    "Taxes": {},
                    "Total": {
                        "USD": "300.00"
                    },
                    "Unit": {
                        "Count": 1,
                        "Unit": "pc"
                    }
                }
            ],
            "Name": "Master Bedroom",
            "Price": {
                "USD": "700.00"
            },
            "SubTotal": {
                "USD": "700.00"
            },
            "Tax": {},
            "Taxes": {},
            "Total": {
                "USD": "700.00"
            },
            "Unit": {
                "Count": 1,
                "Unit": "pc"
            }
        },
        {
            "Count": 4,
            "Discount": {},
            "Discounts": {},
            "Extended": {
                "USD": "400.00"
            },
            "Name": "Entryway Mirror",
            "Price": {
                "USD": "100.00"
            },
            "SubTotal": {
                "USD": "400.00"
            },
            "Tax": {},
            "Taxes": {},
            "Total": {
                "USD": "400.00"
            },
            "Unit": {
                "Count": 1,
                "Unit": "pc"
            }
        }
    ],
    "Discount": {},
    "Discounts": {},
    "Extended": {
        "USD": "1100.00"
    },
    "Name": "44 Elm Blvd",
    "Shipping": {
        "Count": 1,
        "Discount": {},
        "Discounts": {},
        "Extended": {},
        "Items": [],
        "Name": "Default",
        "Price": {},
        "SubTotal": {},
        "Tax": {},
        "Taxes": {},
        "Total": {},
        "Unit": {
            "Count": 1,
            "Unit": "pc"
        }
    },
    "SubTotal": {
        "USD": "1100.00"
    },
    "Tax": {},
    "Taxes": {},
    "Total": {
        "USD": "1100.00"
    }
}