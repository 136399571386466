import React from 'react';

import { Opening } from '~/src/design/opening';

import { Requestor } from '~/src/api/Requestor';

import OpeningEditor from '~/src/design/opening/edit';

export interface OpeningPageProps {
	req: Requestor;
}

export interface OpeningPageState {
	opening?: Opening;
}

export default class OpeningPage extends React.Component<OpeningPageProps, OpeningPageState> {
	constructor(props: OpeningPageProps) {
		super(props);
		this.state = {};
		this.getNewOpening();
	}

	getNewOpening() {
		this.props.req.request(Opening)('minimal/silica/opening', 'GET')
			.then(opening => this.setState({opening}))
			.catch(failure => console.warn('Failed to fetch minimal opening.', failure));
	}

	componentDidUpdate(prevProps: OpeningPageProps, _prevState: OpeningPageState) {
		// keep minimal opening up to date
		if (prevProps.req !== this.props.req) this.getNewOpening();
	}

	render = () => !this.state.opening
		? <em>Loading...</em>
		: <OpeningEditor req={this.props.req} opening={this.state.opening}/>;
}
