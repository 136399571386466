import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';

const useToggle = (initialValue?: boolean) => {
	const[value, setValue] = React.useState<boolean>(initialValue || false);

	const toggle = () => pipe(!value, setValue);

	return {
		value,
		toggle,
	};
};

export default useToggle;
