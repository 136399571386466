/**
 * Requastor performing "JSON Web Token"-style authentication.
 *
 * @since 0.1.0
 */
import { match } from 'fp-ts/Either';
import { Decoder } from 'io-ts';

import { Requestor } from '~/src/api/Requestor';
import { JWT } from '~/src/model/Authentication';
import { Method, xhr } from '~/src/api/xhr';
import { jsonPromise } from '~/src/model/Util';

/**
 * Create a `Requestor` for "JSON Web Tokene"-style authentication,
 *
 * @since 0.1.0
 */
export const CreateRequestor = (endpoint: string, jwt: JWT): Requestor => {
	const authorization = (): string => {
		return `Bearer ${jwt.accessToken}`;
	};

	const requestRaw = (path: string, method: Method, data?: object): Promise<object> =>
		xhr(method, endpoint + '/' + path, {
			Authorization: authorization(),
			'Content-Type': 'application/json;charset=utf-8',
		}, data && JSON.stringify(data)).then(jsonPromise);

	const request = <T>(dec: Decoder<unknown,T>) => (path: string, method: Method, data?: object|undefined): Promise<T> =>
		requestRaw(path, method, data).then((resp) => match(
			(failures) => Promise.reject({ error: 'JSON decode failure', response: resp, failures }),
			(good: T) => Promise.resolve(good)
		)(dec.decode(resp))).catch((e) => Promise.reject({ error: 'Request error', response: e }));

	return {
		requestRaw,
		request,
	};
};
