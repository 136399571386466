import { number , string , interface as iface , union , tuple , array , literal , Type } from 'io-ts';

import { Translations } from '~/src/model/Language';


export interface MetaQty {
	type: 'MetaQty';
	Qty: number;
}

export const MetaQty : Type<MetaQty> = iface({
	type: literal('MetaQty'),
	Qty: number,
});


export interface MetaThickness {
	type: 'MetaThickness';
	Thickness: number;
}

export const MetaThickness : Type<MetaThickness> = iface({
	type: literal('MetaThickness'),
	Thickness: number,
});


export interface MetaText {
	type: 'MetaText';
	Text: string;
}

export const MetaText : Type<MetaText> = iface({
	type: literal('MetaText'),
	Text: string,
});


export interface MetaMaterial {
	type: 'MetaMaterial';
	Material: string;
	Thickness: number;
}

export const MetaMaterial : Type<MetaMaterial> = iface({
	type: literal('MetaMaterial'),
	Material: string,
	Thickness: number,
});


export interface MetaArea {
	type: 'MetaArea';
	Area: number;
}

export const MetaArea : Type<MetaArea> = iface({
	type: literal('MetaArea'),
	Area: number,
});


export interface MetaWeight {
	type: 'MetaWeight';
	Weight: number;
}

export const MetaWeight : Type<MetaWeight> = iface({
	type: literal('MetaWeight'),
	Weight: number,
});


export type MetaValue =
	MetaQty | MetaThickness | MetaText | MetaMaterial | MetaArea | MetaWeight
;

export const MetaValue : Type<MetaValue> =
	union([MetaQty , MetaThickness , MetaText , MetaMaterial , MetaArea , MetaWeight])
;


export interface MetaRecord {
	Title: Translations;
	Tag: string;
	Value: MetaValue;
}

export const MetaRecord : Type<MetaRecord> = iface({
	Title: Translations,
	Tag: string,
	Value: MetaValue,
});


export interface Meta {
	Entries: [string, MetaRecord][];
}

export const Meta : Type<Meta> = iface({
	Entries: array(tuple([string, MetaRecord])),
});
