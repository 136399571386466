import React from 'react';

import { Requestor } from '~/src/api/Requestor';
import { list } from '~/src/design/template/api/list';

import * as css from './template.module.css';
import { Template, LabelValueObj, templateTransEntry } from '~/src/design/template';
import { TemplateEditor } from '~/src/design/template/edit';

import { Import } from '~/src/ui/input/Import';
import { Export } from '~/src/ui/input/Export';
import { ShareURL } from '~/src/ui/input/ShareURL';
import TextField from '@mui/material/TextField';
import { ToolBar } from '~/src/ui/toolbar/ToolBar';

import { Update } from '~/src/base/Function';
import { useQuery } from '~/src/ui/query';
import { comp } from '~/src/base/Function';
import { maybe, pdot, withDefault } from '~/src/base/Optic';
import SelectEnum from '~/src/edit/SelectEnum';
import { mmap } from '~/src/base/Array';


export interface TemplatePageProps {
	req: Requestor;
}

export interface TemplatePageState {
	template?: Template;
	limits?: string;
	serverTemplates: Template[];
	defaultSelections?: LabelValueObj;
}

const TemplatePage = (props: TemplatePageProps) => {
	const { req } = props;
	const [template, updateTemplate] = React.useState<Template>();
	const [limits, setLimits] = React.useState<string>();
	const [serverTemplates, setServerTemplates] = React.useState<Template[]>([]);

	const [ queries, updateQueries ] = useQuery();

	const templateId = queries['id'];
	const updateTemplateId: Update<string|undefined> = comp(updateQueries,pdot('id'));

	React.useEffect(() => {
		list(req)()
			.then(setServerTemplates)
			.catch(console.warn);
	}, [req]);

	React.useEffect(() => {
		updateTemplate(serverTemplates.find((t) => t.Id === templateId));
	}, [serverTemplates, templateId]);

	return (
		<div className={`${css.template}`}>
			<ToolBar>
				<Import codec={Template} name="Template" success={updateTemplate}
					failure={(e) => {
						alert('Import failed, check console for details.');
						console.warn(e);
					}}
				/>

				<Export name="Template" getContent={() => template}
					onError={(e) => {
						alert('Export failed, check console for details.');
						console.warn(e);
					}}
				/>

				<SelectEnum
					size="small"
					value={templateId ?? ''}
					update={comp(updateTemplateId,withDefault('' as string))}
					items={mmap(templateTransEntry)(serverTemplates)}
				/>

				<TextField size="small" value={limits ?? ''} label="Limit Id" variant="outlined"
					onChange={(ev) => setLimits(ev.target.value == '' ? undefined : ev.target.value)} />

				<ShareURL size="small" />
			</ToolBar>

			{template ? <TemplateEditor req={req} limits={limits} template={template} update={comp(updateTemplate,maybe)} /> : null}
		</div>
	);
};

export default TemplatePage;
