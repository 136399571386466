
/**
 * Optics for Options.
 *
 */
import { fromTraversable, Lens, Optional, Prism, Traversal } from 'monocle-ts';

import { Option } from '~/src/design/template';
import { Translations } from '~/src/model/Language';
import { Entry } from '~/src/design/template/option';
import { Traversable } from 'fp-ts/lib/Array';


/**
 * Focus on Range of an `Option`
 *
 * @catgory Option
 */
export const optionRange: Lens<Option, Entry<string>[]> = Lens.fromProp<Option>()('Range');

/**
 * Traversal for Entries in an `Option` range
 *
 * @category Option
 */
export const entryTraversal = fromTraversable(Traversable)<Entry<string>>();

/**
 * Focus on Entry with specific `label`
 *
 * @category Option
 */
export const getEntryPrism: (label: string) => Prism<Entry<string>, Entry<string>> = (label: string) => Prism.fromPredicate((x) => x.Label === label);

/**
 * Focus on Names field of `Entry<string>`
 *
 * @category Option
 */
export const entryTranslations: Lens<Entry<string>, Translations> = Lens.fromProp<Entry<string>>()('Names');

/**
 * Focus on optional Rank in Entry
 *
 * @category Option
 */
export const entryRank: Optional<Entry<string>, number> = Optional.fromNullableProp<Entry<string>>()('Rank');

export const getEntryRankTraversal: (label: string) => Traversal<Option, number> = (label: string) =>
	optionRange
		.composeTraversal(entryTraversal)
		.composePrism(getEntryPrism(label))
		.composeOptional(entryRank);

/**
 * Focus on Names in Option
 *
 * @category Option
 */
export const optionTranslations: Lens<Option, Translations> = Lens.fromProp<Option>()('Names');
