import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { Requestor } from '~/src/api/Requestor';

import * as css from './HeaderBar.module.css';

interface HeaderBarProps {
	req ?: Requestor;
}

const state = ( { isActive } : { isActive : boolean } ) : string => isActive ? css.active : '';

export const HeaderBar : FC<HeaderBarProps> = ( { req } ) =>
	<header className={ css.headerbar }>
		<nav className={ css.main }>
			{ !req ? null : <NavLink to="/opening" className={state}>Opening</NavLink> }
			{ !req ? null : <NavLink to="/template" className={state}>Template</NavLink> }
			{ !req ? null : <NavLink to="/template-mod" className={state}>Template Mod</NavLink> }
			{ !req ? null : <NavLink to="/template-limits" className={state}>Template Limits</NavLink> }
			{ !req ? null : <NavLink to="/config-table" className={state}>Configs</NavLink> }
			{ !req ? <NavLink to="/login" className={state}>Login</NavLink> : <NavLink to="/logout" className={state}>Logout</NavLink> }
		</nav>

		<nav className={ css.lside }></nav>

		<nav className={ css.rside }></nav>
	</header>
;
