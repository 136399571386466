/**
 * Piemenu.
 *
 * @since 0.2.0
 */
import { SvgIcon } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { constant, flow, pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import * as O from 'fp-ts/lib/Option';
import * as A from 'fp-ts/lib/Array';
import * as N from 'fp-ts/lib/number';
import * as B from 'fp-ts/lib/boolean';

import './PieMenu.css';
import { DRAWING_DIV_ID } from '~/src/design/opening/walls/edit';
import { DEFAULT_DRAWING_ROTATION } from '~/src/design/opening/edit';
import { Opening } from '~/src/design/opening';
import { Update } from '~/src/base/Function';
import { Converter } from '~/src/util/Units';
import useTouchAngle from '~/src/hooks/useTouchAngle';

export interface XY {
	x: number;
	y: number;
}

/**
 * PieMenu single entry.
 *
 * @since 0.2.0
 */
export interface PieEntry {
	icon: React.ReactNode;
	title?: string;
	onSelect: (xy: XY) => void;
}

/**
 * PieMenu props.
 *
 * @since 0.2.0
 */
export interface PieMenuProps extends XY {
	entries?: (PieEntry|null)[];
	radius?: number;
	onClose: () => void;
	drawingRotation?: number
	updateOpening?: Update<Opening>
	angleConverter?: Converter<number, string>
	lengthConverter?: Converter<number, string>
	angle?: number | null
	updateAngle?: Update<number | null>
	trackingAngle?: number | null
	updateTrackingAngle?: Update<number | null>
}

const actClick = (entry: PieEntry) => (e: React.MouseEvent<SVGPathElement, MouseEvent>) => {
	e.preventDefault();
	entry.onSelect({x: e.clientX, y: e.clientY});
};
const actTouch = (entry: PieEntry) => (e: React.TouchEvent) => {
	e.stopPropagation();
	pipe(
		e.touches[0],
		O.fromNullable,
		O.fold(constant(null), (touch) => {
			entry.onSelect({x: touch.clientX, y: touch.clientX});
		})
	);
};
const actKeyDown = (entry: PieEntry) => (e: React.KeyboardEvent<SVGPathElement>) => {
	e.preventDefault();
	entry.onSelect({x: e.currentTarget.getBoundingClientRect().x, y: e.currentTarget.getBoundingClientRect().y});
};

const handleSliceKeydown = (onClose: () => void) => (onEnter: (e: React.KeyboardEvent<SVGPathElement>) => void ) => (event: React.KeyboardEvent<SVGPathElement>) => {
	switch (event.key) {
	case 'Enter':
		onEnter(event);
		break;
	case 'Escape':
		onClose();
		break;
	default:
		null;
	}
};

const parity = (i: number) => i % 2 === 0 ? 'even' : 'odd';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildIcon = (icon: React.ReactNode) => typeof icon === 'string'
	? <text alignmentBaseline="middle" textAnchor="middle"> { icon } </text>
	: icon;

const generateEntrySliceArray: (entries: (PieEntry | null)[] | undefined) => O.Option<(PieEntry | null)[]> = flow(
	O.fromNullable,
	O.map((a) => a.length < 8 ? A.concatW(A.replicate(8 - a.length, null))(a) : a)
);

const getFillColor = (parity: 'even' | 'odd') => parity === 'even' ? grey[100] : blue[100];

const handleRotationChange = (num1: number | undefined, num2: number | undefined) => (f: () => void) => pipe(
	num1,
	O.fromNullable,
	O.fold(
		() => pipe(num2, O.fromNullable, O.fold(constant(null), f)),
		(a) => pipe(num2, O.fromNullable, O.fold(f, (a2) => pipe(
			N.Eq.equals(a, a2),
			B.fold(f, constant(null))
		)))
	)
);


const rangeAnd = (range: [number, number]) => (angle: number) => angle > range[0] && angle < range[1];
const rangeOr = (range: [number, number]) => (angle: number) => angle > range[0] || angle < range[1];

const angleIndexMap: {[key: number]: (angle: number) => boolean} = {
	4: rangeAnd([-22.5 ,22.5]),
	3:rangeAnd([22.5,67.5]),
	2:rangeAnd([67.5,112.5]),
	1:rangeAnd([112.5 ,157.5]),
	0: rangeOr([157.5 ,-157.5]),
	7: rangeAnd([-157.5,-112.5]),
	6:rangeAnd([-112.5,-67.5]),
	5:rangeAnd([-67.5 ,-22.5]),
};

const PieMenu: React.FC<PieMenuProps> = ({
	onClose,
	x, y,
	drawingRotation,
	updateOpening,
	angleConverter,
	lengthConverter,
	entries,
	angle,
	updateAngle,
	trackingAngle = null,
	updateTrackingAngle,
	...props
}) => {
	const [rotationShadow, setRotationShadow] = React.useState<number | undefined>(DEFAULT_DRAWING_ROTATION);

	React.useEffect(() => {
		if (angle && updateAngle) {
			updateAngle(() => null);
			if (entries?.length && entries.length > 0) {
				if (angle > -22.5 && angle < 22.5) {
					entries[4]?.onSelect({ x, y });
				}
				if (angle > 22.5 && angle < 67.5) {
					entries[3]?.onSelect({ x, y });
				}
				if (angle > 67.5 && angle < 112.5) {
					entries[2]?.onSelect({ x, y });
				}
				if (angle > 112.5 && angle < 157.5) {
					entries[1]?.onSelect({ x, y });
				}
				if (angle > 157.5 || angle < -157.5) {
					entries[0]?.onSelect({ x, y });
				}
				if (angle > -157.5 && angle < -112.5) {
					entries[7]?.onSelect({ x, y });
				}
				if (angle > -112.5 && angle < -67.5) {
					entries[6]?.onSelect({ x, y });
				}
				if (angle > -67.5 && angle < -22.5) {
					entries[5]?.onSelect({ x, y });
				}
			}
		}
	}, [angle, entries, x, y, updateAngle]);


	React.useEffect(() => {
		setRotationShadow(drawingRotation);
	}, [drawingRotation]);

	React.useEffect(() => {
		handleRotationChange(drawingRotation, rotationShadow)(onClose);
	}, [drawingRotation, rotationShadow, onClose]);

	const r = props.radius ?? 120;

	const dia = 2*r;

	const [position, setPosition] = React.useState<XY>({
		x: 0,
		y: 0
	});

	React.useEffect(() => {
		setPosition({ x, y });
	}, [x, y]);

	const style = {
		left: position.x - r,
		top: position.y - r,
	};

	const entry = (i: number, entry: PieEntry | null) => (
		<Slice
			key={entry?.title}
			onClose={onClose}
			title={entry?.title}
			icon={entry?.icon}
			i={i} r={r}
			actClick={actClick(entry || { icon: '', onSelect: onClose })}
			actTouch={actTouch(entry || { icon: '', onSelect: onClose })}
			actKeyDown={actKeyDown(entry || { icon: '', onSelect: onClose })}
			movingAngle={trackingAngle}
			range={angleIndexMap[i]}
		/>
	);

	const drawingEl = document.getElementById(DRAWING_DIV_ID);

	// Close the pie if mounted and user presses 'Escape' key
	React.useEffect(() => {
		function handleKeyDown(event: KeyboardEvent) {
			if (event.key === 'Escape') onClose();
		}

		if (drawingEl) {
			drawingEl.addEventListener('keydown', handleKeyDown);
			drawingEl.addEventListener('wheel', onClose, { passive: true });
			drawingEl.addEventListener('mousedown', onClose);
			drawingEl.addEventListener('touchstart', onClose);

			return () => {
				drawingEl.removeEventListener('keydown', handleKeyDown);
				drawingEl.removeEventListener('wheel', onClose);
				drawingEl.removeEventListener('mousedown', onClose);
				drawingEl.removeEventListener('touchstart', onClose);
			};
		}
		return;

	}, [onClose, drawingEl]);

	return <SvgIcon
		onWheel={onClose}
		name='PieMenu'
		sx={{
			height:dia,
			width: dia,
			fontSize: '14px',
			fontFamily: '"Poppins", sans-serif'
		}}
		xmlns={'http://www.w3.org/2000/svg'}
		viewBox={`-${r+1} -${r+1} ${dia+2} ${dia+2}`}
		className={'PieMenu'}
		style={style}
	>
		{[
			updateAngle && updateTrackingAngle ? <PieHole trackingAngle={trackingAngle} updateTrackingAngle={updateTrackingAngle} updateAngle={updateAngle} value={angle} radius={r} /> : null,
			pipe(
				entries,
				O.fromNullable,
				O.chain(generateEntrySliceArray),
				O.foldW(
					() => A.replicate(8, null),
					A.mapWithIndex(entry)
				)
			)
		]}
	</SvgIcon>;
};

interface SliceProps {
	i: number;
	r: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	icon: React.ReactNode;
	title?: string;
	onClose: () => void;
	actClick: (e: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
	actTouch: (e: React.TouchEvent<SVGPathElement>) => void;
	actKeyDown: (e: React.KeyboardEvent<SVGPathElement>) => void;
	range: ((angle: number) => boolean) | undefined;
	movingAngle: number | null;
}

const Slice: React.FC<SliceProps> = ({
	i,
	r,
	title,
	icon,
	onClose,
	actClick,
	actTouch,
	actKeyDown,
	range,
	movingAngle,
}) => {
	const n = 8;
	const theta = (i - 0.5) * 2*Math.PI / n;
	const phi = (i + 0.5) * 2*Math.PI / n;
	const ir = r/3;
	const ps: [number, number][] = [
		[theta, r],
		[phi, r],
		[phi, ir],
		[theta, ir],
		[(theta+phi)/2, (ir+r)/2],
	];
	const x = ps.map(([ang, m]) => (-Math.cos(ang) * m).toFixed(6));
	const y = ps.map(([ang, m]) => (Math.sin(ang) * m).toFixed(6));
	const ix = x[4];
	const iy = y[4];

	const ref = React.useRef<SVGPathElement>(null);

	return (
		<React.Fragment>
			<path
				style={{ filter: movingAngle && range && range(movingAngle) ? 'brightness(0.8)' : '' }}
				ref={ref}
				name={title}
				onTouchEnd={actTouch}
				onMouseUp={actClick}
				onKeyUp={handleSliceKeydown(onClose)(actKeyDown)}
				tabIndex={1}
				d={ `M ${x[0]} ${y[0]} A ${r} ${r} 0 0 0 ${x[1]} ${y[1]} ` +
					`L ${x[2]} ${y[2]} A ${ir} ${ir} 0 0 1 ${x[3]} ${y[3]} z`}
				className={'PieSlice'}
				fill={pipe(i, parity, getFillColor)}
			>
				{title ? <title>{title}</title> : null}
			</path>
			<g transform={`translate(${ix} ${iy})`}>
				{buildIcon(icon)}
			</g>
		</React.Fragment>
	);
};

interface PieHoleProps {
	radius: number
	value?: number | null
	updateAngle: Update<number | null>
	updateTrackingAngle: Update<number | null>
	trackingAngle?: number | null
}

const PieHole: React.FC<PieHoleProps> = ({
	radius,
	value = null,
	updateAngle,
	trackingAngle = null,
	updateTrackingAngle
}) => {
	const { ref } = useTouchAngle(updateAngle, value, updateTrackingAngle, trackingAngle);
	return (
		<circle
			ref={ref}
			tabIndex={2}
			className={'PieHole'}
			key={-1}
			r={radius/3}
		/>
	);
};

export default PieMenu;
