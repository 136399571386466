import { Fn } from '~/src/base/Function';
import { Varadic } from '~/src/base/Function';

export interface Mem {
	weak: WeakMap<object, Mem>;
	strong: Map<unknown, Mem>;
	/**
	 * Has this value already been set?
	 *
	 * undefined: never seen
	 * false: pending
	 * true: known value
	 */
	isSet?: boolean;
	value?: unknown;
}

export const defMem = (): Mem => ({
	weak: new WeakMap(),
	strong: new Map(),
});

const memory: Mem = defMem();

// walk through a memory tree creating nodes as needed along the way
export const walk = (m: Mem, ...keys: unknown[]): Mem => {
	for (const key of keys) {
		// we want as much garbage collection as possible, so objects and functions go in a weak map
		if ((typeof key === 'object' && key !== null) || typeof key === 'function') {
			const mnext = m.weak.get(key);
			const next = mnext ?? defMem();
			if (mnext === undefined) m.weak.set(key, next);
			m = next;
		} else {
			const mnext = m.strong.get(key);
			const next = mnext ?? defMem();
			if (mnext === undefined) m.strong.set(key, next);
			m = next;
		}
	}
	return m;
};

export const fetch = <T>(m: Mem, calc: () => T, ...keys: unknown[]): T => {
	m = walk(m, ...keys);
	if (!m.isSet) {
		m.value = calc();
		m.isSet = true;
	}
	return m.value as T;
};

export const debug_fetch = <T>(m: Mem, calc: () => T, ...keys: unknown[]): T => {
	m = walk(m, ...keys);
	if (!m.isSet) {
		m.value = calc();
		console.log('miss', keys, m.value);
		m.isSet = true;
	} else {
		console.log('set', keys, m.value);
	}
	return m.value as T;
};

interface Executer<T> {
	resolve: (x: T | PromiseLike<T>) => void;
	/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
	reject: (e?: any) => void;
}

export const fetchPromise = <T>(m: Mem, calc: () => Promise<T>, ...keys: unknown[]): Promise<T> => {
	m = walk(m, ...keys);
	switch (m.isSet) {
	// first time for these keys
	case undefined:
		m.value = [];
		return new Promise<T>((resolve, reject) => {
			(m.value as Executer<T>[]).push({resolve, reject});
			calc().then((x: T) => {
				(m.value as Executer<T>[]).forEach(ex => ex.resolve(x));
				m.value = Promise.resolve(x);
				m.isSet = true;
			}).catch((e) => {
				// don't cache failures
				(m.value as Executer<T>[]).forEach(ex => ex.reject(e));
				m.isSet = undefined;
			});
			m.isSet = false;
		});
	// promise hasn't been resolved yet
	case false:
		return new Promise<T>((resolve, reject) => {
			(m.value as Executer<T>[]).push({resolve, reject});
		});
	// promise has been resolved
	case true:
		return m.value as Promise<T>;
	}
};

/**
 * Memoized values.
 *
 * This takes an initial key, some further keys, a suspended computation of the result,
 * and implements the memoziation of that result.
 *
 * The key identifies what memoization you want to perform, and also is the primary point for garbage collection,
 * the function you're calling from usually works pretty well.
 */
export const memo = <T extends unknown[],R>(key: unknown, args: T, res: () => R): R =>
	fetch(memory, res, key, ...args);

/**
 * Memoized values (with debugging).
 *
 * This takes an initial key, some further keys, a suspended computation of the result,
 * and implements the memoziation of that result.
 *
 * The key identifies what memoization you want to perform, and also is the primary point for garbage collection,
 * the function you're calling from usually works pretty well.
 */
export const debug_memo = <T extends unknown[],R>(key: unknown, args: T, res: () => R): R =>
	debug_fetch(memory, res, key, ...args);

/**
 * Memoize a suspended computation.
 *
 * `sus(f, a, b c)` is the same as `() => f(a,b,c)`,
 * except that it always returns the same anonymous function.
 * That is `sus(f, a, b, c) === sus(f, a, b, c)`.
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const sus = <T extends unknown[],R>(f: Varadic<T,R>, ...xs: T): Fn<any,R> =>
	fetch(memory, () => () => f(...xs), sus, f, ...xs);

/**
 * Memoize a function, and our memoization it.
 */
export const mem = <T extends unknown[],R>(f: Varadic<T,R>, ...keys: unknown[]): Varadic<T,R> =>
	fetch(memory, () => {
		const m = defMem();
		return (...ps) => fetch(m, () => f(...ps), ...ps);
	}, f, ...keys);

/**
 * Memoize a function, and our memoization it.
 */
export const debug_mem = <T extends unknown[],R>(f: Varadic<T,R>, ...keys: unknown[]): Varadic<T,R> =>
	debug_fetch(memory, () => {
		const m = defMem();
		return (...ps) => debug_fetch(m, () => f(...ps), ...ps);
	}, f, ...keys);

export const memP = <T extends unknown[],R>(f: Varadic<T,Promise<R>>, ...keys: unknown[]): Varadic<T,Promise<R>> =>
	fetch(memory, () => {
		const m = defMem();
		return (...ps) => fetchPromise(m, () => f(...ps), ...ps);
	}, f, ...keys);
