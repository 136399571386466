import FormControl from '@mui/material/FormControl';

import { createContext, useContext } from 'react';
import { SelectEnum } from '~/src/edit/SelectEnum';
import { EditProps } from '~/src/edit/types';
import { Language, TranslationList, Translations } from '~/src/model/Language';

const LanguageContext = createContext<Language>('eng');

export const LanguageProvider = LanguageContext.Provider;

export const useLanguage = () => useContext(LanguageContext);

// This translation list is a little special, because we want people to be able to find languages they recognize.
const languageTransList: TranslationList<Language> = [
	{key: 'eng',
		names: {
			eng: 'English',
			cmn: 'English',
		}
	},
	{key: 'cmn',
		names: {
			eng: '官话',
			cmn: '官话',
		}
	}
];

const languageTrans: Translations = {
	eng: 'Language',
};

export const LanguageEdit = ({value, update}: EditProps<Language>) =>
	<FormControl style={{ padding: '12px 4px' }}>
		<SelectEnum
			size='small'
			value={value}
			label={languageTrans}
			items={languageTransList}
			update={update}
		/>
	</FormControl>;
