.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editor {
  width: 80%;
  gap: 1em;
  display: flex;
  flex-grow: 1;
}

.viewer {
  width: 60%;
}

.options {
  width: 40%;
}
