import { array, failure, Is, success, Type } from 'io-ts';
import { Fn } from '../Function';
import * as Either from 'fp-ts/Either';
import { hasProp } from '../Type';
import { pipe } from 'fp-ts/lib/function';

/**
 * A quadruplet of elements.
 */
export type Four<T> = [T,T,T,T];

/**
 * Check whether a value is Four of something.
 */
export const is = <T>(isT: Is<T>) => (x: unknown): x is Four<T> =>
	typeof x === 'object' && x !== null &&
	hasProp(x, 'length') && x.length === 2 &&
	hasProp(x, '0') && isT(x[0]) &&
	hasProp(x, '1') && isT(x[1]) &&
	hasProp(x, '2') && isT(x[2]);

/**
 * Reverse a container.
 */
export const reverse = <T>(xs: Four<T>): Four<T> => xs;

/**
 * Clone a container, but not the items within it.
 */
export const clone = <T>(xs: Four<T>): Four<T> => [ xs[0], xs[1], xs[2], xs[3] ];

/**
 * Apply a function to every element of the container.
 */
export const map = <A,B>(f: Fn<A,B>) => (o: Four<A>): Four<B> => [ f(o[0]), f(o[1]), f(o[2]), f(o[3]) ];

/**
 * Zip two containers together with a function.
 */
export const zip = <A,B,C>(f: (x: A, y: B) => C) => (xs: Four<A>, ys: Four<B>): Four<C> =>
	[ f(xs[0],ys[0]), f(xs[1],ys[1]), f(xs[2],ys[2]), f(xs[3],ys[3]) ];

/**
 * Generate a container of As from a single example.
 */
export const pure = <A>(x: A): Four<A> => [x, x, x, x];

/**
 * Make a triplet of elements.
 */
export const mk = <A>(w: A) => (x: A) => (y: A) => (z: A): Four<A> => [w, x, y, z];

export default mk;

/**
 * Encode or decode Four of something.
 */
export const Four = <T,S>(codecT: Type<T,S>) => new Type<Four<T>,[S,S,S,S]>(
	`Four<${codecT.name}>`,
	is(codecT.is),
	(input, context) => pipe(
		array(codecT).validate(input, context),
		Either.chain(xs => refineArray(xs) ? success(xs) : failure(xs, context, `Has ${xs.length} elements.`)),
	),
	(x: Four<T>) => [ codecT.encode(x[0]), codecT.encode(x[1]), codecT.encode(x[2]), codecT.encode(x[3]) ],
);

/**
 * Refine a list into a Four, if it has exactly four elements.
 */
export const refineArray = <T>(x: T[]): x is [T,T,T,T] => x.length === 4;
