import { useRef , useState , useEffect , useCallback } from 'react';

import { Optic , set , dot , withDefault } from '~/src/base/Optic';
import { comp } from '~/src/base/Function';

import { UnitEditProps } from '~/src/edit/types';
import { ItemType , ItemHandlers , FigureSize , ViewFigureWrap , FragFig } from '~/src/figure/View';

import { P2 , P3 } from '~/src/geometry/point';
import * as Pnt from '~/src/geometry/point';
import { V2 } from '~/src/geometry/vector';
import * as Vec from '~/src/geometry/vector';
import { Figure } from '~/src/figure';
import * as Fig from '~/src/figure';
import { FigureMod , _RulerById, applyFigureMod } from '~/src/figure/FigureMod';
import { Piece } from '~/src/figure/Piece';
import { Ruler, direction } from '~/src/figure/Ruler';
import { IId } from '~/src/figure/Item';
import { RulerMod, _Info } from './mod/ruler';
import { _LabelLayer, _Layer } from './mod/ruler-info';

export interface EditableFigure {
	figure : Figure;
	modifier: FigureMod;
}

const getRuler = (rid: IId) => (fig: Figure): Ruler | undefined =>
	fig.Pieces.flatMap((pce: Piece) => pce.Rulers.filter( (rlr: Ruler) => rlr.Id === rid))[0]
;

const _rulerLayer : (rid: IId) => Optic<EditableFigure, number|undefined> = (rid: IId) =>
	comp(dot('modifier'), _RulerById(rid.join('-')), withDefault<RulerMod>({}), _Info(), _Layer);

const _rulerLabelLayer : (rid: IId) => Optic<EditableFigure, number|undefined> = (rid: IId) =>
	comp( dot('modifier'), _RulerById(rid.join('-')), withDefault({}), _Info(), _LabelLayer)
;

const negateY = ([x, y] : P2) : P2 => [x, -y];

const project = (a: number) => (p: P3) : P2 =>
	negateY(Pnt.lowerXZ3(Pnt.map(Vec.aboutZ(a))(p)))
;

/*
 *	const reverse = (a: number) => (p: P2) : P3 =>
 *		Pnt.map(Vec.aboutZ(a))(Pnt.liftXZ3(negateY(p)))
 *	;
 */

const delPan = (n: V2) => (p: P2) : P2 =>
	Pnt.add(p)(Vec.scale(-1)(n))
;

const delZoom = (z: number) => (p: P2) : P2 =>
	Pnt.map(Vec.v2_scale(1 / z))(p)
;

export const FigureEditor = ({value: eFigure, update: updateEFigure, width, height, ...ps}: UnitEditProps<EditableFigure> & FigureSize) => {
	// REF
	const svgRef = useRef<SVGSVGElement>(null);

	// DATA
	const [iniCtrd, setIniCtrd] = useState<boolean>(false);
	const [pan, setPan] = useState<V2>([0, 0]);
	const [zoom, setZoom] = useState<number>(0.5);
	const [angle, setAngle] = useState<number>(0);
	const figure = applyFigureMod(eFigure.modifier)(eFigure.figure);

	// UTILS
	const project_ = useCallback((p : P3) => project(angle)(p), [angle]);
	// const reverse_ = useCallback(p => reverse(angle)(p), [angle]);
	const delPan_ = useCallback((p : P2) => delPan(pan)(p), [pan]);
	const delZoom_ = useCallback((p : P2) => delZoom(zoom)(p), [zoom]);

	const normalize_ = useCallback((p: P2) : P2 =>
		delPan_(delZoom_(p))
	, [delPan_, delZoom_]);

	// USER ACTIONS
	const doPan = useCallback((dxy: V2) =>
		setPan(Vec.v2_add(delZoom_(Pnt.p(dxy))))
	, [delZoom_, setPan]);

	const doZoom = useCallback((dzf: number, pnt: V2) => {
		if (dzf < 0.1 || dzf > 10)
			return;

		const fpt = normalize_(Pnt.p(pnt));

		setPan(p => Vec.v2_add(p)(Vec.v2_scale(1 / dzf - 1)(Vec.v2_add(fpt)(p))));
		setZoom(z => z * dzf);
	}, [normalize_, setPan, setZoom]);

	const doRotate = useCallback((a: number, _p: P2) =>
		setAngle(b => a + b)
	, [setAngle]);

	const moveRuler = useCallback((rid: IId, pnt: V2): void => {
		if (!figure) return;

		const ruler = getRuler(rid)(figure);
		if (!ruler) return;

		const layer_offset = project_(Pnt.p(direction(32/zoom)(ruler)));
		const left = project_(ruler.For.Left);
		const target = normalize_(Pnt.p(pnt));
		const layers = Vec.firstBasis(Vec.sub(target)(left))(layer_offset);

		updateEFigure(set(_rulerLayer(rid))(layers));
	}, [zoom, figure, normalize_, project_, updateEFigure]);

	const moveRulerLabel = useCallback((rid: IId, pnt: V2): void => {
		if (!figure) return;

		const ruler = getRuler(rid)(figure);
		if (!ruler) return;

		const layer_offset = project_(Pnt.p(direction(32/zoom)(ruler)));
		const left = project_(ruler.For.Left);
		const target = normalize_(Pnt.p(pnt));
		const layers = Vec.firstBasis(Vec.sub(target)(left))(layer_offset);

		updateEFigure(set(_rulerLabelLayer(rid))(layers - ruler.Info.Layer));
	}, [zoom, figure, normalize_, project_, updateEFigure]);

	const doItemMove = useCallback((typ: ItemType, iid: IId, pnt: P2) => {
		switch (typ) {
		case 'Ruler':
			moveRuler(iid, pnt);
			break;
		case 'RulerLabel':
			moveRulerLabel(iid, pnt);
			break;
		default:
			return;
		}
	}, [moveRuler, moveRulerLabel]);

	const doCenter = useCallback(() => {
		const svg = svgRef.current;
		const rct = svg?.getBoundingClientRect();
		const svgWidth = rct?.width ?? 0;
		const svgHeight = rct?.height ?? 0;

		if (svgWidth <= 0 || svgHeight <= 0)
			return;

		const defbox : [P3, P3]= [[0, 0, 0] , [0, 0, 0]];
		const figure = eFigure.figure;
		const figbox : [P3, P3] = !figure ? defbox : Fig.bbox(figure) ?? defbox;
		const [min, max] : [P2 , P2] = figbox.map(project_) as [P2, P2];

		const widthFactor : number = svgWidth / Math.abs(max[0] - min[0]);
		const heightFactor : number = svgHeight / Math.abs(max[1] - min[1]);
		const finalFactor : number = Math.min(widthFactor, heightFactor);

		const newZoom : number = finalFactor ? finalFactor : 0.5;

		const sizeFig : V2 = Vec.sub(max)(min);
		const sizeSvg : V2 = Vec.scale(1/newZoom)([svgWidth, svgHeight] as V2);

		const cptFig : V2 = Vec.scale(0.5)(sizeFig);
		const cptSvg : V2 = Vec.scale(0.5)(sizeSvg);

		const newPan : V2 = Vec.sub(cptSvg)(cptFig);

		setPan(newPan);
		setZoom(newZoom);
	}, [project_ , eFigure]);

	// INITIALISATION
	useEffect(() => {
		if (iniCtrd)
			return;

		doCenter();
		setIniCtrd(true);
	}, [doCenter, iniCtrd, setIniCtrd]);

	return (
		<ViewFigureWrap
			pref="Figure"

			ref={svgRef}

			pan={pan}
			zoom={zoom}
			width={width}
			height={height}

			onPan={doPan}
			onZoom={doZoom}
			onRotate={doRotate}
			onCenter={doCenter}

			onItemMove={doItemMove}
			actionables={['Ruler', 'RulerLabel']}
		>
			{ (hs: ItemHandlers) =>
				!figure ? null : <FragFig proj={project_} zoom={zoom} value={figure} {...hs} {...ps} />
			}
		</ViewFigureWrap>
	);
};
