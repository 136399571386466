import { AddCircle } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Chip, { ChipProps } from '@mui/material/Chip';

const StyledChip = styled(Chip)<ChipProps>({
	cursor: 'pointer',
	'& .MuiChip-label': {
		display: 'flex'
	}
});

const ChipAdd = (props: ChipProps) => {
	return (
		<StyledChip
			label={<AddCircle />}
			variant='outlined'
			clickable
			{...props}
		/>
	);
};


export default ChipAdd;
