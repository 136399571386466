import { constant, pipe } from 'fp-ts/lib/function';
import { useCallback, useEffect, useState } from 'react';
import * as O from 'fp-ts/Option';

type UseResizeReturn = {
	width: number
	enableResize: () => void
}

const useResize = ({
	minWidth,
}: {
		minWidth: number
}): UseResizeReturn => {
	const [isResizing, setIsResizing] = useState(false);
	const [width, setWidth] = useState(minWidth);

	// Allow the handler to update the width
	const enableResize = useCallback(() => {
		setIsResizing(true);
	}, [setIsResizing]);

	// Prevent the handler from updating the width
	const disableResize = useCallback(() => {
		setIsResizing(false);
	}, [setIsResizing]);

	/*
	 * Calculate width for drawer on right side of screen
	 * and set the new width
	 */
	const resize = useCallback(
		(e: MouseEvent) => pipe(
			e.clientX,
			O.fromPredicate(() => isResizing),
			O.map((x) => window.innerWidth - x),
			O.chain(O.fromPredicate((x) => x >= minWidth)),
			O.foldW(
				constant(null),
				setWidth
			)
		),
		[minWidth, isResizing, setWidth]
	);

	/*
	 * Calculate width for drawer on right side of screen
	 * and set the new width for touch events
	 */
	const resizeTouch = useCallback(
		(e: TouchEvent) => pipe(
			e.targetTouches[0]?.clientX,
			O.fromNullable,
			O.chain(O.fromPredicate(constant(isResizing))),
			O.map((a) => window.innerWidth - a),
			O.chain(O.fromPredicate((x) => x >= minWidth)),
			O.foldW(
				constant(null),
				setWidth
			)
		),
		[minWidth, isResizing, setWidth]
	);

	useEffect(() => {
		document.addEventListener('mousemove', resize);
		document.addEventListener('mouseup', disableResize);
		document.addEventListener('touchmove', resizeTouch);
		document.addEventListener('touchend', disableResize);

		return () => {
			document.removeEventListener('mousemove', resize);
			document.removeEventListener('mouseup', disableResize);
			document.removeEventListener('touchmove', resizeTouch);
			document.removeEventListener('touchend', disableResize);
		};
	}, [disableResize, resize, resizeTouch]);

	return { width, enableResize };
};

export default useResize;
