import { mem } from '~/src/base/Memoize';
import { Fn } from './Function';

/**
 * Monomorphic map.
 *
 * Transform an array into one of the same type using a function.
 */
export const mapMono = <A>(f: Fn<A,A>) => <S extends Array<A>>(xs: S): S =>
	xs.map(f) as S;

/**
 * Memoized map.
 *
 * Both the function and array are memoized.
 */
export const mmap: <A,B>(f: Fn<A,B>) => (xs: A[]) => B[] =
	mem(<A,B>(f: Fn<A,B>) => mem((xs: A[]) => xs.map(f)));

/**
 * Monomorphic zip.
 *
 * Combine two arrays into an array of the same type using a merge operator.
 */
export const zipMono = <A>(f: Fn<A,Fn<A,A>>) => <S extends Array<A>>(xs: S) => (ys: S): S => {
	const zs: S = [] as unknown as S;
	xs.forEach((x, i) => {
		const y = ys[i];
		if (y === undefined) return;
		zs.push(f(x)(y));
	});
	return zs;
};


/**
 * Map the first function over the first item in a list, and the second function over each pair of elements.
 *
 * `mapWithPrior(f, (a,b) => f(b))` is equivalant to `map`.
 */
export const mapWithPrior = <A,B>(f: Fn<A,B>, g: (x: A, y: A) => B) => (xs: A[]): B[] => {
	let x = xs[0];
	if (x === undefined) return [];
	const ys = [f(x)];
	for (const y of xs.slice(1)) {
		ys.push(g(x,y));
		x = y;
	}
	return ys;
};
