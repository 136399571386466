import { Box, BoxProps, useTheme } from '@mui/material';
import * as React from 'react';

type QDrawerActionsProps = React.PropsWithChildren<BoxProps>

const QDrawerActions = React.forwardRef(({ children, ...boxProps }: QDrawerActionsProps, ref) => {
	const theme = useTheme();

	return (
		<Box
			zIndex={1}
			ref={ref}
			position='absolute'
			bottom={0}
			right={0}
			left={0}
			display='flex'
			alignItems={'center'}
			padding={3}
			justifyContent={'flex-end'}
			flex={'0 0 auto'}
			boxShadow={`0px -1px 10px ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0, 0, 0, 0.06)'}`}
			{...boxProps}
		>
			{children}
		</Box>
	);
});

export default QDrawerActions;
