import * as React from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref,
) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export type ToastType = 'error' | 'warning' | 'info' | 'success';
export type ToastPosition = 'top-center' | 'top-right' | 'top-left' | 'bottom-right' | 'bottom-center' | 'bottom-left';
interface ToastProps {
	open: boolean;
	message?: string;
	type: ToastType;
	vertical: SnackbarOrigin['vertical'];
	horizontal: SnackbarOrigin['horizontal'];
	children?: React.ReactNode;
	autoHideDuration?: number;
	handleClose: () => void;
}

export const Toast = (ps: ToastProps) => {
	const {open, message, type, vertical, horizontal, children, autoHideDuration = 6000, handleClose} = ps;
	return (
		<>
			<Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
				{children ? (
					<div>
						{children}
					</div>
				) : (
					<Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
						{message}
					</Alert>
				)}
			</Snackbar>
		</>

	);
};


