/**
 * Optics for Template Limits
 *
 */
import { transLang } from '~/src/optics/Language';

import { Language, Translations } from '~/src/model/Language';
import { Limits, Limit, All, Any, If, Opt, Var, Var2, isAll, isAny, isIf, isOpt, isVar, isVar2 } from '~/src/admin/template/limits';
import { PartialOrdering } from '~/src/model/PartialOrdering';
import { dot, index, Optic, optionalString, pdot, where, withDefault } from '~/src/base/Optic';
import { comp, pipe } from '~/src/base/Function';

/**
 * Focus on the Id of a Limits.
 *
 * @category Limits
 */
export const limitsId: Optic<Limits,string> = comp(pdot('Id'),optionalString);

/**
 * Focus on the Owner of a Limits.
 *
 * @category Limits
 */
export const limitsOwner: Optic<Limits,string> = comp(pdot('Owner'),optionalString);

/**
 * Focus on the Names of a Limits.
 *
 * @category Limits
 */
export const limitsNames: Optic<Limits,Translations> = comp(pdot('Names'),withDefault({}));

/**
 * Focus on a particular Name Translation.
 *
 * @category Limits
 */
export const limitsName = (lang: Language): Optic<Limits,string> => comp(limitsNames,transLang(lang));

/**
 * Focus on the Limits of a Limits.
 *
 * @category Limits
 */
export const limitsLimits: Optic<Limits,Limit[]> = comp(pdot('Limits'),withDefault([] as Limit[]));

/**
 * Focus on a particulare Limit in a Limits.
 *
 * @category Limits
 */
export const limitsLimit = (n: number): Optic<Limits,Limit> => comp(limitsLimits,index(n));

/**
 * Focus on an All Limit.
 *
 * @category Limit
 */
export const limitAll: Optic<Limit,All> = where(isAll);

/**
 * Focus on an Any Limit.
 *
 * @category Limit
 */
export const limitAny: Optic<Limit,Any> = where(isAny);

/**
 * Focus on an If Limit.
 *
 * @category Limit
 */
export const limitIf: Optic<Limit,If> = where(isIf);

/**
 * Focus on an Opt Limit.
 *
 * @category Limit
 */
export const limitOpt: Optic<Limit,Opt> = where(isOpt);

/**
 * Focus on an Var Limit.
 *
 * @category Limit
 */
export const limitVar: Optic<Limit,Var> = where(isVar);

/**
 * Focus on an Var2 Limit.
 *
 * @category Limit
 */
export const limitVar2: Optic<Limit,Var2> = where(isVar2);

/**
 * Focus on the contained Limits in an All.
 *
 * @category All
 */
export const allOf: Optic<All,Limit[]> = dot('Of');

/**
 * Focus on the contained Limits in an Any.
 *
 * @category Any
 */
export const anyOf: Optic<Any,Limit[]> = dot('Of');

/**
 * Focus on the predicate Limit in an If.
 *
 * @category If
 */
export const ifIf: Optic<If,Limit> = dot('If');

/**
 * Focus on the conditional Limit in an If.
 *
 * @category If
 */
export const ifThen: Optic<If,Limit> = dot('Then');

/**
 * Focus on the membership predicate in an Opt.
 *
 * @category Opt
 */
export const optMember: Optic<Opt,boolean> = dot('Member');

/**
 * Focus on the Space label in an Opt.
 *
 * @category Opt
 */
export const optSpace: Optic<Opt,string> = dot('Space');

/**
 * Focus on the Option label list in an Opt.
 *
 * @category Opt
 */
export const optOption: Optic<Opt,string[]> = dot('Option');

/**
 * Focus on the Variable label in an Var.
 *
 * @category Var
 */
export const varVariable: Optic<Var,string> = dot('Variable');

/**
 * Focus on the Relation in an Var.
 *
 * @category Var
 */
export const varRelation: Optic<Var,PartialOrdering> = dot('Relation');

/**
 * Focus on the Threshold in an Var.
 *
 * @category Var
 */
export const varThreshold: Optic<Var,number> = dot('Threshold');

/**
 * Focus on the Variables label in an Var2.
 *
 * @category Var2
 */
export const var2Variables: Optic<Var2,[string,string]> = dot('Variables');

/**
 * Focus on the nth Variable in an Var2.
 *
 * @category Var2
 */
export const var2Variable = (i: 0|1): Optic<Var2,string> => pipe(index(i),dot('Variables'));

/**
 * Focus on the Relation in an Var2.
 *
 * @category Var2
 */
export const var2Relation: Optic<Var2,PartialOrdering> = dot('Relation');

/**
 * Focus on the Thresholds in an Var2.
 *
 * @category Var2
 */
export const var2Thresholds: Optic<Var2,[number,number]> = dot('Thresholds');

/**
 * Focus on the nth Threshold in an Var2.
 *
 * @category Var2
 */
export const var2Threshold = (i: 0|1): Optic<Var2,number> => pipe(index(i),dot('Thresholds'));
