/**
 * Generic file importer.
 *
 * @since 0.4.0
 */
import * as React from 'react';
import Box from '@mui/material/Box';
import FileUpload from '~/src/ui/input/FileUpload';
import { decodePromise, jsonPromise } from '~/src/model/Util';
import { Decoder } from 'io-ts';

/**
 * Import props.
 *
 * @since 0.4.0
 */
export interface ImportProps<T> {
	name: string;
	codec: Decoder<unknown, T>;
	success: (x: T) => void;
	failure?: (err: unknown) => void;
}

export const Import = <T extends unknown>(ps: ImportProps<T>): React.ReactElement =>
	<Box m='auto'>
		<FileUpload title={`Import ${ps.name}`}
			accept=".json"
			onSuccess={ (x: string) => jsonPromise(x).then(decodePromise(ps.codec)).then(ps.success).catch(ps.failure) }
			onError={ ps.failure ? ps.failure : console.warn } />
	</Box>;

export default Import;
