import { Optic , dot } from '~/src/base/Optic';
import { Type , type } from 'io-ts';
import { P } from '~/src/geometry/point';
import { V3 } from '~/src/geometry/vector';

export interface RulerObject {
	Start: P<V3>;
	End: P<V3>;
}

export const RulerObject: Type<RulerObject> = type({
	Start: P(V3),
	End: P(V3),
});

export const _Start: Optic<RulerObject, P<V3>> = dot('Start');

export const _End: Optic<RulerObject, P<V3>> = dot('End');
