import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';

const ScrollContent: React.FC<{children: React.ReactNode}> = ({children}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [elHeight, setElHeight] = useState(0);

	React.useLayoutEffect(() => {
		if (ref?.current) {
			const rect = ref?.current.getBoundingClientRect();
			const offsetTop = rect.top;
			const windowHeight = window.innerHeight;

			const calcHeight = windowHeight - offsetTop;
			setElHeight(calcHeight);
		}
	}, [ref]);

	return (
		<Box ref={ref} sx={{ overflowY: 'auto', overflowX: 'hidden' }} height={elHeight}>
			{children}
		</Box>
	);
};

export default ScrollContent;
