/**
 * Optics for internal use.
 *
 * @internal
 * @since 0.1.0
 */
import { Optional, Lens, fromTraversable, Prism } from 'monocle-ts';
import { some } from 'fp-ts/Option';
import { NonEmpty, refineNonEmpty } from '~/src/model/Boxes';
import { Traversable } from 'fp-ts/lib/NonEmptyArray';
import * as A from 'fp-ts/lib/Array';

/**
 * The identity `Optional` optic, which always successed.
 *
 * @since 0.1.0
 */
export const idOpt = <A>() => new Optional<A,A>(
	(s) => some(s),
	(a) => (_s) => a
);

/**
 * Lens from an optional string to a string, where undefined maps to the empty string.
 *
 */
export const optionalString = new Lens<string|undefined,string>(
	(s) => s === undefined ? '' : s,
	(a) => (_s) => a === '' ? undefined : a,
);

/**
 * Lens from an optional object to an object, where undefined maps to the given object.
 *
 */
export const optionalT = <T>(o: T) => new Lens<T|undefined,T>(
	(s) => s === undefined ? o : s,
	(a) => (_s) => a === o ? undefined : a,
);

/**
 * Lens from an optional NonEmpty Array to an Array, where undefined maps to the empty array.
 *
 */
export const optionalNonEmpty = <A>() => new Lens<NonEmpty<A>|undefined,Array<A>>(
	(xs) => xs === undefined ? [] : xs,
	(xs) => (_xs) => refineNonEmpty(xs) ? xs : undefined,
);

/**
 * Lens between a list of values and the presence of a particular value in the list.
 */
export const presentInList = <A>(x: A) => new Lens<A[],boolean>(
	(xs) => xs.some((a) => x === a),
	(present) => (xs) => xs.some((a) => x === a)
		? present ? xs : xs.filter((a) => a !== x)
		: present ? [...xs, x] : xs
);

/**
 * Lens into an item of a tuple list.
 */
export const index = <T extends Array<unknown>,I extends number>(i: I): Lens<T,T[I]> => new Lens(
	s => s[i],
	a => s => [...s.slice(0, i), a, ...s.slice(i+1)] as unknown as T,
);

/**
 * Traverse NonEmpty of numbers
 */
export const nonEmptyNumberArrayTraversal = fromTraversable(Traversable)<number>();

/**
 * Prism for matching a number to a number
 */
export const getNumberPrism = (value: number): Prism<number, number> => Prism.fromPredicate((x) => x === value);

/**
 * Optional to last item in array
 *
 */
export const optionalLast: <T>() => Optional<T[], T> = <T>() => new Optional<T[], T>(
	A.last,
	(a) => (s) => s.length === 0 ? s : [...s.slice(0, s.length - 1), a],
);
