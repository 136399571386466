import { Optic, dot } from '~/src/base/Optic';
import { Type, boolean, number, keyof } from 'io-ts';
import { typeWithDefaults } from '~/src/codec/default';

export type LabelOrient = 'RulerAlong' | 'RulerAcross' | 'ObjectAlong' | 'ObjectAcross';
export const LabelOrient: Type<LabelOrient> = keyof({
	RulerAcross: true,
	RulerAlong: true,
	ObjectAcross: true,
	ObjectAlong: true,
});

export type LineStyle = 'Solid' | 'Heavy' | 'Dotted' | 'Dashed';
export const LineStyle: Type<LineStyle> = keyof({
	Heavy: true,
	Solid: true,
	Dashed: true,
	Dotted: true,
});

export interface RulerInfo {
	Visible: boolean;
	BarStyle: LineStyle;
	ExtendWhisker: boolean;
	WhiskerStyle: LineStyle;
	Layer: number;
	LabelLayer: number;
	LabelOrient: LabelOrient;
}

export const def: RulerInfo = {
	Visible: true,
	BarStyle: 'Dashed',
	ExtendWhisker: false,
	WhiskerStyle: 'Dotted',
	Layer: 0,
	LabelLayer: 0.5,
	LabelOrient: 'RulerAlong',
};

export const RulerInfo: Type<RulerInfo> = typeWithDefaults({
	Visible: boolean,
	BarStyle: LineStyle,
	ExtendWhisker: boolean,
	WhiskerStyle: LineStyle,
	Layer: number,
	LabelLayer: number,
	LabelOrient: LabelOrient,
}, def);

export const _Visible: Optic<RulerInfo, boolean> = dot('Visible');
export const _BarStyle: Optic<RulerInfo, LineStyle> = dot('BarStyle');
export const _ExtendWhisker: Optic<RulerInfo, boolean> = dot('ExtendWhisker');
export const _WhiskerStyle: Optic<RulerInfo, LineStyle> = dot('WhiskerStyle');
export const _Layer: Optic<RulerInfo, number> = dot('Layer');
export const _LabelLayer: Optic<RulerInfo, number> = dot('LabelLayer');
export const _LabelOrient: Optic<RulerInfo, LabelOrient> = dot('LabelOrient');
