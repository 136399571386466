import type {} from '@mui/material/themeCssVarsAugmentation';
import { experimental_extendTheme as extendTheme, PaletteOptions } from '@mui/material/styles';

interface AppPaletteOptions {
	primary: string;
	dark: string;
	light: string;
	border: string;
	choreBorder: string;
	active: string;
	outline: string;
}

declare module '@mui/material/styles' {
	interface Palette {
		app : AppPaletteOptions
	}

	interface PaletteOptions {
		app : AppPaletteOptions
	}
}

const palette : PaletteOptions = (
	{
		background: {
			default: '#f7fafc',
			paper: '#f7fafc',
		},
		text: {
			disabled: 'rgba(0, 0, 0, 0.16)',
			primary: '#4a5568',
			secondary: '#4a5568',
		},
		action: {
			active: '#2d3748',
		},
		app: {
			primary: '#f7fafc',
			dark: '#e2e8f0',
			light: '#4a5568',
			active: '#cbd5e0',
			border: '#a0aec0',
			choreBorder: '#a0aec0',
			outline: '#90caf9',
		}
	}
);

export const theme = extendTheme(
	{
		cssVarPrefix: 'thm',
		colorSchemes: {
			dark: { palette : palette },
			light : { palette : palette},
		},
		typography :
		{
			fontFamily: 'inherit',
		},
		components :
		{
			MuiButton:
			{
				styleOverrides:
				{
					outlined: ( { theme } ) => (
						{
							border: `1px solid ${theme.vars.palette.app.choreBorder}`,
							color: theme.vars.palette.text.primary,
							'&:hover': {
								borderColor: theme.vars.palette.app.border,
								backgroundColor: 'inherit',
							},
						}
					),
				},
			},
			MuiToggleButton: {
				styleOverrides: {
					root: {
						padding: '8px 32px',
						textTransform: 'capitalize',
						fontSize: '16px',
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: '6px'
					},
				},
			},
			MuiToolbar: {
				styleOverrides: {
					root: ({theme}) => (
						{
							color: theme.vars.palette.text.secondary
						}
					),
				},
			},
			MuiToggleButtonGroup: {
				styleOverrides: {
					grouped: ({theme}) => (
						{
							fontSize: '16px',
							padding: '10px 16px',
							border: `1px solid ${theme.vars.palette.app.choreBorder}`,
							color: theme.vars.palette.app.light,
							fontWeight: 400,
							'&:hover': {
								borderColor: theme.vars.palette.app.border,
								zIndex: 1
							},
						}
					),
				},
			},
		},
	}
);

export default theme;
