import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { index } from '~/src/optics/Common';
import { mem } from '~/src/base/Memoize';
import { } from '~/src/edit/types';
import { modifyUpdater } from '~/src/edit/util';
import { EditProps } from '~/src/edit/types';
import { Converter } from '~/src/util/Units';

export interface ListParams<T> extends EditProps<T[]> {
	Edit: (ps: EditProps<T> & { converter: Converter<number,string> }) => React.ReactElement;
	newItem?: T;
	children?: React.ReactElement | React.ReactElement[];
	converter: Converter<number, string>
}

export const ListEdit = <T extends unknown>({value, update, converter, Edit, newItem, children}: ListParams<T>): React.ReactElement =>
	<Grid container direction="column" spacing={1}>
		{ children }
		{ value.map((x,i) =>
			<Grid key={i} item>
				<Grid container direction="row">
					<Grid item> <Edit converter={converter} value={x} update={modifyUpdater(mem(index)<T[],number>(i), update)}/> </Grid>
					<Grid item>
						<IconButton color="primary" edge="end" aria-label="delete" onClick={() => update(xs => [...xs.slice(0,i), ...xs.slice(i+1)])}>
							<RemoveIcon/>
						</IconButton>
					</Grid>
				</Grid>
			</Grid>
		) }
		{ newItem === undefined ? null :
			<Grid item>
				<IconButton color="primary" edge="end" aria-label="add" onClick={() => update(xs => [...xs, newItem])}>
					<AddIcon/>
				</IconButton>
			</Grid>
		}
	</Grid>;

export default ListEdit;
