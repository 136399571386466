/**
 * Partial Ordering Relations
 *
 * @since 0.1.0
 */
import { keyof, Type } from 'io-ts';
import { TranslationList } from '~/src/model/Language';

/**
 * A Partial Ordering relation between values.
 */
export type PartialOrdering = '?' | '<' | '≤' | '=' | '≥' | '>';
/**
 * Codec for PartialOrdering
 */
export const PartialOrdering: Type<PartialOrdering> = keyof({
	'?': true,
	'<': true,
	'≤': true,
	'=': true,
	'≥': true,
	'>': true,
});

/**
 * "Translations" for partial orderings.
 */
export const partialOrderingTrans: TranslationList<PartialOrdering> = [
	{ key: '<', names: { } },
	{ key: '≤', names: { } },
	{ key: '=', names: { } },
	{ key: '≥', names: { } },
	{ key: '>', names: { } },
	{ key: '?', names: { } },
];
