/**
 * Generic cell.
 *
 * @note probably to be removed soon.
 *
 * @since 0.2.0
 */
import * as React from 'react';
import './Cell.css';

/**
 * Cell props.
 *
 * @since 0.2.0
 */
export interface CellProps {
	onClick?: Function;
	onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
	onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
	onDragStart?: React.MouseEventHandler<HTMLDivElement>;
	children?: React.ReactNode;
	selected?: boolean;
	style?: {}
}

/**
 * Cell state.
 *
 * @since 0.2.0
 */
export interface CellState {
	selected?: boolean;
	hover?: boolean;
}

export class Cell extends React.Component<CellProps, CellState> {
	constructor(ps: CellProps) {
		super(ps);

		this.state = {
		};
	}

	render() {
		const {onClick, onMouseEnter, onMouseLeave, onDragStart, children, style, selected} = this.props;

		return <div
			className={`Cell ${selected ? 'selected' : ''}`}
			style={{...style}}
			onClick={() => {
				onClick ? onClick() : undefined;
			}}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onDragStart={onDragStart}
		>
			{children}
		</div>;
	}
}
