/**
 * Getting information about Configs.
 *
 * @since 0.4.0
 */
import { array } from 'io-ts';
import { OrganizationConfig } from '~/src/admin/glassd-config';
import { Requestor } from '~/src/api/Requestor';

/**
 * Fetch a list of configs from the server.
 *
 * @since 0.4.0
 */
export const list = (req: Requestor) : Promise<OrganizationConfig[]> =>
	req.request(array(OrganizationConfig))('glassd/org_config', 'GET');

export const listById = (req: Requestor) => (id: string): Promise<OrganizationConfig> =>
	req.request(OrganizationConfig)(`glassd/org_config/${id}`, 'GET');

export const updateConfig = (req: Requestor) => (orgConfig: OrganizationConfig): Promise<OrganizationConfig> =>
	req.request(OrganizationConfig)('glassd/org_config', 'PUT', orgConfig);
