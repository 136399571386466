import { FC , ReactNode } from 'react';

import * as css from './ToolBar.module.css';

interface ToolBarProps {
	children ?: ReactNode;
	className ?: String;
}

export const ToolBar : FC<ToolBarProps> = ( { children , className } ) =>
	<nav className={ `${ className ?? '' } ${ css.toolbar }` }>
		{ children }
	</nav>
;
