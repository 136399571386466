/**
 * Methods for making http requests.
 */
export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type Headers = {
  [header in string]: string;
};

export const xhr = (method: Method, url: string, headers: Headers, body?: string): Promise<string> =>
	fetch(url, { method, mode: 'cors', headers, body })
		.then((resp) => resp.status >= 400 ? Promise.reject(resp) : resp.text());
