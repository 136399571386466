/**
 * Fetch drawings from Silica.
 *
 * @since 0.1.0
 */

import { Type, array, string, literal, boolean, keyof, interface as iface, union } from 'io-ts';
import { boolTrue } from '~/src/model/type-helpers';

import { Opening } from '~/src/design/opening';
import { calc, Want } from '~/src/design/opening/api/calc';
import { Requestor } from '~/src/api/Requestor';

import { Projection } from '~/src/design/opening/projection';

/**
 * Fetch some drawings from a using a Requestor.
 *
 * @since 0.1.0
 */
export const getDrawings = (req: Requestor) => (opening: Opening, drawings: Draw[]): Promise<string[]> =>
	calc(req)({ silicaOpening: opening, silicaDrawings: drawings }, GetDrawings)
		.then(({silicaDrawings, messages}) => array(string).is(silicaDrawings) && silicaDrawings.length === drawings.length
			? Promise.resolve(silicaDrawings)
			: Promise.reject({error: 'Failed to receive correct drawings', messages}));

/**
 * Request a drawing of the `Opening`'s itself.
 *
 * @since 0.1.0
 */
export interface DrawOpening {
	type: 'Opening';
	Projection: Projection;
	CurbEdges: boolean;
	DrawFloor: boolean;
	RulerDirection: 'out' | 'away';
}
/**
 * Codec for 'DrawOpening'.
 *
 * @since 0.1.0
 */
export const DrawOpening: Type<DrawOpening> = iface({
	type: literal('Opening'),
	Projection: Projection,
	CurbEdges: boolean,
	DrawFloor: boolean,
	RulerDirection: keyof({out: null, away: null}),
});

/**
 * Request a drawing of the `Panel`s filling in the `Opening`.
 *
 * @since 0.1.0
 */
export interface DrawPanels {
	type: 'Panels';
	Projection: Projection;
}
/**
 * Codec for 'DrawPanels'.
 *
 * @since 0.1.0
 */
export const DrawPanels: Type<DrawPanels> = iface({
	type: literal('Panels'),
	Projection: Projection,
});

/**
 * Request some sort of drawing.
 *
 * @since 0.1.0
 */
export type Draw = DrawOpening | DrawPanels;
/**
 * Codec for 'Draw'.
 *
 * @since 0.1.0
 */
export const Draw: Type<Draw> = union([
	DrawOpening,
	DrawPanels,
]);

/**
 * Everything we need to get drawings of `Opening`s.
 *
 * @since 0.1.0
 */
export interface GetDrawings {
	silicaOpening: Want<true, Opening>;
	silicaDrawings: Want<Draw[], string[]>;
}
/**
 * Codec for 'GetDrawings'.
 *
 * @since 0.1.0
 */
export const GetDrawings = iface({
	silicaOpening: Want(boolTrue, Opening),
	silicaDrawings: Want(array(Draw), array(string)),
});
