import * as NE from '~/src/base/Array/NonEmpty';
import { lerp, P, P3, p3_add, p3_sub } from '~/src/geometry/point';
import { V3 } from '~/src/geometry/vector';
import { Fn } from '~/src/base/Function';
import { Two } from '~/src/base/Array/Two';
import { Four } from '~/src/base/Array/Four';
import { Type, union } from 'io-ts';

/**
 * Fixed Segments may be either Linear or Cubic.
 */
export type Fixed<T> = FixedLinear<T> | FixedCubic<T>;

export const Fixed = <T,S>(codecT: Type<T,S>): Type<Fixed<T>,Fixed<S>> =>
	union([FixedLinear(codecT), FixedCubic(codecT)]);

/**
 * Project from parameter space to a point on the segment.
 *
 * `atParam(0)(seg)` yields the starting point of the segment.
 * `atParam(1)(seg)` yields the ending point of the segment.
 */
export const atParam = (p: number) => <V extends number[]>(seg: Fixed<V>): P<V> =>
	isFixedLinear(seg) ? atParam_linear(p)(seg) : atParam_cubic(p)(seg);

/**
 * Project from parameter space to a point on the linear segment.
 */
export const atParam_linear = (p: number) => <V extends number[]>(seg: FixedLinear<V>): P<V> =>
	lerp(p)(seg[1])(seg[0]);

/**
 * Project from parameter space to a point on the cubic segment.
 */
export const atParam_cubic = (p: number) => <V extends number[]>(seg: FixedCubic<V>): P<V> =>
	splitAtParam_cubic(p)(seg)[1][0];

/**
 * Split a segment at a parameter.
 */
export const splitAtParam = (p: number) => <V extends number[]>(seg: Fixed<V>): Two<Fixed<V>> =>
	isFixedLinear(seg) ? splitAtParam_linear(p)(seg) : splitAtParam_cubic(p)(seg);

/**
 * Split a linear segment at a parameter.
 */
export const splitAtParam_linear = (p: number) => <V extends number[]>(seg: FixedLinear<V>): Two<FixedLinear<V>> => {
	const mid = lerp(p)(seg[1])(seg[0]);
	return [[seg[0], mid], [mid, seg[1]]];
};

/**
 * Split a cubic segment at a parameter.
 */
export const splitAtParam_cubic = (p: number) => <V extends number[]>(seg: FixedCubic<V>): Two<FixedCubic<V>> => {
	const lp = lerp(p);
	const a = lp(seg[1])(seg[0]);
	const o = lp(seg[2])(seg[1]);
	const d = lp(seg[3])(seg[2]);
	const b = lp(o)(a);
	const c = lp(d)(o);
	const r = lp(c)(b);
	return [[seg[0], a, b, r], [r, c, d, seg[3]]];
};

/**
 * Transform a fixed segment from one affine vector space to another.
 */
export const transform = <T,S>(f: Fn<P<T>,P<S>>) => (seg: Fixed<T>): Fixed<S> =>
	isFixedLinear(seg) ? transform_linear(f)(seg) : transform_cubic(f)(seg);

/**
 * Find the end point of the fixed segment.
 */
export const fixed_end = <T>(seg: Fixed<T>): P<T> =>
	isFixedLinear(seg) ? seg[1] : seg[3];

/**
 * A fixed linear segment.
 */
export type FixedLinear<T> = Two<P<T>>;
/**
 * Check whether a fixed segment is linear.
 */
export const isFixedLinear = <T>(seg: Fixed<T>): seg is FixedLinear<T> => seg.length === 2;

export const FixedLinear = <T,S>(codecT: Type<T,S>): Type<FixedLinear<T>,FixedLinear<S>> => Two(P(codecT));

/**
 * Transform a fixed linear segment from one affine vector space to another.
 */
export const transform_linear = <T,S>(f: Fn<P<T>,P<S>>) => (seg: FixedLinear<T>): FixedLinear<S> =>
	[ f(seg[0]), f(seg[1]) ];

/**
 * A fixed cubic segment.
 */
export type FixedCubic<T> = Four<P<T>>;

export const FixedCubic = <T,S>(codecT: Type<T,S>): Type<FixedCubic<T>,FixedCubic<S>> => Four(P(codecT));

/**
 * Transform a fixed cubic segment from one affine vector space to another.
 */
export const transform_cubic = <T,S>(f: Fn<P<T>,P<S>>) => (seg: FixedCubic<T>): FixedCubic<S> =>
	[ f(seg[0]), f(seg[1]), f(seg[2]), f(seg[3]) ];

/**
 * Relative Segments may be either Linear or Cubic.
 */
export type Relative<T> = RelativeLinear<T> | RelativeCubic<T>;

/**
 * A Relative Linear segment.
 *
 * Ends at offset v0, straight line.
 */
export type RelativeLinear<T> = [T];
/**
 * Check whether a relative segment is linear.
 */
export const isRelativeLinear = <T>(seg: Relative<T>): seg is RelativeLinear<T> => seg.length === 1;

/**
 * Construct a relative linear segment.
 */
export const straight = <T>(v: T): RelativeLinear<T> => [v];

/**
 * Cubic segments vectorized.
 *
 * Ends at offset v0 using c1 and c2 as control points.
 */
export type RelativeCubic<T> = [T,T,T];

/**
 * Construct a relative linear segment.
 */
export const cubic = <T>(c0: T, c1: T, v: T): RelativeCubic<T> => [c0, c1, v];

/**
 * Project a relative linear segment from one vector space to another.
 */
export const project_linear = <T,S>(f: Fn<T,S>) => (seg: RelativeLinear<T>): RelativeLinear<S> =>
	[ f(seg[0]) ];

/**
 * Project a relative cubic segment from one vector space to another.
 */
export const project_cubic = <T,S>(f: Fn<T,S>) => (seg: RelativeCubic<T>): RelativeCubic<S> =>
	[ f(seg[0]), f(seg[1]), f(seg[2]) ];

/**
 * Project a relative segment from one vector space to another.
 */
export const project = <T,S>(f: Fn<T,S>) => (seg: Relative<T>): Relative<S> =>
	isRelativeLinear(seg) ? project_linear(f)(seg) : project_cubic(f)(seg);


/**
 * Fix a linear segment in space relative to a point.
 */
export const fix_linear = (p: P3, seg: RelativeLinear<V3>): FixedLinear<V3> =>
	[ p, p3_add(p, seg[0]) ];

/**
 * Fix a bezier segment in space relative to a point.
 */
export const fix_cubic = (p: P3, seg: RelativeCubic<V3>): FixedCubic<V3> =>
	[ p, p3_add(p, seg[0]), p3_add(p, seg[1]), p3_add(p, seg[2]) ];

/**
 * Fix a segment in space relative to a point.
 */
export const fix = (p: P3, seg: Relative<V3>): Fixed<V3> =>
	isRelativeLinear(seg) ? fix_linear(p, seg) : fix_cubic(p, seg);

/**
 * Fix a non-empty sequence of vectors in space, relative to a point.
 */
export const fix_some = (p: P3, segs: NE.NonEmpty<Relative<V3>>): NE.NonEmpty<Fixed<V3>> => {
	const out: NE.NonEmpty<Fixed<V3>> = [fix(p, segs[0])];
	segs.slice(1).forEach((seg, i) => {
		const s = out[i];
		if (s === undefined) return;
		out.push(fix(s[0], seg));
	});
	return out;
};

/**
 * Loosen a linear segment in 3d space from its initial point.
 */
export const loose_linear = (seg: FixedLinear<V3>): RelativeLinear<V3> =>
	[ p3_sub(seg[1], seg[0]) ];

/**
 * Loosen a bezier segment in 3d space from its initial point.
 */
export const loose_cubic = (seg: FixedCubic<V3>): RelativeCubic<V3> =>
	[ p3_sub(seg[1], seg[0]), p3_sub(seg[2], seg[0]), p3_sub(seg[3], seg[0]) ];

/**
 * Loosen a segment in 3d space from its initial point.
 */
export const loose = (seg: Fixed<V3>): Relative<V3> =>
	isFixedLinear(seg) ? loose_linear(seg) : loose_cubic(seg);
