import * as React from 'react';
import { Anomaly, Opening } from '~/src/design/opening';
import { OffsetLocation} from '~/src/design/opening/polymorphic';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Optional } from 'monocle-ts';
import { anomalyDepth, anomalyDescription, anomalyOffset, anomalyOffsetOffset, anomalyOffsetLocation, anomalySize, anomalyWidth } from '~/src/design/opening/optics';
import { constant, pipe } from 'fp-ts/lib/function';
import { Update } from '~/src/base/Function';
import { modifyUpdater } from '~/src/edit/util';

import * as O from 'fp-ts/lib/Option';
import { optionalLast } from '~/src/optics/Common';

import TextEdit from '~/src/edit/Text';
import { Converter } from '~/src/util/Units';
import NumberBase from '~/src/edit/Number';
import QDrawerError from '~/src/ui/drawer/QDrawerError';
import { Form } from '~/src/edit/types';
import ToggleButtonLayout from '~/src/ui/input/ToggleButtonLayout';


interface AnomalyEditProps {
	anomaly: Anomaly,
	lengthConverter: Converter<number, string>,
	update: Update<Anomaly>
}

const handleLocation = (update: Update<OffsetLocation>) => (
	_: React.MouseEvent<HTMLElement>,
	newLocation: OffsetLocation,
) => {
	update(constant(newLocation));
};

const AnomalyEdit: React.FC<AnomalyEditProps> = ({
	anomaly,
	update
}) => (
	<>
		<NumberBase
			unit='mm'
			min={0}
			value={anomaly.Offset.Offset}
			update={modifyUpdater(anomalyOffset.composeLens(anomalyOffsetOffset), update)}
			tlabel={{eng: 'Offset'}}
			sx={{ mx: 0, mt: 2 }}
		/>
		<ToggleButtonLayout htmlFor="start of offset measurement" label='Location'>
			<ToggleButtonGroup
				value={anomaly.Offset.Location}
				exclusive
				onChange={handleLocation(modifyUpdater(anomalyOffset.composeLens(anomalyOffsetLocation), update))}
				aria-label="start of offset measurement"
			>
				<ToggleButton value="start" aria-label="start">
			Start
				</ToggleButton>
				<ToggleButton value="end" aria-label="end">
			End
				</ToggleButton>
			</ToggleButtonGroup>
		</ToggleButtonLayout>
		<NumberBase
			unit='mm'
			min={0}
			value={anomaly.Size}
			update={modifyUpdater(anomalySize, update)}
			tlabel={{eng: 'Size'}}
			fullWidth
		/>
		<NumberBase
			unit='mm'
			value={anomaly.Depth}
			update={modifyUpdater(anomalyDepth, update)}
			tlabel={{eng: 'Depth'}}
			fullWidth
		/>
		<NumberBase
			unit='mm'
			min={0}
			value={anomaly.Width || 0}
			update={modifyUpdater(anomalyWidth, update)}
			tlabel={{eng: 'Width'}}
			fullWidth
		/>
		<TextEdit
			InputLabelProps={{ shrink: true }}
			sx={{ my: 2 }}
			multiline
			rows={4}
			name="Description"
			label='Description'
			value={anomaly.Description || ''}
			update={modifyUpdater(anomalyDescription, update)}
			fullWidth
		/>
	</>
);

const AnomalyForm = (
	optic: Optional<Opening,Anomaly[]>,
): Form<Opening> => ({
	lengthConverter,
	update,
	value,
}) => pipe(
	value,
	(a) => optic.compose(optionalLast<Anomaly>()).getOption(a),
	O.fold(
		constant(<QDrawerError>Anomaly Not Found</QDrawerError>),
		(anomaly) => (
			<AnomalyEdit
				anomaly={anomaly}
				lengthConverter={lengthConverter}
				update={modifyUpdater(optic.compose(optionalLast()),update)}
			/>
		)
	)
);

export default AnomalyForm;
