/**
 * Language and Translation support.
 *
 * @since 0.1.0
 */
import { keyof , partial , string , Type } from 'io-ts';

const trans: {[k in Language]: Type<string>} = {
	eng: string,
	cmn: string,
};

/**
 * ISO 639-3 codes of supported languages.
 *
 * @since 0.1.0
 */
export type Language = 'eng' | 'cmn';

/**
 * Codec for `Language`.
 *
 * @since 0.1.0
 */
export const Language: Type<Language> = keyof(trans);

/**
 * Translations of something into multiple languages.
 *
 * @since 0.1.0
 */
export type Translations = {[k in Language]?: string};

/**
 * Codec for `Translations`.
 *
 * @since 0.1.0
 */
export const Translations: Type<Translations> = partial(trans);

/**
 * Translations of something into multiple languages.
 *
 * @since 0.1.0
 */
export type TranslationMap<T extends string> = {[k in T]: Translations};

export interface TranslationEntry<T> {
	key: T;
	icon?: string | React.ReactElement;
	names: Translations;
}

/**
 * Translations of something into multiple languages.
 *
 * @since 0.1.0
 */
export type TranslationList<T> = TranslationEntry<T>[];
