import { type, string, Type, unknown } from 'io-ts';
import * as NE from '~/src/base/Array/NonEmpty';
import { Two } from '~/src/base/Array/Two';
import { Item } from './Item';
import { P } from '~/src/geometry/point';
import * as Seg from '~/src/geometry/segment';
import { V3 } from '~/src/geometry/vector';

export interface Curve extends Item {
	Segments: NE.NonEmpty<Seg.Fixed<V3>>;
}

export const Curve: Type<Curve> = type({
	Id: NE.NonEmpty(string),
	Class: NE.NonEmpty(string),
	Info: unknown,
	Segments: NE.NonEmpty(Seg.Fixed(V3)),
});

/**
 * Project from parameter space to a point on the curve.
 *
 * `atParam(0)(c)` yields the starting point of the curve.
 * `atParam(1)(c)` yields the ending point of the curve.
 */
export const atParam = (p: number) => (c: Curve): P<V3> => {
	const scaledP = p * c.Segments.length;
	const i = Math.floor(scaledP);
	const subP = scaledP - i;
	const seg = c.Segments[i];
	if (seg === undefined) {
		if (i < 0) {
			return Seg.atParam(scaledP)(c.Segments[0]);
		} else {
			return Seg.atParam(scaledP - c.Segments.length)(NE.last(c.Segments));
		}
	}
	return Seg.atParam(subP)(seg);
};

/**
 * Split a curve at a parameter.
 */
export const splitAtParam = (p: number) => <V extends number[]>(segs: NE.NonEmpty<Seg.Fixed<V>>): Two<NE.NonEmpty<Seg.Fixed<V>>> => {
	const scaledP = p * segs.length;
	const i = Math.floor(scaledP);
	const subP = scaledP - i;
	const [bs, seg, fs] = NE.extract(i)(segs);
	const [b, f] = Seg.splitAtParam(i < 0 ? scaledP : i >= segs.length ? scaledP - i : subP)(seg);
	return [NE.snoc(bs, b), [f, ...fs]];
};
