import * as React from 'react';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';

import { comp } from '~/src/base/Function';
import { set , dot , Optic } from '~/src/base/Optic';

import { getPriceListList , getPriceGridList } from '~/src/api/Pricing';
import { Requestor } from '~/src/api/Requestor';

import { ListId , PriceList , GridId , PriceGrid } from '~/src/model/Pricing';

import { PriceListListPage , PriceListEditPage , PriceListMakePage } from '~/src/pages/pricing/PriceList';
import { PriceGridListPage , PriceGridEditPage , PriceGridMakePage } from '~/src/pages/pricing/PriceGrid';

type Id = string;

type Mode = 'PriceList' | 'PriceGrid';
type Page = 'List' | 'Edit' | 'Make';

interface State {
	links : [ Mode , Page , Id ];
	lists : Record<ListId , PriceList>;
	grids : Record<GridId , PriceGrid>;
}

const links : Optic<State, [ Mode , Page , Id ]> = dot('links');
const lists : Optic<State, Record<ListId, PriceList>> = dot('lists');
const grids : Optic<State, Record<GridId, PriceGrid>> = dot('grids');

const Pricing = ( { req } : { req : Requestor } ) => {
	const [ state , setState ] = React.useState<State>({
		links : [ 'PriceList' , 'List' , '' ],
		lists : {},
		grids : {},
	});

	const [ mode , page , item ] = state.links;

	const matchMaker = ( mode : Mode , page : Page ) => ( tmode : Mode , tpage : Page ) =>
		mode === tmode && page === tpage;

	const match = matchMaker( mode , page );

	// Makes sure appropriate data is loaded and refreshed + it set's the view
	const loadr = ( mode : Mode , page : Page , item ?: Id ) => () => {
		const match = matchMaker( mode , page );

		const setLinks = set( links )( [ mode , page , item ?? '' ] );
		const setGrids = set( grids );

		switch( true ){
		case match( 'PriceList' , 'List' ) :
			getPriceListList( req )
				.then( lst => setState( comp( setLinks , set( lists )( lst ) )( state ) ) )
				.catch( e => console.error(e) );
			break;
		case match( 'PriceGrid' , 'List' ) :
			getPriceGridList( req )
				.then( lst => setState( comp( setLinks , setGrids( lst ) )( state ) ) )
				.catch( e => console.error(e) );
			break;
		case match( 'PriceGrid' , 'Edit' ) :
			if ( !item || item === '' )
				break;
			setState( setLinks );
			break;
		case match( 'PriceList' , 'Edit' ) :
			if ( !item || item === '' )
				break;
			setState( setLinks );
			break;
		default:
			setState( setLinks );
		}
	};

	const priceGrid = state.grids[item];

	const priceList = state.lists[item];

	// State initialization.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect( loadr( mode , page ) , [] );

	return (
		<Grid container spacing={2} style={{ height : '100%' , padding : '0 1em' , marginTop : 0 , marginBottom : 0 }} direction='column'>
			<Grid item sx={{ flex : 0 }}>
				<Grid container spacing={2}>
					<Grid item>
						<ButtonGroup variant='contained'>
							<Button onClick={ loadr( 'PriceList' , 'List' ) } variant={ mode === 'PriceList' ? 'contained' : 'outlined' }>
								Price Lists
							</Button>

							<Button onClick={ loadr( 'PriceGrid' , 'List' ) } variant={ mode === 'PriceGrid' ? 'contained' : 'outlined' }>
								Price Grids
							</Button>
						</ButtonGroup>
					</Grid>

					<Grid item>
						<Button
							variant='outlined'
							onClick={ loadr( mode , 'Make' ) }
							disabled={ page === 'Make' }
						>
							New
						</Button>
					</Grid>
				</Grid>
			</Grid>

			<Grid item sx={{ flex : 1 }}>
				{ match( 'PriceList' , 'List' ) ? <PriceListListPage value={state.lists} edit={ lid => loadr( 'PriceList' , 'Edit' , lid ) } /> : null }

				{ match( 'PriceGrid' , 'List' ) ? <PriceGridListPage value={state.grids} edit={ gid => loadr( 'PriceGrid' , 'Edit' , gid ) } /> : null }

				{ match( 'PriceList' , 'Edit' ) && item !== '' && priceList !== undefined ? <PriceListEditPage req={req} list={priceList} /> : null }

				{ match( 'PriceGrid' , 'Edit' ) && item !== '' && priceGrid !== undefined ? <PriceGridEditPage req={req} grid={priceGrid} /> : null }

				{ match( 'PriceList' , 'Make' ) ? <PriceListMakePage req={req} aok={ loadr( 'PriceList' , 'List' ) } /> : null }

				{ match( 'PriceGrid' , 'Make' ) ? <PriceGridMakePage req={req} aok={ loadr( 'PriceGrid' , 'List' ) } /> : null }
			</Grid>
		</Grid>
	);
};


export default Pricing;
