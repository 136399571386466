/* eslint-disable @typescript-eslint/no-explicit-any */

import { number , tuple } from 'io-ts';

import { get1 , index } from '~/src/base/Optic';
import { Update, comp } from '~/src/base/Function';
import { Name , Plan , Elem , ElemVal , RRange , Ranges , RangesS , Mag , Kind , KindT , KindTF , RRangeS , RRangeM , ranges , lo , hi , sVal , mVal , nName , nItem , elMag , elLbl , elLst } from '~/src/model/Tableau';
import { EditProps } from '~/src/edit/types';

function quickSwap<T>( list: ( T | undefined)[], x: number, y: number ): (T | undefined)[] {
	if (x < 0 || x >= list.length || y < 0 || y >= list.length) {
		return [...list];
	}

	const swappedList = [...list];
	const temp = swappedList[x];
	swappedList[x] = swappedList[y];
	swappedList[y] = temp;

	return swappedList;
}

function swapNameElem(elem: Name<Elem[]>, x: number, y: number): Name<Elem[]> {
	const newItem: Name<Elem[]> = {
		...elem,
		item: quickSwap(elem.item, x, y).filter((item): item is Elem => item !== undefined),
	};
	return newItem;
}

function swapElemVal(elem: ElemVal<Kind>, x: number, y: number): ElemVal<Kind> {
	const newItem: ElemVal<Kind> = {
		...elem,
		lst: quickSwap(elem.lst, x, y) as KindT[Kind][],
	};
	return newItem;
}

// The main entry point for the plan builder
const PlanEdit = ({ value, update }: EditProps<Plan>) => {
	const newItem: Name<Elem[]> = { name: 'NewItem', item: [] };

	return (
		<table>
			<thead>
				<tr>
					<th colSpan={5}>Planner</th>
				</tr>
				<tr>
					<th>↑</th>
					<th>↓</th>
					<th>Value</th>
					<th>Del</th>
					<th>Add</th>
				</tr>
			</thead>

			<tbody>
				{value.map((item, idx) => (
					<ItemEdit
						key={idx}
						value={item}
						update={comp( update , index(idx) )}
						remove={() => update(() => value.filter((_el, ix) => ix !== idx))}
						swap={ud => update(() => quickSwap(value, idx, idx + ud) as Plan)}
					/>
				))}
			</tbody>

			<tfoot>
				<tr>
					<td colSpan={5}>
						<input
							type="button"
							value="Add"
							onClick={() => update(() => [...value, newItem])}
						/>
					</td>
				</tr>
			</tfoot>
		</table>
	);
};

// The editor for `Name<Elem[]>`, or a plan property / item.
const ItemEdit = ( { value, update, remove, swap }: { remove: () => void , swap: (upOrDown: number) => void } & EditProps<Name<Elem[]>>) => {
	return (
		<>
			<tr>
				<td>
					<input onClick={() => swap(-1)} type="button" value="↑" />
				</td>

				<td>
					<input onClick={() => swap(1)} type="button" value="↓" />
				</td>

				<td>
					<TextEdit
						value={value.name}
						update={comp( update , nName )}
					/>
				</td>

				<td>
					<input type="button" value="Del" onClick={remove} />
				</td>

				<td>
					<input
						type="button"
						value="Add"
						onClick={ () => update( nItem<Elem[]>()( a => [...a, 'NewItem'] ) ) }
					/>
				</td>
			</tr>

			{value.item.map( (elem: Elem , idx: number) => (
				<ElemEdit
					key={idx}
					value={elem}
					update={ comp( update , nItem<Elem[]>() , index( idx ) ) }
					remove={ () => comp( update , nItem<Elem[]>() )( (es: Elem[]) => es.filter((_el, ix) => ix !== idx) ) }
					swap={ ud => update(() => swapNameElem(value, idx, idx + ud) as Name<Elem[]>) }
				/>
			))}
		</>
	);
};

// Edior for a single Plan (item / property) element.
const ElemEdit = ( { value, update, remove, swap }: { remove: () => void , swap: (upOrDown: number) => void } & EditProps<Elem>) => {
	// Is the Elem a label?
	const isLabel = typeof value == 'string';
	// Text for the Elem type toggler.
	const tgLabel = isLabel ? 'VAL' : 'LBL';
	// Actual label of the 'Elem'
	const elLabel = isLabel ? value : value.lbl;
	// Function that returns a value to be used when we switch from label to value and vice versa
	const flipper = () =>
		(isLabel ? { lbl: elLabel, mag: 'MNum', knd: 'KSimpl1', lst: [] } : elLabel) as Elem;
	// If we are working with ElemVal's, when we add new element this will create a default value of appropriate kind
	const defItem = () : number | [number, number] | RRange<number> | RRange<[number, number]> => {
		// this should not occur
		if (isLabel) {
			return 0;
		}

		switch (value.knd) {
		case 'KSimpl1':
			return 0;
		case 'KSimpl2':
			return [0, 0];
		case 'KRange1':
			return { tag: 'REQ', val: 0 };
		case 'KRange2':
			return { tag: 'REQ', val: [0, 0] };
		default:
			return 0;
		}
	};

	return (
		<>
			<tr>
				<td>
					<input onClick={() => swap(-1)} type="button" value="↑" />
				</td>

				<td>
					<input onClick={() => swap(1)} type="button" value="↓" />
				</td>

				<td>
					{/* Here we toggle from value elem to label elem and vice versa */}
					<input
						type="button"
						value={tgLabel}
						onClick={() => update(flipper)}
					/>
					{isLabel ? null : (
						<MagEdit
							value={value.mag}
							update={comp( update as unknown as Update<ElemVal<Kind>> , elMag<Kind>() )}
						/>
					)}
					{isLabel ? null : <KindEdit value={value.knd} update={update} />}
					<TextEdit value={elLabel} update={comp( update , elLbl )} />
				</td>

				<td>
					<input type="button" value="Del" onClick={remove} />
				</td>

				<td>
					{isLabel ? null : (
						<input
							type="button"
							value="Add"
							onClick={() => comp( update as Update<ElemVal<Kind>> , elLst<Kind>() )( (lst : any[]) => [...lst, defItem() as any] ) }
						/>
					)}
				</td>
			</tr>

			{isLabel
				? null
				: value.lst.map((val, idx) => (
					<ValEdit
						key={idx}
						value={val}
						update={ comp( update as Update<ElemVal<Kind>> , elLst<Kind>() , index(idx) ) as Update<KindT[Kind]> }
						remove={ () => comp( update as Update<ElemVal<Kind>> , elLst<Kind>() )( (es : any[] ) => es.filter( (_el, ix) => ix !== idx ) ) }
						swap={ ud => update(() => swapElemVal(value, idx, idx + ud) as Elem) }
					/>
				))}
		</>
	);
};

// Magnitude editor that will change the magnitude of some item element.
const MagEdit = ({ value, update }: EditProps<Mag>) => {
	const MagOption = ({ name }: { name: Mag }) => (
		<option selected={value === name} value={name}>
			{name}
		</option>
	);

	return (
		<select onChange={(e) => update(() => e.target.value as Mag)}>
			<MagOption name="MNum" />
			<MagOption name="MLen" />
			<MagOption name="MAng" />
			<MagOption name="MAre" />
		</select>
	);
};

/*
 *	Kind editor will not only change the kind of the item element, but also the
 *	element itself,	because the element will have different type depending on the
 *	selected kind.
 */
const KindEdit = ({ value, update }: EditProps<Elem>) => {
	const changeElemKind = (knd: Kind) => (elm: Elem) =>
		(typeof elm == 'string' ? elm : { ...elm, knd: knd, lst: [] }) as Elem;

	const KindOption = ({ name }: { name: Kind }) => (
		<option selected={ get1( elLbl )( value ) === name } value={name}>
			{name}
		</option>
	);

	return (
		<select onChange={(e) => update(changeElemKind(e.target.value as Kind))}>
			<KindOption name="KSimpl1" />
			<KindOption name="KSimpl2" />
			<KindOption name="KRange1" />
			<KindOption name="KRange2" />
		</select>
	);
};

/*
 *	The value item editor wrapper. It will display appropriate editor for each
 *	kind of value item.
 */
const ValEdit = <T extends Kind>({ remove , swap , value , update }: { remove: () => void , swap: (upOrDown: -1 | 1) => void } & EditProps<KindT[T]>) => {
	const vitem = () => {
		if (KindTF('KSimpl1').is(value)) {
			return (
				<KSimpl1
					value={value}
					update={update as unknown as Update<KindT['KSimpl1']>}
				/>
			);
		} else if (KindTF('KSimpl2').is(value)) {
			return (
				<KSimpl2
					value={value}
					update={update as unknown as Update<KindT['KSimpl2']>}
				/>
			);
		} else if (KindTF('KRange1').is(value)) {
			return (
				<KRange1
					value={value as KindT['KRange1']}
					update={update as unknown as Update<KindT['KRange1']>}
				/>
			);
		} else {
			return (
				<KRange2
					value={value as KindT['KRange2']}
					update={update as unknown as Update<KindT['KRange2']>}
				/>
			);
		}
	};

	return (
		<tr>
			<td>
				<input onClick={() => swap(-1)} type="button" value="↑" />
			</td>

			<td>
				<input onClick={() => swap(-1)} type="button" value="↓" />
			</td>

			<td>{vitem()}</td>

			<td>
				<input type="button" value="Del" onClick={remove} />
			</td>

			<td></td>
		</tr>
	);
};

const KSimpl1 = ({ value, update }: EditProps<KindT['KSimpl1']>) => (
	<input
		type="text"
		value={value}
		onChange={(e) => update(() => Number(e.target.value) as KindT['KSimpl1'])}
	/>
);

const KSimpl2 = ({ value, update }: EditProps<KindT['KSimpl2']>) => (
	<>
		(
		<input
			type="text"
			value={value[0]}
			onChange={(e) =>
				update((p: [number, number]) => [Number(e.target.value), p[1]])
			}
		/>
		,
		<input
			type="text"
			value={value[1]}
			onChange={(e) =>
				update((p: [number, number]) => [p[0], Number(e.target.value)])
			}
		/>
		)
	</>
);

const KRange1 = ({ value, update }: EditProps<KindT['KRange1']>) => {
	const mkr = (r: Ranges): RRange<number> => {
		if (RangesS.is(r)) {
			return { tag: r, val: 0 } as RRange<number>;
		} else {
			return { tag: r, val: { lo: 0, hi: 1 } } as RRange<number>;
		}
	};

	if (RRangeS(number).is(value)) {
		return (
			<>
				<Ranger<number> mkr={mkr} value={value} update={update} />
				<input
					type="text"
					value={value.val}
					onChange={e => comp( update as unknown as Update<RRangeS<number>> , sVal<number>() )( () => Number(e.target.value)) }
				/>
			</>
		);
	} else {
		return (
			<>
				<Ranger<number> mkr={mkr} value={value} update={update} />

				<input
					type="text"
					value={value.val.lo}
					onChange={ e => comp( update as unknown as Update<RRangeM<number>> , mVal<number>() , lo<number>() )( () => Number(e.target.value) ) }
				/>

				<input
					type="text"
					value={value.val.hi}
					onChange={ e => comp( update as unknown as Update<RRangeM<number>> , mVal<number>() , hi<number>() )( () => Number(e.target.value) ) }
				/>
			</>
		);
	}
};

const KRange2 = ({ value, update }: EditProps<KindT['KRange2']>) => {
	type NT = [number, number];

	const mkr = (r: Ranges): RRange<NT> => {
		if (RangesS.is(r)) {
			return { tag: r, val: [0, 0] } as RRange<NT>;
		} else {
			return { tag: r, val: { lo: [0, 0], hi: [1, 1] } } as RRange<NT>;
		}
	};

	if (RRangeS<NT>(tuple([number , number])).is(value)) {
		return (
			<>
				<Ranger<[number, number]> mkr={mkr} value={value} update={update} />

				<NumPairEdit
					value={value.val}
					update={comp( update as unknown as Update<RRangeS<NT>> , sVal<NT>() )}
				/>
			</>
		);
	} else {
		return (
			<>
				<Ranger<[number, number]> mkr={mkr} value={value} update={update} />

				<NumPairEdit
					value={ value.val.lo }
					update={ comp( update as unknown as Update<RRangeM<NT>> , mVal<NT>() , lo<NT>() ) }
				/>

				<NumPairEdit
					value={ value.val.hi }
					update={ comp( update as unknown as Update<RRangeM<NT>> , mVal<NT>() , hi<NT>() ) }
				/>
			</>
		);
	}
};

export default PlanEdit;

/* UTILITY EDITORS */

const Ranger = <T,>({ mkr , value , update } : { mkr: (r: Ranges) => RRange<T> } & EditProps<RRange<T>>) => {
	return (
		<select onChange={(e) => update(() => mkr(e.target.value as Ranges))}>
			{ranges.map( tag => <option selected={tag === value.tag} value={tag}>{tag}</option> ) }
		</select>
	);
};

const NumPairEdit = ({ value, update }: EditProps<[number, number]>) => (
	<>
		<input
			type="text"
			value={value[0]}
			onChange={(e) => update((p) => [Number(e.target.value), p[1]])}
		/>

		<input
			type="text"
			value={value[1]}
			onChange={(e) => update((p) => [p[0], Number(e.target.value)])}
		/>
	</>
);

const TextEdit = ({ value, update }: EditProps<string>) => (
	<input
		type="text"
		value={value}
		onChange={(ev) => update(() => ev.target.value)}
	/>
);
