/**
 * Building Templates and getting information about them.
 *
 * @since 0.4.0
 */
import { array, boolean, keyof, type, intersection, number, partial, string, Type, unknown } from 'io-ts';
import { NonEmpty } from '~/src/model/Boxes';
import { Template } from '~/src/design/template';
import { RetailPricing } from '~/src/pricing/retail';
import { Message } from '~/src/model/Message';
import { Requestor } from '~/src/api/Requestor';
import { Drawing } from '~/src/model/Drawing';
import { FigMap, Figure } from '~/src/figure';
import { Quote } from '~/src/pricing/quote';
import { TranslationList } from '~/src/model/Language';

/**
 * Drawing Type
 *
 * Default is 'Retail'.
 *
 * @since 0.4.0
 */
export type DrawingType = 'Retail' | 'Design' | 'Cutting' | 'Product';

/**
 * Codec for `DrawingType`.
 *
 * @since 0.4.0
 */
export const DrawingType: Type<DrawingType> = keyof({
	Retail: true,
	Design: true,
	Cutting: true,
	Product: true,
});

/**
 * Drawing Type interface names.
 */
export const DrawingTypeTranslations: TranslationList<DrawingType> = [
	{
		key : 'Retail',
		names: { eng: 'Retail' },
	},
	{
		key : 'Design',
		names: { eng: 'Design' },
	},
	{
		key : 'Cutting',
		names: { eng: 'Cutting' },
	},
	{
		key : 'Product',
		names: { eng: 'Product' },
	},
];

/**
 * Drawing Format
 *
 * Default is 'svg'.
 *
 * @since 0.4.0
 */
export type DrawingFormat = 'png' | 'svg' | 'dxf' | 'pdf';

/**
 * Codec for `DrawingType`.
 *
 * @since 0.4.0
 */
export const DrawingFormat: Type<DrawingFormat> = keyof({
	png: true,
	svg: true,
	dxf: true,
	pdf: true,
});

/**
 * Drawing Format interface names.
 */
export const DrawingFormatTranslations: TranslationList<DrawingFormat> = [
	{
		key : 'svg',
		names: { eng: 'SVG' },
	},
	{
		key : 'png',
		names: { eng: 'PNG' },
	},
	{
		key : 'dxf',
		names: { eng: 'DXF' },
	},
	{
		key : 'pdf',
		names: { eng: 'PDF' },
	},
];

export type FigDrawingFormat = 'figure' | DrawingFormat;

export const FigDrawingFormatTranslations: TranslationList<FigDrawingFormat> = ([
	{
		key: 'figure',
		names: { eng: 'Edit' },
	}
] as TranslationList<FigDrawingFormat>).concat(DrawingFormatTranslations);

/**
 * Template Drawing Request.
 *
 * @since 0.4.0
 */
export type DrawingReq = {
	Type?: DrawingType,
	Format?: DrawingFormat,
};

/**
 * Codec for `DrawingReq`.
 *
 * @since 0.4.0
 */
export const DrawingReq: Type<DrawingReq> = partial({
	Type: DrawingType,
});

/**
 * Template Build Request.
 *
 * @since 0.4.0
 */
export type BuildReq = {
	Template: Template, /** The template we're building. */
	ApplyLimits?: string, /** Apply the given limitations to the template before building. */
	GetBuilt?: boolean, /** Build the template and return the internal data structure. */
	GetDrawings?: NonEmpty<DrawingReq>, /** Fetch drawings. */
	GetFigures?: NonEmpty<DrawingType>, /** Fetch the figure for this template. */
	GetLimits?: boolean, /** Fetch updated fabrication soft limits on variables. */
	GetMass?: boolean, /** Get masses. */
	GetPrice?: boolean, /** Calculate prices. */
	GetQuote?: boolean, /** Get the full Quote object. */
	GetValid?: boolean, /** Check whether the template is within the fabrication limits. */
};

/**
 * Codec for `BuildReq`.
 *
 * @since 0.4.0
 */
export const BuildReq: Type<BuildReq> = intersection([
	type({
		Template: Template,
	}),
	partial({
		ApplyLimits: string,
		GetBuilt: boolean,
		GetDrawings: NonEmpty(DrawingReq),
		GetFigures: NonEmpty(DrawingType),
		GetLimits: boolean,
		GetMass: boolean,
		GetPrice: boolean,
		GetValid: boolean,
		GetQuote: boolean,
	}),
]);

/**
 * Template Build Response
 *
 * @since 0.4.0
 */
export type BuildResp = {
	Price?: RetailPricing,
	Mass?: number[],
	Drawings?: NonEmpty<Drawing>,
	Figures?: FigMap<DrawingType>,
	Messages: Message[],
	Quote?: Quote,
};

/**
 * Codec for `BuildResp`.
 *
 * @since 0.4.0
 */
export const BuildResp: Type<BuildResp> = intersection([
	type({
		Messages: array(Message),
	}),
	partial({
		Built: unknown,
		Drawings: NonEmpty(Drawing),
		Limits: boolean,
		Figures: partial({
			Retail: Figure,
			Design: Figure,
			Cutting: Figure,
			Product: Figure,
		}),
		Mass: array(number),
		Price: RetailPricing,
		Valid: boolean,
		Quote: Quote,
	}),
]);

/**
 * Make a Template Build request.
 *
 * @since 0.4.0
 */
export const build = (req: Requestor) => (buildReq: BuildReq): Promise<BuildResp> =>
	req.request(BuildResp)('template/build', 'POST', buildReq);
