import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { solarizedDark , solarizedLight } from '@uiw/codemirror-theme-solarized';
import { vim } from '@replit/codemirror-vim';
import { useMediaQuery } from '@mui/material';

import { EditProps } from '~/src/edit/types';

export const DhallEdit = ( { value , update } : EditProps<string> ) =>(
	<CodeMirror
		theme={ useMediaQuery('(prefers-color-scheme: dark)') ? solarizedDark : solarizedLight }
		value={ value }
		extensions={ [ vim() , langs.haskell() ] }
		onChange={ content => update( () => content ) }
	/>
);
