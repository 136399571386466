import * as React from 'react';
import { constant, flow, pipe } from 'fp-ts/lib/function';
import { isModifiedNumber, showModifiedMessage, textValue } from '~/src/edit/util';
import { optionalVariableRank, variableRank } from '~/src/admin/template/mod/inner-optics';
import ModTextField from '~/src/ui/input/ModTextField';
import * as O from 'fp-ts/lib/Option';
import { modTemplate } from '~/src/admin/template/mod/optics';
import { ClientTemplateModProps } from '~/src/admin/template/mod/edit';
import { TemplateMod } from '~/src/admin/template/mod/inner';
import * as Id from 'fp-ts/lib/Identity';

interface ModVariableProps extends Pick<ClientTemplateModProps, 'update'> {
	variableLabel: string;
	currentRank?: number;
	ModTemplate: TemplateMod;
}

const ModVariableRank: React.FC<ModVariableProps> = ({
	variableLabel,
	currentRank,
	ModTemplate,
	update,
}) => {

	const handleVariableRankChange = (label: string) => (rank: string) => modTemplate.modify(
		variableRank.modify(
			constant(
				({
					...variableRank.get(ModTemplate),
					[label]: rank ? Number(rank) : undefined
				})
			)
		)
	);

	return (
		<ModTextField
			value={pipe(optionalVariableRank(variableLabel).getOption(ModTemplate), O.toNullable)}
			label={`Display Rank: ${currentRank || 'None'}`}
			inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
			onChange={flow(textValue, (x) => handleVariableRankChange(variableLabel)(x), update)}
			helperText={
				pipe(
					isModifiedNumber,
					Id.ap(O.fromNullable(currentRank)),
					Id.ap(optionalVariableRank(variableLabel).getOption(ModTemplate)),
					showModifiedMessage
				)}
		/>
	);
};

export default ModVariableRank;
