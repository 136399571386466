.headerbar
{
  height: 50px;
  display: flex;
  flex-shrink: 0;

  background: var(--scnd-bg);
}

.main, .lside, .rside
{
  display: flex;
}

.main
{
  order: 2;
  flex-grow: 2;
  justify-content: center;
}

.lside
{
  order: 1;
  flex-grow: 1;
  justify-content: left;
}

.rside
{
  order: 3;
  flex-grow: 1;
  justify-content: right;
}

.headerbar a {
  padding: 0 30px;
  border-width: 0;
  border-style: solid;
  border-left-width: 1px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--prim-fg);
  border-color: var(--prim-border);

  transition: 200ms ease all;
}

.headerbar a:hover, .headerbar a.active {
  background: var(--prim-bg);
}

.headerbar a:last-child {
  border-right-width: 1px;
}
