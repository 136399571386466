import { flatten } from 'flat';
import { constant, flow, pipe } from 'fp-ts/lib/function';
import { axial, Curb, Opening, SectionStep, SectionTree, Wall } from '~/src/design/opening';

import { mem } from '~/src/base/Memoize';
import { Mod, Update } from '~/src/base/Function';

import * as A from 'fp-ts/lib/Array';
import * as O from 'fp-ts/lib/Option';
import { exists, none, some } from 'fp-ts/lib/Option';
import { Directed, Measurement } from '~/src/design/opening/measurement';
import { openingLeft, opticRight, stretchMeasure, sectionFloor, openingSection, sectionCeiling, curbDirection, ceilingCurbsOptic, floorCurbsOptic, indexCurb, curbAnomalies, curbMeasure } from '~/src/design/opening/optics';
import { majorValue, major, majorMeasure, minorValue, measureMajor, measureMinor } from '~/src/design/opening/measurement/optics';
import { Path } from './types';
import { sectionSection } from '~/src/design/opening/polymorphic/optics';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as N from 'fp-ts/number';
import * as S from 'fp-ts/string';
import { sequenceT } from 'fp-ts/lib/Apply';
import { Optional } from 'monocle-ts';
import { OneOrUptoThree } from '~/src/model/Boxes';

/**
 * Most optics provide a `modify` function, which allows you to modify the parent object with a transformation of a contained object.
 *
 * This is a type for the intersection of all such optics.
 */
export type Modifier<S,T> = Readonly<{
	modify: (f: Mod<T>) => Mod<S>,
}>;

/**
 * Compose an updater with something that provides a `modify` function.
 *
 * This is the non-memoized version, which may be useful when it's not going to be called repeatedly.
 */
export const modifyUpdater_ = <S,T>(optic: Modifier<S,T>, update: Update<S>): Update<T> =>
	(f: Mod<T>) => update(optic.modify(f));

/**
 * Compose an updater with something that provides a `modify` function.
 *
 * This is memoized! If the inputs have been used before they will be remembered and the exact same `Updater` will be returned.
 * This makes it suitable for passing in props of functional components.
 */
export const modifyUpdater: <S,T>(optic: Modifier<S,T>, update: Update<S>) => Update<T> = mem(modifyUpdater_);

/**
 * Compose an updater with an update function.
 *
 * This is the non-memoized version, which may be useful when it's not going to be called repeatedly.
 */
export const updateUpdater_ = <T>(f: Mod<T>, updater: Update<T>): Update<T> =>
	(g: Mod<T>) => updater((x: T) => f(g(x)));

/**
 * Compose an updater with an update function.
 *
 * This is memoized! If the inputs have been used before they will be remembered and the exact same `Updater` will be returned.
 * This makes it suitable for passing in props of functional components.
 */
export const updateUpdater: <T>(f: Mod<T>, updater: Update<T>) => Update<T> = mem(updateUpdater_);

/**
 * Get the index values to walk down an Opening by navigating each SectionTree
 *
 * @param path string[] Path to section in Opening
 * @returns number[]
 */
export const parseSectionPath = (path: Path): SectionStep[] =>
	path.filter((e, i, a) => (e == '0' || e == '1' || e == '2') && a[i - 1] == 'Rest').map(e => parseInt(e)) as SectionStep[];

/**
 * Checks value in Opening by conditional
 *
 * @param opening Opening
 * @returns Option of string or none
 */
export const filterOpeningWithFlatKeys = (opening: Opening) => (cond: (value: unknown) => boolean) => (x: string) => {
	const flattened: Record<string, unknown> = flatten(opening);
	return cond(flattened[x]) ? some(x) : none;
};

/**
 * Filters Opening path keys for keys that indicate wall curb
 *
 * @param x string Object path in Opening
 * @returns Option of string or none
 */
export const filterFuncForWallCurbs = (x: string) => x.includes('Right.Curbs.0.Measure.Major') || x.includes('Left.Curbs.0.Measure.Major') ? some(x) : none;

/**
 * Filters Opening path keys for keys that indicate
 * ceiling or floor minor measurement
 *
 * @param x string Object path in Opening
 * @returns Option of string or none
 */
export const filterFuncForHorizontalComponents = (x: string) => x.includes('Floor.Curbs.0.Measure.Minor') || x.includes('Ceiling.Curbs.0.Measure.Minor') ? some(x) : none;

/**
 * Returns paths into Opening object to Walls that have
 * null as the Major dimension in they're first curb.
 *
 * @param opening Opening Object representing Opening Design
 * @returns string[] Object paths to wall
 */
export const unlockedWallPaths: (op: Opening) => string[] = (opening: Opening) =>pipe(
	opening,
	flatten,
	Object.keys,
	A.filterMap(filterFuncForWallCurbs),
	A.filterMap(
		filterOpeningWithFlatKeys(opening)(
			(x) => x === null
		)
	)
);

/**
 * Returns paths into Opening object to Walls that have
 * a numeric value as the Major dimension in they're first curb.
 *
 * @param opening Opening Object representing Opening Design
 * @returns string[] Object paths to wall
 */
export const lockedWallPaths: (op: Opening) => string[] = (opening: Opening) => pipe(
	opening,
	flatten,
	Object.keys,
	A.filterMap(filterFuncForWallCurbs),
	A.filterMap(
		filterOpeningWithFlatKeys(opening)(
			(x) => pipe(some(x), exists((n: unknown) => typeof n === 'number'))
		)
	)
);

/**
 * Returns paths into Opening object to Ceilings and Floors
 * that have null as the Minor dimension in they're first curb.
 *
 * @param opening Opening Object representing Opening Design
 * @returns string[] Object paths to wall
 */
export const unlockedHorizontalComponentPaths: (op: Opening) => string[] = (opening: Opening) => pipe(
	opening,
	flatten,
	Object.keys,
	A.filterMap(filterFuncForHorizontalComponents),
	A.filterMap(
		filterOpeningWithFlatKeys(opening)(
			(x) => x === null
		)
	)
);

/**
 * Returns paths into Opening object to Ceilings and Floors
 * that have a numeric value as the Minor dimension in
 * they're first curb.
 *
 * @param opening Opening Object representing Opening Design
 * @returns string[] Object paths to wall
 */
export const lockedHorizontalComponentPaths: (op: Opening) => string[] = (opening: Opening) => pipe(
	opening,
	flatten,
	Object.keys,
	A.filterMap(filterFuncForHorizontalComponents),
	A.filterMap(
		filterOpeningWithFlatKeys(opening)(
			(x) => pipe(some(x), exists((n: unknown) => typeof n === 'number'))
		)
	)
);

/**
 * Converts down and left measurements to negative values while
 * preserving up and right measurements.
 *
 * @param direction string Direction of measurement
 * @returns number
 */
export const checkValueSign = (direction: 'down'|'left'|'right'|'up') => (value: number) => {
	switch (direction) {
	case 'down':
	case 'left': return -value;
	case 'right':
	case 'up': return value;
	}
};

/**
 * Gets the Major measurement, converting it to a
 * positive or negative value, depending on measurement direction.
 *
 *
 * Used to calculate missing value in vertical loop components
 *
 * @param m Measurement Object that is part of vertical opening loop
 * @returns number
 */
const transformMajorVerticalValue = (m: Measurement<'Bottom'|'Left'|'Right'|'Top'>) => pipe(measureMajor().composeLens(majorValue).get(m), checkValueSign(m.Direction));

/**
 * Gets the Minor measurement, converting it to a
 * positive or negative value, depending on measurement direction.
 *
 *
 * Used to calculate missing value in vertical loop components
 *
 * @param m Measurement Object that is part of vertical opening loop
 * @returns number
 */
const transformMinorVerticalValue = (m: Measurement<'Bottom'|'Left'|'Right'|'Top'>) => pipe(measureMinor().composeLens(minorValue).get(m), checkValueSign(m.Direction));

/**
 * Determines whether to access and convert Minor or Major
 * Measurement value based on Measurement direction
 *
 * @param m Measurement Object that is part of vertical opening loop
 * @returns number
 */
export const verticalComponentValueByDirection = (m: Measurement<'Bottom'|'Left'|'Right'|'Top'>) => {
	switch (m.Direction) {
	case 'down': return transformMajorVerticalValue(m);
	case 'up': return transformMajorVerticalValue(m);
	case 'left': return transformMinorVerticalValue(m);
	case 'right': return transformMinorVerticalValue(m);
	}
};

/**
 * Add vertical loop component measurements together to
 * calculate value needed to lock a unlocked Measurement
 *
 * @param args Measurement
 * @returns number
 */
export const sumVerticalComponents = (...args: Measurement<'Bottom'|'Left'|'Right'|'Top'>[]) =>
	[...args]
		.map(verticalComponentValueByDirection)
		.reduce((a, b) => a + b, 0);

/**
 * Calculates absolute value of unspecified vertical loop component
 * given Measurement arguments
 *
 * @example
 *
 * const up: Measurement<'Right'> = {
 *'Direction': 'up',
 *'Major': null,
 *'Minor': 0,
 *'type': 'Axial'
 *};
 *const down: Measurement<'Left'> = {
 *'Direction': 'down',
 *'Major': 1828.8,
 *'Minor': 0,
 *'type': 'Axial'
 *};
 *const left: Measurement<'Top'> = {
 *'Direction': 'left',
 *'Major': null,
 *'Minor': 0,
 *'type': 'Axial'
 *};
 *const right: Measurement<'Bottom'> = {
 *'Direction': 'right',
 *'Major': 812.8,
 *'Minor': 0,
 *'type': 'Axial'
 *};
 *
 * const val = calculateUnlockedMeasurementValue(up, down, left, right);
 *
 * console.log(val)
 *
 * // 1828.8
 */
export const calculateUnlockedMeasurementValue = flow(sumVerticalComponents, Math.abs);

/**
 * Helper function for getting Measurement from Left wall paths
 */
export const getLeftWallOpticValue = (opening: Opening) => (objPath: string) => objPath.includes('Left')
	? openingLeft.composeOptional(stretchMeasure(0)).getOption(opening)
	: O.none;

/**
 * Helper function for getting Measurement from Right wall paths
 */
export const getRightWallOpticValue = (opening: Opening) => (objPath: string) => objPath.includes('Right')
	? opticRight(parseSectionPath(objPath.split('.'))).composeOptional(stretchMeasure(0)).getOption(opening)
	: O.none;

/**
 * Helper function for getting Measurement from Ceiling paths
 */
export const getCeilingOpticValue = (opening: Opening) => (objPath: string) => objPath.includes('Ceiling')
	? openingSection.composeOptional(sectionSection(parseSectionPath(objPath.split('.')))).composeLens(sectionFloor).composeOptional(stretchMeasure(0)).getOption(opening)
	: O.none;

/**
 * Helper function for getting Measurement from Floor paths
 */
export const getFloorOpticValue = (opening: Opening) => (objPath: string) => objPath.includes('Ceiling')
	? openingSection.composeOptional(sectionSection(parseSectionPath(objPath.split('.')))).composeLens(sectionCeiling).composeOptional(stretchMeasure(0)).getOption(opening)
	: O.none;

/**
 * Actualizes the vertical Measurement objects from a list of object paths and an Opening
 * @param opening Opening
 * @returns Measurement[]
 */
export const lockedVerticalMeasurements = (opening: Opening) => (objectPaths: string[]) => {
	const leftMeasurements = pipe(objectPaths, A.map(getLeftWallOpticValue(opening)), A.compact);
	const rightMeasurements = pipe(objectPaths, A.map(getRightWallOpticValue(opening)), A.compact);

	return A.concatW(leftMeasurements)(rightMeasurements);
};

/**
 * Actualizes the horizontal Measurement objects from a list of object paths and an Opening
 * @param opening Opening
 * @returns Measurement[]
 */
export const lockedHorizontalMeasurements = (opening: Opening) => (objectPaths: string[]) => {
	const topMeasurements = pipe(objectPaths, A.map(getCeilingOpticValue(opening)), A.compact);
	const bottomMeasurements = pipe(objectPaths, A.map(getFloorOpticValue(opening)), A.compact);

	return A.concatW(topMeasurements)(bottomMeasurements);
};

/**
 * Returns the unlocked measurement value for the vertical component
 * loop in an Opening
 * @param opening Opening
 * @returns number
 */
export const getUnlockedMeasurementValue = (opening: Opening) => {
	// Locked vertical component paths
	const lockedVerticalPaths = lockedWallPaths(opening);
	// Vertical measurements
	const verticalComponents = lockedVerticalMeasurements(opening)(lockedVerticalPaths);
	// Locked horizontal component paths
	const lockedHorizontalPaths = lockedHorizontalComponentPaths(opening);
	// Horizontal measurements
	const horizontalComponents = lockedHorizontalMeasurements(opening)(lockedHorizontalPaths);
	return calculateUnlockedMeasurementValue(...verticalComponents, ...horizontalComponents);
};
export const textValue = (ev: React.ChangeEvent<HTMLInputElement>): string => ev.target.value;
export const textValueOnBlur = (ev: React.FocusEvent<HTMLInputElement>): string => ev.target.value;

const optionIsEmptyString = (str: O.Option<string>) => pipe(O.some(S.isEmpty), O.ap(str), O.toUndefined);

export const isModifiedString: (templateValue: O.Option<string>) => (modValue: O.Option<string>) => boolean = (templateValue: O.Option<string>) => (modValue: O.Option<string>) => {
	if (O.isNone(templateValue) && O.isNone(modValue)) {
		return false;
	}
	if (O.isSome(templateValue) && O.isNone(modValue)) {
		return false;
	}
	if (O.isNone(templateValue) && O.isSome(modValue)) {
		return true;
	}
	if (optionIsEmptyString(templateValue) && optionIsEmptyString(modValue)) {
		return false;
	}
	if (O.isSome(templateValue) && optionIsEmptyString(modValue)) {
		return false;
	}
	if (optionIsEmptyString(templateValue) && O.isSome(modValue)) {
		return false;
	}
	if (checkStringEquality(templateValue, modValue)) {
		return false;
	}
	return true;
};

const equalsZero = (y: O.Option<number>) => checkNumberEquality(O.some(0), y);

export const isModifiedNumber: (templateValue: O.Option<number>) => (modValue: O.Option<number>) => boolean = (templateValue: O.Option<number>) => (modValue: O.Option<number>) => {
	if (O.isSome(templateValue) && O.isNone(modValue)) {
		return false;
	}
	if (equalsZero(modValue)) {
		return false;
	}
	if (checkNumberEquality(templateValue, modValue)) {
		return false;
	}
	return true;
};

export const showModifiedMessage = (modified: boolean) => modified ? 'Modified' : undefined;

/**
 * Sorts NonEmpty<number> from smallest to largest
 * returning an `Ord`
 */
export const sortNonEmptyNumberAsc = NEA.sort(N.Ord);

const slice = <T>(i: number, xs: T[], ys: T[]) =>
	xs.slice(0, i).concat(ys).concat(xs.slice(i, xs.length));

/**
 * Returns an Option of the curb direction
 * at the start of the stretch
 *
 * @param curbs Stretch of curbs
 * @returns Option<string>
 */
const oStartingCurbDirection = <S extends keyof Directed>(curbs: Curb<S>[] | null) => pipe(
	curbs,
	O.fromNullable,
	O.map(A.map(curbDirection().get)),
	O.chain(A.head)
);

/**
 * Returns an Option of the curb direction
 * at the end of the stretch
 *
 * @param curbs Stretch of curbs
 * @returns Option<string>
 */
const oEndingCurbDirection = <S extends keyof Directed>(curbs: Curb<S>[] | null) => pipe(
	curbs,
	O.fromNullable,
	O.map(A.map(curbDirection().get)),
	O.chain(A.last)
);

export const checkStringEquality = O.getEq(S.Eq).equals;

export const checkNumberEquality = O.getEq(N.Eq).equals;

const divideCurbMeasurementByTwo: <S extends keyof Directed>() => Mod<Curb<S>> = <S extends keyof Directed>() => curbMeasure<S>().compose(major<S>()).compose(majorMeasure).modify((a) => a / 2);
const duplicateCurb: <S extends keyof Directed>(idx: number) => Mod<Curb<S>[]> = <S extends keyof Directed>(idx: number) => (x: Curb<S>[]) => pipe(
	x,
	(a) => indexCurb<S>(idx).getOption(a),
	O.map(curbAnomalies<S>().set([])),
	O.chain((a) => A.insertAt(idx + 1, a)(x)),
	O.fold(() => x, (a) => a)
);

const splitCurb: <S extends keyof Directed>(idx: number) => Mod<Curb<S>[]> = <S extends keyof Directed>(idx: number) => flow(duplicateCurb<S>(idx), indexCurb<S>(idx).modify(divideCurbMeasurementByTwo<S>()), indexCurb<S>(idx + 1).modify(divideCurbMeasurementByTwo<S>()));

export const addCurbs =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(strechOptic: Optional<Opening, Curb<any>[]>) =>
		(curbsPath: number) =>
			(position: keyof Directed) =>
				(directions: Directed[keyof Directed][]) => (placement: 'before' | 'after' | 'split' = 'before') =>
					(value: { type?: 'Major' | 'Minor' } = { type: 'Major' }) =>
						(amount: number) => (opening: Opening) => {
							const major = value.type === 'Major' ? Math.abs(amount) : 200;
							const minor = value.type === 'Minor' ? amount : 0;

							const curbs = directions.map(direction => axial(position)(direction)(major, minor));

							const newCurbsPath = pipe(curbsPath, O.fromPredicate(() => placement === 'after' || placement === 'split'), O.fold(() => curbsPath, (c) => c + 1));

							const stretchCurbs = O.toNullable(strechOptic.getOption(opening));

							if (stretchCurbs) {
								switch (position) {
								case 'Bottom': {
									if (placement === 'split') {
										return strechOptic.modify(splitCurb<'Bottom'>(curbsPath))(opening);
									}
									const newCurbs = slice(newCurbsPath, stretchCurbs, curbs);

									const startIsUp = checkStringEquality(oStartingCurbDirection(newCurbs), some('up'));
									const endIsDown = checkStringEquality(oEndingCurbDirection(newCurbs), some('down'));

									return strechOptic.set(startIsUp || endIsDown ? stretchCurbs : newCurbs)(opening);
								}
								case 'Top': {
									if (placement === 'split') {
										return strechOptic.modify(splitCurb<'Top'>(curbsPath))(opening);
									}
									const newCurbs = slice(newCurbsPath, stretchCurbs, curbs);

									const startIsDown = checkStringEquality(oStartingCurbDirection(newCurbs), some('down'));
									const endIsUp = checkStringEquality(oEndingCurbDirection(newCurbs), some('up'));

									return strechOptic.set(startIsDown || endIsUp ? stretchCurbs : slice(newCurbsPath, stretchCurbs, curbs))(opening);
								}
								default: {

									return strechOptic.set(slice(newCurbsPath, stretchCurbs, curbs))(opening);
								}
								}
							}
							// No op
							return opening;
						};

/**
 * Checks if curb array has a length greater than 1
 */
const greaterThanOne = <S extends keyof Directed>(curbs: Curb<S>[]) => curbs.length > 1;

/**
 * Checks if curb's direction is left or right
 */
const isLeftOrRight = <S extends keyof Directed>(c: Curb<S>) => curbDirection().get(c) === 'left' || curbDirection().get(c) === 'right';

/**
 * Checks if a ceiling curb has two left curbs or if a floor curb has two right curbs
 */
const leavesOneInStretchDirection = (curbIndex: number) => <S extends keyof Directed>(curbs: Curb<S>[]) => pipe(
	curbs,
	A.deleteAt(curbIndex),
	O.chain(A.findFirst(isLeftOrRight)),
	O.isSome
);

/**
 * Checks that removing a curb does not put a curb going up next to a curb going down
 */
const adjacentNotInOppositeDirection = (curbIndex: number) => <S extends keyof Directed>(curbs: Curb<S>[]) => {
	const curb = A.lookup(curbIndex)(curbs);

	if (!O.toNullable(curb)) {
		return false;
	}

	if (curbIndex === 0 || curbIndex === curbs.length - 1) {
		return true;
	}

	const curbBeforeDirection = pipe(curbs, A.lookup(curbIndex - 1), O.toNullable);

	if (!curbBeforeDirection) {
		return false;
	}

	const curbAfterDirection = pipe(curbs, A.lookup(curbIndex + 1), O.toNullable);

	if (!curbAfterDirection) {
		return false;
	}

	const adjacentDirections = [curbDirection().get(curbAfterDirection), curbDirection().get(curbBeforeDirection)];

	return !(adjacentDirections.includes('up') && adjacentDirections.includes('down'));
};

/**
 * Higher order function for validating if delete curb operation creates stretch with valid starting curb
 */
const checkStartingCurb = (horizontalDirection: 'left' | 'right') => (verticalDirection: 'up' | 'down') => (curbIndex: number) => <S extends keyof Directed>(curbs: Curb<S>[]) => pipe(
	A.deleteAt(curbIndex)(curbs),
	O.chain(O.fromPredicate(A.some((d: Curb<S>) => checkStringEquality(some(curbDirection().get(d)), some(horizontalDirection))))),
	O.match(
		() => true,
		(potentialNewStretch) => !checkStringEquality(oStartingCurbDirection(potentialNewStretch), some(verticalDirection))
	)
);

/**
 * Higher order function for validating if delete curb operation creates stretch with valid ending curb
 */
const checkEndingCurb = (horizontalDirection: 'left' | 'right') => (verticalDirection: 'up' | 'down') => (curbIndex: number) => <S extends keyof Directed>(curbs: Curb<S>[]) => pipe(
	A.deleteAt(curbIndex)(curbs),
	O.chain(O.fromPredicate(A.some((d: Curb<S>) => checkStringEquality(some(curbDirection().get(d)), some(horizontalDirection))))),
	O.match(
		() => true,
		(potentialNewStretch) => !checkStringEquality(oEndingCurbDirection(potentialNewStretch), some(verticalDirection))
	)
);

/**
 * Checks that removing a curb in the ceiling does not put a down curb at the start of the stretch.
 */
const noDownwardAtStartOfCeiling = checkStartingCurb('left')('down');

/**
 * Checks that removing a curb in the floor does not put an up curb at the start of the stretch.
 */
const noUpwardAtStartOfFloor = checkStartingCurb('right')('up');

/**
 * Checks that removing a curb in the ceiling does not put an up curb at the end of the stretch.
 */
const noUpwardAtEndOfCeiling = checkEndingCurb('left')('up');

/**
 * Checks that removing a curb in the floor does not put a down curb at the end of the stretch.
 */
const noDownwardAtEndOfFloor = checkEndingCurb('right')('down');

/**
 * Higher order function for removing curbs based on index
 * and whether or not they follow stretch constraints.
 *
 * @param curbs Stretch of curbs
 * @returns Function
 */
export const removeCurb = (curbIndex: number) => <S extends keyof Directed>(curbs: Curb<S>[]) => {
	return pipe(
		curbs,
		O.fromNullable,
		O.chain(O.fromPredicate(greaterThanOne)),
		O.chain(O.fromPredicate(leavesOneInStretchDirection(curbIndex))),
		O.chain(O.fromPredicate(adjacentNotInOppositeDirection(curbIndex))),
		O.chain(O.fromPredicate(noDownwardAtStartOfCeiling(curbIndex))),
		O.chain(O.fromPredicate(noUpwardAtStartOfFloor(curbIndex))),
		O.chain(O.fromPredicate(noUpwardAtEndOfCeiling(curbIndex))),
		O.chain(O.fromPredicate(noDownwardAtEndOfFloor(curbIndex))),
		O.chain(A.deleteAt(curbIndex)),
		O.getOrElse(constant(curbs))
	);
};

/**
 * Higher order function for returning the length of
 * the ceiling stretch in a section given the SectionPath
 *
 * @param opening Opening
 * @returns function
 */
export const lengthOfCeilingCurbs = (opening: Opening) => (sectionPath: SectionStep[]) => pipe(
	opening,
	ceilingCurbsOptic(sectionPath).getOption,
	O.match(
		() => 0,
		(curbs) => curbs.length
	)
);

/**
 * Higher order function for returning the length of
 * the floor stretch in a section given the SectionPath
 *
 * @param opening Opening
 * @returns function
 */
export const lengthOfFloorCurbs = (opening: Opening) => (sectionPath: SectionStep[]) => pipe(
	opening,
	floorCurbsOptic(sectionPath).getOption,
	O.match(
		() => 0,
		(curbs) => curbs.length
	)
);

/**
 * Higher order function to check if index is
 * at beginning or end of array
 *
 * @param curbLength number
 * @returns function
 */
export const isStartOrEndCurb = (curbLength: number) => (index: number) => [0, curbLength - 1].includes(index);

/**
 * Reverses the curb's path (index) if the path indicates a ceiling curb
 *
 *@example
 *const path = ['Ceiling', 'Curbs', '0', 'Measure'];
 *
 *const curbsPath = pipe(
 *    path,
 *    parseCurbs,
 *    reverseCeilingCurb(path)(3)
 *);
 *
 *console.log(curbsPath)
 * // 2
 *
 * @param path Object path in Opening to curb
 * @returns number
 */
export const reverseCeilingCurb = (path: Path) => (stretchLength: number) => (curbPath: number) => {
	const isCeiling = path.includes('Ceiling');

	if (isCeiling) {
		return (stretchLength - 1) - curbPath;
	}
	return curbPath;
};

/**
 * TODO: Implement priority value function for resetting/clearing
 * priority value on measurement
 */
export const getClearPriority = () => 0;

/**
 * Get the closest section identifier given a path
 */
export const parsePathForSection: (a: string) => string = (a) => {
	if (a.includes('Left-Curbs-0-Measure')) {
		return 'Joint-0-box';
	}
	if (A.some((x: string) => a.includes(x))(['Angle', 'Ceiling', 'Floor', 'Corner', 'Curbs'])) {
		// Split to Right
		const s = a.split('-');
		// Find index of right
		const i = s.findIndex((v) => v === 'Right');
		// Slice on right
		if (i > -1) {
			const upToRight = s.slice(0, i + 1);
			return upToRight.join('-');
		} else {
			// No Right value
			const altI = s.findIndex((v) => v === 'Ceiling' || v === 'Floor');
			// Slice on value
			const upToIndicator = s.slice(0, altI);
			return upToIndicator.join('-') + '-Joint-0-box';
		}
	}
	return a;
};

export const nextSectionPath = (oneOrThree: OneOrUptoThree<Wall, SectionTree>) => {
	return pipe(
		'Right' in oneOrThree ? O.none : O.some(oneOrThree),
		O.foldW(() => [SectionStep.encode(0)], (tree) => pipe(tree,
			(a) => a.length,
			O.fromPredicate(SectionStep.is),
			O.foldW(() => null, (a) => {
				return [SectionStep.encode(a)];
			})
		))
	);};

export const refineSectionPath = (step: SectionStep[]) => (isJoint: boolean) => isJoint ? step.slice(0, -1) : step;

export const parseCurbs: (path: Path) => number = (path: Path): number => {
	const i = path.indexOf('Curbs');

	const next = path[i + 1];
	if (next === undefined) return -1;

	const nextI = parseInt(next);
	if (Number.isInteger(nextI)) return nextI;

	return -1;
};

export const sequenceTOption = sequenceT(O.Applicative);

