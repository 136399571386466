import { array, Type, interface as type } from 'io-ts';

import { dot } from '~/src/base/Optic';

import { P } from '~/src/geometry/point';
import { Curve } from './Curve';
import { Ruler } from './Ruler';
import { Label } from './Label';
import { Meta } from './Meta';
import { Mod } from '~/src/base/Function';
import { V3 } from '~/src/geometry/vector';

export interface Piece {
	Curves: Curve[];
	Rulers: Ruler[];
	Labels: Label[];
	Meta: Meta;
}

export const Piece: Type<Piece> = type({
	Curves: array(Curve),
	Rulers: array(Ruler),
	Labels: array(Label),
	Meta: Meta,
});

export const _Rulers: (f: Mod<Ruler[]>) => Mod<Piece> = dot('Rulers');
export const _Curves: (f: Mod<Curve[]>) => Mod<Piece> = dot('Curves');

/**
 * An empty Piece.
 *
 * Contains nothing, with default axes.
 */
export const empty: Piece = ({
	Curves: [],
	Rulers: [],
	Labels: [],
	Meta: { Entries: [] },
});

export const points = (piece: Piece) : P<V3>[] =>
	piece.Curves
		.map((curve: Curve) => curve.Segments)
		.flat(2)
;
