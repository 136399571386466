/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import clsx from 'clsx';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import {
	AutoSizer,
	Column,
	Table,
	TableCellRenderer,
	TableHeaderProps,
} from 'react-virtualized';

interface Row {
	index: number;
}

export interface ColumnData {
	dataKey: string;
	label: string;
	numeric?: boolean;
	width?: number;
	editable: boolean;
	multiline?: true;
	widthPercentage?: number;
	flexGrow?: number;
	minWidth?: number;
	maxWidth?: number;
	displayValue?: (x: any) => string;
}

export interface MuiVirtualizedTableProps {
	classes: MuiVirtualizedTableClasses,
	columns: readonly ColumnData[];
	headerHeight: number;
	height?: number;
	onRowClick?: () => void;
	rowCount: number;
	rowGetter: (row: Row) => unknown;
	rowHeight: number;
	width?: number;
	handleEditCell?: (e: any, rowId: string, dataKey: string) => void
	rows: any;
	tableClassName?: string; // require to get width/specific style
}

interface MuiVirtualizedTableState {
	rows:any;
}

export interface MuiVirtualizedTableClasses {
	flexContainer: string,
	tableRow: string,
	tableRowHover: string,
	tableCell: string,
	noClick: string,
	header?: string
}

export default class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps,MuiVirtualizedTableState> {
	constructor(props: MuiVirtualizedTableProps) {
		super(props);
		this.state = {rows : this.props.rows };
	}

	getRowClassName = ({ index }: Row) => {
		const { classes, onRowClick } = this.props;
		return clsx(classes.tableRow, classes.flexContainer, {
			[classes.tableRowHover]: index !== -1 && onRowClick != null,
		});
	};

	cellRenderer: TableCellRenderer = ({ cellData, columnData, dataKey, rowData }) => {
		const { rowHeight, onRowClick, classes, handleEditCell } = this.props;
		const dataVal = cellData === 'false' ? false : cellData === 'true' ? true :
			(columnData.displayValue ? columnData.displayValue (cellData) : cellData );
		return (
			<TableCell
				padding='none'
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, {
					[classes.noClick]: onRowClick == null,
				})}
				variant="body"
				style={{ height: rowHeight }}
				align='left'
				sx={{
					overflowY: 'auto',
					pl: 2
				}}
			>
				{(typeof dataVal == 'boolean') ?
					(<Checkbox
						checked={dataVal}
						disabled={!columnData.editable}
						onChange={(e) => handleEditCell ? handleEditCell(e.target.checked, rowData.Id, dataKey) : true}
					/>
					) :
					(columnData.editable ?
						(<TextField
							size="small"
							fullWidth={true}
							variant="standard"
							multiline={columnData.multiline ?? false}
							required={columnData.required ?? true}
							InputProps={{ style: { fontSize: 'inherit' } }}
							value={cellData}
							onChange={(e) => handleEditCell ? handleEditCell(e.target.value as string, rowData.Id, dataKey) : true}
						/>) : (dataVal ) )
				}
			</TableCell>
		);
	};

	headerRenderer = ({label}: TableHeaderProps & { columnIndex: number }) => {
		const { classes } = this.props;
		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, classes.noClick, classes.header)}
				variant="head"
				align='center'
			>
				<span>{label}</span>
			</TableCell>
		);
	};

	render() {
		const { columns, rowHeight, headerHeight, classes, tableClassName, ...tableProps } = this.props;
		const table = document.querySelector(`.${tableClassName}`) as HTMLElement;
		const tableWidth = table != null ? table.offsetWidth : window.innerWidth;

		return (
			<AutoSizer>
				{({ height, width }) => (
					<Table
						height={height}
						width={width}
						rowHeight={rowHeight!}
						gridStyle={{
							direction: 'inherit',
						}}
						headerHeight={headerHeight!}
						{...tableProps}
						rowClassName={this.getRowClassName}
					>
						{columns.map(({ dataKey, editable, multiline, widthPercentage, displayValue, ...other }, index) => {
							const width = widthPercentage ? tableWidth * widthPercentage * 0.01 : 500;
							return (
								<Column
									key={dataKey}
									headerRenderer={(headerProps) =>
										this.headerRenderer({
											...headerProps,
											columnIndex: index,
										})
									}
									className={classes.flexContainer}
									cellRenderer={this.cellRenderer}
									dataKey={dataKey}
									width={width}
									columnData={{editable, multiline, displayValue}}
									{...other}
								/>
							);
						})}
					</Table>
				)}
			</AutoSizer>
		);
	}
}
