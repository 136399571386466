import { Template, Option, Variable, LabelValueObj } from '~/src/design/template';
import { Requestor } from '~/src/api/Requestor';

import { TemplateViewer } from '~/src/design/template/view';
import { isMet } from '~/src/design/template/dependency';
import { Update } from '~/src/base/Function';

// Styles

import * as css from './edit.module.css';
import VarOptLayout from '~/src/design/template/groups/edit';

export interface TemplateParams {
	req: Requestor;
	limits?: string;
	template: Template;
	update: Update<Template>;
}
export interface TemplateState { }

const isSelected = (opts: Option[]) => (optLabel: string) => (entLabel: string): boolean => {
	const opt = opts.find((opt) => opt.Label === optLabel);
	if (opt === undefined) return false;
	return opt.Value === entLabel;
};

export const isSelectedOption = (tmpl: Template) => (opt: Option): boolean => opt.Range.length > 1 && isMet(isSelected(tmpl.Opts))(opt.Depends);

export const isSelectedVariable = (tmpl: Template) => (v: Variable): boolean => isMet(isSelected(tmpl.Opts))(v.Depends);

export const getTemplateSelections = (tmpl: Template): LabelValueObj => {
	const sOpts = tmpl.Opts.filter(opt => isSelectedOption(tmpl)(opt)).map(o => [o.Label, o.Value]);
	const sVars = tmpl.Vars.filter(opt => isSelectedVariable(tmpl)(opt)).map(o => [o.Label, o.Value]);
	return Object.assign({}, Object.fromEntries(sOpts), Object.fromEntries(sVars));
};


export const TemplateEditor = (props: TemplateParams) => {
	const { req, template, update } = props;

	return (
		<div className={`scroll ${css.wrap}`}>
			<div className={ css.editor }>
				<div className={ css.viewer }>
					<TemplateViewer req={ req } template={ template } />
				</div>

				<div className={ css.options }>
					<VarOptLayout value={ template } update={ update } />
				</div>
			</div>
		</div>
	);
};
