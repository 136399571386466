import { array, failure, Is, success, Type } from 'io-ts';
import { Fn } from '../Function';
import * as Either from 'fp-ts/Either';
import { hasProp } from '../Type';
import { pipe } from 'fp-ts/lib/function';

/**
 * A triplet of elements.
 */
export type Three<T> = [T,T,T];

/**
 * Check whether a value is Three of something.
 */
export const is = <T>(isT: Is<T>) => (x: unknown): x is Three<T> =>
	typeof x === 'object' && x !== null &&
	hasProp(x, 'length') && x.length === 2 &&
	hasProp(x, '0') && isT(x[0]) &&
	hasProp(x, '1') && isT(x[1]) &&
	hasProp(x, '2') && isT(x[2]);

/**
 * Reverse a container.
 */
export const reverse = <T>(xs: Three<T>): Three<T> => xs;

/**
 * Clone a container, but not the items within it.
 */
export const clone = <T>(xs: Three<T>): Three<T> => [ xs[0], xs[1], xs[2] ];

/**
 * Apply a function to every element of the container.
 */
export const map = <A,B>(f: Fn<A,B>) => (o: Three<A>): Three<B> => [ f(o[0]), f(o[1]), f(o[2]) ];

/**
 * Zip two containers together with a function.
 */
export const zip = <A,B,C>(f: (x: A, y: B) => C) => (xs: Three<A>, ys: Three<B>): Three<C> =>
	[ f(xs[0],ys[0]), f(xs[1],ys[1]), f(xs[2],ys[2]) ];

/**
 * Generate a container of As from a single example.
 */
export const pure = <A>(x: A): Three<A> => [x, x, x];

/**
 * Make a triplet of elements.
 */
export const mk = <A>(x: A) => (y: A) => (z: A): Three<A> => [x, y, z];

export default mk;

/**
 * Encode or decode Three of something.
 */
export const Three = <T,S>(codecT: Type<T,S>) => new Type<Three<T>,[S,S,S]>(
	`Three<${codecT.name}>`,
	is(codecT.is),
	(input, context) => pipe(
		array(codecT).validate(input, context),
		Either.chain(xs => refineArray(xs) ? success(xs) : failure(xs, context, `Has ${xs.length} elements.`)),
	),
	(x: Three<T>) => [ codecT.encode(x[0]), codecT.encode(x[1]), codecT.encode(x[2]) ],
);

/**
 * Refine a list into a Three, if it has exactly three elements.
 */
export const refineArray = <T>(x: T[]): x is [T,T,T] => x.length === 3;
