import QButton from '~/src/ui/input/QButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

type AlertDialogProps = {
	open: boolean;
	onClose?: () => void;
	onOk?: (save: boolean) => void;
}

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		background: '#2D3748',
		borderRadius: '6px',
		border: '4px solid #48BB78',
	}
}));

const StyledDialogTitle = styled(DialogTitle)(() => ({
	color: 'white',
	fontSize: '24px',
	fontWeight: '500',
	paddingRight: '64px',
}));

const StyledDialogActions = styled(DialogActions)(() => ({
	padding: '16px 24px',
}));

export const AlertDialog = ({ open, onClose, onOk }: AlertDialogProps) => {
	const handleRedirect = (save: boolean) => {
		if (onOk) {
			onOk(save);
		}
	};

	return (
		<StyledDialog
			open={open}
			className="alert-dialog"
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<Button
				size="small"
				sx={{
					minWidth: '24px',
					width: '24px',
					height: '24px',
					position: 'absolute',
					top: '10px',
					right: '10px',
					padding: 0,
					color: '#fff',
				}}
				onClick={onClose}
			>
				<CloseIcon
					sx={{
						width: '24px',
						height: '24px',
					}}
				/>
			</Button>
			<StyledDialogTitle id="alert-dialog-title">
				Returning to DAPP, would you like to save?
			</StyledDialogTitle>
			<StyledDialogActions>
				<QButton variant="text" autoFocus onClick={() => handleRedirect(true)}>
					Save
				</QButton>
				<QButton variant="text" onClick={() => handleRedirect(false)}>
					Don't save
				</QButton>
			</StyledDialogActions>
		</StyledDialog>
	);
};
