import { constant, pipe } from 'fp-ts/lib/function';
import { Optional } from 'monocle-ts';
import { Opening, Curb } from '~/src/design/opening';
import { Directed } from '~/src/design/opening/measurement';
import { curbEdge, curbMeasure, curbOffset, curbOuterHeight, shiftOffset } from '~/src/design/opening/optics';
import * as O from 'fp-ts/lib/Option';
import { MeasurementSummary, MeasurementEdit } from '~/src/design/opening/measurement/edit';
import QDrawerError from '~/src/ui/drawer/QDrawerError';
import QAccordion, { QAccordionTitleValueSummary } from '~/src/ui/QAccordion';

import { modifyUpdater } from '~/src/edit/util';
import { FormProps } from '~/src/edit/types';
import { majorValue, measureMajor } from '~/src/design/opening/measurement/optics';
import ShiftEdit from '~/src/design/opening/shift/edit';
import NumberEdit from '~/src/edit/Number';

const CurbForm = <S extends keyof Directed>(
	optic: Optional<Opening,Curb<S>>,
	focus: 'Measurement' | 'Bredth' | 'OuterHeight' | 'Offset',
) => ({
		value,
		lengthConverter,
		angleConverter,
		update,
	}: FormProps<Opening>) =>
		O.fold(
			constant(<QDrawerError>Curb Not Found</QDrawerError>),
			(curb: Curb<S>) => {
				const measurement = curbMeasure<S>().get(curb);
				const updateCurb = modifyUpdater(optic, update);
				const updateMeasurement = modifyUpdater(curbMeasure<S>(), updateCurb);

				const bredth = curbEdge<S>().get(curb);
				const updateBredth = modifyUpdater(curbEdge<S>(), updateCurb);

				const outerHeight = curbOuterHeight<S>().get(curb);
				const updateOuterHeight = modifyUpdater(curbOuterHeight<S>(), updateCurb);

				const offset = curbOffset<S>().get(curb);
				const updateOffset = modifyUpdater(curbOffset<S>(), updateCurb);

				return <>
					<QAccordion
						initialExpanded={focus === 'Measurement'}
						panelName='measurement'
						summary={<MeasurementSummary
							value={measurement}
						/>}
						details={<MeasurementEdit
							update={updateMeasurement}
							value={measurement}
						/>}
					/>
					<QAccordion
						initialExpanded={focus === 'Bredth'}
						panelName='bredth'
						summary={<QAccordionTitleValueSummary
							title='Bredth'
							value={pipe(
								value,
								(m) => pipe(optic.composeLens(curbEdge()).composeLens(measureMajor<'In'>()).composeLens(majorValue).getOption(m), O.getOrElse(constant(0))),
								lengthConverter.build,
							)} />}
						details={<MeasurementEdit
							update={updateBredth}
							value={bredth}
						/>}
					/>
					<QAccordion
						initialExpanded={focus === 'OuterHeight'}
						panelName='OuterHeight'
						summary={<QAccordionTitleValueSummary
							title='Outer Height'
							value={pipe(
								value,
								(m) => pipe(optic.composeLens(curbOuterHeight<S>()).getOption(m), O.getOrElse(constant(0))),
								lengthConverter.build,
							)} />}
						details={<NumberEdit
							tlabel={{eng: 'Outer Height'}}
							unit='mm'
							update={updateOuterHeight}
							value={outerHeight}
						/>}
					/>
					<QAccordion
						initialExpanded={focus === 'Offset'}
						panelName='Offset'
						summary={<QAccordionTitleValueSummary
							title='Offset'
							value={pipe(
								value,
								(m) => pipe(optic.composeLens(curbOffset<S>()).composeLens(shiftOffset).getOption(m), O.getOrElse(constant(0))),
								lengthConverter.build,
							)} />}
						details={<ShiftEdit
							lengthConverter={lengthConverter}
							angleConverter={angleConverter}
							update={updateOffset}
							value={offset}
						/>}
					/>
				</>;
			})(optic.getOption(value));

export default CurbForm;
