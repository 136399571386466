import { indexArray } from 'monocle-ts/Index/Array';
import { optionSelection } from '~/src/design/template/optics';

import { Option } from '~/src/design/template';
import { Fn } from '~/src/base/Function';

import * as React from 'react';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';

import SelectEnum from '~/src/edit/SelectEnum';

import { EditProps } from '~/src/edit/types';

// Styles

import './edit.css';
import { comp } from '~/src/base/Function';

export interface OptionParams extends EditProps<Option> {}

export const OptionEditor = (ps: OptionParams): React.ReactElement =>
	<Grid item className="option-editor">
		<FormControl sx={{ my: 1, width: '100%' }}>
			<SelectEnum
				id={`option-${ps.value.Label}-select`}
				value={ps.value.Value}
				label={ps.value.Names}
				size="small"
				items={ps.value.Range.map(e => ({key: e.Label, icon: e.Image, names: e.Names}))}
				update={comp(ps.update, optionSelection)} />
		</FormControl>
	</Grid>;

export interface OptionsParams extends EditProps<Option[]>{
	filterSelected: Fn<Option,boolean> ;
}

export const OptionsEditor = (ps: OptionsParams): React.ReactElement =>
	<> {
		ps.value.map((opt, i) => !ps.filterSelected(opt) ? null :
			<OptionEditor key={opt.Label} value={opt} update={(x) => ps.update(indexArray<Option>().index(i).modify(x))} />
		)
	} </>;
