import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { MenuButton, Menu } from '~/src/design/bulk/edit/menu';
import {
	BulkDesignContext,
	BULK_DESIGN_VIEW_MODE,
	TOGGLE_VIEW_MODE,
} from '~/src/design/bulk/store';
import { AlertDialog } from '~/src/design/bulk/edit/alert-dialog';

import './header.css';

const HomeIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
	>
		<g>
			<path
				d="M20.6641 9.94076L18.3685 7.59322V3.29646C18.3685 2.97166 18.1051 2.7083 17.7803 2.7083H15.4103C15.0855 2.7083 14.8221 2.97162 14.8221 3.29646V4.55283L11.6907 1.76432C11.2969 1.40537 10.7029 1.40541 10.3092 1.76428L1.33585 9.9408C1.02038 10.2283 0.916127 10.6713 1.07027 11.0693C1.22445 11.4673 1.59984 11.7244 2.02667 11.7244H3.45984V19.9167C3.45984 20.2415 3.72316 20.5048 4.048 20.5048H8.36042C8.68523 20.5048 8.94858 20.2415 8.94858 19.9167V14.1345H13.2533V19.9167C13.2533 20.2415 13.5167 20.5049 13.8415 20.5049H17.9517C18.2765 20.5049 18.5399 20.2415 18.5399 19.9167V11.7245H19.9733C20.4001 11.7245 20.7756 11.4673 20.9298 11.0693C21.0839 10.6713 20.9796 10.2283 20.6641 9.94076Z"
				stroke="#A0AEC0"
				stroke-width="1.6"
				stroke-miterlimit="2"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
);

const UserIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<g clip-path="url(#clip0_2631_2682)">
			<path
				d="M11.9997 0C8.0296 0 4.79968 3.20853 4.79968 7.15232C4.79968 11.0961 8.0296 14.3046 11.9997 14.3046C15.9698 14.3046 19.1997 11.0961 19.1997 7.15232C19.1997 3.20853 15.9698 0 11.9997 0ZM11.9997 12.7152C8.91184 12.7152 6.39968 10.2197 6.39968 7.15232C6.39968 4.08493 8.91184 1.5894 11.9997 1.5894C15.0875 1.5894 17.5997 4.08493 17.5997 7.15232C17.5997 10.2197 15.0875 12.7152 11.9997 12.7152Z"
				fill="#A0AEC0"
			/>
			<path
				d="M20.9582 15.8514C18.987 13.8632 16.3738 12.7682 13.6 12.7682H10.4C7.62624 12.7682 5.01301 13.8632 3.04181 15.8514C1.08027 17.8299 0 20.4415 0 23.2053C0 23.6442 0.358187 24 0.8 24H23.2C23.6418 24 24 23.6442 24 23.2053C24 20.4415 22.9197 17.8299 20.9582 15.8514ZM1.6352 22.4106C2.036 17.9028 5.81392 14.3577 10.4 14.3577H13.6C18.1861 14.3577 21.964 17.9028 22.3648 22.4106H1.6352Z"
				fill="#A0AEC0"
			/>
		</g>
	</svg>
);

const ListIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
	>
		<path
			d="M13.4438 1.6665H7.36543H3.33337V18.3332H17.5V1.6665H13.4438ZM12.4751 2.60879V4.34024H8.33409V2.60879H12.4751ZM7.38964 4.67004C7.4744 5.02339 7.80132 5.2943 8.18879 5.2943H12.6204C13.0079 5.2943 13.3348 5.02339 13.4196 4.67004H14.3882V15.3885H6.50574V4.67004H7.38964V4.67004ZM16.5314 17.4027H4.30204V2.60879H7.36543V3.73953H5.56129V16.3073H15.3448V3.73953H13.4438V2.60879H16.5314V17.4027Z"
			fill="#F7FAFC"
		/>
		<path
			d="M8.3098 8.59244C8.3098 8.59244 8.32191 8.59244 8.3098 8.59244C8.53986 8.59244 8.75781 8.49821 8.91521 8.34509L10.4772 6.75498L9.64171 5.96582L8.32191 7.30858L7.91023 6.87277L7.05054 7.63838L7.70438 8.33331C7.86179 8.49821 8.07974 8.59244 8.3098 8.59244Z"
			fill="#F7FAFC"
		/>
		<path
			d="M8.3098 11.6195C8.3098 11.6195 8.32191 11.6195 8.3098 11.6195C8.56407 11.6195 8.80624 11.5253 8.98786 11.3486L10.4893 9.8292L9.65382 9.04004L8.32191 10.3828L7.91022 9.94699L7.05054 10.7126L7.64384 11.3369C7.81336 11.5135 8.05552 11.6195 8.3098 11.6195Z"
			fill="#F7FAFC"
		/>{' '}
		<path
			d="M8.3098 14.7296C8.3098 14.7296 8.32191 14.7296 8.3098 14.7296C8.53986 14.7296 8.75781 14.6354 8.91521 14.4823L10.4772 12.8922L9.64171 12.103L8.32191 13.434L7.91023 12.9982L7.05054 13.7638L7.70438 14.4587C7.86179 14.6354 8.07974 14.7296 8.3098 14.7296Z"
			fill="#F7FAFC"
		/>
		<path
			d="M13.6739 6.95508H11.4823V7.89737H13.6739V6.95508Z"
			fill="#F7FAFC"
		/>{' '}
		<path
			d="M13.6739 9.80566H11.4823V10.7479H13.6739V9.80566Z"
			fill="#F7FAFC"
		/>
		<path
			d="M13.6739 12.7383H11.4823V13.6806H13.6739V12.7383Z"
			fill="#F7FAFC"
		/>
	</svg>
);

type BulkDesignHeaderProps = {
	goBack: (save: boolean) => void;
}

export const BulkDesignHeader = ({ goBack }: BulkDesignHeaderProps) => {
	const { mainViewMode, dispatch } = useContext(BulkDesignContext);
	const [open, setOpen] = useState(false);

	const handleSwitchView = (mode: BULK_DESIGN_VIEW_MODE) => {
		dispatch({
			type: TOGGLE_VIEW_MODE,
			data: mode,
		});
	};

	const handleRedirect = (save: boolean) => {
		goBack(save);
		setOpen(false);
	};

	return (
		<Box className="bulk-header">
			<Box>
				<MenuButton icon={<HomeIcon />} padding="sm" onClick={() => setOpen(true)} />
				<MenuButton
					title="List view"
					icon={<ListIcon />}
					padding="md"
					isSelectable
					isSelected={mainViewMode === BULK_DESIGN_VIEW_MODE.LIST_VIEW}
					onClick={() => handleSwitchView(BULK_DESIGN_VIEW_MODE.LIST_VIEW)}
				/>
			</Box>
			<Box sx={{ display: 'flex' }}>
				<MenuButton
					title="Design"
					isBorder={true}
					isSelectable
					isSelected={mainViewMode === BULK_DESIGN_VIEW_MODE.DESIGN}
					onClick={() => handleSwitchView(BULK_DESIGN_VIEW_MODE.DESIGN)}
				/>
				<Menu
					title="Production Documents"
					isBorder={true}
					isSelectable
					isSelected={mainViewMode === BULK_DESIGN_VIEW_MODE.PRODUCTION_DOCUMENTS}
					options={[
						{
							title: 'Cut Sheets',
							value: 'cut-sheets',
						},
						{
							title: 'Finishes',
							value: 'finishes',
						},
						{
							title: 'Outages',
							value: 'outages',
						},
					]}
					onClick={() =>
						handleSwitchView(BULK_DESIGN_VIEW_MODE.PRODUCTION_DOCUMENTS)
					}
				/>
				<MenuButton
					title="Additional Field"
					isBorder={true}
					isSelectable
					isSelected={mainViewMode === BULK_DESIGN_VIEW_MODE.ADDITIONAL_FIELD}
					onClick={() =>
						handleSwitchView(BULK_DESIGN_VIEW_MODE.ADDITIONAL_FIELD)
					}
				/>
				<MenuButton
					title="More space here"
					isBorder={true}
					isSelectable
					isSelected={mainViewMode === BULK_DESIGN_VIEW_MODE.MORE_SPACE}
					onClick={() => handleSwitchView(BULK_DESIGN_VIEW_MODE.MORE_SPACE)}
				/>
			</Box>
			<Box>
				<MenuButton icon={<UserIcon />} padding="sm" />
			</Box>
			<AlertDialog open={open} onOk={handleRedirect} onClose={() => setOpen(false)} />
		</Box>
	);
};
