/**
 * Measurements along curbs, walls, and ceilings of openings.
 *
 * @since 0.1.0
 */

import { failure, identity, interface as iface, intersection, literal, number, keyof, success, Type, TypeOf, union, partial, type } from 'io-ts';
import { Distance } from '~/src/model/Numbers';

/**
 * A `Measurement` in a particular set of Directions.
 *
 * @since 0.1.0
 * @category Measurement
 */
export type Measurement<S extends keyof Directed> = MeasureDirection<S> & Measure;
/**
 * Codec for `Measurement`.
 *
 * @since 0.1.0
 * @category Measurement
 */
export const Measurement = <S extends keyof Directed>(s: S): Type<Measurement<S>> =>
	intersection([
		Measure,
		MeasureDirection(s),
	]);

/**
 * The the part of a `Measurement` that specifies the `Direction`.
 *
 * @since 0.1.0
 * @category Direction
 */
export interface MeasureDirection<S extends keyof Directed> {
	Direction: Directed[S];
}
/**
 * Codec for `MeasureDirection`.
 *
 * @since 0.1.0
 * @category Direction
 */
export const MeasureDirection = <S extends keyof Directed>(s: S): Type<MeasureDirection<S>> =>
	iface({
		Direction: Directed(s),
	});

/**
 * Directions
 *
 * We constrain `Measurement`s to be going counter-clockwise around the `Opening`.
 *
 * Things on a particular side or going in a particular direction, may only be
 * measured in particular direcions.
 *
 * @since 0.1.0
 * @category Direction
 */
export interface Directed {
	Bottom: 'up' | 'down' | 'right';
	Left: 'down';
	Right: 'up';
	Top: 'up' | 'down' | 'left';
	In: 'in';
	Out: 'out';
}
/**
 * Codec for `Directed`.
 *
 * @since 0.1.0
 * @category Direction
 */
export const Directed = <S extends keyof Directed>(s: S) => new Type<Directed[S]>(
	'Directed ' + s,
	(input: unknown): input is Directed[S] => isDirected(s)(input),
	(input, context) => isDirected(s)(input) ? success(input) : failure(input, context),
	identity,
);
const isDirected = <S extends keyof Directed>(s: S) => (x: unknown): x is Directed[S] =>
	typeof x === 'string' && mapDirected[s].hasOwnProperty(x);

const mapDirected: {[k in keyof Directed]: {[d in Directed[k]]: true}} = {
	Bottom: {
		'up': true,
		'down': true,
		'right': true,
	},
	Left: {
		'down': true,
	},
	Right: {
		'up': true,
	},
	Top: {
		'up': true,
		'down': true,
		'left': true,
	},
	In: {
		'in': true,
	},
	Out: {
		'out': true,
	},
};


/**
 * The distance in the overall direction of the measurement.
 *
 * @category Measure
 */
export type Major = TypeOf<typeof Major>;
/**
 * Codec for `Measure`.
 *
 * @category Measure
 */
export const Major = iface({
	Major: Distance,
});

/**
 * The magnitude of the measurement vector.
 *
 * @category Measure
 */
export type Mag = TypeOf<typeof Mag>;
/**
 * Codec for `Measure`.
 *
 * @category Measure
 */
export const Mag = iface({
	Mag: number,
});

/**
 * Measurement Major Dimension Type.
 *
 * 'Major' indicates the length of the major axis.
 * 'Magnitude' indicates the diagonal length.
 *
 * @category Measure
 */
export type MajorMeasure = Major | Mag;
/**
 * Codec for `MajorMeasure`.
 *
 * @category Measure
 */
export const MajorMeasure = union([Major, Mag]);

/**
 * The offset perpendicular to the overall direction of measurement.
 *
 * @category Measure
 */
export type Minor = TypeOf<typeof Minor>;
/**
 * Codec for `Measure`.
 *
 * @category Measure
 */
export const Minor = iface({
	Minor: number,
});

/**
 * The angle between the overall and actual directions of measurement.
 *
 * @category Measure
 */
export type Angle = TypeOf<typeof Angle>;
/**
 * Codec for `Measure`.
 *
 * @category Measure
 */
export const Angle = iface({
	Angle: number,
});

/**
 * Measurement Minor Dimension Type.
 *
 * 'Minor' indicates the length of the minor axis.
 * 'Tangent' indicates the ratio of the major axis to the minor axis.
 *
 * @category Measure
 */
export type MinorMeasure = Minor | Angle;
/**
 * Codec for `MinorMeasure`.
 *
 * @category Measure
 */
export const MinorMeasure = union([Minor, Angle]);

/**
 * A circular arc defined by the central offset from the straight line.
 *
 * @category Measure
 */
export type Circular = TypeOf<typeof Circular>;
/**
 * Codec for `Measure`.
 *
 * @category Measure
 */
export const Circular = iface({
	type: literal('Circular'),
	Deflection: number,
});

/**
 * A sine wave scaled by the central offset from a straight line.
 *
 * @category Measure
 */
export type Sinusoidal = TypeOf<typeof Sinusoidal>;
/**
 * Codec for `Measure`.
 *
 * @category Measure
 */
export const Sinusoidal = iface({
	type: literal('Sinusoidal'),
	Deflection: number,
});

/**
 * A circular arc defined by the central offset from the straight line.
 *
 * @category Measure
 */
export type Curvature = Circular | Sinusoidal;
/**
 * Codec for `Curvature`.
 *
 * @category Measure
 */
export const Curvature = union([Circular, Sinusoidal]);

/**
 * A measurement without direction.
 *
 * @category Measure
 */
export type Measure = TypeOf<typeof Measure>;
/**
 * Codec for `Measure`.
 *
 * @category Measure
 */
export const Measure = intersection([
	type({
		Major: MajorMeasure,
	}),
	partial({
		MajorPri: number,
		Minor: MinorMeasure,
		MinorPri: number,
		Curvature: Curvature,
	})
]);

/**
 * Measurement ShiftLocation.
 *
 * The part of a curb, panel, etc. that a measurement is relative to.
 *
 * @since 0.4.0
 * @category ShiftLocation
 */
export type ShiftLocation = 'inside' | 'center' | 'outside';

/**
 * Codec for `ShiftLocation`.
 *
 * @since 0.4.0
 * @category ShiftLocation
 */
export const ShiftLocation: Type<ShiftLocation> = keyof({
	inside: true,
	center: true,
	outside: true,
});
