import { P } from '~/src/geometry/point';
import { V2 } from '~/src/geometry/vector';

export type PanZoom = {
  /** The point at the upper-left of the display. */
  pan: P<V2>,
  /** The scaling factor applied to the object. */
  zoom: number,
};

export const def: PanZoom = {
	pan: [0,0],
	zoom: 1,
};
