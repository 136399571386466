import { comp } from '~/src/base/Function';
import { Optic, dot, pdot } from '~/src/base/Optic';
import { Type, string, record, type } from 'io-ts';

import { NonEmpty } from '~/src/base/Array/NonEmpty';

import { Ruler } from '~/src/figure/Ruler';
import { Piece } from '~/src/figure/Piece';
import { Figure } from '~/src/figure';
import { RulerMod, applyRulerMod } from './mod/ruler';

export interface FigureMod {
	Rulers : Record<string, RulerMod>;
}

export const FigureMod: Type<FigureMod> = type({
	Rulers : record(string, RulerMod),
});

export const _Rulers = () : Optic<FigureMod, Record<string, RulerMod>> =>
	dot('Rulers')
;

export const _RulerById = (rid : string) : Optic<FigureMod, RulerMod | undefined> =>
	comp(_Rulers() , pdot(rid))
;

export const defFigureMod = () : FigureMod =>
	({ Rulers : {} })
;

export const applyFigureMod = (mod : FigureMod) => (val : Figure) : Figure => ({
	Kind: val.Kind,
	Pieces : val.Pieces.map(({Rulers, ...rest}: Piece) => ({
		...rest,
		Rulers : Rulers.map((ruler : Ruler) => {
			const rmod = mod.Rulers[ruler.Id.join('-')];
			return !rmod ? ruler : applyRulerMod(rmod)(ruler);
		}),
	})) as unknown as NonEmpty<Piece>,
});
