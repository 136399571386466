import { indexArray } from 'monocle-ts/Index/Array';
import { variableValue } from '~/src/design/template/optics';

import { Variable } from '~/src/design/template';
import { Update, Fn } from '~/src/base/Function';
import { flow, constant } from 'fp-ts/function';
import { EditProps } from '~/src/edit/types';
import NumberEdit from '~/src/edit/Number';

import * as React from 'react';

import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';

// Styles

import './edit.css';
import { comp } from '~/src/base/Function';

export interface RangeParams {
	value: Variable;
	update: Update<number>;
}

export interface VariableParams extends EditProps<Variable> {}

const sliderValue = (_ev: Event, newValue: number | number[]): number =>
	typeof newValue === 'number' ? newValue : Math.min(...newValue);

export const RangeInput = (ps: RangeParams): React.ReactElement => {
	const range = ps.value.Range;
	switch (range.type) {
	case 'Set':
		return <Slider
			value={ps.value.Value}
			step={null}
			marks={range.Set.map((x) => ({value: x}))}
			onChange={flow(sliderValue, constant, ps.update)} />;
	case 'Range':
		return <Slider
			value={ps.value.Value}
			valueLabelFormat={(val: number) => val.toString()}
			valueLabelDisplay="auto"
			marks={[range.Min, range.Max].map((x) => ({value: x, label: x.toString()}))}
			min={range.Min}
			max={range.Max}
			step={range.Step}
			onChange={flow(sliderValue, constant, ps.update)} />;
	}
};

export const VariableEditor = (ps: VariableParams): React.ReactElement =>
	<Grid item className="variable-editor">
		<NumberEdit
			unit='inch'
			value={ps.value.Value}
			update={comp(ps.update, variableValue)}
			tlabel={ps.value.Names}
			min={ps.value.Range.type === 'Range' ? ps.value.Range.Min : undefined}
			max={ps.value.Range.type === 'Range' ? ps.value.Range.Max : undefined}
		/>
	</Grid>;


export interface VariablesParams {
	variables: Variable[];
	filterSelected: Fn<Variable,boolean> ;
	update: Update<Variable[]>;
}

export const VariablesEditor = (ps: VariablesParams): React.ReactElement =>
	<> {
		ps.variables.map((variable, i) => !ps.filterSelected(variable) ? null :
			<VariableEditor key={variable.Label} value={variable} update={(x) => ps.update(indexArray<Variable>().index(i).modify(x))} />
		)
	} </>;
