import { interface as iface,keyof, string, Type, number, boolean, record, union } from 'io-ts'; // , undefined as undef

export const configEntryModes : ConfigEntryMode[] = [ 'shower','mirror' , 'panel' , 'misc' ];
export const modeAbbr: {[key in ConfigEntryMode]: string } = { 'shower' : 'S','mirror': 'M' , 'panel': 'P' , 'misc': 'MS'};

export const organizations: OrgName[] = [ 'Retail', 'DGM Website', 'Davenport', 'DullesCN'];
export const orgNameAbbr: {[key in OrgName]: string } = {'Retail' : 'R', 'DGM Website': 'DGM', 'Davenport': 'DVP', 'DullesCN': 'CN'};


export const OrganizationMapUserId = {
	'Retail' : '575364e710033c899f296ce7da8615bd',
	'DGM Website': '505419ae4311cb0a58eac51f87971f45',
	'Davenport': '0c465e583b398b05f52d9d0019dd756e',
	'DullesCN': 'c7c5367b8af883bfe8b6d6aca8e81349'
};

export type OrgName = 'Retail' | 'DGM Website' | 'Davenport' | 'DullesCN';
export type ConfigEntryMode = 'shower' | 'mirror' | 'panel' | 'misc';

export const ConfigEntryMode: Type<ConfigEntryMode> = keyof({
	shower: true,
	mirror: true,
	panel: true,
	misc: true
});
export interface ConfigEntry {
	description: string,
	key: string,
	name: string,
	mode: ConfigEntryMode,
	value: string | number | boolean
}

export const ConfigEntry: Type<ConfigEntry> = iface({
	description: string,
	key: string,
	name: string,
	mode: ConfigEntryMode,
	value: union([string, number, boolean])
});

export interface SimpleOrg {
	OrgName: OrgName,
	UserId: string,
	SelectedVendor: string,
}
export interface Id {
	Id: string
}


export type VendorConfig = {[key in string]: ConfigEntry } // TODO: [key in string]?
export const VendorConfig: Type<VendorConfig> = record(string, ConfigEntry ); // union([ConfigEntry, undef])

export interface OrgSelectedConfig extends ConfigEntry, SimpleOrg, Id {}

export interface OrganizationConfig {
	ApiName: string,
	ClassName: string,
	Id: string,
	Name: string,
	SelectedVendor: string,
	UserId: string,
	Vendors: {
			[vendor: string] : VendorConfig
	}
}

export const OrganizationConfig: Type<OrganizationConfig> = iface({
	ApiName: string,
	ClassName: string,
	Id: string,
	Name: string,
	SelectedVendor: string,
	UserId: string,
	Vendors: record(string, VendorConfig)
});


export const getOrgSelectedConfig = (o: OrganizationConfig): OrgSelectedConfig[] => {
	const selectedVendor = o.SelectedVendor as string;
	const selectedConfigEntries = Object.values(o.Vendors[selectedVendor] ?? {}).map(c => ({...c, mode: ('mode' in c ? c.mode : 'shower') as ConfigEntryMode}));
	return selectedConfigEntries.map(c => {
		const obj = {
			'Id' : `${o.UserId}_${c.key}`,
			'OrgName': o.Name,
			'UserId': o.UserId,
			'SelectedVendor': selectedVendor,
			...c
		} as OrgSelectedConfig;
		return obj;
	}) ;
};

export const getAllOrgSelectedConfigEntries = (allOrg: OrganizationConfig[]): OrgSelectedConfig[] => allOrg.map(o => getOrgSelectedConfig(o)).flat();
