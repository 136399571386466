import { Box } from '@mui/material';
import * as React from 'react';
import QInputLabel from './QInputLabel';

const ToggleButtonLayout = ({
	label,
	htmlFor,
	children,
}: React.PropsWithChildren<{ label: string, htmlFor: string }>) => (
	<Box my={1} display='flex' alignItems='center' flexWrap='wrap'>
		{label && (
			<QInputLabel htmlFor={htmlFor} sx={{
				paddingRight: '10px',
				py: '12px'
			}}>{label}</QInputLabel>
		)
		}
		<Box>
			{children}
		</Box>
	</Box>
);

export default ToggleButtonLayout;
