.toolbar {
  display: flex;

  gap: 10px;
  padding: 12px;
  justify-content: center;
}

.toolbar > * {
  margin: 0 !important;
}
