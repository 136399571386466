import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMemo } from 'react';
import { Option, Template, Variable } from '~/src/design/template';
import { Group } from '~/src/design/template/groups';
import { Update } from '~/src/base/Function';
import { OptionEditor } from '~/src/design/template/option/edit';
import { VariableEditor } from '~/src/design/template/variable/edit';
import { EditProps } from '~/src/edit/types';
import { isSelectedOption, isSelectedVariable } from '~/src/design/template/edit';
import { groupFold, templateGroups, templateOpts, templateVars } from '~/src/design/template/optics';
import { comp } from '~/src/base/Function';
import { index } from '~/src/base/Optic';
import { toggle } from '~/src/base/Boolean';
import { sus } from '~/src/base/Memoize';


type NewVarType = Variable & { Type: 'Vars'; ArrIndex: number; };
type NewOptType = Option & { Type: 'Opts'; ArrIndex: number; };

type TemplateLayoutGroupType = Group<NewVarType | NewOptType>;

interface VarOptGroupProps {
	data: TemplateLayoutGroupType;
	updateOpts: Update<Option[]>;
	updateVars: Update<Variable[]>;
	update: Update<Group>;
}

const VarOptGroup = (props: VarOptGroupProps) => {
	const { data, updateOpts, updateVars, update } = props;
	const { Items, Label, Names, Fold } = data;
	const isOption = (ps: NewOptType | NewVarType): ps is NewOptType => ps.Type === 'Opts';

	return (
		<Accordion expanded={!Fold} style={{ marginBottom: '12px' }}>
			<AccordionSummary
				aria-controls="panel1d-content"
				onClick={sus(comp(update, groupFold), toggle)}
				expandIcon={
					<ExpandMoreIcon
						style={{ cursor: 'pointer' }}
					/>
				} >
				<Typography>{Names.eng ?? Label}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container spacing={2} className="variable-editor">
					{
						Items.map((ps) => (
							<Grid item xs={6} key={ps.Label}>
								{isOption(ps) ? (
									<OptionEditor
										update={comp(updateOpts, index(ps.ArrIndex))}
										value={ps}
									/>
								) : (
									<VariableEditor
										update={comp(updateVars, index(ps.ArrIndex))}
										value={ps}
									/>
								)}
							</Grid>
						))
					}
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};

const VarOptLayout = (props: EditProps<Template>) => {
	const { update, value } = props;
	const { Opts, Vars, Groups } = value;

	const layoutData: TemplateLayoutGroupType[] = useMemo(() => {
		const newVars: NewVarType[] = Vars
			.map((variable, ArrIndex): NewVarType => ({ ...variable, Type: 'Vars', ArrIndex }))
			.filter((variable): variable is NewVarType => isSelectedVariable(value)(variable));

		const newOpts: NewOptType[] = Opts
			.map((opt, ArrIndex): NewOptType => ({ ...opt, Type: 'Opts', ArrIndex }))
			.filter((opt): opt is NewOptType => isSelectedOption(value)(opt));

		return Groups.map((group) => {
			const newItems = group.Items.map((item) => {
				if (item.type === 'Opt') {
					const opt = newOpts.find((opt) => opt.Label === item.Label);
					if (opt) return { ...opt, Type: 'Opts' };
				} else if (item.type === 'Var') {
					const varb = newVars.find((varb) => varb.Label === item.Label);
					if (varb) return { ...varb, Type: 'Vars' };
				}
				return null;
			}).filter((item): item is NewVarType | NewOptType => item !== null);
			return {
				...group,
				Items: newItems,
			};
		});
	}, [Groups, Opts, Vars, value]);

	return <Box>
		{layoutData.map((elm, i) =>
			<VarOptGroup key={elm.Label} data={elm} update={comp(update,templateGroups,index(i))} updateOpts={comp(update,templateOpts)} updateVars={comp(update,templateVars)} />
		)}
	</Box>;
};

export default VarOptLayout;
