import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const GhostButton = styled(Button)<ButtonProps>(({ theme }) => ({
	border: theme.palette.mode === 'dark' ? '1px solid #808080' : '1px solid #EDF2F7',
	background: 'rgba(0,0,0,0)',
	color: theme.palette.mode === 'dark' ? '#808080' : '#000',
	padding: '8px 31px'
}));

export default GhostButton;
