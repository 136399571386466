import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Box } from '@mui/material';
import './TemplateTreeView.css';
import { Quote } from '~/src/pricing/quote';
import { ChargeItem } from '~/src/pricing/quote/change';
import LeftBarHeader from './LeftBarHeader';
import { NamedLeaf, NamedTree } from '~/src/model/NamedTree';
import { Path } from '~/src/pages/bulk-design';
import { addItemToNamedTreeClone, checkArrayExceptLast, findSelectedPaths, itemNamedLeaf, itemsNamedTree, itemsOrItemNamedTree, itemsOrItemNamedTreeParent, replaceItemsInNamedTree, swapItemsInTreeData, updateFieldNameTree, removeItemFromNamedTree } from '~/src/optics/TreeView/TreeView';
import { addItemToQuote, chargeItemQuote, removeItemFromQuote, updateChargeField } from '~/src/optics/TreeView/Quote';
import { Tree } from './Tree';
import { BulkDesign } from '~/src/design/bulk';
import TemplateTreeViewFooter from './TemplateTreeViewFooter';

// Types and interfaces
export type NamedItem<A> = NamedTree<A> | NamedLeaf<A>;
export type HandleSelectFunction = (data: { path: Path; selectStatus: boolean }) => void;
export type TreeDataItem = NamedTree<unknown> | NamedLeaf<unknown>;
export type NavigatedItem = Path | undefined;
export type UpdatingFieldProps = (path: Path, field: 'Name' | 'Count', newValue: string | number) => void;
export type TreePath = React.Dispatch<Path | undefined>;
type SelectionModel<T> = selectionModelTree<T> | selectionModelLeaf<T>;
export type DragProps = (e: React.DragEvent, path: Path) => void;

interface TemplateTreeViewProps {
	treeData: NamedTree<unknown>;
	quoteData: Quote;
	updateSelectedPath: (path: Path | undefined, tData?: NamedTree<unknown> | undefined) => void;
	selectedPath: Path | undefined;
	addTemplate: () => void;
	setTreeData: (data: NamedTree<unknown>) => void;
	setQuoteData: (data: Quote) => void;
}

export interface selectionModelTree<A> {
	Name: string;
	Count: number;
	Items: (selectionModelTree<A> | selectionModelLeaf<A>)[];
	selected: boolean;
}

export interface selectionModelLeaf<A> {
	Item: A;
	selected: boolean;
	Name: string;
	Count: number;
}

export type TemplateTreeViewRefProps = {
	handleDuplication: (selectedPath: Path) => void;
}

const TemplateTreeView = forwardRef<TemplateTreeViewRefProps, TemplateTreeViewProps>((props, ref) => {
	const {
		treeData,
		setTreeData,
		updateSelectedPath,
		quoteData,
		addTemplate,
		selectedPath,
		setQuoteData
	} = props;

	const [navigatedItem, setNavigatedItem] = useState<NavigatedItem>(undefined);
	const [editMode, setEditMode] = useState(false);
	const [selectedItemsData, setSelectedItemsData] = useState<selectionModelTree<unknown>>(convertToNewModelTree(treeData));

	useEffect(() => {
		setSelectedItemsData(convertToNewModelTree(treeData));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const handleKeyPress = (e: { key: string }) => {
			switch (e.key) {
			case 'ArrowUp':
				handleArrowPress('up');
				break;
			case 'ArrowDown':
				handleArrowPress('down');
				break;
			case 'Enter':
				handleEnter();
				break;
			case 'Escape':
				handleEscape();
				break;
			case 'Delete':
				removeItem();
				break;
			default:
				break;
			}
		};

		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPath, editMode, selectedItemsData, navigatedItem]);

	const setFieldBasedOnPath = (path: Path, field: 'Name' | 'Count', newValue: string | number) => {
		const newTreeData = updateFieldNameTree(path, field, newValue)(treeData);
		console.log(newTreeData);
		setTreeData(newTreeData as NamedTree<unknown>);
		const newQuoteData = updateChargeField(path, field, newValue)(quoteData);
		setQuoteData(newQuoteData as Quote);
	};

	function convertToNewModelTree<A>(data: NamedTree<A>): selectionModelTree<A> {
		return {
			Name: data.Name,
			Count: data.Count,
			Items: data.Items.map(item => {
				if ('Items' in item) {
					return convertToNewModelTree(item);
				} else {
					return {
						Item: item.Item,
						selected: false,
						Name: item.Name,
						Count: item.Count
					} as selectionModelLeaf<A>;
				}
			}),
			selected: false,
		};
	}

	const toggleEditMode = () => {
		setEditMode(!editMode);
	};

	const handleSelect = (data: {path: Path, selectStatus: boolean}) => {
		const updatedData: selectionModelTree<unknown> = updateSelectedStatus(selectedItemsData, data.path, data.selectStatus);
		const updatedData2: SelectionModel<unknown> = updateSelectionStatusRecursively(updatedData, selectedItemsData, undefined);
		setSelectedItemsData(updatedData2 as selectionModelTree<unknown>);
	};


	function updateSelectionStatusRecursively<T>(node: SelectionModel<T>, selectedItemsData: SelectionModel<T> | undefined, selected: boolean | undefined): SelectionModel<T> {
		if (isLeaf(node)) {
			if ('Item' in node) {
				return { ...node, selected: selected !== undefined ? selected : node.selected };
			}
			return node;
		}

		const anyChildSelected = node.Items.some((child) => {
			return child.selected;
		});

		const allChildrenSelected = node.Items.every((child, index) => {
			if ('Item' in child) {
				return child.selected;
			} else if ('Items' in child && selectedItemsData && 'Items' in selectedItemsData) {
				return updateSelectionStatusRecursively(child, selectedItemsData.Items[index], undefined).selected;
			}
			return false;
		});

		if (selectedItemsData && 'Items' in selectedItemsData){
			if (allChildrenSelected) {
				const selectStatus: boolean = selectedItemsData.selected ? !node.selected ? false : true : node.selected ? true : true;
				return { ...node, selected: selectStatus, Items: node.Items.map((item, index) => updateSelectionStatusRecursively(item, selectedItemsData.Items[index], selectStatus)) };
			} else if (anyChildSelected) {
				const selectStatus = !selectedItemsData.selected ? node.selected ? true : false : node.selected ? false : false;
				return { ...node, selected: selectStatus, Items: node.Items.map((item, index) => updateSelectionStatusRecursively(item, selectedItemsData.Items[index], selectStatus ? selectStatus: undefined)) };
			} else {
				return { ...node, selected: node.selected, Items: node.Items.map((item, index) => updateSelectionStatusRecursively(item, selectedItemsData.Items[index], node.selected)) };
			}
		}else{
			return { ...node, selected: node.selected, Items: node.Items.map((item) => updateSelectionStatusRecursively(item, undefined, node.selected)) };
		}
	}

	function isLeaf<T>(node: SelectionModel<T>): node is selectionModelLeaf<T> {
		return 'Item' in node;
	}

	function updateSelectedStatus(obj: selectionModelTree<unknown>, path: number[], selectStatus: boolean): selectionModelTree<unknown> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const navigateObject = (obj: any, path: number[], selectStatus: boolean): any => {
			if (path.length === 0) {
				return { ...obj, selected: selectStatus }; // Toggle selected property when path is empty
			}

			const index = path[0];
			const restPath = path.slice(1);

			if ('Items' in obj && index !== undefined && index >= 0 && index < obj.Items.length) {
				const updatedItems = [...obj.Items];
				updatedItems[index] = navigateObject(obj.Items[index], restPath, selectStatus);
				return { ...obj, Items: updatedItems };
			}

			return obj;
		};

		if (!obj || path.length === 0) {
			return obj;
		}

		return navigateObject(obj, path, selectStatus);
	}

	const handleEnter = () => {
		const treeItemElements = document.querySelectorAll('[data-tree-path]');
		const treeItemArray = Array.from(treeItemElements);
		const navigatedIndex = treeItemArray.findIndex((element) => {
			return element.getAttribute('data-tree-is-navigated') === 'true';
		});
		const isAnyNavigated = navigatedIndex !== -1;
		if (isAnyNavigated){
			const navigatedPath = treeItemArray[navigatedIndex]?.getAttribute('data-tree-path');
			if(navigatedPath && typeof navigatedPath === 'string'){
				const path = JSON.parse(navigatedPath);
				const isAlreadySelected = treeItemArray[navigatedIndex]?.getAttribute('data-tree-is-selected');
				if (editMode){
					handleSelect({ path, selectStatus: !(isAlreadySelected ? isAlreadySelected === 'true' ? true: false : false) });
				}else{
					if (itemNamedLeaf(path).get(treeData)){
						updateSelectedPath(isAlreadySelected ? undefined: path);
					}
				}
			}
		}
	};

	const handleEscape = () => {
		setNavigatedItem(undefined);
		updateSelectedPath(undefined);
	};

	const getMatchingListElement = (treeItemElements: NodeListOf<Element>, navigatedSelectedIndex: number, resetNumber: number, type: 'up' | 'down'): HTMLElement | undefined => {
		const listItem = treeItemElements[navigatedSelectedIndex];
		const listItemNew = treeItemElements[navigatedSelectedIndex + resetNumber];

		if (listItem && listItemNew){
			const listItemPath = JSON.parse(listItem?.getAttribute('data-tree-path') || '');
			const listItemNewPath = JSON.parse(listItemNew?.getAttribute('data-tree-path') || '');
			if (listItemPath.length === listItemNewPath.length){
				return listItemNew as HTMLElement;
			}else{
				return getMatchingListElement(treeItemElements, navigatedSelectedIndex, type === 'down' ? resetNumber + 1: resetNumber - 1, type);
			}
		}else{
			return undefined;
		}
	};

	const removeItem = () => {
		if (editMode) {
			// remove the checked items (multiple).
			const allSelectedPaths: Path[] = findSelectedPaths({ ...selectedItemsData, selected: false });
			let tData = treeData;
			let qData = quoteData;
			if (allSelectedPaths.length) {
				for (const path of allSelectedPaths){
					const res = handleRemoveItem(path, tData, qData);
					tData = res.tree;
					qData = res.quote;
				}
			}
		} else if (selectedPath) {
			// remove the selected item.
			handleRemoveItem(selectedPath, treeData, quoteData);
		}
	};

	const handleRemoveItem = (path: Path, tData: NamedTree<unknown>, qData: Quote) => {
		if (!path) {
			return {
				tree: tData,
				quote: qData
			};
		}
		const newTreeData = removeItemFromNamedTree(path)(tData) as NamedTree<unknown>;
		setTreeData(newTreeData);
		const newQuoteData = removeItemFromQuote(path)(qData) as Quote;
		setQuoteData(newQuoteData);
		setSelectedItemsData(convertToNewModelTree(newTreeData));
		setNavigatedItem(undefined);
		updateSelectedPath(undefined);
		return {
			tree: newTreeData,
			quote: newQuoteData
		};
	};

	const handleArrowPress = (type: 'up' | 'down') => {
		const treeItemElements = document.querySelectorAll('[data-tree-path]');
		const treeItemArray = Array.from(treeItemElements);
		let resetNumber = 1;

		if (selectedPath && navigatedItem && selectedPath.toString() === navigatedItem.toString()){
			const navigatedSelectedIndex = treeItemArray.findIndex((element) => {
				return element.getAttribute('data-tree-path') === JSON.stringify(selectedPath);
			});
			if(navigatedSelectedIndex >= 0){
				if (type === 'up'){
					resetNumber = -1;
				}
				const listElement = getMatchingListElement(treeItemElements, navigatedSelectedIndex, resetNumber, type);
				if (listElement){
					const path: Path = JSON.parse(treeItemElements[navigatedSelectedIndex]?.getAttribute('data-tree-path') || '') || [];
					const pathToRelpace: Path = JSON.parse(listElement?.getAttribute('data-tree-path') || '') || [];
					const isReplacable = checkArrayExceptLast(path, pathToRelpace);
					if (isReplacable){
						const ItemsToMutate = itemsNamedTree(path.slice(0, -1)).get(treeData as BulkDesign);
						const newTreeDataItems: TreeDataItem[] | undefined = swapItemsInTreeData(path[path.length - 1] ?? 0, pathToRelpace[pathToRelpace.length - 1] ?? 0)(ItemsToMutate);
						if (newTreeDataItems){
							const newTreeData = replaceItemsInNamedTree(path.slice(0, -1), newTreeDataItems)(treeData);
							setTreeData(newTreeData as NamedTree<unknown>);
							updateSelectedPath(pathToRelpace);
							setNavigatedItem(pathToRelpace);
						}
					}
				}
			}
		}else{
			if(selectedPath){
				updateSelectedPath(undefined);
			}
			let resetValue = [0];
			if(type === 'up'){
				const lastValue = treeItemArray[treeItemArray.length - 1]?.getAttribute('data-tree-path');
				if (lastValue && lastValue.toString()){
					resetValue = JSON.parse(lastValue);
					resetNumber = -1;
				}
			}
			const navigatedIndex = treeItemArray.findIndex((element) => {
				return element.getAttribute('data-tree-is-navigated') === 'true';
			});
			const isAnyNavigated = navigatedIndex !== -1;
			if(isAnyNavigated){
				const nextItem = treeItemArray[navigatedIndex + resetNumber]?.getAttribute('data-tree-path');
				if (nextItem && typeof nextItem === 'string'){
					const newNextItem = JSON.parse(nextItem);
					setNavigatedItem(newNextItem);
				}else{
					setNavigatedItem(resetValue);
				}
			}else{
				if (itemsOrItemNamedTree(resetValue).get(treeData)) {
					setNavigatedItem(resetValue);
				}
			}
		}
	};

	const duplicateItem = () => {
		if(editMode){
			const allSelectedPaths: Path[] = findSelectedPaths(selectedItemsData);
			if(allSelectedPaths.length){
				for (const path of allSelectedPaths){
					handleDuplication(path);
				}
			}
		} else if (selectedPath){
			handleDuplication(selectedPath);
		}
	};

	const handleDuplication = (selectedPath: Path) => {
		let newItemToInsert = itemsOrItemNamedTreeParent(selectedPath).get(treeData);
		const newQuoteToInsert = chargeItemQuote(selectedPath).get(quoteData);
		if (newItemToInsert) {
			newItemToInsert = { ...newItemToInsert, Name: `${newItemToInsert.Name} (clone)` };
			const newTreeData = addItemToNamedTreeClone(selectedPath, newItemToInsert)(treeData) as NamedTree<unknown>;
			setTreeData(newTreeData);
			setQuoteData(addItemToQuote(selectedPath, newQuoteToInsert as ChargeItem)(quoteData) as Quote);
			setSelectedItemsData(convertToNewModelTree(newTreeData));
			const newItemPath = [...selectedPath.slice(0, selectedPath.length - 1), (selectedPath[selectedPath.length - 1] || 0) + 1];
			updateSelectedPath(newItemPath, newTreeData);
		}
	};

	const onDragOver = (e: React.DragEvent): void => {
		e.preventDefault();
	};

	const resetNavigatedItem = () => {
		setNavigatedItem(undefined);
	};

	useImperativeHandle(ref, () => ({
		handleDuplication: handleDuplication,
	}));


	return (
		<>
			{/* left-side bar */}
			<Box className="side-bar">
				<Box className='tree-view'>
					<Box className="side-bar-content">
						<LeftBarHeader
							duplicateItem={duplicateItem}
							treeData={treeData}
							addTemplate={addTemplate}
							toggleEditMode={toggleEditMode}
						/>
						<Box onDragOver={onDragOver} className='tree-view__container'>
							<Tree
								navigatedItem={navigatedItem}
								setFieldBasedOnPath={setFieldBasedOnPath}
								selectedPath={selectedPath}
								selectedItemsData={selectedItemsData.Items}
								onSelect={handleSelect}
								editMode={editMode}
								path={undefined}
								quoteData={quoteData.Charges}
								updateSelectedPath={(path) => {
									setNavigatedItem(undefined);
									updateSelectedPath(path);
								}}
								treeData={treeData.Items}
								onDragStart={() => {}}
								onDrop={() => {}}
								onDragOver={onDragOver}
								topParentData={treeData}
								setTreeData={setTreeData}
								resetNavigatedItem={resetNavigatedItem}
							/>
						</Box>
					</Box>
					<TemplateTreeViewFooter quote={quoteData} />
				</Box>
			</Box>
		</>
	);
});

export default TemplateTreeView;
