import * as React from 'react';

import './Kind.module.css';

import { Update, comp } from '~/src/base/Function';
import { EditProps } from '~/src/edit/types';
import { Kind , KindBool , KindPrice , KindDouble , KindTuple , ktFst , ktSnd } from '~/src/model/Pricing';

const KindEdit = ( { value , update } : EditProps<Kind> ) => {
	const opts = [ 'Bool' , 'Price' , 'Double' , 'Tuple' ];
	const selected = KindBool.is(value) ? 'Bool' : KindPrice.is(value) ? 'Price' : KindDouble.is(value) ? 'Double' : 'Tuple';

	const handleChange = ( evt : React.ChangeEvent<HTMLSelectElement> ) => {
		switch ( evt.target.value ) {
		case 'Bool' :
			update( () => ( { kind : 'Bool' } ) );
			break;
		case 'Price' :
			update( () => ( { kind : 'Price' } ) );
			break;
		case 'Double' :
			update( () => ( { kind : 'Double' } ) );
			break;
		default:
			update( () => ( { kind : 'Tuple' , fst : { kind : 'Price' } , snd : { kind : 'Price' } } ) );
		}
	};

	const tuple = !KindTuple.is(value) ? null :
		<ul>
			<li><KindEdit value={value.fst} update={ comp( update as Update<KindTuple> , ktFst ) } /></li>
			<li><KindEdit value={value.snd} update={ comp( update as Update<KindTuple> , ktSnd ) } /></li>
		</ul>;

	return (
		<ul className='kind-edit'>
			<li>
				<div>
					<select onChange={ handleChange } value={selected}>
						{ opts.map( lbl => <option key={lbl} value={lbl}>{lbl}</option> ) }
					</select>
				</div>

				{ tuple }
			</li>
		</ul>
	);
};

export default KindEdit;
