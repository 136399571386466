/**
 * Concrete Opening Types and Codecs.
 *
 * @since 0.1.0
 */
import { Type, TypeOf, unknown as cunknown } from 'io-ts';

import { Strategy } from '~/src/design/opening/division';
import { EdgeInfo } from '~/src/design/opening/panel';
import { Directed, Measure } from '~/src/design/opening/measurement';
import { Distance } from '~/src/model/Numbers';
import * as Poly from '~/src/design/opening/polymorphic';

export {
/**
 * Polymorphic versions of these.
 *
 * @since 0.1.0
 */
	Poly,
};

/**
 * The information on a joint.
 *
 * TODO will be filled in once we understand what's needed.
 *
 * @since 0.1.0
 * @category Paramaters
 */
export type JointInfo = TypeOf<typeof JointInfo>;
/**
 * Codec for `JointInfo`.
 *
 * @since 0.1.0
 * @category Paramaters
 */
export const JointInfo = cunknown;

/**
 * The information about each Section.
 *
 * How to divide a plane (two dimentional view of a section) into panels.
 *
 * @since 0.1.0
 * @category Paramaters
 */
export type SectionInfo = TypeOf<typeof SectionInfo>;
/**
 * Codec for `SectionInfo`.
 *
 * @since 0.1.0
 * @category Paramaters
 */
export const SectionInfo = Strategy;

/**
 * The information at the level of the opening.
 *
 * TODO will be filled in once we understand what's needed.
 *
 * @since 0.1.0
 * @category Paramaters
 */
export type OpeningInfo = TypeOf<typeof OpeningInfo>;
/**
 * Codec for `OpeningInfo`.
 *
 * @since 0.1.0
 * @category Paramaters
 */
export const OpeningInfo = cunknown;

/**
 * An `Opening` in a structure - a model of a bredth of the floor, walls, and
 * ceiling which may be filled with glass.
 *
 * @since 0.1.0
 * @category Opening
 */
export type Opening = TypeOf<typeof Opening>;
/**
 * Codec for `Opening`.
 *
 * @since 0.1.0
 * @category Opening
 */
export const Opening = Poly.Opening(EdgeInfo, JointInfo, SectionInfo, OpeningInfo);

/**
 * An `Section` in an `Opening` - a model of a straight length of floor and ceiling.
 *
 * @since 0.1.0
 * @category Section
 */
export type Section = Poly.Section<EdgeInfo, JointInfo, SectionInfo>;
/**
 * Codec for `Section`.
 *
 * @since 0.1.0
 * @category Section
 */
export const Section: Type<Section> = Poly.Section(EdgeInfo, JointInfo, SectionInfo);

/**
 * The tree of `Section`s on an `Opening`.
 *
 * @since 0.1.0
 * @category Section
 */
export type SectionTree = Poly.SectionTree<EdgeInfo, JointInfo, SectionInfo>;
/**
 * Codec for `SectionTree`.
 *
 * @since 0.1.0
 * @category Section
 */
export const SectionTree: Type<SectionTree> = Poly.SectionTree(EdgeInfo, JointInfo, SectionInfo);

/**
 * Walls at the right hand side / bottom of a `SectionTree`.
 *
 * @since 0.1.0
 * @category Section
 */
export type Wall = Poly.Wall<EdgeInfo, JointInfo>;
/**
 * Codec for `Wall`.
 *
 * @since 0.1.0
 * @category Section
 */
export const Wall: Type<Wall> = Poly.Wall(EdgeInfo, JointInfo);

/**
 * A straight `Stretch` of `Curb` along some side.
 *
 * @since 0.1.0
 * @category Section
 */
export type Stretch<S extends keyof Directed> = Poly.Stretch<S, EdgeInfo>;
/**
 * Codec for `Stretch`.
 *
 * @since 0.1.0
 * @category Section
 */
export const Stretch = <S extends keyof Directed>(s: S): Type<Stretch<S>> => Poly.Stretch(s, EdgeInfo);

/**
 * A individually specified piece of `Curb`.
 *
 * @since 0.1.0
 * @category Curb
 */
export type Curb<S extends keyof Directed> = Poly.Curb<S, EdgeInfo>;
/**
 * Codec for `Curb`.
 *
 * @since 0.1.0
 * @category Curb
 */
export const Curb = <S extends keyof Directed>(s: S): Type<Curb<S>> => Poly.Curb(s, EdgeInfo);

/**
 * Create a curb.
 *
 * @since 0.2.0
 * @category Curb
 */
export const curb = <S extends keyof Directed>(_position: S) =>
	(direction: Directed[S]) =>
		(undirectedMeasurement: Measure): Curb<S> => ({
			Offset: {
				Location: 'center',
				Offset: 0,
			},
			OuterHeight: null,
			Info: null,
			Bredth: null,
			Measure: {
				Direction: direction,
				...undirectedMeasurement
			}
		});

/**
 * Create a straight curb.
 *
 * @since 0.2.0
 * @category Curb
 */
export const straight = <S extends keyof Directed>(p: S) =>
	(d: Directed[S]) =>
		(distance: Distance, outage: number) =>
			curb(p)(d)({ Major: { Mag: distance }, Minor: { Minor: outage } });

/**
 * Create an axial curb.
 *
 * @since 0.2.0
 * @category Curb
 */
export const axial = <S extends keyof Directed>(p: S) =>
	(d: Directed[S]) =>
		(major: Distance, minor: number) =>
			curb(p)(d)({ Major: { Major: major }, Minor: { Minor: minor } });

/**
 * A `Joint` between `Section's
 *
 * @since 0.2.0
 * @category Section
 */
export type Joint = Poly.Joint<JointInfo>;
/**
 * Codec for `Joint`.
 *
 * @since 0.2.0
 * @category Section
 */
export const Joint: Type<Joint> = Poly.Joint(JointInfo);

/**
 * The measurement `Shift`.
 *
 * @since 0.2.0
 * @category CurbEdge
 */
export type Shift = Poly.Shift;
/**
 * Codec for `Shift`.
 *
 * @since 0.2.0
 * @category CurbEdge
 */
export const Shift: Type<Shift> = Poly.Shift;

/**
 * The measurement `Offset`.
 *
 * @since 0.2.0
 * @category CurbEdge
 */
export type Offset = Poly.Offset;
/**
 * Codec for `Offset`.
 *
 * @since 0.2.0
 * @category CurbEdge
 */
export const Offset: Type<Offset> = Poly.Offset;

/**
 * Protrusion or intrustion on curb surface.
 *
 * @category Curb
 */
export type Anomaly = Poly.Anomaly

/**
 * Codec for `Anomaly`.
 *
 * @category Curb
 */
export const Anomaly: Type<Anomaly> = Poly.Anomaly;

/**
 * A single step of an index of a `Section`.
 *
 * @since 0.1.0
 * @category Section
 */
export type SectionStep = Poly.SectionStep;

/**
 * Codec for SectionStep
 */
export const SectionStep: Type<SectionStep> = Poly.SectionStep;
