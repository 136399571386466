import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { default as MMenu } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';

import './menu.css';

type MenuButtonProps = ButtonProps & {
	title?: string;
	icon?: React.ReactNode;
	isSelectable?: Boolean;
	isSelected?: Boolean;
	isBorder?: Boolean;
	padding?: string;
};

type MenuOption = {
	title: string;
	value: string;
}

type MenuProps = MenuButtonProps & {
	options: MenuOption[]
};

export const MenuButton = ({ title = '', isSelectable = false, isSelected = false, isBorder = false, padding = 'lg', icon, ...props }: MenuButtonProps) => {
	return (
		<Button
			className={classNames(
				'bdh-menu',
				'bdh-menu--primary',
				{
					['bdh-menu--border']: isBorder,
					['bdh-menu--selected']: isSelectable && isSelected,
				},
				`bdh-menu--${padding}`
			)}
			{...props}
		>
			{icon}
			{title}
		</Button>
	);
};

export const Menu = ({ options, onClick, ...props }: MenuProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [selectedMenus, setSelectedMenus] = React.useState<string[]>([]);

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleMenuClick = (opt: MenuOption) => {
		setSelectedMenus((prev) => [...prev.filter((p) => p !== opt.value), ...(prev.includes(opt.value) ? [] : [opt.value])]);
	};

	return (
		<div>
			<MenuButton
				id="basic-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				{...props}
			/>
			<MMenu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
					style: {
						padding: 0
					}
				}}
				{...(anchorEl ? {
					PaperProps: {
						style: {
							width: `${anchorEl.clientWidth}px`,
						},
					}
				} : {})}
				transitionDuration={0}
			>
				{options.map((opt: MenuOption) => (
					<MenuItem
						key={opt.value}
						className={classNames(
							'bdh-menu-item',
							'bdh-menu-item--primary',
							{
								['bdh-menu-item--selected']: selectedMenus.includes(opt.value),
							},
						)}
						onClick={() => handleMenuClick(opt)}
					>
						{opt.title}
					</MenuItem>
				))}
			</MMenu>
		</div>
	);
};
