import { Box, Chip, FormHelperText, IconButton, ListItem } from '@mui/material';
import * as React from 'react';
import useToggle from '~/src/hooks/useToggle';
import { Entry } from '~/src/design/template/option';
import * as O from 'fp-ts/lib/Option';
import { constant, pipe } from 'fp-ts/lib/function';
import { modOptionNamesChange, entryTranslation, optionalModEntryRank } from '~/src/admin/template/mod/inner-optics';
import { TemplateMod } from '~/src/admin/template/mod/inner';
import { Cancel } from '@mui/icons-material';
import { Update } from '~/src/base/Function';
import { ClientTemplateMod } from '~/src/admin/template/mod';
import { transTo } from '~/src/optics/Language';
import EntryNameTextField from '~/src/admin/template/mod/space/edit-name';
import EntryRankTextField from '~/src/admin/template/mod/space/edit-rank';
import { isModifiedNumber, isModifiedString, showModifiedMessage } from '~/src/edit/util';
import { entryTranslations } from '~/src/design/template/option/optics';

interface SpaceEntryProps {
		entry: Entry<string>
	spaceName: string
	templateMod: TemplateMod
	update: Update<ClientTemplateMod>
}

const SpaceEntry: React.FC<SpaceEntryProps> = ({
	entry,
	spaceName,
	templateMod,
	update,
}) => {
	const { value: editMode, toggle: toggleEditMode } = useToggle();

	const possibleModEntryName = modOptionNamesChange
		.compose(entryTranslation(spaceName)(entry.Label))
		.composeLens(transTo('eng'))
		.getOption(templateMod);

	/**
	 * Represents the `templateMod` entry name if defined, if not uses a default name
	 */
	const relevantEntryName = pipe(
		possibleModEntryName,
		O.getOrElse(constant(entry.Names.eng || 'No English Translation')),
	);

	const possibleModEntryRank = optionalModEntryRank(spaceName)(entry.Label).getOption(templateMod);

	/**
	 * Represents the `templateMod` entry rank if defined, if not uses a default rank
	 */
	const relevantEntryRank = pipe(
		possibleModEntryRank,
		O.getOrElse(constant(entry.Rank || 0))
	);

	return (
		<ListItem sx={{ p: 0.5, width: 'auto', flexDirection: 'column' }}>
			{!editMode ?
				<>
					<Chip
						label={`(${relevantEntryRank || 'none'}) ${relevantEntryName}`}
						onClick={toggleEditMode}
						variant='outlined'
					/>
					{<FormHelperText>{showModifiedMessage(
						isModifiedString(O.some(entryTranslations.compose(transTo('eng')).get(entry)))(possibleModEntryName) || isModifiedNumber(O.fromNullable(entry.Rank))(possibleModEntryRank)
					)}</FormHelperText>}

				</>
				:
				<Box display='flex' alignItems='center'>
					<Box display={'flex'} flexDirection='column'>
						<EntryNameTextField
							entryLabel={entry.Label}
							spaceName={spaceName}
							update={update}
							templateMod={templateMod}
							value={relevantEntryName}
							label={entry.Names.eng}
						/>
						<EntryRankTextField
							value={relevantEntryRank}
							label={`${relevantEntryName} Rank: ${entry.Rank || 'none'}`}
							entryLabel={entry.Label}
							spaceName={spaceName}
							update={update}
							templateMod={templateMod}
						/>
					</Box>
					<IconButton
						aria-label="cancel edit"
						onClick={toggleEditMode}
					>
						<Cancel />
					</IconButton>
				</Box>
			}
		</ListItem>
	);
};

export default SpaceEntry;
