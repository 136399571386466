import React, { memo } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import { textValue, textValueOnBlur } from '~/src/edit/util';
import { constant, flow } from 'fp-ts/lib/function';
import { EditProps } from './types';

type TextParams = TextFieldProps & EditProps<string>

export const TextEdit = ({ value, update, ...props}: TextParams): React.ReactElement => {
	// State and setters for debounced value
	const [inputValue, setInputValue] = React.useState<string>(value);

	const handleOnUpdate = flow(textValue, setInputValue);

	return (
		<TextField
			type='text'
			InputLabelProps={{ shrink: true }}
			onBlur={flow(textValueOnBlur, constant, update)}
			onChange={handleOnUpdate}
			value={inputValue}
			{...props}
		/>
	);
};

export const MemoTextEdit = memo(TextEdit);

export default TextEdit;
