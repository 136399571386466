import { styled } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
	marginTop: theme.spacing(1),
	marginBottom: theme.spacing(1),
}));

/**
 *  TextField from MUI library but with default props and styling
 *  common to the TemplateMod page
 */
const ModTextField = (props: TextFieldProps) => (
	<StyledTextField
		fullWidth
		InputLabelProps={{ shrink: true }}
		size='small'
		{...props}
	/>
);

export default ModTextField;
