/**
 * Deep Utilities.
 *
 * @since 0.2.0
 */

import { h64 } from 'xxhashjs';
import {isArray, isDate, isObject } from '~/src/base/Refine';

/**
 * Perform a deep conservative equality check.
 */
export const equals = (a: unknown, b: unknown): boolean => {
	if (a === null || b === null)
		return a === b;

	if (typeof a !== 'object' || typeof b !== 'object')
		return a === b;

	// shortcut pointer equality
	if (a === b) return true;

	if (isObject(a) && isObject(b)) {
		const av: [string, unknown][] = Object.entries(a);
		const bv: [string, unknown][] = Object.entries(b);
		if (av.length === bv.length) {
			av.sort(([x], [y]) => x < y ? -1 : x === y ? 0 : 1);
			bv.sort(([x], [y]) => x < y ? -1 : x === y ? 0 : 1);
			return equals(av, bv);
		}
	}

	if (isArray(a) && isArray(b) && a.length === b.length)
		return a.every((x, i) => equals(x, b[i]));

	if (isDate(a) && isDate(b)) {
		return a.getTime() === b.getTime();
	}

	return false;
};

/**
 * Perform a deep copy of an object.
 *
 * Special handling for Arrays and Dates, but nothing else. (Should be extended to Buffers, etc.)
 */
export const copy = <T>(a: T): T => {
	if (typeof a !== 'object') {
		return a;
	} else if (a === null) {
		return a;
	} else if (isArray(a)) {
		return a.map(copy) as unknown as T;
	} else if (isDate(a)) {
		return new Date(a.getTime()) as unknown as T;
	} else if (isObject(a)) {
		return Object.assign(Object.create(Object.getPrototypeOf(a)),
			...Object.entries(a).map(([k,v]) => ({[k]: copy(v)}))
		);
	} else {
		throw { error: 'deepCopy: Unknown Type', value: a };
	}
};

/**
 * Return the hash of an object.
 *
 * This particular function is definitely not collision resistant, e.g.
 * `undefined` and `'___undefined___'` hash to the same value, but it should be
 * good enough for our purposes for now.
 */
export const hash = (key: number) => (a: unknown): string => {
	const h = h64(key);

	const hs = (x: unknown): void => {
		if (x === undefined) {
			h.update('undefined');
		} else if (x === null) {
			h.update('null');
		} else if (typeof x === 'number' || typeof x === 'boolean' || typeof x === 'function') {
			h.update(typeof x);
			h.update(x.toString());
		} else if (typeof x === 'string') {
			h.update('string');
			h.update(x);
		} else if (isArray(x)) {
			h.update(`array ${x.length.toString()}`);
			x.forEach(hs);
		} else if (isDate(x)) {
			h.update('date');
			h.update(x.getTime().toString());
		} else if (isObject(x)) {
			const entries = Object.entries(x).sort(([x],[y]) => x < y ? -1 : x === y ? 0 : 1);
			h.update(`object ${entries}`);
			entries.forEach(([k,v]) => {
				h.update(k);
				hs(v);
			});
		} else {
			throw { error: 'deepHash: Unknown Type', value: x };
		}
	};
	hs(a);
	return h.digest().toString(16);
};
