/**
 * View Drawing.
 *
 * Front-end related drawings, checks for updated props and appends XML to the current react reference.
 *
 * @since 0.2.0
 */
import * as React from 'react';
import { Drawing as DrawingProps } from '~/src/model/Drawing';
import { equals as deepEquals } from '~/src/util/Deep';

export { DrawingProps };

export class Drawing extends React.Component<DrawingProps, unknown> {
	ref: React.RefObject<HTMLDivElement>;

	constructor(ps: DrawingProps) {
		super(ps);
		this.ref = React.createRef();
	}

	shouldComponentUpdate = (newProps: DrawingProps, _newState: unknown) => !deepEquals(this.props, newProps);

	componentDidMount = () => this.componentDidUpdate();

	componentDidUpdate() {
		const div = this.ref.current;
		if (div === null) return;

		if (this.props.Format === 'svg') {
			div.innerHTML = this.props.Data;
			const svg = div.children[0];
			if (svg === undefined) return;
			svg.removeAttribute('id');
			svg.removeAttribute('width');
			svg.removeAttribute('height');
		}
	}

	render() {
		switch (this.props.Format) {
		case 'svg':
			return <div ref={this.ref} className="drawing" />;
		case 'png':
			return <img className="drawing" src={`data:image/png;base64,${this.props.Data}`}/>;
		}
		return null;
	}
}
