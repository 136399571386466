import { string, Type, interface as iface, intersection, array, partial } from 'io-ts';
import { emptyPrice, Price, PriceMap, sum, prices, sub, add } from '~/src/pricing/price';
import { Charge } from '~/src/pricing/quote/change';

/**
 * Top-level Quotes.
 */
export interface Quote {
	/** Display name of this Quote. */
	Name: string,
	/** Regular charges on this Quote. */
	Charges: Charge[],
	/** Shipping Charge on this Quote. */
	Shipping?: Charge,
	/** Total extended cost of all Charges (excluding Shipping). */
	Extended: Price,
	/** Total of Discounts. */
	Discount: Price,
	/** Discounts collected from all Charges (excluding Shipping) grouped by name. */
	Discounts: PriceMap,
	/** Extended Price - Discounts. */
	SubTotal: Price,
	/** Total of Taxes. */
	Tax: Price,
	/** Taxes collected from all Charges (excluding Shipping) grouped by name. */
	Taxes: PriceMap,
	/** Subtotal + Taxes + Shipping Total */
	Total: Price,
}

/**
 * Recalculate the price fields in Quote
 */
export const normalizeQuote = (quote: Quote): Quote => {
	const Extended = sum(quote.Charges.map((charge: Charge) => charge.Extended));
	const Discount = sum(prices(quote.Discounts));
	const SubTotal = sub(Extended, Discount);
	const Tax = sum(prices(quote.Taxes));
	const Total = add(SubTotal, Tax);

	return Object.assign({}, quote, { Extended, Discount, Tax, SubTotal, Total });
};

export const Quote: Type<Quote> = intersection([iface({
	Name: string,
	Charges: array(Charge),
	Extended: Price,
	Discount: Price,
	Discounts: PriceMap,
	SubTotal: Price,
	Tax: Price,
	Taxes: PriceMap,
	Total: Price,
}), partial({
	Shipping: Charge,
})]);

/** An empty Quote. */
export const emptyQuote: Quote = {
	Name: '',
	Charges: [],
	Extended: emptyPrice,
	Discount: emptyPrice,
	Discounts: {},
	SubTotal: emptyPrice,
	Tax: emptyPrice,
	Taxes: {},
	Total: emptyPrice,
};
