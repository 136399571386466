import React, { memo, useCallback } from 'react';

import { constant, flow, pipe } from 'fp-ts/lib/function';
import { EditProps } from './types';
import * as TE from 'fp-ts/lib/TaskEither';
import * as O from 'fp-ts/lib/Option';
import { textValue, textValueOnBlur } from './util';
import { Box, FormControl, FormHelperText, TextField, TextFieldProps } from '@mui/material';
import { Unit, useConverterFor } from '~/src/user/units';
import { Translations } from '~/src/model/Language';
import { useLanguage } from '~/src/user/language';

type NumberParams = TextFieldProps & EditProps<number> & {
	tlabel?: Translations;
	unit?: Unit;
	min?: number;
	max?: number;
}

export const selectFullInputValue = (ev: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => ev.target.select();

export const NumberEdit = ({ value, update, unit, tlabel, id, min, max, ...props }: NumberParams): React.ReactElement => {
	// Handle state for user input value
	const [inputValue, setInputValue] = React.useState<string>('');

	// Handle state for converter parse error
	const [parseError, setParseError] = React.useState<string>('');

	// Get the suitable converter for the unit we're handling.
	const converter = useConverterFor(unit);

	const lang = useLanguage();

	/*
	 * Set the input value to the built
	 * value using converter
	 */
	React.useEffect(() => {
		setInputValue(converter.build(value));
	}, [value, converter]);

	/*
	 * Parse the string to check for an error setting the
	 * parse error or removing the error
	 */
	const checkParseError = useCallback(async () => await TE.tryCatch(
		() => converter.parse(String(inputValue)).then(constant(setParseError(''))),
		(error) => error instanceof Error ? setParseError(error.message) : console.warn('Unknown parsing failure.')
	)(), [converter, inputValue]);

	/*
	 * If the input value is truthy, run the parser
	 * to look for errors when the input value changes
	 */
	React.useEffect(() => {
		if (inputValue) {
			checkParseError();
		}
	}, [inputValue, checkParseError]);

	/*
	 * If there is not a parsing error parse the input value
	 * and submit the update
	 */
	const handleOnUpdate = (parsingError: boolean) => (value: string | number) => pipe(
		value,
		O.fromPredicate(() => !parsingError),
		O.map(String),
		O.foldW(
			() => setParseError('You must fix the error before submitting.'),
			(v) => converter.parse(v).then((num) => update(() => {
				if (min && num < min) num = min;
				else if (max && num > max) num = max;
				return num;
			}))
		)
	);

	return (
		<FormControl sx={{
			my: 1,
			width: '100%'
		}}>
			<Box sx={{
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				alignItems: 'center',
			}}>
				<TextField
					label={(tlabel??{})[lang]}
					error={Boolean(parseError)}
					onFocus={selectFullInputValue}
					onChange={flow(textValue, setInputValue)}
					onBlur={flow(textValueOnBlur, handleOnUpdate(Boolean(parseError)))}
					value={inputValue}
					id={id}
					inputProps={{
						style: {
							padding: '8px 16px',
							color: '#718096'
						}
					}}
					sx={{
						width: '100%',
						minWidth: '110px'
					}}
					{...props}
				/>
			</Box>
			<FormHelperText sx={{ ml: 0 }} error={Boolean(parseError)}>
				{parseError}
			</FormHelperText>
		</FormControl>
	);
};

export const MemoNumberEdit = memo(NumberEdit);

export default NumberEdit;
