/**
 * Calculation requests to Silica.
 *
 * @since 0.1.0
 */

import { array, intersection, null as cnull, partial, Type, union } from 'io-ts';
import { Requestor } from '~/src/api/Requestor';
import { Message } from '~/src/model/Message';

/**
 * Perform a calculation, providing wants and receiving a filled in version with messages.
 *
 * @since 0.1.0
 */
export const calc = (req: Requestor) => <T extends object,E extends object>(want: T, codecT: Type<T,E>): Promise<T & CalcMessages> =>
	req.request<T & CalcMessages>(intersection([codecT,CalcMessages]))('calc/shower', 'POST', want);

/**
 * We have `S` and want `T`, we already have `T`, or we don't and set it to `null`.
 *
 * @since 0.1.0
 */
export type Want<S,T> = S|T|null;

/**
 * Codec for `Want<S,T>`.
 *
 * @since 0.1.0
 */
export const Want = <S,T,SE,TE>(codecS: Type<S,SE>, codecT: Type<T,TE>): Type<Want<S,T>,Want<SE,TE>> =>
	union([
		cnull,
		codecS,
		codecT,
	]);

/**
 * A collection of messages, part of the basic return type from the calculation request.
 *
 * @since 0.1.0
 */
export interface CalcMessages {
	messages?: Message[];
}
/**
 * Codec for `CalcMessages`.
 *
 * @since 0.1.0
 */
export const CalcMessages: Type<CalcMessages> = partial({
	messages: array(Message),
});
