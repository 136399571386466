import { useEffect } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

interface QAlertProps {
	title: string;
	description?: string;
	autoDuration?: number;
	style?: object;
	onClose?: () => void;
}

export const QAlert = ({
	title,
	description = '',
	autoDuration = 6000,
	style = {},
	onClose = () => ({}),
}: QAlertProps) => {
	useEffect(() => {
		let timerId: number = -1;
		if (autoDuration > 0) {
			timerId = setTimeout(onClose, autoDuration) as unknown as number;
		}
		return () => {
			if (timerId) {
				clearTimeout(timerId);
			}
		};
	}, [autoDuration, onClose]);

	return (
		<Box
			className="q-alert"
			sx={{
				borderRadius: '6px',
				border: '4px solid #48BB78',
				opacity: 0.9,
				background: '#2D3748E6',
				padding: '20px 57px 20px 20px',
				position: 'relative',
				...style,
			}}
		>
			<Button
				size="small"
				sx={{
					minWidth: '24px',
					width: '24px',
					height: '24px',
					position: 'absolute',
					top: '10px',
					right: '10px',
					padding: 0,
					color: '#fff',
				}}
				onClick={onClose}
			>
				<CloseIcon
					sx={{
						width: '24px',
						height: '24px',
					}}
				/>
			</Button>
			<Typography
				pb={2}
				variant="h1"
				sx={{
					fontSize: '24px',
					fontWeight: 500,
					lineHeight: '150%',
					color: '#FFF',
					paddingBottom: '0',
				}}
			>
				{title}
			</Typography>
			<Typography
				pb={2}
				variant="h6"
				sx={{
					fontSize: '24px',
					fontWeight: 500,
					lineHeight: '150%',
					color: '#68D391',
					paddingBottom: '0',
				}}
			>
				{description}
			</Typography>
		</Box>
	);
};
