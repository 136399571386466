import { styled, Typography, TypographyProps } from '@mui/material';
import * as React from 'react';

type QDrawerProps = TypographyProps;

const addDarkModeBg = ({
	backgroundColor: 'rgba(0, 0, 0, 0.1)',
	backgroundImage: 'none',
	color: 'black',
	'[data-mui-color-scheme="dark"] &': {
		backgroundColor: '#000' ,
		backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))',
		color: '#808080',
	}
});

const Title = styled(Typography)(addDarkModeBg);

const QDrawerTitle: React.FC<QDrawerProps> = ({ children, ...props }) => (
	<Title p={1} pl={2} textAlign='center' variant='h2' {...props}>{children}</Title>
);

export default QDrawerTitle;
