/**
 * Panels
 *
 * @since 0.1.0
 */
import { Type, unknown as cunkonwn } from 'io-ts';

/**
 * The information about each edge of a panel.
 *
 * TODO will be filled in once we understand what's needed.
 *
 * @since 0.1.0
 * @category Paramaters
 */
export type EdgeInfo = unknown;
/**
 * Codec for `EdgeInfo`.
 *
 * @since 0.1.0
 * @category Paramaters
 */
export const EdgeInfo: Type<EdgeInfo> = cunkonwn;

/**
 * The information about each panel.
 *
 * TODO will be filled in once we understand what's needed.
 *
 * @since 0.1.0
 * @category Paramaters
 */
export type PanelInfo = unknown;
/**
 * Codec for `PanelInfo`.
 *
 * @since 0.1.0
 * @category Paramaters
 */
export const PanelInfo: Type<PanelInfo> = cunkonwn;
