/**
 * Options on Templates.
 *
 * @since 0.1.0
 */
import { array, interface as iface, string, number, Type, unknown as cunknown, intersection, partial } from 'io-ts';
import { Translations } from '~/src/model/Language';
import { Dependency } from '~/src/design/template/dependency';

/**
 * A selection of options. This is made generic to clarify which string goes with what.
 *
 * - The `Label` can be used for form identifiers etc, and may be used to decide when to
 *   show a field. They will be consistant between templates of the same type for particular
 *   purposes.
 * - The `Names` field holds the user-friendly names for this group of options.
 * - The `Value` field is the `label` field from the desired `Entry` from the `range` field.
 *
 * @since 0.1.0
 */
export type Option<T,S> = {
	Rank?: number
	Collate?: string,
	Depends: Dependency,
	Label: S,
	Names: Translations,
	Range: Entry<T>[],
	Value: T,
};
/**
 * Codec for Option.
 *
 * @since 0.1.0
 */
export const Option = <T,S>(codecT: Type<T>, codecS: Type<S>): Type<Option<T,S>> => intersection([ partial({
	Collate: string,
	Rank: number,
}), iface({
	Depends: Dependency,
	Label: codecS,
	Names: Translations,
	Range: array(Entry(codecT)),
	Value: codecT,
}) ]);

/**
 * An entry in an Option table.
 *
 * - The `Label` field is what goes in an `Option`'s `value` field, and may be used for form
 *   identifiers etc.
 * - The 'Names` field holds the user-friendly names for this specific option.
 * - The `Value` field is opaque to the client.
 * - The `Rank` field orders the option amongst it's peers
 *
 * @since 0.1.0
 */
export type Entry<T> = {
	Label: T,
	Image: string,
	Names: Translations,
	Value: unknown,
	Rank?: number
};
/**
 * Codec for Entry.
 *
 * @since 0.1.0
 */
export const Entry = <T>(codecT: Type<T>): Type<Entry<T>> => intersection([
	iface({
		Label: codecT,
		Image: string,
		Names: Translations,
		Value: cunknown,
	}),
	partial({
		Rank: number
	})
]);
