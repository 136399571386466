/**
 * Optics for TemplateMod.
 *
 */
import { Lens, Optional } from 'monocle-ts';
import { optionalString } from '~/src/optics/Common';
import { Language, Translations } from '~/src/model/Language';
import { NameChange } from '~/src/admin/template/mod/inner';
import { comp } from '~/src/base/Function';
import { Optic, pdot, withDefault } from '~/src/base/Optic';

/**
 * Focus on a particular translation.
 */
export const transTo = (lang: Language): Lens<Translations,string> => Lens.fromProp<Translations>()(lang).compose(optionalString);

/**
 * Focus on a particular translation.
 */
export const transLang = (lang: Language): Optic<Translations,string> =>
	comp(pdot(lang), withDefault(''));

/**
 * Focus on optional translation in NameChange
 *
 * @category Language
 */
export const nameChangeTranslations: (key: string) => Optional<NameChange, Translations> = (key: string) => Optional.fromNullableProp<NameChange>()(key);

