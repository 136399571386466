/**
 * TexModal.
 *
 * @since 0.2.0
 */
import * as React from 'react';
import { DialogProps, GenericDialog } from '~/src/ui/modal/Dialog';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

/**
 * TexModal props.
 *
 * @since 0.2.0
 */
export interface TextModalProps extends DialogProps {}

const TextModal: React.FC<TextModalProps> = ({
	open,
	onClose,
	message,
	onSave,
	initialValue,
}) => {
	const [value, setValue] = React.useState('');
	const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setValue(e.target.value);

	React.useEffect(() => {
		if (initialValue) {
			setValue(initialValue);
		}
	}, [initialValue]);

	return <GenericDialog open={open} onClose={onClose} onSave={() => onSave(value)} message={message}>
		<Box sx={{p: 1}}>
			<TextField required label="Input" value={value} autoFocus
				onChange={handleChangeValue} />
		</Box>
	</GenericDialog>;
};

export default TextModal;
