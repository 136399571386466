import * as React from 'react';
import { Cancel } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { constant, flow, pipe } from 'fp-ts/lib/function';
import { ap } from 'fp-ts/lib/Identity';
import { textValue } from '~/src/edit/util';
import { updateRangeSet, variableRange } from '~/src/admin/template/mod/inner-optics';
import { RangeSet } from '~/src/design/template/variable';
import { Update } from '~/src/base/Function';
import { ClientTemplateMod } from '~/src/admin/template/mod';
import { modTemplate } from '~/src/admin/template/mod/optics';
import CheckIconButton from '~/src/ui/input/CheckIconButton';

interface SetOptionTextFieldProps {
	label: string
	value: number
	range: RangeSet,
	toggleVisibility: () => void
	update: Update<ClientTemplateMod>
}

const SetOptionTextField: React.FC<SetOptionTextFieldProps> = ({ label, value, toggleVisibility, range, update }) => {
	const [temporaryValue, setTemporaryValue] = React.useState<number | undefined>(undefined);

	React.useEffect(() => {
		if (value) {
			pipe(value, setTemporaryValue);
		}
	}, [value, setTemporaryValue]);

	const handleMouseDownClose = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const modUpdate = (label: string, old: number, updated: number) => modTemplate.modify(
		variableRange.modify(
			constant(
				pipe(
					pipe(updateRangeSet, ap(label), ap(old)).set(updated),
					ap({ [label]: range})
				)
			)
		));

	const submitUpdate = (val: number | undefined) => pipe(val, Number, (x) => modUpdate(label, value, x), update);

	return (
		<>
			<TextField
				autoFocus
				variant='standard'
				value={temporaryValue}
				type='number'
				onChange={flow(textValue, Number, setTemporaryValue)}
				inputProps={{
					step: 0.0025
				}}
				InputProps={{
					startAdornment: <InputAdornment position="end">
						<IconButton
							aria-label="cancel edit"
							onClick={toggleVisibility}
							onMouseDown={handleMouseDownClose}
							edge="start"
						>
							<Cancel />
						</IconButton>
					</InputAdornment>,
				}}

			/>
			<CheckIconButton
				onClick={() => submitUpdate(temporaryValue)}
				disabled={pipe(temporaryValue, Number, isNaN)}
			/>
		</>

	);
};

export default SetOptionTextField;
