/**
 * Generic file exporter.
 *
 * @since 0.4.0
 */
import * as React from 'react';
import Box from '@mui/material/Box';
import FileDownload from '~/src/ui/input/FileDownload';

/**
 * Export Props.
 *
 * @since 0.4.0
 */
export interface ExportProps<T> {
	name: string;
	getContent: () => T;
	onError?: (err: unknown) => void;
}

export const Export = <T extends unknown>(ps: ExportProps<T>): React.ReactElement =>
	<Box m='auto'>
		<FileDownload title={`Export ${ps.name}`}
			file={{
				name: `${ps.name}.${new Date().toISOString().replace(/:/g,'-').replace(/\..*/, '')}`,
				type: 'json',
				getContent: () => JSON.stringify(ps.getContent(), null, 2),
			}}
			onError={ps.onError ?? console.warn} />
	</Box>;

export default Export;
