import { string, Type, union, undefined as undef, record, partial } from 'io-ts';
import { boolTrue } from '~/src/model/type-helpers';

/**
 * Supported Currencies
 */
export type Currency = 'USD';

/**
 * Prices
 */
export type Price<T=string> = {[k in Currency]?: T } & { Call?: true };

export const Price: Type<Price> = partial({
	USD: string,
	Call: boolTrue,
});

/** An empty price */
export const emptyPrice: Price = {};

/**
 * Normalize a numeric Price to a presentation price.
 */
export const normalize = (p: Price<number>): Price => {
	const q: Price = {};
	if (p.USD) q.USD = p.USD.toFixed(2);
	if (p.Call) q.Call = true;
	return q;
};

/**
 * Multiply a price by a scalar.
 */
export const scale = (n: number, p: Price): Price => normalize({
	USD: p.USD ? n * parseFloat(p.USD) : undefined,
	Call: p.Call,
});

/**
 * Add two Prices.
 */
export const add = (a: Price, b: Price): Price => normalize({
	USD: a.USD ? b.USD ? parseFloat(a.USD) + parseFloat(b.USD) : parseFloat(a.USD) : b.USD ? parseFloat(b.USD) : undefined,
	Call: a.Call || b.Call,
});

/**
 * Subtract the second Price from the first Price.
 */
export const sub = (a: Price, b: Price): Price => normalize({
	USD: a.USD ? b.USD ? parseFloat(a.USD) - parseFloat(b.USD) : parseFloat(a.USD) : b.USD ? -parseFloat(b.USD) : undefined,
	Call: a.Call || b.Call,
});

/**
 * Add a list of Prices.
 *
 * TODO: may be able to gain effiency by rounding instead of converting back to strings each time.
 */
export const sum = (ps: Price[]): Price => ps.reduce(add, {});

/**
 * Prices tagged with names.
 */
export type PriceMap = {[k in string]?: Price};
export const PriceMap: Type<PriceMap> = record(string, union([Price, undef]));

/** Extract the Prices from a PriceMap. */
export const prices = (pm: PriceMap): Price[] => Object.values(pm).filter(x => x) as Price[];
