import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const QButton = styled(Button)<ButtonProps>(() => ({
	height: '40px',
	background: '#2C7A7B',
	color: '#fff',
	padding: '8px',
	fontSize: '20px',
	fontWeight: '500',
	borderRadius: '6px',
	minWidth: 'auto',
	whiteSpace: 'nowrap'
}));

export default QButton;
