{
    "Count": 1,
    "Items": [
        {
            "Count": 1,
            "Items": [
                {
                    "Count": 2,
                    "Item": {
                        "Panels": [
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Hole",
                                            "Radius": 0
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [],
                                        "Front": [
                                            "AcidEtch",
                                            "Sandblast"
                                        ],
                                        "Ribbon": {
                                            "Back": "Aquatex",
                                            "Front": "Seedy",
                                            "Material": "Acrylic",
                                            "Thickness": 1.418955009041802,
                                            "Tint": "Clear"
                                        }
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -0.8200582373883499
                                    },
                                    {
                                        "Back": [
                                            "AluminiumCuPbFree",
                                            "ClearShield"
                                        ],
                                        "Front": [
                                            "AcidEtch"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Material": "Polycarbonate",
                                            "Thickness": 1.8189895505678877,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    -0.6112302862372659,
                                    -0.41220692642735274,
                                    3.448904192832447e-2,
                                    0.6747543945603404
                                ],
                                "Origin": [
                                    0.6661124258339602,
                                    0.24623911101640228,
                                    -0.9275322805216435
                                ]
                            },
                            {
                                "Cutouts": [],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Bevel",
                                            "Width": 0
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [],
                                        "Front": [
                                            "Aluminium",
                                            "AluminiumCuPbFree"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Material": "NeoCeram",
                                            "Thickness": 1.6381948010542642,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 1.3326257012823022
                                    },
                                    {
                                        "Back": [
                                            "AcidEtch",
                                            "AluminiumCuPbFree"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Aquatex",
                                            "Material": "Polycarbonate",
                                            "Thickness": -1.1517660202575855,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 0.8605958017688391
                                    },
                                    {
                                        "Back": [],
                                        "Front": [],
                                        "Ribbon": {
                                            "Back": "Aquatex",
                                            "Front": "Rain",
                                            "Material": "SodaLime",
                                            "Thickness": 0.27772317661201856,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    -0.4954510074028536,
                                    -0.6791947856134701,
                                    -0.5408212142137636,
                                    -2.7113773535240495e-2
                                ],
                                "Origin": [
                                    -1.6025773834932173,
                                    -1.9008985206865774,
                                    2.115348113874294
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Shift",
                                            "Cutout": {
                                                "type": "Hole",
                                                "Radius": 0
                                            },
                                            "Offset": 0
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Overlap",
                                            "Cutouts": [
                                                {
                                                    "type": "Shift",
                                                    "Cutout": {
                                                        "type": "Hole",
                                                        "Radius": 0
                                                    },
                                                    "Offset": 0
                                                }
                                            ]
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Hole",
                                                "Radius": 0
                                            }
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Ogee"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "PencilPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "ClearShield",
                                            "Sandblast"
                                        ],
                                        "Front": [
                                            "SafetyBacking",
                                            "Sandblast"
                                        ],
                                        "Ribbon": {
                                            "Back": "Aquatex",
                                            "Front": "Rain",
                                            "Material": "LowIron",
                                            "Thickness": -1.5687998055098016,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -1.4779881049748111
                                    },
                                    {
                                        "Back": [],
                                        "Front": [
                                            "Aluminium"
                                        ],
                                        "Ribbon": {
                                            "Front": "Rain",
                                            "Material": "Acrylic",
                                            "Thickness": 0.6591369144279845,
                                            "Tint": "White"
                                        }
                                    }
                                ],
                                "Orient": [
                                    -0.22440925687225943,
                                    -0.7415425012800854,
                                    -0.5456598972206137,
                                    -0.31939079634595635
                                ],
                                "Origin": [
                                    -1.8081631846365929,
                                    -0.8464520926283087,
                                    1.4524110503133627
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Overlap",
                                                "Cutouts": [
                                                    {
                                                        "type": "Shift",
                                                        "Cutout": {
                                                            "type": "Shift",
                                                            "Cutout": {
                                                                "type": "Shift",
                                                                "Cutout": {
                                                                    "type": "Shift",
                                                                    "Cutout": {
                                                                        "type": "Rect",
                                                                        "Height": 0,
                                                                        "Radius": 0,
                                                                        "Width": 0
                                                                    },
                                                                    "Offset": 0
                                                                },
                                                                "Offset": 0
                                                            },
                                                            "Offset": 0
                                                        },
                                                        "Offset": 0
                                                    }
                                                ]
                                            }
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "Sandblast"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Back": "Aquatex",
                                            "Front": "Rain",
                                            "Material": "SodaLime",
                                            "Thickness": -0.2653760377263823,
                                            "Tint": "Gray"
                                        }
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -1.524476793840279
                                    },
                                    {
                                        "Back": [],
                                        "Front": [
                                            "ClearShield",
                                            "Sandblast"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Seedy",
                                            "Material": "Polycarbonate",
                                            "Thickness": 0.17722061577971818,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 0.39221915695269893
                                    },
                                    {
                                        "Back": [
                                            "Aluminium",
                                            "AluminiumCuPbFree"
                                        ],
                                        "Front": [
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Material": "Polycarbonate",
                                            "Thickness": -1.3227204839374087,
                                            "Tint": "Gray"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    0.1821154162680912,
                                    0.25486833707917217,
                                    0.7078785612545722,
                                    -0.6330750732955408
                                ],
                                "Origin": [
                                    1.1994490708900203,
                                    2.293111795876295,
                                    2.831209839143462
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Hole",
                                                "Radius": 0
                                            }
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Rotate",
                                                "Angle": 0,
                                                "Cutout": {
                                                    "type": "Rect",
                                                    "Height": 0,
                                                    "Radius": 0,
                                                    "Width": 0
                                                }
                                            }
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [],
                                        "Front": [
                                            "SafetyBacking",
                                            "Sandblast"
                                        ],
                                        "Ribbon": {
                                            "Back": "Polar",
                                            "Front": "Polar",
                                            "Material": "LowIron",
                                            "Thickness": -1.247118761269748,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 1.5947161331807977
                                    },
                                    {
                                        "Back": [
                                            "AcidEtch"
                                        ],
                                        "Front": [
                                            "Aluminium",
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Material": "SodaLime",
                                            "Thickness": -1.2204606143964694,
                                            "Tint": "Bronze"
                                        }
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -0.979031431846439
                                    },
                                    {
                                        "Back": [
                                            "Aluminium",
                                            "ClearShield"
                                        ],
                                        "Front": [
                                            "AcidEtch"
                                        ],
                                        "Ribbon": {
                                            "Front": "Rain",
                                            "Material": "LowIron",
                                            "Thickness": -1.3167691697785586,
                                            "Tint": "Clear"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    0.3816762922471443,
                                    0.316111179125983,
                                    -0.7545823826999073,
                                    0.4301190045638347
                                ],
                                "Origin": [
                                    2.5134765210837844,
                                    2.1770086147194725,
                                    -2.8790006366470036
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Shift",
                                            "Cutout": {
                                                "type": "Hole",
                                                "Radius": 0
                                            },
                                            "Offset": 0
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [],
                                        "Front": [
                                            "Aluminium"
                                        ],
                                        "Ribbon": {
                                            "Back": "Rain",
                                            "Material": "Polycarbonate",
                                            "Thickness": -0.3535123865350711,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    -0.1756889029310814,
                                    0.3349673947920714,
                                    -0.6247746804620479,
                                    0.6830716305532177
                                ],
                                "Origin": [
                                    1.5002992263715194,
                                    -0.5160519151980901,
                                    2.2681379162515314
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Overlap",
                                                "Cutouts": [
                                                    {
                                                        "type": "Overlap",
                                                        "Cutouts": [
                                                            {
                                                                "type": "Shift",
                                                                "Cutout": {
                                                                    "type": "Shift",
                                                                    "Cutout": {
                                                                        "type": "Overlap",
                                                                        "Cutouts": [
                                                                            {
                                                                                "type": "Rect",
                                                                                "Height": 0,
                                                                                "Radius": 0,
                                                                                "Width": 0
                                                                            }
                                                                        ]
                                                                    },
                                                                    "Offset": 0
                                                                },
                                                                "Offset": 0
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Hole",
                                            "Radius": 0
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Shift",
                                                "Cutout": {
                                                    "type": "Shift",
                                                    "Cutout": {
                                                        "type": "Overlap",
                                                        "Cutouts": [
                                                            {
                                                                "type": "Hole",
                                                                "Radius": 0
                                                            }
                                                        ]
                                                    },
                                                    "Offset": 0
                                                },
                                                "Offset": 0
                                            }
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Miter",
                                            "Angle": 0
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "AluminiumCuPbFree"
                                        ],
                                        "Front": [
                                            "Sandblast"
                                        ],
                                        "Ribbon": {
                                            "Back": "Polar",
                                            "Material": "Acrylic",
                                            "Thickness": 0.6705428811963302,
                                            "Tint": "Bronze"
                                        }
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -1.2103914285174462
                                    },
                                    {
                                        "Back": [
                                            "AcidEtch",
                                            "SafetyBacking"
                                        ],
                                        "Front": [
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Rain",
                                            "Material": "SodaLime",
                                            "Thickness": 0.8628434175641166,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -1.6319240565653947
                                    },
                                    {
                                        "Back": [
                                            "Aluminium"
                                        ],
                                        "Front": [
                                            "Aluminium",
                                            "AluminiumCuPbFree"
                                        ],
                                        "Ribbon": {
                                            "Back": "Aquatex",
                                            "Front": "Seedy",
                                            "Material": "LowIron",
                                            "Thickness": -1.92122060211003,
                                            "Tint": "Clear"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    0.6272651501127217,
                                    -0.3645238369376131,
                                    -0.48679722054815044,
                                    0.4865072145661772
                                ],
                                "Origin": [
                                    0.8130842706409506,
                                    -2.5983981745306575,
                                    2.026431975510314
                                ]
                            },
                            {
                                "Cutouts": [],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Miter",
                                            "Angle": 0
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "ClearShield",
                                            "SafetyBacking"
                                        ],
                                        "Front": [
                                            "ClearShield",
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Rain",
                                            "Material": "SodaLime",
                                            "Thickness": 0.8965143755262679,
                                            "Tint": "Bronze"
                                        }
                                    }
                                ],
                                "Orient": [
                                    -0.953809611212371,
                                    -0.10607629387439312,
                                    5.768080622839846e-2,
                                    0.27507811623177364
                                ],
                                "Origin": [
                                    -7.844390872453079e-2,
                                    1.9765215230918454,
                                    1.3201814785782566
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Hole",
                                            "Radius": 0
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Bevel",
                                            "Width": 0
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "PencilPolish"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "ClearShield"
                                        ],
                                        "Front": [
                                            "AcidEtch"
                                        ],
                                        "Ribbon": {
                                            "Front": "Aquatex",
                                            "Material": "NeoCeram",
                                            "Thickness": 0.4411540459032128,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 0.23396410404049037
                                    },
                                    {
                                        "Back": [
                                            "Sandblast"
                                        ],
                                        "Front": [
                                            "AcidEtch"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Material": "NeoCeram",
                                            "Thickness": 0.1320083486700646,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    9.463146671330266e-2,
                                    -0.3932044767706316,
                                    -0.5881983293166462,
                                    0.7003269596012487
                                ],
                                "Origin": [
                                    -2.2664447287675475,
                                    1.032595170605475,
                                    -0.6910163072458746
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Shift",
                                                "Cutout": {
                                                    "type": "Shift",
                                                    "Cutout": {
                                                        "type": "Rotate",
                                                        "Angle": 0,
                                                        "Cutout": {
                                                            "type": "Hole",
                                                            "Radius": 0
                                                        }
                                                    },
                                                    "Offset": 0
                                                },
                                                "Offset": 0
                                            }
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Hole",
                                            "Radius": 0
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Ogee"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "PencilPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "Aluminium"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Front": "Aquatex",
                                            "Material": "Polycarbonate",
                                            "Thickness": -1.1915739138522612,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 0.6617856723623745
                                    },
                                    {
                                        "Back": [
                                            "Aluminium",
                                            "AluminiumCuPbFree"
                                        ],
                                        "Front": [
                                            "Sandblast"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Polar",
                                            "Material": "Polycarbonate",
                                            "Thickness": 1.0296676458492147,
                                            "Tint": "Bronze"
                                        }
                                    }
                                ],
                                "Orient": [
                                    -0.3297085424810446,
                                    0.4384021176364985,
                                    0.5360266288210838,
                                    -0.6416940964833346
                                ],
                                "Origin": [
                                    -2.6230269479858874,
                                    0.3459233957858804,
                                    -0.4082219589999499
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Hole",
                                            "Radius": 0
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Shift",
                                            "Cutout": {
                                                "type": "Overlap",
                                                "Cutouts": [
                                                    {
                                                        "type": "Rect",
                                                        "Height": 0,
                                                        "Radius": 0,
                                                        "Width": 0
                                                    }
                                                ]
                                            },
                                            "Offset": 0
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "PencilPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Miter",
                                            "Angle": 0
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Bevel",
                                            "Width": 0
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "ClearShield",
                                            "Sandblast"
                                        ],
                                        "Front": [
                                            "AcidEtch"
                                        ],
                                        "Ribbon": {
                                            "Front": "Rain",
                                            "Material": "NeoCeram",
                                            "Thickness": 1.2808454383078758,
                                            "Tint": "Clear"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 1.6915258034448333
                                    },
                                    {
                                        "Back": [],
                                        "Front": [
                                            "AluminiumCuPbFree"
                                        ],
                                        "Ribbon": {
                                            "Back": "Rain",
                                            "Front": "Seedy",
                                            "Material": "Acrylic",
                                            "Thickness": 1.715238026883328,
                                            "Tint": "Gray"
                                        }
                                    }
                                ],
                                "Orient": [
                                    2.7934697982784258e-2,
                                    -0.66912487789946,
                                    0.39071474643493437,
                                    -0.6315326890533288
                                ],
                                "Origin": [
                                    -1.9217361593009736,
                                    -1.4091162480039663,
                                    -2.6817382948993345
                                ]
                            },
                            {
                                "Cutouts": [],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "Sandblast"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Front": "Seedy",
                                            "Material": "Acrylic",
                                            "Thickness": 0.38492193518310036,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    -0.14144027840910098,
                                    0.6941021501437881,
                                    0.4627669469284713,
                                    0.5329761773661444
                                ],
                                "Origin": [
                                    1.1563600465945375e-2,
                                    -1.8716127101598519,
                                    -1.0729566947409792
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Rotate",
                                                "Angle": 0,
                                                "Cutout": {
                                                    "type": "Shift",
                                                    "Cutout": {
                                                        "type": "Hole",
                                                        "Radius": 0
                                                    },
                                                    "Offset": 0
                                                }
                                            }
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Overlap",
                                            "Cutouts": [
                                                {
                                                    "type": "Rect",
                                                    "Height": 0,
                                                    "Radius": 0,
                                                    "Width": 0
                                                }
                                            ]
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Ogee"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Bevel",
                                            "Width": 0
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Bevel",
                                            "Width": 0
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "AcidEtch"
                                        ],
                                        "Front": [
                                            "Aluminium",
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Front": "Aquatex",
                                            "Material": "SodaLime",
                                            "Thickness": -0.23878580565703467,
                                            "Tint": "Clear"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    -0.567220631874454,
                                    0.6624905753446048,
                                    -0.37314916660197267,
                                    0.31642801996626785
                                ],
                                "Origin": [
                                    -0.8796418498074845,
                                    -2.726342914708323,
                                    2.4742254337129803
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Hole",
                                            "Radius": 0
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "Sandblast"
                                        ],
                                        "Front": [
                                            "AcidEtch"
                                        ],
                                        "Ribbon": {
                                            "Front": "Rain",
                                            "Material": "SodaLime",
                                            "Thickness": 1.0563505748925484,
                                            "Tint": "Clear"
                                        }
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -1.4020444426695304
                                    },
                                    {
                                        "Back": [
                                            "Aluminium"
                                        ],
                                        "Front": [
                                            "ClearShield",
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Polar",
                                            "Material": "SodaLime",
                                            "Thickness": -1.514940188685774,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -0.6069044427475834
                                    },
                                    {
                                        "Back": [
                                            "Sandblast"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Material": "SodaLime",
                                            "Thickness": -0.20300549785316943,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    -0.41035212263565757,
                                    0.5397124914531669,
                                    0.7349647151498134,
                                    1.2183164719358121e-2
                                ],
                                "Origin": [
                                    0.31842715627673396,
                                    2.2475661641855957,
                                    -0.24768120794883283
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rect",
                                            "Height": 0,
                                            "Radius": 0,
                                            "Width": 0
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Rect",
                                            "Height": 0,
                                            "Radius": 0,
                                            "Width": 0
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "PencilPolish"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "AcidEtch",
                                            "SafetyBacking"
                                        ],
                                        "Front": [
                                            "ClearShield",
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Back": "Polar",
                                            "Front": "Seedy",
                                            "Material": "SodaLime",
                                            "Thickness": 0.16365428785396458,
                                            "Tint": "Bronze"
                                        }
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -5.791918709553234e-3
                                    },
                                    {
                                        "Back": [
                                            "AluminiumCuPbFree",
                                            "SafetyBacking"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Front": "Aquatex",
                                            "Material": "Acrylic",
                                            "Thickness": 1.5233542312509927,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    0.4096658319243915,
                                    -0.763288884333104,
                                    3.422850248187503e-2,
                                    -0.4983898020877533
                                ],
                                "Origin": [
                                    -2.2604223597503172,
                                    -1.8444675928244112,
                                    1.5497449779971892
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Hole",
                                                "Radius": 0
                                            }
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Rect",
                                            "Height": 0,
                                            "Radius": 0,
                                            "Width": 0
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Miter",
                                            "Angle": 0
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Miter",
                                            "Angle": 0
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "PencilPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "SafetyBacking",
                                            "Sandblast"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Rain",
                                            "Material": "Acrylic",
                                            "Thickness": -1.3416620954332432,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    0.5755049346218226,
                                    -0.3136756255198712,
                                    -0.7244218148408411,
                                    0.2135759966928663
                                ],
                                "Origin": [
                                    1.9588226628399754,
                                    2.2326260792147217,
                                    -2.4490335967721535
                                ]
                            }
                        ]
                    },
                    "Name": "South Wall Mirror"
                },
                {
                    "Count": 1,
                    "Item": {
                        "Panels": [
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Hole",
                                            "Radius": 0
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [],
                                        "Front": [
                                            "AcidEtch",
                                            "Sandblast"
                                        ],
                                        "Ribbon": {
                                            "Back": "Aquatex",
                                            "Front": "Seedy",
                                            "Material": "Acrylic",
                                            "Thickness": 1.418955009041802,
                                            "Tint": "Clear"
                                        }
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -0.8200582373883499
                                    },
                                    {
                                        "Back": [
                                            "AluminiumCuPbFree",
                                            "ClearShield"
                                        ],
                                        "Front": [
                                            "AcidEtch"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Material": "Polycarbonate",
                                            "Thickness": 1.8189895505678877,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    -0.6112302862372659,
                                    -0.41220692642735274,
                                    3.448904192832447e-2,
                                    0.6747543945603404
                                ],
                                "Origin": [
                                    0.6661124258339602,
                                    0.24623911101640228,
                                    -0.9275322805216435
                                ]
                            },
                            {
                                "Cutouts": [],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Bevel",
                                            "Width": 0
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [],
                                        "Front": [
                                            "Aluminium",
                                            "AluminiumCuPbFree"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Material": "NeoCeram",
                                            "Thickness": 1.6381948010542642,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 1.3326257012823022
                                    },
                                    {
                                        "Back": [
                                            "AcidEtch",
                                            "AluminiumCuPbFree"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Aquatex",
                                            "Material": "Polycarbonate",
                                            "Thickness": -1.1517660202575855,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 0.8605958017688391
                                    },
                                    {
                                        "Back": [],
                                        "Front": [],
                                        "Ribbon": {
                                            "Back": "Aquatex",
                                            "Front": "Rain",
                                            "Material": "SodaLime",
                                            "Thickness": 0.27772317661201856,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    -0.4954510074028536,
                                    -0.6791947856134701,
                                    -0.5408212142137636,
                                    -2.7113773535240495e-2
                                ],
                                "Origin": [
                                    -1.6025773834932173,
                                    -1.9008985206865774,
                                    2.115348113874294
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Shift",
                                            "Cutout": {
                                                "type": "Hole",
                                                "Radius": 0
                                            },
                                            "Offset": 0
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Overlap",
                                            "Cutouts": [
                                                {
                                                    "type": "Shift",
                                                    "Cutout": {
                                                        "type": "Hole",
                                                        "Radius": 0
                                                    },
                                                    "Offset": 0
                                                }
                                            ]
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Hole",
                                                "Radius": 0
                                            }
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Ogee"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "PencilPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "ClearShield",
                                            "Sandblast"
                                        ],
                                        "Front": [
                                            "SafetyBacking",
                                            "Sandblast"
                                        ],
                                        "Ribbon": {
                                            "Back": "Aquatex",
                                            "Front": "Rain",
                                            "Material": "LowIron",
                                            "Thickness": -1.5687998055098016,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -1.4779881049748111
                                    },
                                    {
                                        "Back": [],
                                        "Front": [
                                            "Aluminium"
                                        ],
                                        "Ribbon": {
                                            "Front": "Rain",
                                            "Material": "Acrylic",
                                            "Thickness": 0.6591369144279845,
                                            "Tint": "White"
                                        }
                                    }
                                ],
                                "Orient": [
                                    -0.22440925687225943,
                                    -0.7415425012800854,
                                    -0.5456598972206137,
                                    -0.31939079634595635
                                ],
                                "Origin": [
                                    -1.8081631846365929,
                                    -0.8464520926283087,
                                    1.4524110503133627
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Overlap",
                                                "Cutouts": [
                                                    {
                                                        "type": "Shift",
                                                        "Cutout": {
                                                            "type": "Shift",
                                                            "Cutout": {
                                                                "type": "Shift",
                                                                "Cutout": {
                                                                    "type": "Shift",
                                                                    "Cutout": {
                                                                        "type": "Rect",
                                                                        "Height": 0,
                                                                        "Radius": 0,
                                                                        "Width": 0
                                                                    },
                                                                    "Offset": 0
                                                                },
                                                                "Offset": 0
                                                            },
                                                            "Offset": 0
                                                        },
                                                        "Offset": 0
                                                    }
                                                ]
                                            }
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "Sandblast"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Back": "Aquatex",
                                            "Front": "Rain",
                                            "Material": "SodaLime",
                                            "Thickness": -0.2653760377263823,
                                            "Tint": "Gray"
                                        }
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -1.524476793840279
                                    },
                                    {
                                        "Back": [],
                                        "Front": [
                                            "ClearShield",
                                            "Sandblast"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Seedy",
                                            "Material": "Polycarbonate",
                                            "Thickness": 0.17722061577971818,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 0.39221915695269893
                                    },
                                    {
                                        "Back": [
                                            "Aluminium",
                                            "AluminiumCuPbFree"
                                        ],
                                        "Front": [
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Material": "Polycarbonate",
                                            "Thickness": -1.3227204839374087,
                                            "Tint": "Gray"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    0.1821154162680912,
                                    0.25486833707917217,
                                    0.7078785612545722,
                                    -0.6330750732955408
                                ],
                                "Origin": [
                                    1.1994490708900203,
                                    2.293111795876295,
                                    2.831209839143462
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Hole",
                                                "Radius": 0
                                            }
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Rotate",
                                                "Angle": 0,
                                                "Cutout": {
                                                    "type": "Rect",
                                                    "Height": 0,
                                                    "Radius": 0,
                                                    "Width": 0
                                                }
                                            }
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [],
                                        "Front": [
                                            "SafetyBacking",
                                            "Sandblast"
                                        ],
                                        "Ribbon": {
                                            "Back": "Polar",
                                            "Front": "Polar",
                                            "Material": "LowIron",
                                            "Thickness": -1.247118761269748,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 1.5947161331807977
                                    },
                                    {
                                        "Back": [
                                            "AcidEtch"
                                        ],
                                        "Front": [
                                            "Aluminium",
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Material": "SodaLime",
                                            "Thickness": -1.2204606143964694,
                                            "Tint": "Bronze"
                                        }
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -0.979031431846439
                                    },
                                    {
                                        "Back": [
                                            "Aluminium",
                                            "ClearShield"
                                        ],
                                        "Front": [
                                            "AcidEtch"
                                        ],
                                        "Ribbon": {
                                            "Front": "Rain",
                                            "Material": "LowIron",
                                            "Thickness": -1.3167691697785586,
                                            "Tint": "Clear"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    0.3816762922471443,
                                    0.316111179125983,
                                    -0.7545823826999073,
                                    0.4301190045638347
                                ],
                                "Origin": [
                                    2.5134765210837844,
                                    2.1770086147194725,
                                    -2.8790006366470036
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Shift",
                                            "Cutout": {
                                                "type": "Hole",
                                                "Radius": 0
                                            },
                                            "Offset": 0
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [],
                                        "Front": [
                                            "Aluminium"
                                        ],
                                        "Ribbon": {
                                            "Back": "Rain",
                                            "Material": "Polycarbonate",
                                            "Thickness": -0.3535123865350711,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    -0.1756889029310814,
                                    0.3349673947920714,
                                    -0.6247746804620479,
                                    0.6830716305532177
                                ],
                                "Origin": [
                                    1.5002992263715194,
                                    -0.5160519151980901,
                                    2.2681379162515314
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Overlap",
                                                "Cutouts": [
                                                    {
                                                        "type": "Overlap",
                                                        "Cutouts": [
                                                            {
                                                                "type": "Shift",
                                                                "Cutout": {
                                                                    "type": "Shift",
                                                                    "Cutout": {
                                                                        "type": "Overlap",
                                                                        "Cutouts": [
                                                                            {
                                                                                "type": "Rect",
                                                                                "Height": 0,
                                                                                "Radius": 0,
                                                                                "Width": 0
                                                                            }
                                                                        ]
                                                                    },
                                                                    "Offset": 0
                                                                },
                                                                "Offset": 0
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Hole",
                                            "Radius": 0
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Shift",
                                                "Cutout": {
                                                    "type": "Shift",
                                                    "Cutout": {
                                                        "type": "Overlap",
                                                        "Cutouts": [
                                                            {
                                                                "type": "Hole",
                                                                "Radius": 0
                                                            }
                                                        ]
                                                    },
                                                    "Offset": 0
                                                },
                                                "Offset": 0
                                            }
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Miter",
                                            "Angle": 0
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "AluminiumCuPbFree"
                                        ],
                                        "Front": [
                                            "Sandblast"
                                        ],
                                        "Ribbon": {
                                            "Back": "Polar",
                                            "Material": "Acrylic",
                                            "Thickness": 0.6705428811963302,
                                            "Tint": "Bronze"
                                        }
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -1.2103914285174462
                                    },
                                    {
                                        "Back": [
                                            "AcidEtch",
                                            "SafetyBacking"
                                        ],
                                        "Front": [
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Rain",
                                            "Material": "SodaLime",
                                            "Thickness": 0.8628434175641166,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -1.6319240565653947
                                    },
                                    {
                                        "Back": [
                                            "Aluminium"
                                        ],
                                        "Front": [
                                            "Aluminium",
                                            "AluminiumCuPbFree"
                                        ],
                                        "Ribbon": {
                                            "Back": "Aquatex",
                                            "Front": "Seedy",
                                            "Material": "LowIron",
                                            "Thickness": -1.92122060211003,
                                            "Tint": "Clear"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    0.6272651501127217,
                                    -0.3645238369376131,
                                    -0.48679722054815044,
                                    0.4865072145661772
                                ],
                                "Origin": [
                                    0.8130842706409506,
                                    -2.5983981745306575,
                                    2.026431975510314
                                ]
                            },
                            {
                                "Cutouts": [],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Miter",
                                            "Angle": 0
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "ClearShield",
                                            "SafetyBacking"
                                        ],
                                        "Front": [
                                            "ClearShield",
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Rain",
                                            "Material": "SodaLime",
                                            "Thickness": 0.8965143755262679,
                                            "Tint": "Bronze"
                                        }
                                    }
                                ],
                                "Orient": [
                                    -0.953809611212371,
                                    -0.10607629387439312,
                                    5.768080622839846e-2,
                                    0.27507811623177364
                                ],
                                "Origin": [
                                    -7.844390872453079e-2,
                                    1.9765215230918454,
                                    1.3201814785782566
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Hole",
                                            "Radius": 0
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Bevel",
                                            "Width": 0
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "PencilPolish"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "ClearShield"
                                        ],
                                        "Front": [
                                            "AcidEtch"
                                        ],
                                        "Ribbon": {
                                            "Front": "Aquatex",
                                            "Material": "NeoCeram",
                                            "Thickness": 0.4411540459032128,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 0.23396410404049037
                                    },
                                    {
                                        "Back": [
                                            "Sandblast"
                                        ],
                                        "Front": [
                                            "AcidEtch"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Material": "NeoCeram",
                                            "Thickness": 0.1320083486700646,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    9.463146671330266e-2,
                                    -0.3932044767706316,
                                    -0.5881983293166462,
                                    0.7003269596012487
                                ],
                                "Origin": [
                                    -2.2664447287675475,
                                    1.032595170605475,
                                    -0.6910163072458746
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Shift",
                                                "Cutout": {
                                                    "type": "Shift",
                                                    "Cutout": {
                                                        "type": "Rotate",
                                                        "Angle": 0,
                                                        "Cutout": {
                                                            "type": "Hole",
                                                            "Radius": 0
                                                        }
                                                    },
                                                    "Offset": 0
                                                },
                                                "Offset": 0
                                            }
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Hole",
                                            "Radius": 0
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Ogee"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "PencilPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "Aluminium"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Front": "Aquatex",
                                            "Material": "Polycarbonate",
                                            "Thickness": -1.1915739138522612,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 0.6617856723623745
                                    },
                                    {
                                        "Back": [
                                            "Aluminium",
                                            "AluminiumCuPbFree"
                                        ],
                                        "Front": [
                                            "Sandblast"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Polar",
                                            "Material": "Polycarbonate",
                                            "Thickness": 1.0296676458492147,
                                            "Tint": "Bronze"
                                        }
                                    }
                                ],
                                "Orient": [
                                    -0.3297085424810446,
                                    0.4384021176364985,
                                    0.5360266288210838,
                                    -0.6416940964833346
                                ],
                                "Origin": [
                                    -2.6230269479858874,
                                    0.3459233957858804,
                                    -0.4082219589999499
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Hole",
                                            "Radius": 0
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Shift",
                                            "Cutout": {
                                                "type": "Overlap",
                                                "Cutouts": [
                                                    {
                                                        "type": "Rect",
                                                        "Height": 0,
                                                        "Radius": 0,
                                                        "Width": 0
                                                    }
                                                ]
                                            },
                                            "Offset": 0
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "PencilPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Miter",
                                            "Angle": 0
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Bevel",
                                            "Width": 0
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "ClearShield",
                                            "Sandblast"
                                        ],
                                        "Front": [
                                            "AcidEtch"
                                        ],
                                        "Ribbon": {
                                            "Front": "Rain",
                                            "Material": "NeoCeram",
                                            "Thickness": 1.2808454383078758,
                                            "Tint": "Clear"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": 1.6915258034448333
                                    },
                                    {
                                        "Back": [],
                                        "Front": [
                                            "AluminiumCuPbFree"
                                        ],
                                        "Ribbon": {
                                            "Back": "Rain",
                                            "Front": "Seedy",
                                            "Material": "Acrylic",
                                            "Thickness": 1.715238026883328,
                                            "Tint": "Gray"
                                        }
                                    }
                                ],
                                "Orient": [
                                    2.7934697982784258e-2,
                                    -0.66912487789946,
                                    0.39071474643493437,
                                    -0.6315326890533288
                                ],
                                "Origin": [
                                    -1.9217361593009736,
                                    -1.4091162480039663,
                                    -2.6817382948993345
                                ]
                            },
                            {
                                "Cutouts": [],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "Sandblast"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Front": "Seedy",
                                            "Material": "Acrylic",
                                            "Thickness": 0.38492193518310036,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    -0.14144027840910098,
                                    0.6941021501437881,
                                    0.4627669469284713,
                                    0.5329761773661444
                                ],
                                "Origin": [
                                    1.1563600465945375e-2,
                                    -1.8716127101598519,
                                    -1.0729566947409792
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Rotate",
                                                "Angle": 0,
                                                "Cutout": {
                                                    "type": "Shift",
                                                    "Cutout": {
                                                        "type": "Hole",
                                                        "Radius": 0
                                                    },
                                                    "Offset": 0
                                                }
                                            }
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Overlap",
                                            "Cutouts": [
                                                {
                                                    "type": "Rect",
                                                    "Height": 0,
                                                    "Radius": 0,
                                                    "Width": 0
                                                }
                                            ]
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Ogee"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Bevel",
                                            "Width": 0
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Round",
                                            "Radius": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Bevel",
                                            "Width": 0
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "AcidEtch"
                                        ],
                                        "Front": [
                                            "Aluminium",
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Front": "Aquatex",
                                            "Material": "SodaLime",
                                            "Thickness": -0.23878580565703467,
                                            "Tint": "Clear"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    -0.567220631874454,
                                    0.6624905753446048,
                                    -0.37314916660197267,
                                    0.31642801996626785
                                ],
                                "Origin": [
                                    -0.8796418498074845,
                                    -2.726342914708323,
                                    2.4742254337129803
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Hole",
                                            "Radius": 0
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Segment": {
                                            "type": "Linear",
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "Sandblast"
                                        ],
                                        "Front": [
                                            "AcidEtch"
                                        ],
                                        "Ribbon": {
                                            "Front": "Rain",
                                            "Material": "SodaLime",
                                            "Thickness": 1.0563505748925484,
                                            "Tint": "Clear"
                                        }
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -1.4020444426695304
                                    },
                                    {
                                        "Back": [
                                            "Aluminium"
                                        ],
                                        "Front": [
                                            "ClearShield",
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Polar",
                                            "Material": "SodaLime",
                                            "Thickness": -1.514940188685774,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -0.6069044427475834
                                    },
                                    {
                                        "Back": [
                                            "Sandblast"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Material": "SodaLime",
                                            "Thickness": -0.20300549785316943,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    -0.41035212263565757,
                                    0.5397124914531669,
                                    0.7349647151498134,
                                    1.2183164719358121e-2
                                ],
                                "Origin": [
                                    0.31842715627673396,
                                    2.2475661641855957,
                                    -0.24768120794883283
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rect",
                                            "Height": 0,
                                            "Radius": 0,
                                            "Width": 0
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Rect",
                                            "Height": 0,
                                            "Radius": 0,
                                            "Width": 0
                                        },
                                        "Edging": {
                                            "type": "FlatPolish"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "PencilPolish"
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "AcidEtch",
                                            "SafetyBacking"
                                        ],
                                        "Front": [
                                            "ClearShield",
                                            "SafetyBacking"
                                        ],
                                        "Ribbon": {
                                            "Back": "Polar",
                                            "Front": "Seedy",
                                            "Material": "SodaLime",
                                            "Thickness": 0.16365428785396458,
                                            "Tint": "Bronze"
                                        }
                                    },
                                    {
                                        "Laminate": "Vinyl",
                                        "Thickness": -5.791918709553234e-3
                                    },
                                    {
                                        "Back": [
                                            "AluminiumCuPbFree",
                                            "SafetyBacking"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Front": "Aquatex",
                                            "Material": "Acrylic",
                                            "Thickness": 1.5233542312509927,
                                            "Tint": "Bronze"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    0.4096658319243915,
                                    -0.763288884333104,
                                    3.422850248187503e-2,
                                    -0.4983898020877533
                                ],
                                "Origin": [
                                    -2.2604223597503172,
                                    -1.8444675928244112,
                                    1.5497449779971892
                                ]
                            },
                            {
                                "Cutouts": [
                                    {
                                        "Cutout": {
                                            "type": "Rotate",
                                            "Angle": 0,
                                            "Cutout": {
                                                "type": "Hole",
                                                "Radius": 0
                                            }
                                        },
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    },
                                    {
                                        "Cutout": {
                                            "type": "Rect",
                                            "Height": 0,
                                            "Radius": 0,
                                            "Width": 0
                                        },
                                        "Edging": {
                                            "type": "Seamed"
                                        },
                                        "Origin": [
                                            0,
                                            0
                                        ]
                                    }
                                ],
                                "Edges": [
                                    {
                                        "Corner": {
                                            "type": "Clip",
                                            "Length": 0
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Miter",
                                            "Angle": 0
                                        },
                                        "Segment": {
                                            "type": "Cubic",
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "P2": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Raw"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Raw"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "Miter",
                                            "Angle": 0
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    },
                                    {
                                        "Corner": {
                                            "type": "Eased"
                                        },
                                        "Cutouts": [],
                                        "Edging": {
                                            "type": "PencilPolish"
                                        },
                                        "Segment": {
                                            "type": "Conic",
                                            "F1": [
                                                0,
                                                0
                                            ],
                                            "P1": [
                                                0,
                                                0
                                            ],
                                            "To": [
                                                0,
                                                0
                                            ]
                                        }
                                    }
                                ],
                                "Material": [
                                    {
                                        "Back": [
                                            "SafetyBacking",
                                            "Sandblast"
                                        ],
                                        "Front": [],
                                        "Ribbon": {
                                            "Back": "Seedy",
                                            "Front": "Rain",
                                            "Material": "Acrylic",
                                            "Thickness": -1.3416620954332432,
                                            "Tint": "White"
                                        },
                                        "Temper": "HeatTempered"
                                    }
                                ],
                                "Orient": [
                                    0.5755049346218226,
                                    -0.3136756255198712,
                                    -0.7244218148408411,
                                    0.2135759966928663
                                ],
                                "Origin": [
                                    1.9588226628399754,
                                    2.2326260792147217,
                                    -2.4490335967721535
                                ]
                            }
                        ]
                    },
                    "Name": "Bathroom Mirror"
                }
            ],
            "Name": "Master Bedroom"
        },
        {
            "Count": 4,
            "Item": {
                "Panels": [
                    {
                        "Cutouts": [
                            {
                                "Cutout": {
                                    "type": "Hole",
                                    "Radius": 0
                                },
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            }
                        ],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Round",
                                    "Radius": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [],
                                "Front": [
                                    "AcidEtch",
                                    "Sandblast"
                                ],
                                "Ribbon": {
                                    "Back": "Aquatex",
                                    "Front": "Seedy",
                                    "Material": "Acrylic",
                                    "Thickness": 1.418955009041802,
                                    "Tint": "Clear"
                                }
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": -0.8200582373883499
                            },
                            {
                                "Back": [
                                    "AluminiumCuPbFree",
                                    "ClearShield"
                                ],
                                "Front": [
                                    "AcidEtch"
                                ],
                                "Ribbon": {
                                    "Back": "Seedy",
                                    "Material": "Polycarbonate",
                                    "Thickness": 1.8189895505678877,
                                    "Tint": "Bronze"
                                },
                                "Temper": "HeatTempered"
                            }
                        ],
                        "Orient": [
                            -0.6112302862372659,
                            -0.41220692642735274,
                            3.448904192832447e-2,
                            0.6747543945603404
                        ],
                        "Origin": [
                            0.6661124258339602,
                            0.24623911101640228,
                            -0.9275322805216435
                        ]
                    },
                    {
                        "Cutouts": [],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Round",
                                    "Radius": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Round",
                                    "Radius": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Raw"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Bevel",
                                    "Width": 0
                                },
                                "Segment": {
                                    "type": "Linear",
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [],
                                "Front": [
                                    "Aluminium",
                                    "AluminiumCuPbFree"
                                ],
                                "Ribbon": {
                                    "Back": "Seedy",
                                    "Material": "NeoCeram",
                                    "Thickness": 1.6381948010542642,
                                    "Tint": "White"
                                },
                                "Temper": "HeatTempered"
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": 1.3326257012823022
                            },
                            {
                                "Back": [
                                    "AcidEtch",
                                    "AluminiumCuPbFree"
                                ],
                                "Front": [],
                                "Ribbon": {
                                    "Back": "Seedy",
                                    "Front": "Aquatex",
                                    "Material": "Polycarbonate",
                                    "Thickness": -1.1517660202575855,
                                    "Tint": "Bronze"
                                },
                                "Temper": "HeatTempered"
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": 0.8605958017688391
                            },
                            {
                                "Back": [],
                                "Front": [],
                                "Ribbon": {
                                    "Back": "Aquatex",
                                    "Front": "Rain",
                                    "Material": "SodaLime",
                                    "Thickness": 0.27772317661201856,
                                    "Tint": "Bronze"
                                },
                                "Temper": "HeatTempered"
                            }
                        ],
                        "Orient": [
                            -0.4954510074028536,
                            -0.6791947856134701,
                            -0.5408212142137636,
                            -2.7113773535240495e-2
                        ],
                        "Origin": [
                            -1.6025773834932173,
                            -1.9008985206865774,
                            2.115348113874294
                        ]
                    },
                    {
                        "Cutouts": [
                            {
                                "Cutout": {
                                    "type": "Shift",
                                    "Cutout": {
                                        "type": "Hole",
                                        "Radius": 0
                                    },
                                    "Offset": 0
                                },
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            },
                            {
                                "Cutout": {
                                    "type": "Overlap",
                                    "Cutouts": [
                                        {
                                            "type": "Shift",
                                            "Cutout": {
                                                "type": "Hole",
                                                "Radius": 0
                                            },
                                            "Offset": 0
                                        }
                                    ]
                                },
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            },
                            {
                                "Cutout": {
                                    "type": "Rotate",
                                    "Angle": 0,
                                    "Cutout": {
                                        "type": "Hole",
                                        "Radius": 0
                                    }
                                },
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            }
                        ],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Raw"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Clip",
                                    "Length": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Ogee"
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "PencilPolish"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Raw"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Segment": {
                                    "type": "Linear",
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [
                                    "ClearShield",
                                    "Sandblast"
                                ],
                                "Front": [
                                    "SafetyBacking",
                                    "Sandblast"
                                ],
                                "Ribbon": {
                                    "Back": "Aquatex",
                                    "Front": "Rain",
                                    "Material": "LowIron",
                                    "Thickness": -1.5687998055098016,
                                    "Tint": "Bronze"
                                },
                                "Temper": "HeatTempered"
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": -1.4779881049748111
                            },
                            {
                                "Back": [],
                                "Front": [
                                    "Aluminium"
                                ],
                                "Ribbon": {
                                    "Front": "Rain",
                                    "Material": "Acrylic",
                                    "Thickness": 0.6591369144279845,
                                    "Tint": "White"
                                }
                            }
                        ],
                        "Orient": [
                            -0.22440925687225943,
                            -0.7415425012800854,
                            -0.5456598972206137,
                            -0.31939079634595635
                        ],
                        "Origin": [
                            -1.8081631846365929,
                            -0.8464520926283087,
                            1.4524110503133627
                        ]
                    },
                    {
                        "Cutouts": [
                            {
                                "Cutout": {
                                    "type": "Rotate",
                                    "Angle": 0,
                                    "Cutout": {
                                        "type": "Overlap",
                                        "Cutouts": [
                                            {
                                                "type": "Shift",
                                                "Cutout": {
                                                    "type": "Shift",
                                                    "Cutout": {
                                                        "type": "Shift",
                                                        "Cutout": {
                                                            "type": "Shift",
                                                            "Cutout": {
                                                                "type": "Rect",
                                                                "Height": 0,
                                                                "Radius": 0,
                                                                "Width": 0
                                                            },
                                                            "Offset": 0
                                                        },
                                                        "Offset": 0
                                                    },
                                                    "Offset": 0
                                                },
                                                "Offset": 0
                                            }
                                        ]
                                    }
                                },
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            }
                        ],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Raw"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Segment": {
                                    "type": "Linear",
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [
                                    "Sandblast"
                                ],
                                "Front": [],
                                "Ribbon": {
                                    "Back": "Aquatex",
                                    "Front": "Rain",
                                    "Material": "SodaLime",
                                    "Thickness": -0.2653760377263823,
                                    "Tint": "Gray"
                                }
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": -1.524476793840279
                            },
                            {
                                "Back": [],
                                "Front": [
                                    "ClearShield",
                                    "Sandblast"
                                ],
                                "Ribbon": {
                                    "Back": "Seedy",
                                    "Front": "Seedy",
                                    "Material": "Polycarbonate",
                                    "Thickness": 0.17722061577971818,
                                    "Tint": "White"
                                },
                                "Temper": "HeatTempered"
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": 0.39221915695269893
                            },
                            {
                                "Back": [
                                    "Aluminium",
                                    "AluminiumCuPbFree"
                                ],
                                "Front": [
                                    "SafetyBacking"
                                ],
                                "Ribbon": {
                                    "Material": "Polycarbonate",
                                    "Thickness": -1.3227204839374087,
                                    "Tint": "Gray"
                                },
                                "Temper": "HeatTempered"
                            }
                        ],
                        "Orient": [
                            0.1821154162680912,
                            0.25486833707917217,
                            0.7078785612545722,
                            -0.6330750732955408
                        ],
                        "Origin": [
                            1.1994490708900203,
                            2.293111795876295,
                            2.831209839143462
                        ]
                    },
                    {
                        "Cutouts": [
                            {
                                "Cutout": {
                                    "type": "Rotate",
                                    "Angle": 0,
                                    "Cutout": {
                                        "type": "Hole",
                                        "Radius": 0
                                    }
                                },
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            },
                            {
                                "Cutout": {
                                    "type": "Rotate",
                                    "Angle": 0,
                                    "Cutout": {
                                        "type": "Rotate",
                                        "Angle": 0,
                                        "Cutout": {
                                            "type": "Rect",
                                            "Height": 0,
                                            "Radius": 0,
                                            "Width": 0
                                        }
                                    }
                                },
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            }
                        ],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Clip",
                                    "Length": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Segment": {
                                    "type": "Linear",
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Clip",
                                    "Length": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Raw"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [],
                                "Front": [
                                    "SafetyBacking",
                                    "Sandblast"
                                ],
                                "Ribbon": {
                                    "Back": "Polar",
                                    "Front": "Polar",
                                    "Material": "LowIron",
                                    "Thickness": -1.247118761269748,
                                    "Tint": "White"
                                },
                                "Temper": "HeatTempered"
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": 1.5947161331807977
                            },
                            {
                                "Back": [
                                    "AcidEtch"
                                ],
                                "Front": [
                                    "Aluminium",
                                    "SafetyBacking"
                                ],
                                "Ribbon": {
                                    "Material": "SodaLime",
                                    "Thickness": -1.2204606143964694,
                                    "Tint": "Bronze"
                                }
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": -0.979031431846439
                            },
                            {
                                "Back": [
                                    "Aluminium",
                                    "ClearShield"
                                ],
                                "Front": [
                                    "AcidEtch"
                                ],
                                "Ribbon": {
                                    "Front": "Rain",
                                    "Material": "LowIron",
                                    "Thickness": -1.3167691697785586,
                                    "Tint": "Clear"
                                },
                                "Temper": "HeatTempered"
                            }
                        ],
                        "Orient": [
                            0.3816762922471443,
                            0.316111179125983,
                            -0.7545823826999073,
                            0.4301190045638347
                        ],
                        "Origin": [
                            2.5134765210837844,
                            2.1770086147194725,
                            -2.8790006366470036
                        ]
                    },
                    {
                        "Cutouts": [
                            {
                                "Cutout": {
                                    "type": "Shift",
                                    "Cutout": {
                                        "type": "Hole",
                                        "Radius": 0
                                    },
                                    "Offset": 0
                                },
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            }
                        ],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Clip",
                                    "Length": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Clip",
                                    "Length": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [],
                                "Front": [
                                    "Aluminium"
                                ],
                                "Ribbon": {
                                    "Back": "Rain",
                                    "Material": "Polycarbonate",
                                    "Thickness": -0.3535123865350711,
                                    "Tint": "Bronze"
                                },
                                "Temper": "HeatTempered"
                            }
                        ],
                        "Orient": [
                            -0.1756889029310814,
                            0.3349673947920714,
                            -0.6247746804620479,
                            0.6830716305532177
                        ],
                        "Origin": [
                            1.5002992263715194,
                            -0.5160519151980901,
                            2.2681379162515314
                        ]
                    },
                    {
                        "Cutouts": [
                            {
                                "Cutout": {
                                    "type": "Rotate",
                                    "Angle": 0,
                                    "Cutout": {
                                        "type": "Overlap",
                                        "Cutouts": [
                                            {
                                                "type": "Overlap",
                                                "Cutouts": [
                                                    {
                                                        "type": "Shift",
                                                        "Cutout": {
                                                            "type": "Shift",
                                                            "Cutout": {
                                                                "type": "Overlap",
                                                                "Cutouts": [
                                                                    {
                                                                        "type": "Rect",
                                                                        "Height": 0,
                                                                        "Radius": 0,
                                                                        "Width": 0
                                                                    }
                                                                ]
                                                            },
                                                            "Offset": 0
                                                        },
                                                        "Offset": 0
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                },
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            },
                            {
                                "Cutout": {
                                    "type": "Hole",
                                    "Radius": 0
                                },
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            },
                            {
                                "Cutout": {
                                    "type": "Rotate",
                                    "Angle": 0,
                                    "Cutout": {
                                        "type": "Shift",
                                        "Cutout": {
                                            "type": "Shift",
                                            "Cutout": {
                                                "type": "Overlap",
                                                "Cutouts": [
                                                    {
                                                        "type": "Hole",
                                                        "Radius": 0
                                                    }
                                                ]
                                            },
                                            "Offset": 0
                                        },
                                        "Offset": 0
                                    }
                                },
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            }
                        ],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Miter",
                                    "Angle": 0
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [
                                    "AluminiumCuPbFree"
                                ],
                                "Front": [
                                    "Sandblast"
                                ],
                                "Ribbon": {
                                    "Back": "Polar",
                                    "Material": "Acrylic",
                                    "Thickness": 0.6705428811963302,
                                    "Tint": "Bronze"
                                }
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": -1.2103914285174462
                            },
                            {
                                "Back": [
                                    "AcidEtch",
                                    "SafetyBacking"
                                ],
                                "Front": [
                                    "SafetyBacking"
                                ],
                                "Ribbon": {
                                    "Back": "Seedy",
                                    "Front": "Rain",
                                    "Material": "SodaLime",
                                    "Thickness": 0.8628434175641166,
                                    "Tint": "Bronze"
                                },
                                "Temper": "HeatTempered"
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": -1.6319240565653947
                            },
                            {
                                "Back": [
                                    "Aluminium"
                                ],
                                "Front": [
                                    "Aluminium",
                                    "AluminiumCuPbFree"
                                ],
                                "Ribbon": {
                                    "Back": "Aquatex",
                                    "Front": "Seedy",
                                    "Material": "LowIron",
                                    "Thickness": -1.92122060211003,
                                    "Tint": "Clear"
                                },
                                "Temper": "HeatTempered"
                            }
                        ],
                        "Orient": [
                            0.6272651501127217,
                            -0.3645238369376131,
                            -0.48679722054815044,
                            0.4865072145661772
                        ],
                        "Origin": [
                            0.8130842706409506,
                            -2.5983981745306575,
                            2.026431975510314
                        ]
                    },
                    {
                        "Cutouts": [],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Raw"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Miter",
                                    "Angle": 0
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Clip",
                                    "Length": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [
                                    "ClearShield",
                                    "SafetyBacking"
                                ],
                                "Front": [
                                    "ClearShield",
                                    "SafetyBacking"
                                ],
                                "Ribbon": {
                                    "Back": "Seedy",
                                    "Front": "Rain",
                                    "Material": "SodaLime",
                                    "Thickness": 0.8965143755262679,
                                    "Tint": "Bronze"
                                }
                            }
                        ],
                        "Orient": [
                            -0.953809611212371,
                            -0.10607629387439312,
                            5.768080622839846e-2,
                            0.27507811623177364
                        ],
                        "Origin": [
                            -7.844390872453079e-2,
                            1.9765215230918454,
                            1.3201814785782566
                        ]
                    },
                    {
                        "Cutouts": [
                            {
                                "Cutout": {
                                    "type": "Hole",
                                    "Radius": 0
                                },
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            }
                        ],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Clip",
                                    "Length": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Bevel",
                                    "Width": 0
                                },
                                "Segment": {
                                    "type": "Linear",
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Raw"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "PencilPolish"
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [
                                    "ClearShield"
                                ],
                                "Front": [
                                    "AcidEtch"
                                ],
                                "Ribbon": {
                                    "Front": "Aquatex",
                                    "Material": "NeoCeram",
                                    "Thickness": 0.4411540459032128,
                                    "Tint": "White"
                                },
                                "Temper": "HeatTempered"
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": 0.23396410404049037
                            },
                            {
                                "Back": [
                                    "Sandblast"
                                ],
                                "Front": [
                                    "AcidEtch"
                                ],
                                "Ribbon": {
                                    "Back": "Seedy",
                                    "Material": "NeoCeram",
                                    "Thickness": 0.1320083486700646,
                                    "Tint": "Bronze"
                                },
                                "Temper": "HeatTempered"
                            }
                        ],
                        "Orient": [
                            9.463146671330266e-2,
                            -0.3932044767706316,
                            -0.5881983293166462,
                            0.7003269596012487
                        ],
                        "Origin": [
                            -2.2664447287675475,
                            1.032595170605475,
                            -0.6910163072458746
                        ]
                    },
                    {
                        "Cutouts": [
                            {
                                "Cutout": {
                                    "type": "Rotate",
                                    "Angle": 0,
                                    "Cutout": {
                                        "type": "Shift",
                                        "Cutout": {
                                            "type": "Shift",
                                            "Cutout": {
                                                "type": "Rotate",
                                                "Angle": 0,
                                                "Cutout": {
                                                    "type": "Hole",
                                                    "Radius": 0
                                                }
                                            },
                                            "Offset": 0
                                        },
                                        "Offset": 0
                                    }
                                },
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            },
                            {
                                "Cutout": {
                                    "type": "Hole",
                                    "Radius": 0
                                },
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            }
                        ],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Ogee"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Round",
                                    "Radius": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "PencilPolish"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [
                                    "Aluminium"
                                ],
                                "Front": [],
                                "Ribbon": {
                                    "Front": "Aquatex",
                                    "Material": "Polycarbonate",
                                    "Thickness": -1.1915739138522612,
                                    "Tint": "White"
                                },
                                "Temper": "HeatTempered"
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": 0.6617856723623745
                            },
                            {
                                "Back": [
                                    "Aluminium",
                                    "AluminiumCuPbFree"
                                ],
                                "Front": [
                                    "Sandblast"
                                ],
                                "Ribbon": {
                                    "Back": "Seedy",
                                    "Front": "Polar",
                                    "Material": "Polycarbonate",
                                    "Thickness": 1.0296676458492147,
                                    "Tint": "Bronze"
                                }
                            }
                        ],
                        "Orient": [
                            -0.3297085424810446,
                            0.4384021176364985,
                            0.5360266288210838,
                            -0.6416940964833346
                        ],
                        "Origin": [
                            -2.6230269479858874,
                            0.3459233957858804,
                            -0.4082219589999499
                        ]
                    },
                    {
                        "Cutouts": [
                            {
                                "Cutout": {
                                    "type": "Hole",
                                    "Radius": 0
                                },
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            },
                            {
                                "Cutout": {
                                    "type": "Shift",
                                    "Cutout": {
                                        "type": "Overlap",
                                        "Cutouts": [
                                            {
                                                "type": "Rect",
                                                "Height": 0,
                                                "Radius": 0,
                                                "Width": 0
                                            }
                                        ]
                                    },
                                    "Offset": 0
                                },
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            }
                        ],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "PencilPolish"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Raw"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Miter",
                                    "Angle": 0
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Raw"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Round",
                                    "Radius": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Bevel",
                                    "Width": 0
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [
                                    "ClearShield",
                                    "Sandblast"
                                ],
                                "Front": [
                                    "AcidEtch"
                                ],
                                "Ribbon": {
                                    "Front": "Rain",
                                    "Material": "NeoCeram",
                                    "Thickness": 1.2808454383078758,
                                    "Tint": "Clear"
                                },
                                "Temper": "HeatTempered"
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": 1.6915258034448333
                            },
                            {
                                "Back": [],
                                "Front": [
                                    "AluminiumCuPbFree"
                                ],
                                "Ribbon": {
                                    "Back": "Rain",
                                    "Front": "Seedy",
                                    "Material": "Acrylic",
                                    "Thickness": 1.715238026883328,
                                    "Tint": "Gray"
                                }
                            }
                        ],
                        "Orient": [
                            2.7934697982784258e-2,
                            -0.66912487789946,
                            0.39071474643493437,
                            -0.6315326890533288
                        ],
                        "Origin": [
                            -1.9217361593009736,
                            -1.4091162480039663,
                            -2.6817382948993345
                        ]
                    },
                    {
                        "Cutouts": [],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Round",
                                    "Radius": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Round",
                                    "Radius": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [
                                    "Sandblast"
                                ],
                                "Front": [],
                                "Ribbon": {
                                    "Front": "Seedy",
                                    "Material": "Acrylic",
                                    "Thickness": 0.38492193518310036,
                                    "Tint": "White"
                                },
                                "Temper": "HeatTempered"
                            }
                        ],
                        "Orient": [
                            -0.14144027840910098,
                            0.6941021501437881,
                            0.4627669469284713,
                            0.5329761773661444
                        ],
                        "Origin": [
                            1.1563600465945375e-2,
                            -1.8716127101598519,
                            -1.0729566947409792
                        ]
                    },
                    {
                        "Cutouts": [
                            {
                                "Cutout": {
                                    "type": "Rotate",
                                    "Angle": 0,
                                    "Cutout": {
                                        "type": "Rotate",
                                        "Angle": 0,
                                        "Cutout": {
                                            "type": "Shift",
                                            "Cutout": {
                                                "type": "Hole",
                                                "Radius": 0
                                            },
                                            "Offset": 0
                                        }
                                    }
                                },
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            },
                            {
                                "Cutout": {
                                    "type": "Overlap",
                                    "Cutouts": [
                                        {
                                            "type": "Rect",
                                            "Height": 0,
                                            "Radius": 0,
                                            "Width": 0
                                        }
                                    ]
                                },
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            }
                        ],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Round",
                                    "Radius": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Ogee"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Round",
                                    "Radius": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Bevel",
                                    "Width": 0
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Round",
                                    "Radius": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Segment": {
                                    "type": "Linear",
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Round",
                                    "Radius": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Bevel",
                                    "Width": 0
                                },
                                "Segment": {
                                    "type": "Linear",
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [
                                    "AcidEtch"
                                ],
                                "Front": [
                                    "Aluminium",
                                    "SafetyBacking"
                                ],
                                "Ribbon": {
                                    "Front": "Aquatex",
                                    "Material": "SodaLime",
                                    "Thickness": -0.23878580565703467,
                                    "Tint": "Clear"
                                },
                                "Temper": "HeatTempered"
                            }
                        ],
                        "Orient": [
                            -0.567220631874454,
                            0.6624905753446048,
                            -0.37314916660197267,
                            0.31642801996626785
                        ],
                        "Origin": [
                            -0.8796418498074845,
                            -2.726342914708323,
                            2.4742254337129803
                        ]
                    },
                    {
                        "Cutouts": [
                            {
                                "Cutout": {
                                    "type": "Hole",
                                    "Radius": 0
                                },
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            }
                        ],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Segment": {
                                    "type": "Linear",
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [
                                    "Sandblast"
                                ],
                                "Front": [
                                    "AcidEtch"
                                ],
                                "Ribbon": {
                                    "Front": "Rain",
                                    "Material": "SodaLime",
                                    "Thickness": 1.0563505748925484,
                                    "Tint": "Clear"
                                }
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": -1.4020444426695304
                            },
                            {
                                "Back": [
                                    "Aluminium"
                                ],
                                "Front": [
                                    "ClearShield",
                                    "SafetyBacking"
                                ],
                                "Ribbon": {
                                    "Back": "Seedy",
                                    "Front": "Polar",
                                    "Material": "SodaLime",
                                    "Thickness": -1.514940188685774,
                                    "Tint": "Bronze"
                                },
                                "Temper": "HeatTempered"
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": -0.6069044427475834
                            },
                            {
                                "Back": [
                                    "Sandblast"
                                ],
                                "Front": [],
                                "Ribbon": {
                                    "Material": "SodaLime",
                                    "Thickness": -0.20300549785316943,
                                    "Tint": "White"
                                },
                                "Temper": "HeatTempered"
                            }
                        ],
                        "Orient": [
                            -0.41035212263565757,
                            0.5397124914531669,
                            0.7349647151498134,
                            1.2183164719358121e-2
                        ],
                        "Origin": [
                            0.31842715627673396,
                            2.2475661641855957,
                            -0.24768120794883283
                        ]
                    },
                    {
                        "Cutouts": [
                            {
                                "Cutout": {
                                    "type": "Rect",
                                    "Height": 0,
                                    "Radius": 0,
                                    "Width": 0
                                },
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            },
                            {
                                "Cutout": {
                                    "type": "Rect",
                                    "Height": 0,
                                    "Radius": 0,
                                    "Width": 0
                                },
                                "Edging": {
                                    "type": "FlatPolish"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            }
                        ],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "PencilPolish"
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [
                                    "AcidEtch",
                                    "SafetyBacking"
                                ],
                                "Front": [
                                    "ClearShield",
                                    "SafetyBacking"
                                ],
                                "Ribbon": {
                                    "Back": "Polar",
                                    "Front": "Seedy",
                                    "Material": "SodaLime",
                                    "Thickness": 0.16365428785396458,
                                    "Tint": "Bronze"
                                }
                            },
                            {
                                "Laminate": "Vinyl",
                                "Thickness": -5.791918709553234e-3
                            },
                            {
                                "Back": [
                                    "AluminiumCuPbFree",
                                    "SafetyBacking"
                                ],
                                "Front": [],
                                "Ribbon": {
                                    "Front": "Aquatex",
                                    "Material": "Acrylic",
                                    "Thickness": 1.5233542312509927,
                                    "Tint": "Bronze"
                                },
                                "Temper": "HeatTempered"
                            }
                        ],
                        "Orient": [
                            0.4096658319243915,
                            -0.763288884333104,
                            3.422850248187503e-2,
                            -0.4983898020877533
                        ],
                        "Origin": [
                            -2.2604223597503172,
                            -1.8444675928244112,
                            1.5497449779971892
                        ]
                    },
                    {
                        "Cutouts": [
                            {
                                "Cutout": {
                                    "type": "Rotate",
                                    "Angle": 0,
                                    "Cutout": {
                                        "type": "Hole",
                                        "Radius": 0
                                    }
                                },
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            },
                            {
                                "Cutout": {
                                    "type": "Rect",
                                    "Height": 0,
                                    "Radius": 0,
                                    "Width": 0
                                },
                                "Edging": {
                                    "type": "Seamed"
                                },
                                "Origin": [
                                    0,
                                    0
                                ]
                            }
                        ],
                        "Edges": [
                            {
                                "Corner": {
                                    "type": "Clip",
                                    "Length": 0
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Miter",
                                    "Angle": 0
                                },
                                "Segment": {
                                    "type": "Cubic",
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "P2": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Raw"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Raw"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "Miter",
                                    "Angle": 0
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            },
                            {
                                "Corner": {
                                    "type": "Eased"
                                },
                                "Cutouts": [],
                                "Edging": {
                                    "type": "PencilPolish"
                                },
                                "Segment": {
                                    "type": "Conic",
                                    "F1": [
                                        0,
                                        0
                                    ],
                                    "P1": [
                                        0,
                                        0
                                    ],
                                    "To": [
                                        0,
                                        0
                                    ]
                                }
                            }
                        ],
                        "Material": [
                            {
                                "Back": [
                                    "SafetyBacking",
                                    "Sandblast"
                                ],
                                "Front": [],
                                "Ribbon": {
                                    "Back": "Seedy",
                                    "Front": "Rain",
                                    "Material": "Acrylic",
                                    "Thickness": -1.3416620954332432,
                                    "Tint": "White"
                                },
                                "Temper": "HeatTempered"
                            }
                        ],
                        "Orient": [
                            0.5755049346218226,
                            -0.3136756255198712,
                            -0.7244218148408411,
                            0.2135759966928663
                        ],
                        "Origin": [
                            1.9588226628399754,
                            2.2326260792147217,
                            -2.4490335967721535
                        ]
                    }
                ]
            },
            "Name": "Entryway Mirror"
        }
    ],
    "Name": "44 Elm Blvd"
}