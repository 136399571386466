import React, { Dispatch } from 'react';
import { Template } from '~/src/design/template';

/**
 * Actions
 */
export enum BULK_DESIGN_VIEW_MODE {
	DEFAULT,
	LIST_VIEW,
	DESIGN,
	PRODUCTION_DOCUMENTS,
	ADDITIONAL_FIELD,
	MORE_SPACE,
}

export const SET_SERVER_TEMPLATES = 'SET_SERVER_TEMPLATES';
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const TOGGLE_VIEW_MODE = 'TOGGLE_VIEW_MODE';

/**
 * Initial State
 */
export interface AlertDataType {
	open: boolean;
	title: string;
	description?: string;
	autoDuration?: number;
}
export interface BulkDesignStore {
	mainViewMode: BULK_DESIGN_VIEW_MODE;
	serverTemplates: Template[];
	alertProps: AlertDataType;
	dispatch: Dispatch<BulkDesignAction>;
}
export const INITIAL_STATE: BulkDesignStore = {
	mainViewMode: BULK_DESIGN_VIEW_MODE.DEFAULT,
	serverTemplates: [],
	alertProps: {
		open: false,
		title: '',
		description: '',
		autoDuration: 6000,
	},
	dispatch: () => ({})
};

/**
 * Context
 */
const BulkDesignContext = React.createContext<BulkDesignStore>(INITIAL_STATE);

export { BulkDesignContext };

/**
 * Reducer
 */
export interface BulkDesignAction {
	type: string;
	data: Template[] | Template | AlertDataType | BULK_DESIGN_VIEW_MODE | undefined;
}

export const reducer = (
	state: BulkDesignStore,
	action: BulkDesignAction
): BulkDesignStore => {
	switch (action.type) {case SET_SERVER_TEMPLATES:
		return {
			...state,
			serverTemplates: action.data as Template[]
		};
	case SHOW_ALERT:
	case HIDE_ALERT:
		return {
			...state,
			alertProps: {
				...state.alertProps,
				...action.data as AlertDataType,
			}
		};
	case TOGGLE_VIEW_MODE:
		return {
			...state,
			mainViewMode: action.data === state.mainViewMode ? BULK_DESIGN_VIEW_MODE.DEFAULT : action.data as BULK_DESIGN_VIEW_MODE,
		};
	default:
		return state;
	}
};
