import * as Either from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { map, NonEmptyArray } from 'fp-ts/lib/NonEmptyArray';
import { array, failure, Type, success } from 'io-ts';

export { map } from 'fp-ts/lib/NonEmptyArray';

/**
 * NonEmpty<T> is an `Array<T>` with at least one element.
 *
 * @since 0.1.1
 */
export type NonEmpty<T> = NonEmptyArray<T>;

/**
 * Codec for NonEmpty.
 *
 * @since 0.1.1
 * @category NonEmpty
 */
export const NonEmpty = <T,S>(codecT: Type<T,S>) => new Type<NonEmpty<T>,NonEmpty<S>>(
	`NonEmpty<${codecT.name}`,
	isNonEmpty(codecT),
	(input, context) => pipe(
		array(codecT).validate(input, context),
		Either.chain(xs => refineNonEmpty(xs) ? success(xs) : failure(xs, context, 'Empty Array')),
	),
	(xs: NonEmpty<T>) => map((x: T) => codecT.encode(x))(xs),
);
const isNonEmpty = <T,S>(codecT: Type<T,S>) => (x: unknown): x is NonEmpty<T> => array(codecT).is(x) && x.length > 0;

/**
 * Refine a list into a non-empty list, if it is indeed non-empty.
 *
 * @category NonEmpty
 */
export const refineNonEmpty = <T>(x: T[]): x is NonEmpty<T> => x.length > 0;

/**
 * Get the last item of a nonempty list.
 */
export const last = <T>(xs: NonEmpty<T>): T =>
	xs[xs.length-1] as T;

/**
 * Rotate a nonempty array by `i` elements.
 *
 * The element with index `i` becomes the new element 0.
 */
export const rotate = (i: number) => <T>(xs: NonEmpty<T>): NonEmpty<T> =>
	[...xs.slice(i, xs.length), ...xs.slice(0, i)] as NonEmpty<T>;

/**
 * Extract the `i`th element, along with the preceeding and following lists.
 *
 * Clamps `i` to the range `0` to `length-1`.
 */
export const extract = (i: number) => <T>(ys: NonEmpty<T>): [T[], T, T[]] => {
	const y = ys[i];
	return y !== undefined ? [ys.slice(0, i), y, ys.slice(i+1)]
		: i < 0 ? [[], ys[0], ys.slice(1)] : [ys.slice(0, -1), last(ys), []];
};

/**
 * Append an item to an Array to get a NonEmpty array.
 */
export const snoc = <T>(xs: T[], x: T): NonEmpty<T> =>
	[...xs, x] as unknown as NonEmpty<T>;
