import { Lens, Optional, Prism } from 'monocle-ts';
import { Distance } from '~/src/model/Numbers';
import * as O from 'fp-ts/lib/Option';

import { Directed, Angle, Minor, Major, Mag, MajorMeasure, Curvature, Circular, Measurement, MinorMeasure } from '~/src/design/opening/measurement';

import * as A from '~/src/geometry/angle';
import { optionalT } from '~/src/optics/Common';

/**
 * View and Edit the number within an Major.
 *
 * @category Major
 */
export const majorValue: Lens<Major,Distance> = Lens.fromProp<Major>()('Major');

/**
 * View and Edit the `Major` in a `Measurement`.
 *
 * @category Major
 */
export const measureMajor = <S extends keyof Directed>(): Lens<Measurement<S>, Major> =>
	new Lens(getMajor, (a) => (m) => ({ ...m, Major: a }));

const getMajor = <S extends keyof Directed>(m: Measurement<S>): Major =>
	Major.is(m.Major) ? m.Major : getMajorFromMagMinor(m.Major, m.Minor);

const getMajorFromMagMinor = (mag: Mag, minor?: MinorMeasure): Major => ({ Major:
	Minor.is(minor) ? Math.sqrt(mag.Mag*mag.Mag - minor.Minor*minor.Minor) :
		Angle.is(minor) ? mag.Mag * A.cos(minor.Angle) :
			0 });

/**
 * View and Edit the number within an Mag.
 *
 * @category Major
 */
export const magValue: Lens<Mag,Distance> = Lens.fromProp<Mag>()('Mag');

/**
 * View and Edit the `Mag` of a `Measurement`.
 *
 * @category Major
 */
export const measureMag = <S extends keyof Directed>(): Lens<Measurement<S>, Mag> =>
	new Lens(getMag, (a) => (m) => ({ ...m, Major: a }));

const getMag = <S extends keyof Directed>(m: Measurement<S>): Mag =>
	Mag.is(m.Major) ? m.Major : getMagFromMajorMinor(m.Major, m.Minor);

const getMagFromMajorMinor = (major: Major, minor?: MinorMeasure): Mag => ({ Mag:
	Minor.is(minor) ? Math.sqrt(major.Major*major.Major + minor.Minor*minor.Minor) :
		Angle.is(minor) ? major.Major / A.cos(minor.Angle) :
			0 });

/**
 * View and Edit the number within an Minor.
 *
 * @category Minor
 */
export const minorValue: Lens<Minor,number> = Lens.fromProp<Minor>()('Minor');

/**
 * View and Edit the `Minor` in a `Measurement`.
 *
 * @category Minor
 */
export const measureMinor = <S extends keyof Directed>(): Lens<Measurement<S>, Minor> =>
	new Lens(getMinor, (a) => (m) => ({ ...m, Minor: a }));

const getMinor = <S extends keyof Directed>(m: Measurement<S>): Minor =>
	Minor.is(m.Minor) ? m.Minor :
		Angle.is(m.Minor) ? getMinorFromAngleMajor(m.Minor, m.Major) :
			{Minor: 0};

const getMinorFromAngleMajor = (angle: Angle, major?: MajorMeasure): Minor => ({ Minor:
	Major.is(major) ? major.Major * A.tan(angle.Angle) :
		Mag.is(major) ? major.Mag * A.sin(angle.Angle) :
			0 });

/**
 * View and Edit the number within an Angle.
 *
 * @category Minor
 */
export const angleValue: Lens<Angle,A.Angle> = Lens.fromProp<Angle>()('Angle');

/**
 * View and Edit the `Angle` in a `Measurement`.
 *
 * @category Minor
 */
export const measureAngle = <S extends keyof Directed>(): Lens<Measurement<S>, Angle> =>
	new Lens(getAngle, (a) => (m) => ({ ...m, Minor: a }));

const getAngle = <S extends keyof Directed>(m: Measurement<S>): Angle =>
	Angle.is(m.Minor) ? m.Minor :
		Minor.is(m.Minor) ? getAngleFromMinorMajor(m.Minor.Minor, m.Major) :
			{Angle: 0};

const getAngleFromMinorMajor = (minor: number, major?: MajorMeasure): Angle => ({ Angle:
	Major.is(major) ? A.atan(minor / major.Major) :
		Mag.is(major) ? A.asin(minor / major.Mag) :
			0 });

/**
 * View and Edit the major priority within a Measurement.
 *
 * @category Major
 */
export const measureMajPri = <S extends keyof Directed>(): Lens<Measurement<S>, number> => Lens.fromNullableProp<Measurement<S>>()('MajorPri', 0);

/**
 * View and Edit the minor priority within a Measurement.
 *
 * @category Minor
 */
export const measureMinPri = <S extends keyof Directed>(): Lens<Measurement<S>, number> => Lens.fromNullableProp<Measurement<S>>()('MinorPri', 0);

/**
 * View and Edit the curvature within a Measurement.
 *
 * @category Curvature
 */
export const measureCurvature = <S extends keyof Directed>(): Lens<Measurement<S>, Curvature | undefined> => Lens.fromProp<Measurement<S>>()('Curvature');

export const curvaturePrism = new Prism<Curvature | undefined, Curvature>(
	(s) => Curvature.is(s) ? O.some(s) : O.none,
	(a) => a
);

/**
 * Focus on defined Curvature in Measurement
 *
 * @category Curvature
 */
export const definedCurvature = <S extends keyof Directed>() => measureCurvature<S>().composePrism(curvaturePrism);

/**
 * View and Edit the number in a Curvature.
 *
 * @category Curvature
 */
export const curvatureValue = new Lens<Curvature, number>(
	(s) => Circular.is(s) ? s.Deflection : s.Deflection,
	(a) => (s) => Circular.is(s) ? { type: 'Circular', Deflection: a } : { type: 'Sinusoidal', Deflection: a },
);

/**
 * Focus on `Major` in Measurement
 *
 * @category Major
 */
export const major = <S extends keyof Directed>() => Lens.fromProp<Measurement<S>>()('Major');

/**
 * Focus on `Minor` in Measurement
 *
 * @category Major
 */
export const minor = <S extends keyof Directed>() => Lens.fromProp<Measurement<S>>()('Minor');

export const minorPrism = new Prism<MinorMeasure | undefined, MinorMeasure>(
	(s) => MinorMeasure.is(s) ? O.some(s) : O.none,
	(a) => a
);

/**
 * Focus on defined Minor in Measurement
 *
 * @category Measurement
 */
export const definedMinorMeasurement = <S extends keyof Directed>(): Lens<Measurement<S>,MinorMeasure> =>
	minor<S>().compose(optionalT<MinorMeasure>({ Minor: 0 }));

/**
 * Focus on major priority in Measurement
 *
 * * @category Major
 */
export const majorPri: <S extends keyof Directed>() => Lens<Measurement<S>, number> = <S extends keyof Directed>() => Lens.fromNullableProp<Measurement<S>>()('MajorPri', 0);

/**
 * Focus on minor priority in Measurement
 *
 * * @category Major
 */
export const minorPri: <S extends keyof Directed>() => Lens<Measurement<S>, number> = <S extends keyof Directed>() => Lens.fromNullableProp<Measurement<S>>()('MinorPri', 0);

/**
 * View and edit an optional Major from a MajorMeasure
 *
 * @category Major
 */
export const majorMajorMeasure: Optional<MajorMeasure, Major> = new Optional<MajorMeasure, Major>(
	(s) => Major.is(s) ? O.some(s) : O.none,
	(a) => (_) => a
);

/**
 * View and edit an optional Mag from a MajorMeasure
 *
 * @category Major
 */
export const magMajorMeasure: Optional<MajorMeasure, Mag> = new Optional<MajorMeasure, Mag>(
	(s) => Mag.is(s) ? O.some(s) : O.none,
	(a) => (_) => a
);

/**
 * View and edit an optional Major from a MinorMeasure
 *
 * @category Minor
 */
export const minorMinorMeasure: Optional<MinorMeasure, Minor> = new Optional<MinorMeasure, Minor>(
	(s) => Minor.is(s) ? O.some(s) : O.none,
	(a) => (_) => a
);

/**
 * View and edit an optional Angle from a MinorMeasure
 *
 * @category Minor
 */
export const angleMinorMeasure: Optional<MinorMeasure, Angle> = new Optional<MinorMeasure, Angle>(
	(s) => Angle.is(s) ? O.some(s) : O.none,
	(a) => (_) => a
);

export const minorMeasure: Lens<MinorMeasure, number> = new Lens<MinorMeasure, number>(
	(s) => Minor.is(s) ? s.Minor : s.Angle,
	(a) => (s) => Minor.is(s) ? { Minor: a } : { Angle: a }
);

export const majorMeasure: Lens<MajorMeasure, number> = new Lens<MajorMeasure, number>(
	(s) => Major.is(s) ? s.Major : s.Mag,
	(a) => (s) => Major.is(s) ? { Major: a } : { Mag: a }
);
