/**
 * Lightweight optics with memoization.
 */

import { constant, Fn, Is, Mod } from '~/src/base/Function';
import { mem } from '~/src/base/Memoize';

/**
 * Modify a value of type `A` inside of type `T`.
 */
export type Optic<T,A> = Fn<Mod<A>,Mod<T>>;

/**
 * Use the function `f` to modify the field `key` of an object.
 *
 * The key is memoized, so `dot('a') === dot('a')`.
 */
export const dot: <K extends string>(key: K) => <A>(f: Mod<A>) => <T extends {[key in K]: A}>(x: T) => T =
	mem(<K extends string>(key: K) => <A>(f: Mod<A>) => <T extends {[key in K]: A}>(x: T): T => {
		const oldv = x[key];
		const v = f(oldv);
		return v === oldv ? x : Object.assign({}, x, {[key]: v});
	});

/**
 * Use the function `f` to modify the partial field `key` of an object.
 *
 * The key is memoized, so `pdot('a') === pdot('a')`.
 */
export const pdot: <K extends string>(key: K) => <A>(f: Mod<A|undefined>) => <T extends {[key in K]?: A}>(x: T) => T =
	mem(<K extends string>(key: K) => <A>(f: Mod<A|undefined>) => <T extends {[key in K]?: A}>(x: T): T => {
		const oldv = x[key];
		const v = f(oldv);
		return v === oldv ? x : Object.assign({}, x, {[key]: v});
	});

/**
 * Use the function `f` to modify the `i`th element of an array.
 *
 * The index is memoized.
 */
export const index: <I extends number>(i: I) => <A>(f: Mod<A>) => <T extends A[]>(xs: T) => T =
	mem(<I extends number>(i: I) => <A>(f: Mod<A>) => <T extends A[]>(xs: T): T => {
	// we check the length instead of the value because A could extend undefined.
		if (xs.length <= i) return xs;
		const oldv = xs[i] as A;
		const v = f(oldv);
		if (v === oldv) return xs;
		const ys = xs.slice() as T;
		ys[i] = v;
		return ys;
	});

/**
 * Modify each value of a list-like structure that matches a predicate.
 *
 * The predicate is memoized, but the modifiying function is not.
 *
 */
export const each: <A>(predicate: Fn<A,boolean>) => (f: Mod<A>) => <T extends A[]>(xs: T) => T =
	mem(<A>(predicate: Fn<A,boolean>) => (f: Mod<A>) => <T extends A[]>(xs: T): T => {
		let diff = false;
		const ys = xs.map((x) => {
			if (!predicate(x)) return x;
			const y = f(x);
			if (y !== x) {
				diff = true;
				return y;
			}
			return x;
		});
		return diff ? ys as T : xs;
	});

/**
 * Modify every value of a list-like structure.
 *
 */
export const every: <A>(f: Mod<A>) => <T extends A[]>(xs: T) => T =
	mem(<A>(f: Mod<A>) => <T extends A[]>(xs: T): T => {
		let diff = false;
		const ys = xs.map((x) => {
			const y = f(x);
			if (y !== x) {
				diff = true;
				return y;
			}
			return x;
		});
		return diff ? ys as T : xs;
	});

/**
 * Modify some `T` as if it were an `A`, if it is in fact an `A`.
 *
 * The test for the `T` being an `A` is memoized.
 *
 * This is often refered to as a prism.
 */
export const where: <T,A extends T>(t: Is<T,A>) => Optic<T,A> =
	mem(<T,A extends T>(t: Is<T,A>) => (f: Mod<A>) => (x: T): T => t(x) ? f(x) : x);

/**
 * Modify a possibly undefined value, providing a default value for undefined.
 *
 * The default is memoized.
 */
export const withDefault = mem(<T>(def: T) => (f: Mod<T>) => (x: T|undefined): T|undefined => {
	const y = x === undefined ? def : x;
	const v = f(y);
	return v === def ? undefined : v === y ? x : v;
});

export const optionalString = withDefault('' as string);

/**
 * Use an optic to set the inner field (of type `A`) to `x`.
 *
 * The optic and the value to set are memoized.
 */
export const set: <A,T>(optic: Optic<T,A>) => (x: A) => Mod<T> =
	mem(<A,T>(optic: Optic<T,A>) => mem((x: A): Mod<T> => optic(constant(x))));

/**
 * Use an optic to get out all the inner values it points to in an outer value.
 */
export const get = <T,A>(optic: Optic<T,A>) => (x: T): A[] => {
	const ys: A[] = [];
	optic((y: A) => { ys.push(y); return y; })(x);
	return ys;
};

/**
 * Use an optic to get out the first inner value it points to.
 *
 * Note that this is only weakly checked, and you must ensure that your optic actually always returns a value.
 * If in doubt, use `get`.
 */
export const get1 = <T,A>(optic: Optic<T,A>) => (x: T): A =>
	get(optic)(x)[0] as A;

/**
 * Modify a value only if it is defined.
 *
 * The modifier is memoized.
 */
export const maybe: <T>(f: Mod<T>) => Mod<T|undefined> =
	<T>(f: Fn<T,T>) => (x: T|undefined) =>
		x === undefined ? undefined : f(x);

/**
 * Log the modification.
 */
export const warn = <A>(f: Fn<A,A>) => (x: A): A => {
	const y = f(x);
	console.warn(x, 'to', y);
	return y;
};
