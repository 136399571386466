/**
 * Polymorphic Opening Types and Guards.
 *
 * @since 0.1.0
 */
import { array, interface as iface, intersection, number, null as cnull, partial, recursion, Type, TypeOf, keyof, union, string, literal } from 'io-ts';
import { boolTrue } from '~/src/model/type-helpers';
import { Angle, Distance } from '~/src/model/Numbers';
import { OneOrUptoThree } from '~/src/model/Boxes';
import { Measurement, Directed, ShiftLocation } from '~/src/design/opening/measurement';


/**
 * An `Opening` in a structure, paramaterized by information at various places.
 *
 * @since 0.1.0
 * @category Opening
 */
export interface Opening<A,B,C,D> {
	Info: D;
	Left: Stretch<'Left',A>;
	Section: SectionTree<A,B,C>;
}
/**
 * Codec for `Opening`.
 *
 * @since 0.1.0
 * @category Opening
 */
export const Opening = <A,B,C,D>(codecA: Type<A>, codecB: Type<B>, codecC: Type<C>, codecD: Type<D>): Type<Opening<A,B,C,D>> =>
	iface({
		Info: codecD,
		Left: Stretch('Left', codecA),
		Section: SectionTree(codecA, codecB, codecC),
	});

/**
 * The tree of `Section`s on an `Opening`.
 *
 * @since 0.1.0
 * @category Section
 */
export interface SectionTree<A,B,C> extends Section<A,B,C> {
	Rest: OneOrUptoThree<Wall<A,B>,SectionTree<A,B,C>>;
}
/**
 * Codec for `SectionTree`.
 *
 * @since 0.1.0
 * @category Section
 */
export const SectionTree = <A,B,C>(codecA: Type<A>, codecB: Type<B>, codecC: Type<C>): Type<SectionTree<A,B,C>> =>
	recursion('SectionTree', () =>
		intersection([
			Section(codecA, codecB, codecC),
			iface({
				Rest: OneOrUptoThree(Wall(codecA, codecB), SectionTree(codecA, codecB, codecC)),
			})
		])
	);

/**
 * Walls at the right hand side / bottom of a `SectionTree`.
 *
 * @since 0.1.0
 * @category Section
 */
export interface Wall<A,B> {
	Angle: Joint<B>;
	Right: Stretch<'Right',A>
}
/**
 * Codec for `Wall`.
 *
 * @since 0.1.0
 * @category Section
 */
export const Wall = <A,B>(codecA: Type<A>, codecB: Type<B>): Type<Wall<A,B>> =>
	iface({
		Angle: Joint(codecB),
		Right: Stretch('Right', codecA),
	});

/**
 * A three dimensional view of a straight `Section` of floor and ceiling.
 *
 * @since 0.1.0
 * @category Section
 */
export interface Section<A,B,C> {
	Angle: Joint<B>;
	Ceiling: Stretch<'Top',A>;
	Info: C;
	Floor: Stretch<'Bottom',A>;
}
/**
 * Codec for `Section`.
 *
 * @since 0.1.0
 * @category Section
 */
export const Section = <A,B,C>(codecA: Type<A>, codecB: Type<B>, codecC: Type<C>): Type<Section<A,B,C>> =>
	iface({
		Angle: Joint(codecB),
		Ceiling: Stretch('Top', codecA),
		Info: codecC,
		Floor: Stretch('Bottom', codecA),
	});

/**
 * A `Joint` between `Section's
 *
 * @since 0.1.0
 * @category Section
 */
export interface Joint<A> {
	Angle: Angle;
	Corner: Join;
	Info: A;
}
/**
 * Codec for `Joint`.
 *
 * @since 0.1.0
 * @category Section
 */
export const Joint = <A>(codecA: Type<A>): Type<Joint<A>> =>
	iface({
		Angle: Angle,
		Corner: Join,
		Info: codecA,
	});

/**
 * Joints on curbs can be various shapes.
 *
 * @since 0.1.0
 * @category Section
 */
export const Join = keyof({
	'miter': true,
	'round': true,
	'bevel': true,
});
/**
 * Codec for `Join`.
 *
 * @since 0.1.0
 * @category Section
 */
export type Join = TypeOf<typeof Join>;

/**
 * A straight `Stretch` of `Curb` along some side.
 *
 * @since 0.1.0
 * @category Curb
 */
export interface Stretch<S extends keyof Directed,A> extends CurbEdge {
	Curbs: Curb<S,A>[];
}
/**
 * Codec for `Stretch`.
 *
 * @since 0.1.0
 * @category Curb
 */
export const Stretch = <S extends keyof Directed,A>(s: S, codecA: Type<A>): Type<Stretch<S,A>> =>
	intersection([
		CurbEdge,
		iface({
			Curbs: array(Curb(s, codecA)),
		})
	]);

/**
 * A individually specified piece of `Curb`.
 *
 * @since 0.1.0
 * @category Curb
 */
export interface Curb<S extends keyof Directed,A> extends CurbEdge {
	'!mid'?: true;
	Info: A;
	Measure: Measurement<S>;
	Anomalies?: Anomaly[]
}
/**
 * Codec for `Curb`.
 *
 * @since 0.1.0
 * @category Curb
 */
export const Curb = <S extends keyof Directed,A>(s: S, codecA: Type<A>): Type<Curb<S,A>> =>
	intersection([
		CurbEdge,
		partial({
			'!mid': boolTrue,
			Anomalies: array(Anomaly)
		}),
		iface({
			Info: codecA,
			Measure: Measurement(s),
		})
	]);

/**
 * Offsets to/from a `Location`.
 *
 * @since 0.4.0
 * @category Offset
 */
export interface Shift {
	Offset: number | null;
	Location: ShiftLocation;
}
/**
 * Codec for `Shift`.
 *
 * @since 0.1.0
 * @category Shift
 */
export const Shift: Type<Shift> =
	iface({
		Offset: union([number, cnull]),
		Location: ShiftLocation,
	});

/**
 * Location for the start of a measurement that is dependent
 * on a direction
 *
 * @category OffsetLocation
 */
export type OffsetLocation = 'start' | 'end';

/**
 * Codec for `OffsetLocation`.
 *
 * @category OffsetLocation
 */
export const OffsetLocation: Type<OffsetLocation> = keyof({
	start: true,
	end: true,
});

/**
 * Offsets to/from a `OffsetLocation`.
 *
 * @category Offset
 */
export interface Offset {
	Offset: number;
	Location: OffsetLocation;
}
/**
 * Codec for `Offset`.
 *
 * @category Offset
 */
export const Offset: Type<Offset> =
	iface({
		Offset: number,
		Location: OffsetLocation,
	});

/**
 * Measurements from the outside to inside of the `Curb`.
 *
 * @since 0.1.0
 * @category Curb
 */
export interface CurbEdge {
	Bredth: Measurement<'In'> | null;
	Offset: Shift | null;
	OuterHeight: number | null;
}
/**
 * Codec for `CurbEdge`.
 *
 * @since 0.1.0
 * @category Curb
 */
export const CurbEdge: Type<CurbEdge> =
	iface({
		Bredth: union([Measurement('In'), cnull]),
		Offset: union([Shift, cnull]),
		OuterHeight: union([number, cnull]),
	});

/**
 * Protrusion or intrustion on curb surface.
 *
 * @category Curb
 */
export interface Anomaly {
	Offset: Offset,
	Depth: number,
	Size: Distance,
	Width?: Distance,
	Description?: string
}

/**
 * Codec for `Anomaly`.
 *
 * @category Curb
 */
export const Anomaly: Type<Anomaly> = intersection([
	iface({
		Offset: Offset,
		Depth: number,
		Size: Distance,
	}),
	partial({
		Width: Distance,
		Description: string
	}),
]);

/**
 * A single step of an index of a `SectionTree`.
 *
 * @since 0.1.0
 * @category SectionTree
 */
export type SectionStep = 0 | 1 | 2;

export const SectionStep: Type<SectionStep> = union([literal(0), literal(1), literal(2)]);
