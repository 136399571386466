import React, {
	KeyboardEvent,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import classNames from 'classnames';
import { TreeDataItem, UpdatingFieldProps } from './TemplateTreeView';
import { Path } from '~/src/pages/bulk-design';
import { Box } from '@mui/material';

interface TreeNodeLabelProps {
	node: TreeDataItem;
	path: Path | undefined;
	editMode: boolean;
	showChildren: boolean;
	editModeSelected: boolean | undefined;
	setFieldBasedOnPath: UpdatingFieldProps;
	onShowChildren: () => void;
	onSelectChecbox: () => void;
}

const TreeNodeLabel = ({
	node,
	path,
	editMode,
	showChildren,
	editModeSelected,
	setFieldBasedOnPath,
	onShowChildren,
	onSelectChecbox,
}: TreeNodeLabelProps) => {
	const [isNameEditing, setIsNameEditing] = useState(false);

	const contentNameEditableRef = useRef<HTMLElement | null>(null);

	const { Name } = node;

	const offset = useMemo(() => {
		return `${
			10 + 22 * ((path || []).length - 1) + ('Item' in node ? 8 : 0)
		}px`;
	}, [path, node]);

	const hasDivider = (path || []).length > 1 && 'Item' in node;

	const handleNameDoubleClick = () => {
		setIsNameEditing(true);
	};

	const handleNameBlur = () => {
		if (path) {
			setFieldBasedOnPath(
				path,
				'Name',
				contentNameEditableRef.current?.innerText || ''
			);
			setIsNameEditing(false);
		}
	};

	const handleEnterPressOnName = (e: KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (path) {
				setFieldBasedOnPath(path, 'Name', e.currentTarget.textContent || '');
				setIsNameEditing(false);
			}
		}
	};

	useEffect(() => {
		if (isNameEditing) {
			contentNameEditableRef.current?.focus();
		}
	}, [isNameEditing]);

	return (
		<Box
			className={classNames('tree-label', {
				['tree-label--divider']: hasDivider,
			})}
			style={{ '--tree-label-offset': offset } as React.CSSProperties}
			overflow="hidden"
		>
			{'Items' in node && !showChildren && (
				<img
					onClick={() => !editMode && onShowChildren()}
					className="mx-2"
					src="/img/treeview/tooltip-hidden.svg"
				/>
			)}
			{'Items' in node && showChildren && (
				<img
					onClick={() => !editMode && onShowChildren()}
					className="mx-2 tooltip-show"
					src="/img/treeview/tooltip-show.svg"
				/>
			)}
			<img
				onClick={() => onSelectChecbox()}
				className={`${editMode && !editModeSelected ? '' : 'd-none'} me-2`}
				style={{ marginLeft: '2px' }}
				src="/img/treeview/checkbox-o.svg"
			/>
			<img
				onClick={() => onSelectChecbox()}
				className={`${editMode && editModeSelected ? '' : 'd-none'}`}
				style={{ marginRight: '2px' }}
				src="/img/treeview/checkbox-new.svg"
			/>
			{'Items' in node && !showChildren && (
				<img
					onClick={() => !editMode && onShowChildren()}
					className="me-2"
					src="/img/treeview/folder-close.svg"
				/>
			)}
			{'Items' in node && showChildren && (
				<img
					onClick={() => !editMode && onShowChildren()}
					className="me-2"
					src="/img/treeview/folder-open.svg"
				/>
			)}
			<Box
				component="span"
				textOverflow="ellipsis"
				whiteSpace="nowrap"
				overflow="hidden"
				onDoubleClick={handleNameDoubleClick}
				onBlur={handleNameBlur}
				onKeyDown={handleEnterPressOnName}
				contentEditable={isNameEditing}
				ref={contentNameEditableRef}
				dangerouslySetInnerHTML={{ __html: Name }}
			/>
		</Box>
	);
};

export default TreeNodeLabel;
