.template-mod {
}

.wrap {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.template-mod .template-viewer {
  min-height: 400px;
}
