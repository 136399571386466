import React, { useContext } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Quote } from '~/src/pricing/quote';
import { Charge } from '~/src/pricing/quote/change';
import { BulkDesignContext, BULK_DESIGN_VIEW_MODE } from '~/src/design/bulk/store';

interface ColumnProp {
	id: string;
	label: string;
	width: string;
	align?: 'left' | 'center' | 'right';
	render?: (quote: Quote | Charge | undefined) => React.ReactNode;
}

interface TemplateTreeViewFooterProps {
	quote: Quote;
}

const TemplateTreeViewFooter = ({ quote }: TemplateTreeViewFooterProps) => {
	const { mainViewMode } = useContext(BulkDesignContext);

	const columns: ColumnProp[] = [
		{
			id: 'price',
			width: '90px',
			label: 'Price',
			align: 'right',
			render: (quote) => (
				<Typography fontSize={'14px'} fontFamily={'Inter'}>
					$ {quote?.Total.USD}
				</Typography>
			),
		},
		{
			id: 'count',
			width: '168px',
			label: 'Price',
			align: 'center',
			render: () => (
				<Typography fontSize={'14px'} fontFamily={'Inter'}>
					0
				</Typography>
			),
		},
	];

	return (
		<Grid justifyContent={'space-between'} className="side-bar-bottom">
			<Typography fontSize={'14px'} fontFamily={'Inter'}>
				Job / Project Total
			</Typography>
			{mainViewMode === BULK_DESIGN_VIEW_MODE.LIST_VIEW ? (
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'auto auto',
						alignItems: 'center',
					}}
				>
					{columns.map((column) => (
						<Box
							key={column.id}
							style={{
								display: 'flex',
								padding: '0 0.25rem',
								width: column.width,
								minWidth: column.width,
								maxWidth: column.width,
								borderLeft: '1px solid #EDF2F7',
								justifyContent: column.align || 'center',
								alignItems: 'center',
								boxSizing: 'border-box',
							}}
						>
							{column.render ? column.render(quote) : column.label}
						</Box>
					))}
				</Box>
			) : (
				<Typography fontSize={'14px'} fontFamily={'Inter'}>
					$ {quote.Total.USD}
				</Typography>
			)}
		</Grid>
	);
};

export default TemplateTreeViewFooter;
