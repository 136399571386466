/**
 * Authenication Paramaters
 *
 * @since 0.4.0
 */
import { type, string, Type, union } from 'io-ts';

/**
 * Glassd Authentication Key Pair.
 *
 * @since 0.4.0
 */
export interface TokenSecret {
	/** Plaintext token to identify user. */
	token: string;
	/** HMAC shared key to authenticate user. */
	secret: string;
 }
/**
 * Codec for Glassd `TokenSecret` pair.
 *
 * @since 0.4.0
 */
export const TokenSecret: Type<TokenSecret> = type({
	token: string,
	secret: string
});

/**
 * JSON Web Token.
 *
 * @since 0.4.0
 */
export interface JWT {
	/** The login ticket after successful auth0 login **/
	accessToken: string;
	refreshToken: string;
 }
/**
 * Codec for 'JWT'.
 *
 * @since 0.4.0
 */
export const JWT: Type<JWT> = type({
	accessToken: string,
	refreshToken: string
});

/**
 * General Authenication Tokens.
 *
 * @since 0.4.0
 */
export type AuthInfo = TokenSecret | JWT;
/**
 * Codec for `AuthInfo`.
 *
 * @since 0.4.0
 */
export const AuthInfo = union([TokenSecret, JWT]);

/**
 * Check whether we have a TokenSecret pair.
 *
 * @since 0.4.0
 */
export const isTokenSecret = (auth: AuthInfo): auth is TokenSecret =>
	auth.hasOwnProperty('secret');

/**
 * A JWT payload.
 *
 * @see {@link https://www.rfc-editor.org/rfc/rfc7519#section-4.1 RFC7519}
 */
export interface JWTPayload {
  /**
   * JWT Issuer
   */
  iss?: string
  /**
   * JWT Subject
   */
  sub?: string
  /**
   * JWT Audience
   */
  aud?: string | string[]
  /**
   * JWT ID
   */
  jti?: string
  /**
   * JWT Not Before
   */
  nbf?: number
  /**
   * JWT Expiration Time
   */
  exp?: number
  /**
   * JWT Issued At
   */
  iat?: number
  /** Any other JWT Claim Set member. */
  [propName: string]: unknown
}/**

 * Decode jwt.
 *
 * @param jwt JWT
 * @returns JWTPayload
 */
export const decode = (jwt: JWT): JWTPayload|undefined => {
	const base64 = jwt.accessToken.split('.')[1]?.replace(/-/g, '+').replace(/_/g, '/');
	if (!base64) return undefined;
	return JSON.parse(atob(base64));
};
