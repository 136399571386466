import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type {} from '@mui/material/themeCssVarsAugmentation';
import { Language, TranslationEntry, Translations } from '~/src/model/Language';
import { EditProps } from '~/src/edit/types';

interface QButtonGroupProps<T> extends Partial<EditProps<T | T[]>> {
	lang?: Language;
	label?: Translations;
	divider?: boolean;
	size?: 'small' | 'medium';
	items: QButtonGroupItem<T>[];
}

export interface QButtonGroupItem<T> extends Partial<EditProps<boolean>>, TranslationEntry<T> {}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	'& .MuiButtonBase-root': {
		height: 40,
		'&.MuiToggleButton-sizeMedium': {
			height: 56
		},
		'&.Mui-selected': {
			color: theme.vars.palette.action.active,
			backgroundColor: theme.vars.palette.app?.active,
			fontWeight: 600,
			borderLeft: `1px solid ${theme.vars.palette.app?.choreBorder} !important`,
			'&:not(:first-of-type)': {
				marginLeft: '-1px !important',
			},
			'&:hover': {
				borderLeftColor: `${theme.vars.palette.app?.border} !important`,
			}
		},
		'&:first-of-type': {
			borderTopLeftRadius: 6,
			borderBottomLeftRadius: 6,
		},
		'&:last-of-type': {
			borderTopRightRadius: 6,
			borderBottomRightRadius: 6,
		},
	},
	'&.divided .MuiButtonBase-root.Mui-selected': {
		color: theme.vars.palette.app?.light,
		backgroundColor: theme.vars.palette.background.default,
		fontWeight: 400
	},
	'&.divided .MuiButtonBase-root:not(:first-of-type)': {
		borderLeftColor: 'transparent !important',
		position: 'relative',
		'&:before': {
			content: '""',
			position: 'absolute',
			left: '-1px',
			height: 'calc(100% - 12px)',
			width: '1px',
			backgroundColor: theme.vars.palette.app?.border,
		}
	},
	'&.divided .MuiButtonBase-root:not(:last-of-type)': {
		borderRightColor: 'transparent !important',
		position: 'relative',
		'&:before': {
			content: '""',
			position: 'absolute',
			right: '-1px',
			height: 'calc(100% - 12px)',
			width: '1px',
			backgroundColor: theme.vars.palette.app?.border,
		}
	}
}));

const QButtonGroupLabel = styled(Typography)(({ theme }) => ({
	background: theme.vars.palette.background.default,
	position: 'absolute',
	top: '-9px',
	fontSize: '12px',
	color: theme.vars.palette.app?.light,
	zIndex: 9
}));

const QButtonGroup = <T extends unknown>(ps: QButtonGroupProps<T>): React.ReactElement => {
	const [value, setValue] = React.useState(ps.value);

	return (
		<Box display='inline-flex'
			alignItems='center'
			flexDirection='column'
			position='relative'>
			<QButtonGroupLabel pr={1} pl={1}>
				{(ps.label ?? {})[ps.lang ?? 'eng']}
			</QButtonGroupLabel>
			<StyledToggleButtonGroup value={value}
				size={ps.size}
				className={ps.divider ? 'divided' : ''}
				exclusive={!Array.isArray(value)}
				onChange={(_, v) => {
					setValue(v);
					ps.update && ps.update(() => v);
				}}
			>
				{ps.items.map((item, i) => (
					<ToggleButton key={i}
						value={item.key ?? ''}
						onClick={() => item.update && item.update(() =>
							!(Array.isArray(value) ? value.indexOf(item.key) > -1 : value === item.key))
						}
					>
						{item.names[ps.lang ?? 'eng']}
						{item.icon}
					</ToggleButton>
				))}
			</StyledToggleButtonGroup>
		</Box>
	);
};

export default QButtonGroup;
