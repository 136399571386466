import React, { forwardRef, useEffect, useState, useReducer, useMemo, useImperativeHandle, useRef } from 'react';
import Grid from '@mui/material/Grid';
import TemplateTreeView from '~/src/edit/TreeView/TemplateTreeView';
import { Box } from '@mui/material';
import { BulkItem, BulkDesignLeaf, BulkDesign } from '~/src/design/bulk';
import { Quote } from '~/src/pricing/quote';
import * as sampleData from '~/src/design/bulk/sample-design.json';
import * as sampleQuoteData from '~/src/design/bulk/sample-quote.json';
import {
	NamedLeafWithPath,
	addItemToNamedTreeClone,
	generateNameFromTemplate,
	itemsOrItemNamedTree,
	itemsOrItemNamedTreeParent,
	updateTemplateInNameTree,
	updateFieldNameTree,
} from '~/src/optics/TreeView/TreeView';
import { addItemToQuote, updateChargeField } from '~/src/optics/TreeView/Quote';
import { ChargeItem } from '~/src/pricing/quote/change';
import { NamedLeaf, NamedTree } from '~/src/model/NamedTree';
import { Requestor } from '~/src/api/Requestor';
import { Template } from '~/src/design/template';
import { comp } from '~/src/base/Function';
import { maybe } from '~/src/base/Optic';
import { list } from '~/src/design/template/api/list';
import {
	BulkDesignContext,
	reducer,
	INITIAL_STATE,
	BULK_DESIGN_VIEW_MODE,
	SET_SERVER_TEMPLATES,
	HIDE_ALERT,
} from '~/src/design/bulk/store';
import { TreeViewTemplateEditor } from '~/src/edit/TreeView/Template/TreeViewTemplate';
import ResizeHandle from '~/src/components/ResizeHandle';
import { Toast } from '~/src/view/Toast';
import { QAlert } from '~/src/view/QAlert';
import type { TemplateTreeViewRefProps } from '~/src/edit/TreeView/TemplateTreeView';
import { BulkDesignHeader } from '~/src/design/bulk/edit/header';

export type NamedLeafWithChargeItem = {
	namedLeafs: NamedLeafWithPath<BulkItem>;
	chargeItem: ChargeItem | undefined;
};
export type Path = number[];

export interface BulkDesignPageProps {
	req: Requestor;
	onQuoteChange?: (quote: Quote) => void;
	onTreeDataChange?: (tree: NamedTree<unknown>) => void;
	onSaveChange?: (tree: NamedTree<unknown>, quote: Quote) => void;
	onCancel?: () => void;
}

export type BulkDesignRefProps = {
	updateQuote: (quote: Quote) => void;
	updateTreeData: (tree: NamedTree<unknown>) => void;
}

const BulkDesignPage = forwardRef<BulkDesignRefProps, BulkDesignPageProps>(({ req, onTreeDataChange, onQuoteChange, onSaveChange, onCancel }, ref) => {
	const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

	const treeviewRef = useRef<TemplateTreeViewRefProps>(null);

	const [selectedPath, setSelectedPath] = useState<Path>();
	const [selectedNode, setSelectedNode] = useState<BulkDesignLeaf | BulkDesign>();
	const [treeData, setTreeData] = useState<NamedTree<unknown>>(
		JSON.parse(JSON.stringify(sampleData))
	);
	const [quoteData, setQuoteData] = useState<Quote>(
		JSON.parse(JSON.stringify(sampleQuoteData))
	);
	const [leftSideBarWidth, setLeftSideBarWidth] = useState(330);
	const [limits, setLimits] = React.useState<string>();
	setLimits;
	const [template, updateTemplate] = React.useState<Template | undefined>();
	const [serverTemplates, setServerTemplates] = React.useState<Template[]>([]);
	const handleWidth = 16;

	const treeViewWidth = useMemo(() => {
		if (state.mainViewMode === BULK_DESIGN_VIEW_MODE.LIST_VIEW) {
			return '100vw';
		}
		return `${leftSideBarWidth}px`;
	}, [leftSideBarWidth, state.mainViewMode]);

	const alertProps = useMemo(() => state.alertProps, [state.alertProps]);

	const updateTreeData = (data: NamedTree<unknown>) => {
		setTreeData({ Name: data.Name, Count: data.Count, Items: data.Items });
		if (onTreeDataChange) {
			onTreeDataChange(data);
		}
	};

	const updateQuoteData = (quote: Quote) => {
		setQuoteData(quote);
		if (onQuoteChange) {
			onQuoteChange(quote);
		}
	};

	useEffect(() => {
		list(req)().then((templates) => {
			setServerTemplates(templates);
			dispatch({
				type: SET_SERVER_TEMPLATES,
				data: templates,
			});
		}).catch(console.warn);
	}, [req]);

	const handleSelectTemplate = (path: Path | undefined, tData?: NamedTree<unknown>) => {
		if (path && path.length > 0) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const selectedTemplate: any = itemsOrItemNamedTree(path).get(tData || treeData);
			if (selectedTemplate) {
				if (selectedTemplate.hasOwnProperty('Panels')) {
					updateTemplate(undefined);
				} else {
					updateTemplate(selectedTemplate as Template);
				}
			} else {
				updateTemplate(undefined);
			}
		} else {
			updateTemplate(undefined);
		}
	};

	useEffect(() => {
		if (selectedPath && template) {
			let newTreeData: NamedTree<unknown> | NamedLeaf<unknown> = treeData;
			let newQuoteData: Quote = quoteData;
			// Check if the current name is default one or not.
			let hasDefaultName = false;
			const selectedItem = itemsOrItemNamedTreeParent(selectedPath).get(treeData) as BulkDesignLeaf;
			if (selectedItem) {
				const defaultName = generateNameFromTemplate(selectedItem.Item as Template);
				hasDefaultName = defaultName === selectedItem.Name;
			}
			// In case of default name, update the name from new template.
			if (hasDefaultName) {
				const newDefaultName = generateNameFromTemplate(template);
				if (newDefaultName !== selectedItem.Name) {
					newTreeData = updateFieldNameTree(selectedPath as Path, 'Name', newDefaultName)(treeData);
					newQuoteData = updateChargeField(selectedPath as Path, 'Name', newDefaultName)(quoteData) as Quote;
					updateQuoteData(newQuoteData);
				}
			}
			newTreeData = updateTemplateInNameTree<unknown>(selectedPath as Path, template as Template)(newTreeData);
			updateTreeData(newTreeData as NamedTree<unknown>);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPath, template]);

	useEffect(() => {
		if (selectedPath && selectedPath.length > 0) {
			const selected = itemsOrItemNamedTreeParent(selectedPath).get(treeData);
			setSelectedNode(selected as BulkDesign | BulkDesignLeaf);
		} else {
			setSelectedNode(undefined);
		}
	}, [selectedPath, treeData]);

	useImperativeHandle(ref, () => ({
		updateQuote: (quote: Quote) => {
			setQuoteData(JSON.parse(JSON.stringify(quote)) as Quote);
		},
		updateTreeData: updateTreeData,
	}));

	if (!treeData) {
		return <div>Loading...</div>;
	}

	const updateSelectedPath = (path: Path | undefined, tData?: NamedTree<unknown>) => {
		if (path) {
			if (selectedPath?.toString() === path.toString()) {
				setSelectedPath(undefined);
				handleSelectTemplate(undefined);
			} else {
				setSelectedPath(path);
				handleSelectTemplate(path, tData);
			}
		} else {
			setSelectedPath(undefined);
			handleSelectTemplate(undefined);
		}
	};

	const addTemplate = () => {
		const newItemName = generateNameFromTemplate(serverTemplates[0] as Template);
		const newItemToInsert = {
			Name: newItemName,
			Count: 1,
			Item: serverTemplates[0],
		};
		const newQuoteToInsert = {
			Name: newItemName,
			Count: 1,
			Price: {
				USD: '0',
			},
			Unit: {
				Count: 1,
				Unit: 'pc',
			},
			Discounts: {},
			Taxes: {},
			Extended: {
				USD: '0',
			},
			Discount: {},
			SubTotal: {
				USD: '0',
			},
			Tax: {},
			Total: {
				USD: '0',
			},
		};
		const pathToInsert = treeData.Items.length === 0 ? [0] : selectedPath || [treeData.Items.length - 1];
		updateTreeData(
			addItemToNamedTreeClone(
				pathToInsert,
				newItemToInsert
			)(treeData as NamedTree<Template>) as NamedTree<unknown>
		);
		updateQuoteData(
			addItemToQuote(
				pathToInsert,
				newQuoteToInsert as ChargeItem
			)(quoteData) as Quote
		);
	};

	const handleCloseAlert = () => {
		dispatch({
			type: HIDE_ALERT,
			data: {
				open: false,
				title: '',
				description: ''
			}
		});
	};

	const setFieldBasedOnPath = (field: 'Name' | 'Count', newValue: string | number) => {
		if (selectedPath) {
			const newTreeData = updateFieldNameTree(selectedPath, field, newValue)(treeData);
			console.log(newTreeData);
			setTreeData(newTreeData as NamedTree<unknown>);
			const newQuoteData = updateChargeField(selectedPath, field, newValue)(quoteData);
			setQuoteData(newQuoteData as Quote);
		}
	};

	const handleDuplication = () => {
		if (treeviewRef.current && selectedPath) {
			treeviewRef.current.handleDuplication(selectedPath);
		}
	};

	const handleGoBack = (save: boolean) => {
		if (save) {
			if (onSaveChange) {
				onSaveChange(treeData, quoteData);
			}
		} else {
			// Do something here.
			if (onCancel) {
				onCancel();
			}
		}
	};

	return (
		<BulkDesignContext.Provider value={{ ...state, dispatch }}>
			<Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
				<BulkDesignHeader goBack={handleGoBack} />
				{(state.mainViewMode === BULK_DESIGN_VIEW_MODE.LIST_VIEW || state.mainViewMode === BULK_DESIGN_VIEW_MODE.DEFAULT) && (
					<Grid className="h-100" style={{ display: 'flex' }}>
						<div
							className="left-side-bar-draggable"
							style={{
								width: treeViewWidth,
								paddingRight:
									state.mainViewMode !== BULK_DESIGN_VIEW_MODE.LIST_VIEW ? `${handleWidth}px` : 0,
							}}
						>
							{/* hide resize handle if itemized list view is displayed. */}
							{state.mainViewMode !== BULK_DESIGN_VIEW_MODE.LIST_VIEW && (
								<ResizeHandle
									defaultValue={330}
									minValue={308}
									maxValue={380}
									position="right"
									onChange={setLeftSideBarWidth}
								/>
							)}
							<Box className="bar" style={{ width: '100%' }}>
								<TemplateTreeView
									ref={treeviewRef}
									setTreeData={updateTreeData}
									selectedPath={selectedPath}
									addTemplate={addTemplate}
									updateSelectedPath={updateSelectedPath}
									treeData={treeData}
									quoteData={quoteData}
									setQuoteData={updateQuoteData}
								/>
							</Box>
						</div>

						{/* Right Side Bar: hide if itemized list view is displayed */}
						{state.mainViewMode !== BULK_DESIGN_VIEW_MODE.LIST_VIEW && (
							<Box className="tree-view right-side-bar">
								{template && (
									<TreeViewTemplateEditor
										req={req}
										limits={limits}
										template={template}
										selectedNode={selectedNode}
										setFieldBasedOnPath={setFieldBasedOnPath}
										handleDuplication={handleDuplication}
										update={comp(updateTemplate, maybe)}
									/>
								)}
							</Box>
						)}
					</Grid>
				)}
			</Box>
			{alertProps.open && (
				<Toast
					open={true}
					type='info'
					vertical='bottom'
					horizontal='right'
					autoHideDuration={3000}
					handleClose={() => ({})}
				>
					<QAlert
						title={alertProps.title}
						description={alertProps.description}
						autoDuration={alertProps.autoDuration}
						style={{
							marginBottom: '60px',
							marginRight: '-16px'
						}}
						onClose={handleCloseAlert}
					/>
				</Toast>
			)}
		</BulkDesignContext.Provider>
	);
});

export default BulkDesignPage;
