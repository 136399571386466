.Opening {
	text-align: center;
	width: 100%;
	height: 100%;
}

.Select {
	background-color: unset !important;
	width: 100px;
	height: unset !important;
	display: inline-block !important;
	vertical-align: top !important;
}
