import { Box, FormHelperText, ListItem, Stack, Typography } from '@mui/material';
import { constant, flow, pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { ClientTemplateMod } from '~/src/admin/template/mod';
import { Variable } from '~/src/design/template';
import { TemplateMod } from '~/src/admin/template/mod/inner';
import { rangeSet, variableRange } from '~/src/admin/template/mod/inner-optics';
import { Update } from '~/src/base/Function';
import { modTemplate } from '~/src/admin/template/mod/optics';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { RangeSet } from '~/src/design/template/variable';
import ChipAdd from '~/src/ui/input/ChipAdd';
import SetOption from '~/src/admin/template/mod/option/edit';
import { showModifiedMessage } from '~/src/edit/util';
import ModVariableRank from '~/src/admin/template/mod/variable/edit-rank';

interface SetOptionsProps {
	variable: Variable
	range: RangeSet
	templateMod: TemplateMod
	update: Update<ClientTemplateMod>
}

export const ModSetOptions: React.FC<SetOptionsProps> = ({ variable, range, templateMod, update }) => {

	const handleAddSetItem = (label: string) => (range: RangeSet) => modTemplate
		.modify(
			variableRange.modify(
				constant({
					[label]: {
						type: 'Set',
						Set: pipe(range.Set, A.copy, A.append(0))
					}
				}))
		);

	return (
		<Box my={2}>
			<ModVariableRank
				variableLabel={variable.Label}
				currentRank={variable.Rank}
				ModTemplate={templateMod}
				update={update}
			/>
			<Typography color='GrayText' variant='caption'>{variable.Names.eng || variable.Label} Set Values</Typography>
			<Stack pl={0} mt={0} flexWrap='wrap' component='ul' direction="row">
				{range.Set.map((setValue, i) => (
					<SetOption
						index={i}
						key={variable.Label + setValue}
						range={range}
						label={variable.Label}
						value={setValue}
						update={update}
					/>
				))}
				<ListItem sx={{ p: 0.5, width: 'auto', }}>
					<ChipAdd
						onClick={flow(() => handleAddSetItem(variable.Label)(range), update)}
					/>
				</ListItem>
			</Stack>
			{pipe(rangeSet(variable.Label).getOption(templateMod), O.isSome) && <FormHelperText>{showModifiedMessage(true)}</FormHelperText>}
		</Box>
	);
};

export default ModSetOptions;
