import Grid from '@mui/material/Grid';
import * as React from 'react';
import { dot } from '~/src/base/Optic';
import { EditProps } from '~/src/edit/types';
import { comp } from '~/src/base/Function';
import { AreaUnit, LengthUnit } from '~/src/util/Units';
import { Language } from '~/src/model/Language';
import { LanguageEdit, LanguageProvider } from './language';
import { AreaUnitProvider, LengthUnitEdit, LengthUnitProvider } from './units';

export interface Settings {
	language: Language,
	lengthUnit: LengthUnit,
	areaUnit: AreaUnit,
}

export const defaultSettings = (): Settings => ({
	language: 'eng',
	lengthUnit: 'inch',
	areaUnit: 'ft²',
});

export const SettingsProvider = ({value, children}: {value: Settings, children: React.ReactNode;}) =>
	<LanguageProvider value={value.language}>
		<LengthUnitProvider value={value.lengthUnit}>
			<AreaUnitProvider value={value.areaUnit}>
				{children}
			</AreaUnitProvider>
		</LengthUnitProvider>
	</LanguageProvider>;

export const SettingsEdit = ({value, update}: EditProps<Settings>) =>
	<Grid container>
		<Grid item>
			<LanguageEdit value={value.language} update={comp(update,dot('language'))}/>
		</Grid>
		<Grid item>
			<LengthUnitEdit value={value.lengthUnit} update={comp(update,dot('lengthUnit'))}/>
		</Grid>
	</Grid>;
