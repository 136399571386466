import React, { useCallback, useEffect, useState } from 'react';

import { array, unknown as unk, string } from 'io-ts';

import { Requestor } from '~/src/api/Requestor';

import { Template, templateTransEntry } from '~/src/design/template';
import { list as listTemplates } from '~/src/design/template/api/list';

import { Limits } from '~/src/admin/template/limits';
import { limitsName } from '~/src/admin/template/limits/optics';
import { LimitsEditor } from '~/src/admin/template/limits/edit';

import { Import } from '~/src/ui/input/Import';
import { Export } from '~/src/ui/input/Export';
import { useQueryKey } from '~/src/ui/query';
import { ToolBar } from '~/src/ui/toolbar/ToolBar';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SelectEnum from '~/src/edit/SelectEnum';
import { SelectChangeEvent } from '@mui/material';
import { mmap } from '~/src/base/Array';
import { comp, constant } from '~/src/base/Function';
import { get1, Optic, pdot, withDefault } from '~/src/base/Optic';
import { mem } from '~/src/base/Memoize';

export type LimitProps = Readonly<{
	req: Requestor,
}>;

type Edits = {[k in string]?: Limits};

const editLimit_ = (id: string|undefined): Optic<Edits,Limits|undefined> =>
	pdot(id ?? '');
const editLimit = mem((lims: Limits[], id: string|undefined): Optic<Edits,Limits> =>
	comp(editLimit_(id),withDefault(lims.find(l => l.Id === id) ?? {}))
);

export const LimitPage = ({req}: LimitProps): React.ReactElement => {
	const [ limits, setLimits ] = useState<Limits[]>([]);
	const [ edits, setEdits ] = useState<Edits>({});
	const [ saves, setSaves ] = useState<number>(0);
	const [ templates, setTemplates ] = useState<Template[]>([]);

	const [ id, updateId ] = useQueryKey('id');
	const [ template, updateTemplateId ] = useQueryKey('template');

	const updateTemplate = comp(updateTemplateId,withDefault('_'));

	// keep our list of limits up to date
	useEffect(() => {
		req.request(array(Limits))('template/limit/list', 'GET').then(setLimits).catch(console.warn);
	}, [req, saves]);

	// fetch the template list (to base the options/vars on)
	useEffect(() => {
		listTemplates(req)().then(setTemplates).catch(console.warn);
	}, [req]);

	const limit = get1(editLimit(limits,id))(edits);

	const save = useCallback(() => {
		const lim = edits[id ?? ''];
		if (lim === undefined) return;
		const limId = lim.Id ?? '';
		if (limId === '') {
			req.request(string)('template/limit', 'POST', lim).then((newId) => {
				setEdits(editLimit_(id)(constant(undefined)));
				setSaves(i => i+1);
				updateId(() => newId);
			}).catch(console.warn);
		} else {
			req.request(unk)('template/limit', 'PUT', lim).then(() => {
				setEdits(editLimit_(id)(constant(undefined)));
				setSaves(i => i+1);
				updateId(() => limId);
			}).catch(console.warn);
		}
	}, [id, edits, req, updateId]);

	return (
		<div className="screen template-limits">
			<ToolBar>
				<SelectEnum
					label={{eng: 'Template'}}
					size="small"
					items={mmap(templateTransEntry)(templates)}
					value={template ?? '_'}
					update={updateTemplate}
				/>
				<Import codec={Limits} name="import-limits" success={comp(setEdits,editLimit(limits,id),constant)} />
				<Export name="export-limits" getContent={() => limit} />
				<Select
					size="small"
					value={id ?? '_'}
					onChange={useCallback((ev : SelectChangeEvent<string>) => updateId(() => ev.target.value), [updateId])}>
					<MenuItem value="_">Load</MenuItem>
					<MenuItem value="">New</MenuItem>
					{ limits.map((m) => <MenuItem key={m.Id} value={m.Id}>{get1(limitsName('eng'))(m)}</MenuItem>) }
				</Select>
				<Button size="small" variant='outlined' onClick={save}>Save</Button>
			</ToolBar>

			<div className="scroll">
				<LimitsEditor value={limit} update={comp(setEdits,editLimit(limits,id))}
					template={templates.find(t => t.Id == template)}
				/>
			</div>
		</div>
	);
};

export default LimitPage;
