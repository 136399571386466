import { Decoder } from 'io-ts';
import { useCallback, useEffect } from 'react';
import * as Either from 'fp-ts/Either';
import { Fn } from '~/src/base/Function';

export const useMessage = <A>(codec: Decoder<unknown, A>, onMessage: Fn<MessageEvent<A>,void>, originFilter?: Fn<string,boolean>) => {
	const onEvent = useCallback((ev: MessageEvent<unknown>) => {
		if (originFilter && !originFilter(ev.origin)) return;
		Either.fold(
			() => undefined, /// if it doesn't decode properly it's not for us
			(x: A) => onMessage({...ev, data: x}),
		)(codec.decode(ev.data));
	}, [codec, onMessage, originFilter]);

	useEffect(() => {
		window.addEventListener('message', onEvent);
		return () => window.removeEventListener('message', onEvent);
	});
};
