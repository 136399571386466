import { Requestor } from '~/src/api/Requestor';
import { Opening, SectionStep } from '~/src/design/opening';
import { Projection } from '~/src/design/opening/projection';
import { Division } from '~/src/design/opening/division';
import { Side } from '~/src/model/Side';
import { divisionDivisionStep, DivisionStep } from '~/src/design/opening/division/optics';
import { openingDivision } from '~/src/design/opening/optics';

import * as React from 'react';

import { Space } from '~/src/ui/SpaceControl';
import { LengthConverter, AngleConverter, Converter } from '~/src/util/Units';
import PieMenu, {PieMenuProps, XY } from '~/src/ui/PieMenu';
import TextModal, { TextModalProps } from '~/src/ui/TextModal';

import { toNullable } from 'fp-ts/Option';

import { Mod, Update } from '~/src/base/Function';

import './edit.css';
import PanelViewer from '~/src/design/opening/panel/view';
import { Lens } from 'monocle-ts';
import { flow } from 'fp-ts/lib/function';
import useDrawingDragAngles from '~/src/hooks/useDrawingDragAngles';

export interface PaneledProps {
	req: Requestor;
	visible: boolean;
	space: Space,
	value: Opening;
	projection: Projection;
	lengthConverter: LengthConverter;
	angleConverter: AngleConverter;
	update: Update<Opening>;
	spaceControl: (space: Space) => void;
}

export interface PaneledState {
	pie?: PieMenuProps;
	modal?: TextModalProps;
}

/**
 * Focus on text modal in WalledState
 *
 * @category WalledState
 */
export const modal: Lens<PaneledState, TextModalProps> = Lens.fromNullableProp<PaneledState>()('modal', {
	onSave: () => {},
	onClose: () => {},
	open: false,
});

/**
 * Focus on pie menu props in PaneledState
 *
 * @category PaneledState
 */
export const pieOptic: Lens<PaneledState, PieMenuProps> = Lens.fromNullableProp<PaneledState>()('pie', {
	onClose: () => {},
	clicked: false,
	x: 0,
	y: 0
});

const closeModal: Mod<PaneledState> = modal.modify(() => ({ open: false, onClose: () => {}, onSave: () => {}}));
export const closePie: Mod<PaneledState> = pieOptic.modify((prevState) => ({...prevState, entries: undefined, radius: 0}));
const openModal: (modalProps: TextModalProps) => Mod<PaneledState> = (modalProps: TextModalProps) => modal.set({ ...modalProps, open: true });

const openPie: (pieProps: PieMenuProps) => Mod<PaneledState> = (pieProps) => pieOptic.set(pieProps);

const splitPie = (updatePanel: Update<PaneledState>, updateOpening: Update<Opening>, lengthConverter: Converter<number, string>, path: DivisionPath, side: Side|undefined = undefined, _corner = 0) => (xy: XY) => {
	if (side === undefined) {
		updatePanel(openPie({ ...xy, entries: [
			{
				icon: '←',
				title: 'Split panel from the right.',
				onSelect: () => updatePanel(flow(splitModal(updatePanel, updateOpening, lengthConverter, path, 'right'), closePie)),
			},
			path.division.length > 0 ? {
				icon: 'x',
				title: 'Remove split.',
				onSelect: () => {
					removeSplit(updateOpening, path)();
					updatePanel(closePie);
				}
			} : null,
			{
				icon: '↓',
				title: 'Split panel from the top.',
				onSelect: () => updatePanel(flow(splitModal(updatePanel, updateOpening, lengthConverter, path, 'top'), closePie)),
			},
			null,
			{
				icon: '→',
				title: 'Split panel from the left.',
				onSelect: () => updatePanel(flow(splitModal(updatePanel, updateOpening, lengthConverter, path, 'left'), closePie)),
			},
			null,
			{
				icon: '↑',
				title: 'Split panel from the bottom.',
				onSelect: () => updatePanel(flow(splitModal(updatePanel, updateOpening, lengthConverter, path, 'bottom'), closePie)),
			},
		],
		onClose: () => updatePanel(closePie)
		})
		);
	} else {
		return;
	}
};

const topPie = (updatePanel: Update<PaneledState>) => (updateOpening: Update<Opening>) => (lengthConverter: Converter<number, string>) => (updateElId: Update<string>) => (e: React.MouseEvent | React.TouchEvent) => {
	/*
	 * if (pe.state.pie !== undefined) {
	 * 	return updatePanel(closePie);
	 * }
	 */

	let xy = { x: 0, y: 0 };

	if ('clientX' in e) {
		xy = { x: e.clientX, y: e.clientY };
	}
	if ('targetTouches' in e){
		const firstTouch = e.targetTouches[0];
		if (firstTouch) {
			xy = { x: firstTouch.clientX, y: firstTouch.clientY };
		}
	}

	const target = e.target;
	if (!(target && target instanceof Node)) return;
	const ele = target.parentElement;
	if (!ele) return;

	if (ele.classList.contains('Panel') || ele.classList.contains('Split')) {
		const path = parseDivisionPath(ele.id.split('-'));
		if (typeof path === 'string') {
			console.warn('Invalid Division Path:', path, ele.id.split('-'));
			return;
		}

		updateElId(() => ele.id);

		updatePanel(
			openPie({ ...xy, entries: [
				null,
				null,
				{
					icon: '⬚│⬚',
					title: 'Split.',
					onSelect: splitPie(updatePanel,updateOpening, lengthConverter, path),
				},
				null,
				null,
				null,
				null,
				null,
			],
			onClose: () => updatePanel(closePie)
			})
		);
	} else {
		console.log(ele, xy);
	}
};

const splitModal = (updatePanel: Update<PaneledState>, updateOpening: Update<Opening>, lengthConverter: Converter<number,string>, path: DivisionPath, side: Side) => openModal({
	open: true,
	message: `Distance from ${side} to split:`,
	onSave: doSplit(updateOpening, lengthConverter, path, side),
	onClose: () => updatePanel(flow(closePie, closeModal)),
});

type SectionPath = { section: SectionStep[] };
type DivisionPath = SectionPath & { division: DivisionStep[] };

const parseSectionPath = (path: string[]): SectionPath & { consumed: number } | string => {
	const section: SectionStep[] = [];
	let i = 0;
	for (;;i++) {
		const step = path[i];
		if (step === undefined) break;
		if (step === 'Rest') continue;

		const n = parseInt(step);
		if (isNaN(n)) break;

		if (n === 0 || n === 1 || n === 2)
			section.push(n);
		else
			return `Invalid Section Id ${i}`;
	}
	return { section, consumed: i };
};

const parseDivisionPath = (path: string[]): DivisionPath & { consumed: number } | string => {
	const sectionParse = parseSectionPath(path);
	if (typeof sectionParse === 'string') return sectionParse;

	let i = sectionParse.consumed;
	if (path[i] !== 'Panels' && path[i] !== 'Split') return 'This path does not select a Division';
	i++;

	const division: DivisionStep[] = [];
	for (;;i++) {
		const step = path[i];
		if (step === undefined) break;

		const n = parseInt(step);
		if (isNaN(n)) break;
		if (n > 1 || n < 0) return `Invalid Division Id ${i}`;

		division.push(n === 0 ? 'Left' : 'Right');
	}
	return { section: sectionParse.section, division, consumed: i };
};

const doSplit = (updateOpening: Update<Opening>, lengthConverter: Converter<number, string>, path: DivisionPath, side: Side) => (amountStr: string) =>
	lengthConverter.parse(amountStr).then((amount) => updateOpening((op) => {
		const optic = openingDivision(path.section, path.division);
		const existing = toNullable(optic.getOption(op));
		if (existing === null) {
			console.warn('No Division at Division Path', path);
			return op;
		}
		const whole: Division = {
			type: 'Whole',
			Info: null,
		};
		const division: Division = {
			type: 'Divided',
			Left: whole,
			Right: existing,
			LeftEdge: null,
			RightEdge: null,
			Curve: {
				type: 'Angled',
				Angle: 0,
				Corner: 0,
				Distance: amount,
				Side: side,
			},
		};
		return optic.modify((_old) => division)(op);
	})).catch(console.warn);

const removeSplit = (updateOpening: Update<Opening>, path: DivisionPath) => () => updateOpening((op) => {
	const lastStep = path.division[path.division.length-1];
	if (lastStep === undefined) return op;

	const parentDivisionPath = path.division.slice(0,-1);
	const siblingStep = lastStep === 'Left' ? 'Right' : 'Left';
	const parentOptic = openingDivision(path.section, parentDivisionPath);
	const sibling = toNullable(parentOptic.compose(divisionDivisionStep(siblingStep)).getOption(op));
	if (sibling === null) return op;

	return parentOptic.modify((_old) => sibling)(op);
});

const PanelEditor: React.FC<PaneledProps> = (props) => {
	// Main state control object for PanelEditor
	const [panelState, setPanelState] = React.useState<PaneledState>({
		modal: undefined,
		pie: undefined
	});

	// State for managing pie slice selection and highlighting on touch screens
	const { angle, setAngle, trackingAngle, setTrackingAngle } = useDrawingDragAngles();

	const { pie, modal } = panelState;
	const [elId, setElId] = React.useState('');

	React.useEffect(() => {
		if (!pie?.entries) {
			setElId('');
		}
	}, [pie?.entries]);

	return <>
		<PanelViewer {...props}
			angle={angle}
			updateAngle={setAngle}
			trackingAngle={trackingAngle}
			updateTrackingAngle={setTrackingAngle}
			updatePanel={setPanelState}
			onDown={topPie(setPanelState)(props.update)(props.lengthConverter)(setElId)}
			activeElementId={elId}
		/>
		{ pie ? <PieMenu
			{...pie}
			angle={angle}
			updateAngle={setAngle}
			trackingAngle={trackingAngle}
			updateTrackingAngle={setTrackingAngle}
			drawingRotation={props.projection.type === 'Isometric' ? props.projection.Rotate: undefined}
			updateOpening={props.update}
			angleConverter={props.angleConverter}
			lengthConverter={props.lengthConverter}
		/> : null }
		{ modal ? <TextModal {...modal} /> : null }
	</>;
};

export default PanelEditor;
