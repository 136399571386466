import React, {
	KeyboardEvent,
	useContext,
	useEffect,
	useRef,
	useMemo,
	useState,
} from 'react';
import classNames from 'classnames';
import { Quote } from '~/src/pricing/quote';
import { Entry } from '~/src/design/template/option';
import {
	DragProps,
	HandleSelectFunction,
	NavigatedItem,
	TreeDataItem,
	TreePath,
	UpdatingFieldProps,
	selectionModelLeaf,
	selectionModelTree,
} from './TemplateTreeView';
import { Charge } from '~/src/pricing/quote/change';
import { Path } from '~/src/pages/bulk-design';
import { Box, ListItem } from '@mui/material';
import { Tree } from './Tree';
import { NamedTree } from '~/src/model/NamedTree';
import TreeNodeLabel from './TreeNodeLabel';
import { BulkDesignContext, BULK_DESIGN_VIEW_MODE } from '~/src/design/bulk/store';

interface TreeNodeProps {
	node: TreeDataItem;
	quoteData: Quote | Charge | undefined;
	updateSelectedPath: TreePath;
	path: Path | undefined;
	editMode: boolean;
	onSelect: HandleSelectFunction;
	selectedItemsData:
		| selectionModelTree<unknown>
		| selectionModelLeaf<unknown>
		| undefined;
	selectedPath: Path | undefined;
	setFieldBasedOnPath: UpdatingFieldProps;
	navigatedItem: NavigatedItem;
	onDragStart: DragProps;
	onDrop: DragProps;
	onDragOver: (e: React.DragEvent) => void;
	topParentData: NamedTree<unknown>;
	setTreeData: (e: NamedTree<unknown>) => void;
	resetNavigatedItem: () => void;
}

interface ColumnProp {
	id: string;
	label: string;
	width: string;
	align?: 'left' | 'right' | 'center';
	render?: (
		node: TreeDataItem,
		quote: Quote | Charge | undefined
	) => React.ReactNode;
}

export function TreeNode({
	node,
	navigatedItem,
	updateSelectedPath,
	quoteData,
	path,
	editMode,
	onSelect,
	selectedItemsData,
	selectedPath,
	setFieldBasedOnPath,
	onDragStart,
	onDrop,
	onDragOver,
	topParentData,
	setTreeData,
	resetNavigatedItem,
}: TreeNodeProps) {
	const { mainViewMode, serverTemplates } = useContext(BulkDesignContext);
	const countDivRef = useRef<HTMLDivElement>(null);
	const [isNavigatedItem, setIsNavigatedItem] = useState(
		path && navigatedItem && path.toString() === navigatedItem.toString()
	);
	const isSelectedPath =
		selectedPath && selectedPath.toString() === path?.toString();
	const editModeSelected = selectedItemsData?.selected;

	const [showChildren, setShowChildren] = React.useState(false);
	const [dragEntered, setDragEntered] = useState(false);

	const defaultServerTemplate = useMemo(() => {
		if (serverTemplates && serverTemplates.length > 0) {
			return serverTemplates[0];
		}
		return null;
	}, [serverTemplates]);

	const templateValues = useMemo(() => {
		if (defaultServerTemplate) {
			return [
				...defaultServerTemplate.Opts.map((variable) => ({
					...variable,
					Type: 'Opts',
				})),
				...defaultServerTemplate.Vars.map((variable) => ({
					...variable,
					Type: 'Vars',
				})),
			];
		}

		return [];
	}, [defaultServerTemplate]);

	const getValueFromTemplate = (label: string): string | number => {
		if (templateValues) {
			const searchLabel = templateValues.find((item) => item.Label === label);
			if (searchLabel) {
				switch (searchLabel.Type) {
				case 'Opts':
					return (
						(searchLabel.Range as Entry<string>[]).find(
							(r) => r.Label === searchLabel.Value
						)?.Names?.eng ?? ''
					);
				case 'Vars':
					return searchLabel.Value;
				}
			}
		}
		return '';
	};

	const columns: ColumnProp[] = [
		{
			id: 'measurements',
			width: '141px',
			label: 'Measurements',
			render: () => {
				const width = getValueFromTemplate('width');
				const height = getValueFromTemplate('height');
				if (width || height) {
					return `${width} x ${height}"`;
				}
				return '-';
			},
		},
		{
			id: 'shape',
			width: '87px',
			label: 'Shape',
			render: () => getValueFromTemplate('Shape'),
		},
		{
			id: 'edge-finish',
			width: '120px',
			label: 'Edge Finish',
			render: () => getValueFromTemplate('Edge'),
		},
		{
			id: 'tempered',
			width: '115px',
			label: 'Tempered',
			render: () => {
				const tempered = getValueFromTemplate('TemperMark');
				return tempered;
			},
		},
		{
			id: 'corners',
			width: '98px',
			label: 'Corners',
			render: () => '-',
		},
		{
			id: 'radius',
			width: '90px',
			label: 'Radius',
			render: () => '-',
		},
		{
			id: 'sku',
			width: '70px',
			label: 'SKU',
			render: () => '-',
		},
		{
			id: 'price',
			width: '90px',
			label: 'Price',
			align: 'right',
			render: (_node, quote) => `$ ${quote?.Total.USD}`,
		},
	];

	const gridTemplateColumns = useMemo(() => {
		if (mainViewMode === BULK_DESIGN_VIEW_MODE.LIST_VIEW) {
			return `minmax(12px, 12px) 1fr repeat(${columns.length}, auto) 176px`;
		}
		return '12px 1fr auto';
	}, [mainViewMode, columns.length]);

	const moveXToEnd = (input: string) => {
		return (
			input
				.split('')
				.filter((char) => char !== 'x')
				.join('') + 'x'
		);
	};

	const handleCountChange = (newCount: string) => {
		const newValue = parseInt(newCount, 10);
		if (!isNaN(newValue)) {
			if (path) {
				setFieldBasedOnPath(path, 'Count', newValue);
			}
		}
	};

	const handleTooltipClick = () => {
		setShowChildren(!showChildren);
	};

	const handleCheckboxClick = () => {
		if (path) {
			onSelect({ path, selectStatus: !editModeSelected || false });
		}
	};

	const handleCountKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		const isNumberKey = /^[0-9]$/.test(e.key);
		if (
			e.key === 'Backspace' ||
			e.key === 'ArrowLeft' ||
			e.key === 'ArrowRight'
		) {
			// Do nothing
		} else if (e.key === 'Enter' && e.currentTarget.textContent) {
			e.preventDefault();
			if (!e.currentTarget.textContent.includes('x')) {
				e.currentTarget.textContent += 'x';
			} else {
				e.currentTarget.textContent = moveXToEnd(e.currentTarget.textContent);
			}
			handleCountChange(e.currentTarget.textContent);
			if (countDivRef.current) {
				countDivRef.current.blur();
			}
		} else if (!isNumberKey) {
			e.preventDefault();
		}
	};

	const handleCountBlur = (e: { target: { innerText: string } }) => {
		if (e.target.innerText === '') {
			e.target.innerText = '1x';
		}
		if (!e.target.innerText.includes('x')) {
			e.target.innerText += 'x';
		} else {
			e.target.innerText = moveXToEnd(e.target.innerText);
		}
		handleCountChange(e.target.innerText);
	};

	const onDragEnter = (): void => {
		setDragEntered(true);
	};

	const onDragLeave = (): void => {
		setDragEntered(false);
	};

	useEffect(() => {
		if (navigatedItem?.toString() === path?.toString()) {
			setIsNavigatedItem(true);
		} else {
			setIsNavigatedItem(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigatedItem]);

	/*
	 * 'Items' in node means data type is BulkDesign
	 * 'Item' in node means data type is BulkDesignLeaf
	 */

	return (
		<>
			<ListItem
				draggable
				onDragStart={(e) => {
					if (path) {
						onDragStart(e, path);
					}
				}}
				onDrop={(e) => {
					setDragEntered(false);
					if (path) {
						onDrop(e, path);
					}
				}}
				onDragOver={onDragOver}
				onDragLeave={onDragLeave}
				onDragEnter={onDragEnter}
				data-tree-path={JSON.stringify(path)}
				data-tree-is-navigated={isNavigatedItem}
				data-tree-is-selected={isSelectedPath}
				className="tree-list-item"
				onMouseEnter={() => {
					resetNavigatedItem();
					setIsNavigatedItem(true);
				}}
				onMouseLeave={() => setIsNavigatedItem(false)}
			>
				<Box
					className={classNames('tree-node-container', {
						['tree-node-container--selected']: !editMode && isSelectedPath,
						['tree-node-container--selected-edit']:
							isNavigatedItem ||
							(!isNavigatedItem && editMode && editModeSelected),
						['tree-node-container--selected-hover']:
							!isNavigatedItem && !editMode && isSelectedPath,
						['tree-node-container--dragging']: dragEntered,
					})}
					style={{ gridTemplateColumns: gridTemplateColumns }}
				>
					{/* divider */}
					<Box className="tree-node-container__divider"></Box>
					{/* label */}
					<TreeNodeLabel
						node={node}
						path={path}
						editMode={editMode}
						showChildren={showChildren}
						editModeSelected={editModeSelected}
						setFieldBasedOnPath={setFieldBasedOnPath}
						onShowChildren={handleTooltipClick}
						onSelectChecbox={handleCheckboxClick}
					/>
					{/* Columns - visible only in itemized list view */}
					{mainViewMode === BULK_DESIGN_VIEW_MODE.LIST_VIEW && (
						<>
							{columns.map((column) => (
								<Box
									key={column.id}
									className="tree-node-column"
									style={{
										width: column.width,
										minWidth: column.width,
										maxWidth: column.width,
										justifyContent: column.align || 'center',
									}}
								>
									{column.render
										? column.render(node, quoteData)
										: column.label}
								</Box>
							))}
						</>
					)}
					{/* button group */}
					<Box
						className={classNames('label-right', {
							mini: mainViewMode !== BULK_DESIGN_VIEW_MODE.LIST_VIEW,
						})}
					>
						<Box component="span">
							{mainViewMode === BULK_DESIGN_VIEW_MODE.LIST_VIEW
								? ''
								: `$ ${quoteData?.Total?.USD ?? 0}`}
						</Box>
						<Box className="count-box">
							<Box
								component="span"
								className="tag"
								contentEditable={true}
								onKeyDown={handleCountKeyDown}
								ref={countDivRef}
								onBlur={handleCountBlur}
								suppressContentEditableWarning={true}
							>
								{node.Count}x
							</Box>
							<Box component="span" className="count-arrows-div">
								<Box
									component="span"
									className="count-arrows-item"
									onClick={() => {
										handleCountChange((node.Count + 1).toString());
									}}
								>
									<img src="/img/treeview/up-arrow.svg" />
								</Box>
								<Box
									component="span"
									className="count-arrows-item"
									onClick={() => {
										node.Count > 1 &&
											handleCountChange((node.Count - 1).toString());
									}}
								>
									<img src="/img/treeview/down-arrow.svg" />
								</Box>
							</Box>
						</Box>
						<Box className="tree-icon">
							<img src="/img/treeview/dots-option.svg" />
						</Box>
						<Box
							style={{ width: '16px' }}
							onClick={() => path && updateSelectedPath(path)}
							className="tree-icon"
						>
							{'Item' in node && (
								<img src="/img/treeview/arrow-right.svg" alt="" />
							)}
						</Box>
					</Box>
				</Box>
				{'Items' in node && node.Items.length && showChildren ? (
					<Tree
						navigatedItem={navigatedItem}
						setFieldBasedOnPath={setFieldBasedOnPath}
						selectedPath={selectedPath}
						selectedItemsData={
							selectedItemsData
								? 'Items' in selectedItemsData
									? selectedItemsData.Items
									: undefined
								: undefined
						}
						onSelect={onSelect}
						editMode={editMode}
						path={path}
						quoteData={
							quoteData
								? 'Charges' in quoteData
									? quoteData.Charges
									: 'Items' in quoteData
										? quoteData.Items
										: undefined
								: undefined
						}
						updateSelectedPath={updateSelectedPath}
						treeData={node.Items}
						onDragStart={onDragStart}
						onDrop={onDrop}
						onDragOver={onDragOver}
						topParentData={topParentData}
						setTreeData={setTreeData}
						resetNavigatedItem={resetNavigatedItem}
					/>
				) : null}
			</ListItem>
		</>
	);
}
