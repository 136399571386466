import { Mixed , Type , type as typ , array , string , literal , union , number , tuple } from 'io-ts';

import { Mod } from '~/src/base/Function';
import { Optic , dot , get1 } from '~/src/base/Optic';

export interface View {
	Cols : string[];
	Rows : string[];
}

export const View : Type<View> = typ({
	Cols : array(string),
	Rows : array(string),
});

export interface Name<T> {
	name : string;
	item : T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const nName : Optic<Name<any>, string> = dot('name');
export const nItem = <T>() : Optic<Name<T>, T> => dot('item');

export const Name : <T>( item : Type<T>) => Type<Name<T>> = <T>( item : Type<T> ) => typ({
	name : string,
	item : item,
});

export type Path = Name<Frag>[];

export type PathKey = string;

export const showPath = (path : Path) : string =>
	path
		.map( ( { name , item } : Name<Frag> ) => `${name}_${showFrag(item)}` )
		.join('-');

export const humanPath = (path: Path) : string =>
	path
		.map( ( { name, item }: Name<Frag> ) => `${name}[${humanFrag(item)}]` )
		.join('/');

export type Plan = Name<Elem[]>[];

const hasSpace = ( s : string ) : boolean => /\s/.test(s);

export const validPlan = ( plan : Plan ) : boolean => {
	const props = plan.map( ( { name } ) => name );
	const propsUnique = props.length === new Set(props).size;
	const propsHaveSpace = plan.reduce( ( acc , { name } ) => acc || hasSpace(name) , false);

	const propsValid = !!plan.length && !propsHaveSpace && plan
		.map( ( { name , item } ) => {
			const elems = item.map( get1( elLbl ) );
			const elemsHaveSpace = elems.reduce( (acc , elem) => acc || hasSpace( elem ) , false);
			const elemsUnique = elems.length === new Set(elems).size;

			return !elemsHaveSpace && (name !== '') && !!item.length && elemsUnique && item.map( validElem ).reduce( ( acc , res ) => acc && res , true );
		} )
		.reduce( (acc , res) => acc && res , true );

	return propsUnique && propsValid;
};

export const validElem = ( elem : Elem ) : boolean =>
	typeof elem === 'string' ? elem !== '' : elem.lbl !== '' && elem.lst.length !== 0;

export type Mag = 'MNum' | 'MLen' | 'MAng' | 'MAre';

export const Mag : Type<Mag> = union( [ literal('MNum') , literal('MLen') , literal('MAng') , literal('MAre') ] );

export type RRange<T> = RRangeS<T> | RRangeM<T>;

export const RRange = <T>( val : Type<T> ) : Type<RRange<T>> => union( [ RRangeS( val ) , RRangeM( val ) ] );

// Range determined by a single value
export type RRangeS<T> = RS<'REQ',T> | RS<'RLT',T> | RS<'RGT',T> | RS<'RLTE',T> | RS<'RGTE',T>;

export const RRangeS = <T>( val : Type<T> ) : Type<RRangeS<T>> =>
	union([
		RS( 'REQ' , val ),
		RS( 'RLT' , val ),
		RS( 'RGT' , val ),
		RS( 'RLTE' , val ),
		RS( 'RGTE' , val ),
	]);

// Range determined by two values
export type RRangeM<T> = RM<'RBound',T> | RM<'RBoundL',T> | RM<'RBoundR',T> | RM<'RBoundEx',T>;

export const RRangeM = <T>( val : Type<T> ) : Type<RRangeM<T>> =>
	union([
		RM( 'RBound' , val ),
		RM( 'RBoundL' , val ),
		RM( 'RBoundR' , val ),
		RM( 'RBoundEx' , val ),
	]);

export type Ranges = RangesS | RangesM;

export type RangesS = 'REQ' | 'RLT' | 'RGT' | 'RLTE' | 'RGTE';

export type RangesM = 'RBound' | 'RBoundL' | 'RBoundR' | 'RBoundEx';

export const rangesS = [ 'REQ' , 'RLT' , 'RGT' , 'RLTE' , 'RGTE' ];

export const rangesM = [ 'RBound' , 'RBoundL' , 'RBoundR' , 'RBoundEx' ];

export const ranges = rangesS.concat( rangesM );

export const Ranges : Type<Ranges> = union( [...ranges.map( n => literal(n))] as unknown as [Mixed, Mixed, ...Mixed[]] );

export const RangesS : Type<RangesS> = union( [...rangesS.map(n => literal(n))] as unknown as [Mixed, Mixed, ...Mixed[]] );

export const RangesM : Type<RangesM> = union( [...rangesM.map(n => literal(n))] as unknown as [Mixed, Mixed, ...Mixed[]] );

export interface RS<R extends RangesS, T> {
	tag : R;
	val : T;
}

export const sVal : <T>() => Optic<RRangeS<T>, T> = () => dot('val');

export const RS = <R extends RangesS, T>( tag : R , val : Type<T> ) : Type<RS<R, T>> => typ({
	tag : literal(tag),
	val : val,
});


export interface LH<T> {
	lo : T;
	hi : T;
}

export const lo : <T>() => Optic<LH<T>, T> = () => dot( 'lo' );
export const hi : <T>() => Optic<LH<T>, T> = () => dot( 'hi' );

export const LH = <T>( t : Type<T>) => typ({
	lo : t,
	hi : t,
});

export interface RM<R extends RangesM , T> {
	tag : R;
	val : LH<T>;
}

export const mVal : <T>() => Optic<RRangeM<T>, LH<T>> = () => dot('val');

export const RM = <R extends RangesM , T>( tag : R , val : Type<T> ) : Type<RM<R , T>> => typ({
	tag : literal(tag),
	val : LH(val),
});

const showRRange = <T>( cod : Type<T> , range : RRange<T> , showT : (t : T) => string ) => {
	const RS = RRangeS(cod);

	if ( RS.is( range ) ) {
		const val = showT(range.val);

		return `${range.tag}_${val}`;
	} else {
		const lo = showT(range.val.lo);
		const hi = showT(range.val.hi);

		return `${range.tag}_${lo}_${hi}`;
	}
};


export type Kind = 'KSimpl1' | 'KSimpl2' | 'KRange1' | 'KRange2';

export type KindT = {
	'KSimpl1' : number;
	'KSimpl2' : [number, number];
	'KRange1' : RRange<number>;
	'KRange2' : RRange<[number, number]>;
};

export const KindT : Type<KindT> = typ({
	'KSimpl1' : number,
	'KSimpl2' : tuple( [ number , number ] ),
	'KRange1' : RRange( number ),
	'KRange2' : RRange( tuple( [ number , number ] ) ),
});

export const KindTF = <T extends Kind>( t : T ) : Type<KindT[T]> => {
	switch( t ){
	case 'KSimpl1' : return number as unknown as Type<KindT[T]>;
	case 'KSimpl2' : return tuple( [ number , number ] ) as unknown as Type<KindT[T]>;
	case 'KRange1' : return RRange( number ) as unknown as Type<KindT[T]>;
	default : return RRange( tuple( [ number , number ] ) ) as unknown as Type<KindT[T]>;
	}
};


export type Elem = string | ElemVal<'KSimpl1'> | ElemVal<'KSimpl2'> | ElemVal<'KRange1'> | ElemVal<'KRange2'>;

export interface ElemVal<T extends Kind> {
	lbl : string;
	mag : Mag;
	knd : T;
	lst : KindT[T][];
}

export const ElemVal = <T extends Kind>( t : T ) : Type<ElemVal<T>> => typ({
	lbl : string,
	mag : Mag,
	knd : literal( t ),
	lst : array( KindTF( t ) ),
});

export const elLbl : Optic<Elem, string> = (f : Mod<string>) => ( el : Elem ) =>
	typeof el === 'string' ? f( el ) : ({ ...el , lbl : f( el.lbl ) }) as Elem;

export const elMag : <T extends Kind>() => Optic<ElemVal<T>, Mag> = () => dot('mag');

export const elLst : <T extends Kind>() => Optic<ElemVal<T>, KindT[T][]> = () => dot('lst');

export const Elem : Type<Elem> = union(
	[ string , ElemVal('KSimpl1') , ElemVal('KSimpl2') , ElemVal('KRange1') , ElemVal('KRange2') ]
);


export type Frag = string | FragVal<'KSimpl1'> | FragVal<'KSimpl2'> | FragVal<'KRange1'> | FragVal<'KRange2'>;

export interface FragVal<T extends Kind> {
	lbl : string;
	knd : T;
	val : KindT[T];
}

export const FragVal = <T extends Kind>( t : T ) : Type<FragVal<T>> => typ({
	lbl : string,
	knd : literal( t ),
	val : KindTF( t ),
});

export const fragLbl : Optic<Frag, string> = ( m : Mod<string> ) => ( f : Frag ) =>
	( string.is( f ) ? m( f ) : {...f , lbl : m( f.lbl ) } ) as Frag;

export const Frag : Type<Frag> = union(
	[ string , FragVal('KSimpl1') , FragVal('KSimpl2') , FragVal('KRange1') , FragVal('KRange2') ]
);

export const Path : Type<Path> = array(Name(Frag));

export const Plan : Type<Plan> = array(Name(array(Elem)));

export const showFrag = ( frag : Frag ) : string =>
	string.is( frag ) ? frag : `${frag.lbl}~${showKndVal(frag.knd , frag.val)}`;

export const showKndVal = <T extends 'KSimpl1' | 'KSimpl2' | 'KRange1' | 'KRange2'>( _ : T , val : KindT[T] ) => {
	if (KindTF('KSimpl1').is(val)) {
		return strNumbr(val);
	} else if (KindTF('KSimpl2').is(val)) {
		return showPair(val);
	} else if (KindTF('KRange1').is(val)) {
		return showRRange( number , val , strNumbr );
	} else {
		return showRRange( tuple( [number , number] ) , val , showPair );
	}
};

const showPair = ( [ fst , snd ] : [ number , number ] ) => `${strNumbr(fst)}_${strNumbr(snd)}`;

const strNumbr = ( n : number ) : string => Math.round(n) === n ? n + '.0' : n.toString();

export const humanFrag = ( frag : Frag ) : string => {
	if (string.is(frag)) {
		return frag as string;
	} else if ( FragVal('KSimpl1').is(frag) ) {
		return `${frag.lbl}:${frag.val}`;
	} else if ( FragVal('KSimpl2').is(frag) ) {
		return `${frag.lbl}:${frag.val[0]}x${frag.val[1]}`;
	} else if ( FragVal('KRange1').is(frag) ) {
		return `${frag.lbl}:${humanRange<number>( number , frag.val , n => `${n}`)}`;
	} else {
		return `${frag.lbl}:${humanRange<[number, number]>( tuple( [number, number] ) , frag.val , n => `${n[0]}x${n[1]}`)}`;
	}
};

export const humanRange = <T>( cod : Type<T> , range : RRange<T> , showT : (t : T) => string ) => {
	const RS = RRangeS(cod);

	if (RS.is(range)) {
		const val = showT(range.val);

		switch( range.tag ) {
		case 'REQ' : return `(= ${val})`;
		case 'RLT' : return `(< ${val})`;
		case 'RGT' : return `(> ${val})`;
		case 'RLTE' : return `(≤ ${val})`;
		case 'RGTE' : return `(≥ ${val})`;
		}
	} else {
		const lo = showT(range.val.lo);
		const hi = showT(range.val.hi);

		switch ( range.tag ){
		case 'RBound' : return `[${lo} , ${hi}]`;
		case 'RBoundL' : return `[${lo} , ${hi}〉`;
		case 'RBoundR' : return `〈${lo} , ${hi}]`;
		case 'RBoundEx' : return `〈${lo} , ${hi}〉`;
		}
	}
};
