import * as React from 'react';
import type {} from '@mui/material/themeCssVarsAugmentation';
import { Toolbar, ToolbarProps, styled } from '@mui/material';

export const minToolbarHeight = '70px';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
	'&.MuiToolbar-root': {
		backgroundColor: theme.vars.palette.background.default,
		height: minToolbarHeight,
		minHeight: minToolbarHeight,
	}
}));

export const QToolbar = ({ children, ...toolbarProps }: React.PropsWithChildren<ToolbarProps>) => {
	return <StyledToolbar
		{...toolbarProps}
	>
		{children}
	</StyledToolbar>;
};
