/**
 * TemplateViewer.
 *
 * Front-end related template drawings.
 *
 * @since 0.4.0
 */
import * as React from 'react';

import { Template } from '~/src/design/template';
import { BuildReq, BuildResp, build, DrawingType, FigDrawingFormatTranslations, FigDrawingFormat, DrawingTypeTranslations } from '~/src/design/template/api/build';

import { Requestor } from '~/src/api/Requestor';

import { missing as missingDrawing } from '~/src/model/Drawing';
import { Drawing } from '~/src/view/Drawing';

import {
	equals as deepEquals,
} from '~/src/util/Deep';
import { FigureViewer } from '~/src/figure/View';
import { AngleConverters, inchConverter } from '~/src/util/Units';
import SelectEnum from '~/src/edit/SelectEnum';
import { Mod } from '~/src/base/Function';
import { download, FileInfo } from '~/src/ui/input/FileDownload';

import * as css from './view.module.css';

/**
 * TemplateViewer Props.
 *
 * @since 0.4.0
 */
export interface TemplateProps {
	req: Requestor;
	template: Template;
}

const getDrawings = (req: Requestor, template: Template, drawType: DrawingType, format: FigDrawingFormat): Promise<BuildResp> => {
	const buildreq: BuildReq = {
		Template: template,
		GetFigures: format === 'figure' ? [drawType] : undefined,
		GetDrawings: format === 'figure' ? undefined : [{Type: drawType, Format: format}],
	};
	return build(req)(buildreq);
};

export const TemplateView = (props: TemplateProps) => {
	const { req, template } = props;

	const [_loading, setLoading] = React.useState<boolean>(true);
	const [lastResp, setLastResp] = React.useState<BuildResp>({ Messages: [] });
	const [drawType, setDrawType] = React.useState<DrawingType>('Design');
	const [format, setFormat] = React.useState<FigDrawingFormat>('figure');

	React.useEffect(() => {
		setLoading(true);
		getDrawings(req, template, drawType, format).then((resp: BuildResp) => {
			setLoading(false);
			setLastResp(resp);
		}).catch((err) => {
			console.warn(err);
			setLastResp({ Messages: [{ level: 'error', text: err.toString() }] });
			setLoading(false);
		});
	}, [req, template, drawType, format]);

	const handleFormatSelection = React.useCallback((f: Mod<FigDrawingFormat>) => {
		const fmt = f('svg');
		// Download dxf or pdf, don't try to show it.
		if (fmt === 'dxf' || fmt === 'pdf') {
			getDrawings(req, template, drawType, fmt).then((resp: BuildResp): Promise<FileInfo> => {
				const drawing = (resp.Drawings ?? [])[0];
				if (!drawing) return Promise.reject('No drawings.');
				return Promise.resolve({
					name: `template-${drawType}`,
					type: drawing.Format,
					getContent: () => drawing.Data,
					base64: true,
				});
			}).then(download).catch(console.warn);
		} else {
			setFormat(f);
		}
	}, [req, template, drawType, setFormat]);

	const fig = lastResp.Figures?.[drawType];

	return <div className={`template-viewer ${css['template-viewer']}`}>
		<div className={css.controls}>
			<SelectEnum size='small' items={DrawingTypeTranslations} value={drawType} update={setDrawType} />
			<SelectEnum size='small' items={FigDrawingFormatTranslations} value={format} update={handleFormatSelection} />
		</div>
		{ fig === undefined ? <Drawing {...(lastResp.Drawings ?? [missingDrawing])[0]} /> :
			<FigureViewer value={fig} lengthConverter={inchConverter} angleConverter={AngleConverters.exterior} />
		}
	</div>;
};

export const TemplateViewer = React.memo(
	TemplateView,
	(prevProps, nextProps) => {
		return deepEquals(prevProps, nextProps);
	}
);

