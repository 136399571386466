import { constant, pipe } from 'fp-ts/lib/function';
import React from 'react';
import * as O from 'fp-ts/lib/Option';
import * as A from 'fp-ts/lib/Array';
import { Mod } from '~/src/base/Function';
import { XY } from '~/src/ui/PieMenu';
import { touchListToArray } from './useTouchAngle';
import { sequenceTOption } from '~/src/edit/util';

const MAX_ZOOM = 0.10;
const MIN_ZOOM = 4;

export type Viewbox = { x: number, y: number, w: number, h: number };

const handleTouchZoom = (svgSize: {w: number, h: number} | null) => (m: XY) => (viewBox: Viewbox) => (distanceRatio: number) => {
	if (!svgSize) return null;

	const w = viewBox.w;
	const h = viewBox.h;
	const dw = (w*distanceRatio) - w;
	const dh = (h*distanceRatio) - h;

	const dx = dw*m.x/svgSize.w;
	const dy = dh*m.y/svgSize.h;

	const newScale = svgSize.w/(viewBox.w - dw);
	const isOutOfBounds = newScale < MAX_ZOOM || newScale > MIN_ZOOM;

	return isOutOfBounds ? viewBox : {
		x: viewBox.x + dx,
		y: viewBox.y + dy,
		w: viewBox.w - dw,
		h: viewBox.h - dh
	};
};

/*
 * Calculate new SVG viewbox zoom from mouse interaction
 */
const handleMouseZoom = (svgSize: {w: number, h: number} | null) => (m: XY) => (viewBox: Viewbox) => (distanceDelta: number) => {
	if (!svgSize) return null;

	const w = viewBox.w;
	const h = viewBox.h;
	const dw = w*Math.sign(distanceDelta)*0.05*-1;
	const dh = h*Math.sign(distanceDelta)*0.05*-1;
	const dx = dw*m.x/svgSize.w;
	const dy = dh*m.y/svgSize.h;

	const newScale = svgSize.w/(viewBox.w - dw);
	const isOutOfBounds = newScale < MAX_ZOOM || newScale > MIN_ZOOM;

	return isOutOfBounds ? viewBox : {
		x: viewBox.x + dx,
		y: viewBox.y + dy,
		w: viewBox.w - dw,
		h: viewBox.h - dh
	};
};

/*
 * Update x and y values in SVG viewbox
 */
const updateViewboxXY: (xy: XY) => Mod<Viewbox> = (xy: XY) => ({x, y, ...rest}: Viewbox) => {
	return { x: (x+xy.x), y: (y+xy.y), ...rest };
};

/*
 * Add two `TouchPageChange` types together for calculating zoom values
 */
const sumTouchPageData: (b: XY, a: Touch | React.Touch) => XY = (b: XY, a: Touch | React.Touch) => {
	return { x: b.x+a.pageX, y: b.y+a.pageY };
};

/*
 * Average of multiple `TouchPageChange` types used for calculating zoom values
 */
const avgTouchPageData: (a: (Touch | React.Touch)[]) => XY = (a: (Touch | React.Touch)[]) => pipe(
	a,
	A.reduce({ x: 0, y: 0 }, sumTouchPageData),
	({ x, y }) => ({ x: x / a.length, y: y / a.length })
);

/*
 * Average of multiple `XY` type coordinates
 */
const avgXY: (a: XY[]) => XY = (a: XY[]) => pipe(
	a,
	A.reduce({ x: 0, y: 0 }, (b, a) => ({ x: b.x + a.x, y: b.y + a.y })),
	({ x, y }) => ({ x: x / a.length, y: y / a.length })
);

/*
 * Calculate mouse delta given to `TouchPageChange` objects
 */
const touchMovement: (a: XY, b: XY) => { mx: number, my: number } = (a: XY, b: XY) => ({
	mx: b.x - a.x,
	my: b.y - a.y
});

/*
 * Get delta x and y from `TouchPageChange`'s as a ratio of the SVG to container scale
 */
const deltaPageScale = (scale: number) => (movement: { mx: number, my: number }) => ({
	dx: ((0 - movement.mx) / scale),
	dy: ((0 - movement.my) / scale)
});

/*
 * Calculate an average `TouchPageChange` from a TouchList
 */
const touchAvgFromTouches = (a: TouchList) => pipe(
	a,
	touchListToArray,
	avgTouchPageData,
);

/*
 * Change in coordinates between mouse events as a ratio of the SVG to container scale
 */
const deltaMouse = (scale: number) => (initial: XY, ev: React.MouseEvent<HTMLDivElement>) => ({
	dx: (initial.x - ev.movementX) / scale,
	dy: (initial.y - ev.movementY) / scale
});

/*
 * Transform a `Touch` to an `XY` given a vertical offset
 */
const toXY = (offsetTop: number) => (touch: Touch) => {
	return {
		x: touch.clientX,
		y: touch.clientY - offsetTop
	};
};

/*
 * Distance between two points (`XY`)
 */
const distance = (xy1: XY, xy2: XY) => Math.sqrt(Math.pow((xy2.x - xy1.x), 2) + Math.pow((xy2.y - xy1.y), 2));

const useDrawingInteractions = (onDown: (e: React.MouseEvent | React.TouchEvent) => void, activeElId: string | null) => {
	/*
	 * Ref for container that holds SVG
	 *
	 *
	 * Left side of this container must be
	 * against the left side of the window or
	 * you need a left offset
	 */
	const containerRef = React.useRef<HTMLDivElement>(null);

	/*
	 * Zoom for SVG
	 *
	 * scale = svg width / viewbox w
	 */
	const [scale, setScale] = React.useState(1);

	/*
	 * State for tracking svg size used in calculating scale and zoom
	 */
	const [svgSize, setSvgSize] = React.useState<{ w: number, h: number } | null>(null);

	/*
	 * State for svg viewBox attribute. Viewbox is used for svg zooming
	 */
	const [viewBox, setViewBox] = React.useState<Viewbox>({ x: 0, y: 0, w: 1024, h: 1024 });

	/*
	 * State for correctly positioning the viewBox when zooming
	 */
	const [offsetTop, setOffsetTop] = React.useState<number>(0);

	/*
	 * Calculate zoom scale
	 */
	React.useEffect(() => {
		if (svgSize?.w) {
			setScale(svgSize.w/viewBox.w);
		}
	}, [svgSize?.w, viewBox.w]);

	/*
	 * Initial calculation to set svgSize and offset
	 * from top to use for zooming
	 */
	React.useEffect(() => {
		if (containerRef?.current) {
			const rect = containerRef?.current.getBoundingClientRect();
			setSvgSize({ w: rect.width, h: rect.height });
			setOffsetTop(rect.top);
		}
	}, [containerRef]);

	/*
	 * Panning ability for mouse and touch events
	 *
	 */
	const [panning, setPanning] = React.useState(false);

	/*
	 * State to track first point of contact with mouse from user
	 */
	const [mouseStartPosition, setMouseStartPosition] = React.useState({ x: 0, y: 0 });

	/*
	 *
	 * Mouse events
	 *
	 */
	const onMouseDown = (e: React.MouseEvent) => {
		onDown(e);
		setPanning(true);
		setMouseStartPosition({x: e.movementX, y: e.movementY});
	};

	const mousePanWithScale = deltaMouse(scale);
	const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
		if (panning) {
			const d = mousePanWithScale(mouseStartPosition, e);
			setViewBox({x:viewBox.x+d.dx,y:viewBox.y+d.dy,w:viewBox.w,h:viewBox.h});
		}
	};

	const onMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
		if (panning) {
			const d = mousePanWithScale(mouseStartPosition, e);
			setViewBox({x:viewBox.x+d.dx,y:viewBox.y+d.dy,w:viewBox.w,h:viewBox.h});
			setPanning(false);
		}
	};

	const onMouseLeave = (_: React.MouseEvent<HTMLDivElement>) => {
		setPanning(false);
	};

	const wheel = React.useCallback((e: WheelEvent) => {
		e.preventDefault();
		pipe(
			handleMouseZoom(svgSize)({ x: e.pageX, y: e.pageY - offsetTop })(viewBox)(e.deltaY),
			O.fromNullable,
			O.fold(constant(null), setViewBox)
		);
	}, [offsetTop, svgSize, viewBox]);

	/*
	 * Add container event listeners for mouse
	 */
	React.useEffect(() => {
		const el = containerRef?.current;
		if (el) {
			/*
			 * Event handler needs to be passed to node
			 * as opposed to being passed as React prop because
			 * React does not support non-passive wheel events
			 */
			containerRef.current.addEventListener('wheel', wheel, { passive: false });

			return () => {
				el?.removeEventListener('wheel', wheel);
			};
		}
		return;

	}, [wheel]);

	const [touchStartPosition, setTouchStartPosition] = React.useState<XY>({ x: 0, y: 0});

	const [touchStartDistance, setTouchStartDistance] = React.useState(0);
	/*
	 *
	 * Touch events
	 *
	 */
	const onTouchStart = (e: React.TouchEvent) => {
		pipe(
			e,
			O.fromPredicate((event) => pipe(event.touches, touchListToArray).length === 1),
			O.fold(constant(null), onDown)
		);
	};

	React.useEffect(() => {
		const containerEl = containerRef?.current;
		function handlePanTouchStart(e: TouchEvent) {
			e.preventDefault();

			// Pan
			pipe(
				e.touches,
				touchListToArray,
				O.fromPredicate((touchList) => touchList.length === 1),
				O.fold(constant(null), (touches) => {
					setPanning(true);
					pipe(
						touches,
						avgTouchPageData,
						setTouchStartPosition
					);

				})
			);

			// Zoom
			pipe(
				e.touches,
				(a) => sequenceTOption(pipe(a.item(0), O.fromNullable, O.map(toXY(offsetTop))), pipe(a.item(1), O.fromNullable, O.map(toXY(offsetTop)))),
				O.fold(constant(null), (touchPoints) => {
					const dist = distance(...touchPoints);
					const center = avgXY(touchPoints);
					setPanning(false);
					setTouchStartDistance(dist);
					setTouchStartPosition(center);
				})
			);
		}

		function handlePanTouchMove(e: TouchEvent) {
			e.preventDefault();

			// Pan
			pipe(
				e.touches,
				touchListToArray,
				O.fromPredicate((touchList) => touchList.length === 1),
				O.chain(O.fromPredicate(() => panning && !activeElId)),
				O.map(avgTouchPageData),
				O.map((b) => touchMovement(touchStartPosition, b)),
				O.map((mov) => deltaPageScale(scale)(mov)),
				O.map((a) => updateViewboxXY({ x: a.dx, y: a.dy })),
				O.fold(constant(null), setViewBox)
			);

			pipe(
				e.touches,
				O.fromPredicate(() => panning && !activeElId),
				O.map(touchAvgFromTouches),
				O.fold(constant(null), setTouchStartPosition)
			);

			// Zoom
			pipe(
				e.touches,
				(a) => sequenceTOption(pipe(a.item(0), O.fromNullable, O.map(toXY(offsetTop))), pipe(a.item(1), O.fromNullable, O.map(toXY(offsetTop)))),
				O.map((xys) => {
					const center = avgXY(xys);
					const scale = distance(...xys) / touchStartDistance;

					setTouchStartDistance(distance(...xys));
					return handleTouchZoom(svgSize)(center)(viewBox)(scale);
				}),
				O.chain(O.fromNullable),
				O.fold(constant(null), (vb) => {
					setViewBox(vb);
				})
			);
		}
		function handleTouchEnd() {
			setPanning(false);
			setTouchStartPosition({ x: 0, y: 0 });
			setTouchStartDistance(0);
		}

		if (containerEl) {
			containerEl.addEventListener('touchstart', handlePanTouchStart, { passive: false });
			containerEl.addEventListener('touchmove', handlePanTouchMove, { passive: false });
			containerEl.addEventListener('touchend', handleTouchEnd);

			return () => {
				containerEl.removeEventListener('touchstart', handlePanTouchStart);
				containerEl.removeEventListener('touchmove', handlePanTouchMove);
				containerEl.removeEventListener('touchend', handleTouchEnd);

			};
		}
		return;
	}, [containerRef, panning, scale, touchStartPosition, activeElId, offsetTop, svgSize, viewBox, touchStartDistance]);

	return {
		onTouchStart,
		onMouseDown,
		onMouseLeave,
		onMouseMove,
		onMouseUp,
		zooming: Boolean(touchStartDistance),
		containerRef,
		viewBox,
	};
};

export default useDrawingInteractions;
