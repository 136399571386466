import * as React from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import SaveIcon from '@mui/icons-material/Save';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import CloseIcon from '@mui/icons-material/Close';
import TerminalIcon from '@mui/icons-material/Terminal';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { set } from '~/src/base/Optic';
import { EditProps } from '~/src/edit/types';
import { Requestor } from '~/src/api/Requestor';
import { getPriceGrid , getPriceGridList , postPriceList } from '~/src/api/Pricing';
import { PriceGridEditPage } from '~/src/pages/pricing/PriceGrid';
import { DhallEdit } from '~/src/edit/Dhall';

import { Code , PriceList , GridId , PriceGrid , PriceElem , plCode , plElems } from '~/src/model/Pricing';

const LinkGrid = ( { req , value , update } : { req : Requestor } & EditProps<PriceList> ) => {
	type ButtonStatus = 'Normal' | 'Sending' | 'Error';

	const [ status , setStatus ] = React.useState<ButtonStatus>( 'Normal' );

	const [ open , setOpen ] = React.useState( false );
	const [ grids , setGrids ] = React.useState<PriceGrid[]>( [] );
	const [ selected , setSelected ] = React.useState<GridId[]>( [] );

	const curGrids : GridId[] = value.Elems.map( elem => elem.Grid );

	React.useEffect( () => {
		getPriceGridList( req ).then( grids => setGrids( Object.values( grids ) ) ).catch( console.error );
	} , [ req , value ] );

	const makePriceElem = ( s : GridId ) : PriceElem => ({ Grid : s });

	const handleAdd = () => {
		const newElems = selected.map(makePriceElem);
		const newPList = plElems( old => old.concat( newElems ) )( value );

		setStatus( 'Sending' );

		postPriceList( req )( newPList )
			.then( list => {
				update( () => list );
				setOpen( false );
				setStatus( 'Normal' );
			} )
			.catch( err => {
				console.error( err );
				setStatus( 'Error' );
			} );
	};

	return (
		<>
			<Button variant='outlined' endIcon={<LinkIcon />} onClick={ () => setOpen( true ) }>Link grid</Button>
			<Dialog open={open} onClose={ () => setOpen( false ) } >
				<DialogTitle>Select price grids to add</DialogTitle>

				<DialogContent>
					<Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
						<FormControl sx={{ m: 1 , width : 300 }}>
							<Select
								multiple
								value={ selected }
								onChange={ ( e : SelectChangeEvent<typeof selected> ) => {
									const val = e.target.value;
									setSelected( typeof val === 'string' ? val.split(',') : val );
								} }
								input={ <OutlinedInput /> }
								renderValue={ selected => (
									<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
										{selected.map((value) => {
											return <Chip key={value} label={value} />;
										}
										)}
									</Box>
								) }
							>
								{ grids.filter( grid => !curGrids.includes( grid.Id ) ).map( grid => <MenuItem key={grid.Id} value={grid.Id}>{ grid.Name }</MenuItem> ) }
							</Select>
						</FormControl>
					</Box>
				</DialogContent>

				<DialogActions>
					<Button onClick={ () => setOpen( false ) }>Cancel</Button>
					<Button
						color={status === 'Error' ? 'error' : 'primary' }
						disabled={status === 'Sending'}
						endIcon={<SaveIcon />}
						variant='outlined'
						onClick={handleAdd}
					>
						{ status === 'Sending' ? <span>Saving...</span> : <span>Add</span> }
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

const UnlinkGrid = ( { idx , req , value , update } : { idx : number , req : Requestor } & EditProps<PriceList> ) => {
	type ButtonStatus = 'Normal' | 'Sending' | 'Error';

	const [ status , setStatus ] = React.useState<ButtonStatus>( 'Normal' );

	const handleUnlink = () => {
		const newElems = [...value.Elems];
		newElems.splice(idx, 1);
		const newPList = set(plElems)(newElems)(value);

		setStatus( 'Sending' );

		postPriceList( req )( newPList )
			.then( list => {
				update( () => list );
				setStatus( 'Normal' );
			} )
			.catch( err => {
				setStatus( 'Error' );
				console.error( err );
			} );
	};

	return (
		<Button
			color={ status === 'Error' ? 'error' : 'primary' }
			disabled={ status === 'Sending' }
			endIcon={ <LinkOffIcon /> }
			variant='outlined'
			onClick={ handleUnlink }
		>
			{ status === 'Sending' ? <span>Saving...</span> : <span>Unlink</span> }
		</Button>
	);
};

export const PriceListEdit = ( { req , value , update } : { req : Requestor } & EditProps<PriceList> ) => {
	const [ tab , setTab ] = React.useState( 0 );

	const [ grids , setGrids ] = React.useState<PriceGrid[]>( [] );

	const [ listCode , setListCode ] = React.useState<Code>( value.Code );

	const [ dhallEditorOpen , setDhallEditorOpen ] = React.useState(false);

	const dhallToggle = () => setDhallEditorOpen(!dhallEditorOpen);

	React.useEffect( () => {
		Promise
			.all( value.Elems.map( elem => getPriceGrid( req )( elem.Grid ) ) )
			.then( ( grids : PriceGrid[] ) => setGrids( grids ) );
	} , [ req , value.Elems ] );

	const saveCode = () : void => {
		const updatedList : PriceList = set( plCode )( listCode )( value );

		// save to the DB
		postPriceList( req )( updatedList )
			.then( lst => update( () => lst ) )
			.catch( console.error );
	};

	const selectedGrid : PriceGrid | undefined = grids[tab];

	const gridEditor = selectedGrid ? <PriceGridEditPage req={req} grid={selectedGrid} /> : null;

	const dhallEditor = (
		<Grid container spacing={2}>
			<Grid item xs={8}>
				<Paper elevation={12}>
					<DhallEdit
						value={ listCode }
						update={ f => setListCode( f( listCode ) ) }
					/>
				</Paper>
			</Grid>

			<Grid item xs={4}>
				<Paper elevation={4}>
					<Toolbar>
						<Button onClick={ saveCode } endIcon={ <SaveIcon /> }>Save</Button>
					</Toolbar>
				</Paper>
			</Grid>
		</Grid>
	);

	return (
		<Box sx={{ height : '100%' , display : 'flex' , flexDirection : 'column' }}>
			<Grid container spacing={2} sx={{ flex : 0 }}>
				<Grid item xs={4}>
					<Paper variant='outlined'>
						<Tabs value={ tab } onChange={ (_ , t) => setTab( t ) } variant='scrollable' scrollButtons>
							{ value.Elems.map( (elem , ix ) => <Tab key={elem.Grid} label={ grids[ix]?.Name ?? elem.Grid } /> ) }
						</Tabs>
					</Paper>
				</Grid>

				<Grid item xs={8}>
					<Box sx={{ display : 'flex' , height : '100%' }}>
						<ButtonGroup size='large'>
							<LinkGrid req={req} value={value} update={update} />
							<UnlinkGrid idx={tab} req={req} value={value} update={update} />
							<Button onClick={dhallToggle} endIcon={ dhallEditorOpen ? <CloseIcon /> : <TerminalIcon /> }>Dhall</Button>
						</ButtonGroup>
					</Box>
				</Grid>
			</Grid>

			<Grid container spacing={2} sx={{ flex : 1 , marginTop : 0 }} direction='column'>
				<Grid item xs={12} sx={{ flex : 1 }}>
					{ dhallEditorOpen ? dhallEditor : gridEditor }
				</Grid>
			</Grid>
		</Box>
	);
};

export default PriceListEdit;

