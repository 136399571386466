import React from 'react';

const useAccordion = (initialValue?: string | false) => {
	const [expanded, setExpanded] = React.useState<string | false>(initialValue || false);

	const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return {
		expanded,
		handleChange
	};
};

export default useAccordion;
