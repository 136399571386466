import * as React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { Translations, TranslationList } from '~/src/model/Language';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EditProps } from '~/src/edit/types';
import { useLanguage } from '~/src/user/language';

export interface SelectEnumProp<T extends string> extends EditProps<T> {
	id?: string;
	label?: Translations;
	items: TranslationList<T>;
	size?: 'small' | 'medium';
}

export const SelectEnum = <T extends string>(ps: SelectEnumProp<T>): React.ReactElement => {
	const mentry = ps.items.find((item) => item.key === ps.value);
	const entry = mentry ?? { key: ps.value, names: {} };
	const options = mentry === undefined ? [entry, ...ps.items] : ps.items;
	const lang = useLanguage();
	return <Autocomplete id={ps.id} autoHighlight autoSelect disableClearable size={ps.size}
		sx={{ minWidth: '20ex'}} //Math.max(3, ...ps.items.map(item => (item.names[lang] ?? item.key).length * 1 + 6)).toString() + 'ex'
		value={entry}
		onChange={(_ev, item) => ps.update(() => item.key)}
		options={options}
		getOptionLabel={(item) => item.names[lang] ?? item.key}
		renderInput={(params) => <TextField {...params} size={ps.size} label={(ps.label ?? {})[lang]} />}
		popupIcon={<ExpandMoreIcon />}
	/>;
};

export interface SelectEnumSetProp<T extends string> extends EditProps<T[]> {
	id?: string;
	label?: Translations;
	items: TranslationList<T>;
	size?: 'small' | 'medium';
}

export const SelectEnumSet = <T extends string>(ps: SelectEnumSetProp<T>): React.ReactElement => {
	const lang = useLanguage();
	return <Autocomplete id={ps.id} multiple autoHighlight autoSelect disableClearable size={ps.size}
		sx={{ width: Math.max(3, ...ps.items.map(item => (item.names[lang] ?? item.key).length + 6)).toString() + 'ex' }}
		value={ps.value.map(sel => ps.items.find((item) => item.key === sel) ?? { key: sel, names: {} })}
		onChange={(_ev, item) => ps.update(() => item.map(e => e.key))}
		options={ps.items}
		getOptionLabel={(item) => item.names[lang] ?? item.key}
		renderInput={(params) => <TextField {...params} label={(ps.label ?? {})[lang]}/>}
	/>;
};

export default SelectEnum;
