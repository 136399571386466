/**
 * Options on Templates.
 *
 * @since 0.1.0
 */
import {
	array,
	boolean,
	interface as iface,
	keyof,
	literal,
	number,
	partial,
	recursion,
	string,
	tuple,
	Type,
	union,
} from 'io-ts';
import { Translations, TranslationList } from '~/src/model/Language';
import { PartialOrdering } from '~/src/model/PartialOrdering';

export const limitTranslations: TranslationList<LimitType> = [
	{ key: 'All', names: { eng: 'all' } },
	{ key: 'Any', names: { eng: 'any' } },
	{ key: 'If', names: { eng: 'if' } },
	{ key: 'Opt', names: { eng: 'opt' } },
	{ key: 'Var', names: { eng: 'var' } },
	{ key: 'Var2', names: { eng: 'var2' } },
];

export type LimitType = Limit['type'];
export const LimitType: Type<LimitType> = keyof({
	All: true,
	Any: true,
	If: true,
	Opt: true,
	Var: true,
	Var2: true,
});

/**
 * An expression descriming limitations on the selected options and values of variable in a template.
 *
 */
export type Limit = All | Any | If | Opt | Var | Var2;
/**
 * Codec for Limit
 *
 */
export const Limit: Type<Limit> = recursion('Limit', () => union([All, Any, If, Opt, Var, Var2]));

/**
 * All the contained limits limits must be met.
 */
export type All = Readonly<{
	type: 'All',
	Of: Limit[],
}>;
/**
 * Codec for All
 */
export const All: Type<All> = iface({
	type: literal('All'),
	Of: array(Limit),
});
/**
 * Refinement for All
 */
export const isAll = (x: Limit): x is All => x.type === 'All';
/**
 * Default for All
 */
export const defAll: All = {
	type: 'All',
	Of: [],
};

/**
 * Any of the contained limits limits must be met.
 */
export type Any = Readonly<{
	type: 'Any',
	Of: Limit[],
}>;
/**
 * Codec for Any
 */
export const Any: Type<Any> = iface({
	type: literal('Any'),
	Of: array(Limit),
});
/**
 * Refinement for Any
 */
export const isAny = (x: Limit): x is Any => x.type === 'Any';
/**
 * Default for Any
 */
export const defAny: Any = {
	type: 'Any',
	Of: [],
};

/**
 * If the If limit is met, the Then limit must be met.
 */
export type If = Readonly<{
	type: 'If',
	If: Limit,
	Then: Limit,
}>;
/**
 * Codec for If
 */
export const If: Type<If> = iface({
	type: literal('If'),
	If: Limit,
	Then: Limit,
});
/**
 * Refinement for If
 */
export const isIf = (x: Limit): x is If => x.type === 'If';
/**
 * Default for If
 */
export const defIf: If = {
	type: 'If',
	If: defAll,
	Then: defAll,
};

/**
 * If Member is true, the selected entry of Space must be listed in Option.
 * If Member is false, the selected entry of Space must not be listed in Option.
 */
export type Opt = Readonly<{
	type: 'Opt',
	Space: string,
	Member: boolean,
	Option: string[],
}>;
/**
 * Codec for Opt
 */
export const Opt: Type<Opt> = iface({
	type: literal('Opt'),
	Space: string,
	Member: boolean,
	Option: array(string),
});
/**
 * Refinement for Opt
 */
export const isOpt = (x: Limit): x is Opt => x.type === 'Opt';
/**
 * Default for Opt
 */
export const defOpt: Opt = {
	type: 'Opt',
	Member: true,
	Space: '',
	Option: [],
};

/**
 * Expect the Relation between the value of the Variable and the Threshold to hold.
 */
export type Var = Readonly<{
	type: 'Var',
	Variable: string,
	Relation: PartialOrdering,
	Threshold: number,
}>;
/**
 * Codec for Var
 */
export const Var: Type<Var> = iface({
	type: literal('Var'),
	Variable: string,
	Relation: PartialOrdering,
	Threshold: number,
});
/**
 * Refinement for Var
 */
export const isVar = (x: Limit): x is Var => x.type === 'Var';
/**
 * Default for Var
 */
export const defVar: Var = {
	type: 'Var',
	Variable: '',
	Threshold: 0,
	Relation: '≤',
};

/**
 * Expect the Relation between the value of the Variables and the Thresholds to
 * hold, either matching 0 to 0 and 1 to 1 or 0 to 1 and 1 to 0.
 *
 * E.g. both [4,8] < [9,6] and [4,8] < [9,6] will succeed
 */
export type Var2 = Readonly<{
	type: 'Var2',
	Variables: [string, string],
	Relation: PartialOrdering,
	Thresholds: [number, number],
}>;
/**
 * Codec for Var
 */
export const Var2: Type<Var2> = iface({
	type: literal('Var2'),
	Variables: tuple([string, string]),
	Relation: PartialOrdering,
	Thresholds: tuple([number, number]),
});
/**
 * Refinement for Var2
 */
export const isVar2 = (x: Limit): x is Var2 => x.type === 'Var2';
/**
 * Default for Var2
 */
export const defVar2: Var2 = {
	type: 'Var2',
	Variables: ['', ''],
	Thresholds: [0,0],
	Relation: '≤',
};

/**
 * A set of limits stored in the database with a particular id and name.
 *
 */
export type Limits = Readonly<{
	Id?: string,
	Owner?: string,
	Names?: Translations,
	Limits?: Limit[],
}>;
/**
 * Codec for Limits
 *
 */
export const Limits: Type<Limits> = partial({
	Id: string,
	Owner: string,
	Names: Translations,
	Limits: array(Limit),
});
