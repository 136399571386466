import { debug_memo, memo } from '~/src/base/Memoize';

/**
 * A function from A to B.
 * @example
 * const fn: Fn<string, number> = (x: string) => parseInt(x);
 */
export type Fn<A,B> = (x: A) => B;

/**
 * A generalized function, from ...T to S.
 */
export type Varadic<T extends unknown[],R> = (...ps: T) => R;

/**
 * Turn an S into a T by turning As into Bs.
 */
export type Modify<S,A,B=A,T=S> = Fn<Fn<A,B>,Fn<S,T>>;

/**
 * Type-preserving update.
 *
 * @example
 * const inc: Mod<number> = (x: number) => x + 1;
 */
export type Mod<A> = Fn<A,A>;

/**
 * Perform some action that takes a type-preserving update.
 *
 * Usually used as an argument to a editing component.
 *
 * @example
 * const incUpdater: Update<number> = (inc: Mod<number>) => {
 *  inc(0);
 * }
 */
export type Update<T> = Fn<Mod<T>,void>;

/**
 * Predicates on type `A`.
 */
export type Pred<A> = Fn<A,boolean>

/**
 * Refinements from type `A` to `B`.
 */
export type Is<A,B extends A> = (x: A) => x is B;

/**
 * The constant function, used to set a value.
 */
export const constant = <A,B=A>(x: A) => (_: B): A => x;

/*
 * The rest of the file is pipe and compose.
 *
 * It is possible to fully type them, but it hurts the error messages and type inference.
 * So instead here we provide aliases, and the actual implementation is less strictly typed.
 *
 * Hence:
 */
/* eslint-disable @typescript-eslint/no-explicit-any */

export function pipe<A>(): Fn<A,A>;
export function pipe<A,B>(f: Fn<A,B>): Fn<A,B>;
export function pipe<A,B,C>(f: Fn<A,B>, g: Fn<B,C>): Fn<A,C>;
export function pipe<A,B,C,D>(f: Fn<A,B>, g: Fn<B,C>, h: Fn<C,D>): Fn<A,D>;
export function pipe<A,B,C,D,E>(f: Fn<A,B>, g: Fn<B,C>, h: Fn<C,D>, i: Fn<D,E>): Fn<A,E>;
/**
 * Construct a pipeline of functions, by feeding the value into the first function,
 * the result of that into the second function, and so on, returning the result of the last function.
 *
 * The list of functions is memoized, however the inputs are not.
 */
export function pipe(...fs: any[]): unknown {
	return memo(pipe, fs as any, () => (x: any) => fs.reduce((a: any, f: any) => f(a), x));
}

export function mpipe<A>(...fs: Mod<A>[]): Mod<A> {
	return memo(mpipe, fs as Mod<A>[], () => (x: A) => fs.reduce((a: A, f: Mod<A>) => f(a), x));
}

export function debug_pipe<A>(): Fn<A,A>;
export function debug_pipe<A,B>(f: Fn<A,B>): Fn<A,B>;
export function debug_pipe<A,B,C>(f: Fn<A,B>, g: Fn<B,C>): Fn<A,C>;
export function debug_pipe<A,B,C,D>(f: Fn<A,B>, g: Fn<B,C>, h: Fn<C,D>): Fn<A,D>;
export function debug_pipe<A,B,C,D,E>(f: Fn<A,B>, g: Fn<B,C>, h: Fn<C,D>, i: Fn<D,E>): Fn<A,E>;
/**
 * Construct a dpipeline of functions, by feeding the value into the first function,
 * the result of that into the second function, and so on, returning the result of the last function.
 *
 * The list of functions is memoized, however the inputs are not.
 */
export function debug_pipe(...fs: any[]): unknown {
	return debug_memo(debug_pipe, fs as any, () => (x: any) => fs.reduce((a: any, f: any) => {
		const b = f(a);
		console.warn(f, a, b);
		return b;
	}, x));
}

export function comp<A>(): Fn<A,A>;
export function comp<B,A>(f: Fn<A,B>): Fn<A,B>;
export function comp<C,B,A>(f: Fn<B,C>, g: Fn<A,B>): Fn<A,C>;
export function comp<D,C,B,A>(f: Fn<C,D>, g: Fn<B,C>, h: Fn<A,B>): Fn<A,D>;
export function comp<E,D,C,B,A>(f: Fn<D,E>, g: Fn<C,D>, h: Fn<B,C>, i: Fn<A,B>): Fn<A,E>;
export function comp<F,E,D,C,B,A>(f: Fn<E,F>, g: Fn<D,E>, h: Fn<C,D>, i: Fn<B,C>, j: Fn<A,B>): Fn<A,F>;
/**
 * Construct a reversed pipeline of functions, by feeding the value into the last function,
 * the result of that into the previous function, and so on, returning the result of the first function.
 *
 * The list of functions is memoized, however the inputs are not.
 */
export function comp(...fs: any[]): unknown {
	return memo(comp, fs as any, () => (x: any) => fs.reduceRight((a: any, f: any) => f(a), x));
}

export function mcomp<A>(...fs: Mod<A>[]): Mod<A> {
	return memo(mcomp, fs as Mod<A>[], () => (x: A) => fs.reduceRight((a: A, f: Mod<A>) => f(a), x));
}

export function debug_comp<A>(): Fn<A,A>;
export function debug_comp<B,A>(f: Fn<A,B>): Fn<A,B>;
export function debug_comp<C,B,A>(f: Fn<B,C>, g: Fn<A,B>): Fn<A,C>;
export function debug_comp<D,C,B,A>(f: Fn<C,D>, g: Fn<B,C>, h: Fn<A,B>): Fn<A,D>;
export function debug_comp<E,D,C,B,A>(f: Fn<D,E>, g: Fn<C,D>, h: Fn<B,C>, i: Fn<A,B>): Fn<A,E>;
/**
 * Construct a reversed pipeline of functions, by feeding the value into the last function,
 * the result of that into the previous function, and so on, returning the result of the first function.
 *
 * The list of functions is memoized, however the inputs are not.
 */
export function debug_comp(...fs: any[]): unknown {
	return debug_memo(debug_comp, fs as any, () => (x: any) => fs.reduceRight((a: any, f: any) => {
		const b = f(a);
		console.warn(f, a, b);
		return b;
	}, x));
}
/* eslint-enable @typescript-eslint/no-explicit-any */
