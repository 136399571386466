.kind-edit
{
	padding: 0;
	margin: 0;
	list-style-type: none;
	position: relative;
}

.kind-edit li
{
	list-style-type: none;
	border-left: 2px solid #000;
	margin-left: -2px;
}

.kind-edit li div
{
	padding-left: 1em;
	position: relative;
}

.kind-edit li div::before
{
	content: '';
	position: absolute;
	top: 0;
	left: -2px;
	bottom: 50%;
	width: 0.75em;
	border: 2px solid #000;
	border-top: 0 none transparent;
	border-right: 0 none transparent;
}

.kind-edit > li:last-child,
.kind-edit > li > ul > li:last-child
{
	border-left: 2px solid transparent;
}

@media ( prefers-color-scheme : dark )
{
	.kind-edit li,
	.kind-edit li div::before
	{
		border-color: #fff;
	}
}
