import { ConfigEntry, ConfigEntryMode, modeAbbr, OrgSelectedConfig, OrganizationMapUserId, OrgName, orgNameAbbr} from '~/src/admin/glassd-config';
import * as React from 'react';

import { Theme, styled } from '@mui/material/styles';
import type {} from '@mui/material/themeCssVarsAugmentation';

// import Paper from '@mui/material/Paper';

import MuiVirtualizedTable from '~/src/view/MuiVirtualizedTable';
import { MuiVirtualizedTableClasses, ColumnData } from '~/src/view/MuiVirtualizedTable';

import * as css from './edit-table.module.css';

const configCompare = (a: OrgSelectedConfig, b: OrgSelectedConfig): number => {
	if (a.key < b.key) return -1;
	if (a.key > b.key) return 1;
	if (a.OrgName < b.OrgName) return -1;
	if (a.OrgName > b.OrgName) return 1;
	return 0;
};

const configTableStyles = {
	headerHeight: 48,
	rowHeight: 65,
};

const configTableClasses: MuiVirtualizedTableClasses = {
	flexContainer: 'ConfigTable-flexContainer',
	tableRow: 'ConfigTable-tableRow',
	tableRowHover: 'ConfigTable-tableRowHover',
	tableCell: 'ConfigTable-tableCell',
	noClick: 'ConfigTable-noClick',
	header: 'ConfigTable-header',
};

const styles = ({ theme }: { theme: Theme }) =>
	({
		'& .ReactVirtualized__Table__headerRow': {
			...(theme.direction === 'rtl' && {
				paddingLeft: '0 !important',
			}),
			...(theme.direction !== 'rtl' && {
				paddingRight: undefined,
			}),
		},
		[`& .${configTableClasses.flexContainer}`]: {
			display: 'flex',
			alignItems: 'center',
			boxSizing: 'border-box',
		},
		[`& .${configTableClasses.tableRow}`]: {
			cursor: 'pointer',
		},
		[`& .${configTableClasses.tableRowHover}`]: {
			'&:hover': {
				backgroundColor: theme.vars.palette.grey[200],
			},
		},
		[`& .${configTableClasses.tableCell}`]: {
			flex: 1,
			// overflow: 'scroll'
		},
		[`& .${configTableClasses.noClick}`]: {
			cursor: 'initial',
		},
		[`& .${configTableClasses.header}`]: {
			'font-weight' :'900 !important',
			height: configTableStyles.headerHeight
		},
	} as const);



const VirtualizedTable = styled(MuiVirtualizedTable)(styles);

export interface ConfigTableProps {
	allOrgSelectedConfigEntries: OrgSelectedConfig[];
	editMode: boolean;
	filterText: string;
	filterMode: ConfigEntryMode[];
	filterOrganization: OrgName[];
	onConfigEntryUpdate: (v: string, rowId: string, dataKey: string) => void
}

export interface ConfigTableState {

}

const filteredEntry = (entry: ConfigEntry, filterText: string, filterMode:ConfigEntryMode[] ) : boolean => {
	return filterMode.indexOf(entry.mode) > -1
			&& entry.description.concat(entry.name, entry.key, entry.value.toString()).toLowerCase().indexOf(filterText.toLowerCase()) > -1;
};

export default class ConfigTable extends React.Component<ConfigTableProps, ConfigTableState> {
	constructor(props: ConfigTableProps) {
		super(props);
	}

	render () {
		const { editMode, allOrgSelectedConfigEntries, filterText, filterMode, filterOrganization} = this.props;

		const configTableColumns: ColumnData[] = [{
			widthPercentage: 5,
			label: 'Org',
			dataKey: 'OrgName',
			editable: false && editMode,
			displayValue: (x: OrgName ) => orgNameAbbr[x]
		},
		{
			widthPercentage: 3,
			label: 'Mode',
			dataKey: 'mode',
			editable: false && editMode,
			displayValue: (x: ConfigEntryMode ) => modeAbbr[x]
		},
		{
			widthPercentage: 30,
			label: 'Key',
			dataKey: 'key',
			editable: false && editMode
		},
		{
			widthPercentage: 30,
			label: 'Name',
			dataKey: 'name',
			editable: true && editMode
		},
		{
			widthPercentage: 25,
			label: 'Value',
			dataKey: 'value',
			editable: true && editMode,
			flexGrow: 1,
		},
		{
			multiline: true,
			label: 'Description',
			dataKey: 'description',
			editable: true && editMode,
			widthPercentage: 17
		}];
		const rows = allOrgSelectedConfigEntries.filter(o => filterOrganization.map(o => OrganizationMapUserId[o]).includes(o.UserId) && filteredEntry(o,filterText, filterMode)).sort(configCompare);
		return <>
			<div className={ `ConfigTable ${css.ConfigTable}` }>
				<VirtualizedTable
					classes={configTableClasses}
					rowCount={rows.length}
					rowGetter={({ index }) => rows[index]}
					columns={configTableColumns}
					rows={rows}
					{...configTableStyles}
					handleEditCell={this.props.onConfigEntryUpdate}
				/>
			</div>
		</>;
	}
}
