import SelectEnum from '~/src/edit/SelectEnum';
import { TranslationList } from '~/src/model/Language';
import { ShiftLocation } from '~/src/design/opening/measurement';
import { Box } from '@mui/material';
import { UnitEditProps } from '~/src/edit/types';
import { Shift } from '~/src/design/opening';
import { shiftLocation, shiftOffset } from '~/src/design/opening/optics';
import NumberEdit from '~/src/edit/Number';
import { modifyUpdater } from '~/src/edit/util';

export interface ShiftEditProps extends UnitEditProps<Shift> { }

// TODO: Build function for creating translation lists given values for certain languages
const shiftLocationTransList: TranslationList<ShiftLocation> = [{key: 'center', names: { eng: 'center' }}, {key: 'inside', names: { eng: 'inside' }}, {key: 'outside', names: { eng: 'outside' }}];

export const ShiftEdit = ({
	value,
	update,
}: ShiftEditProps) => {
	return <>
		<NumberEdit
			tlabel={{eng: 'Offset'}}
			unit='inch'
			update={modifyUpdater(shiftOffset, update)}
			value={shiftOffset.get(value)}
		/>
		<Box py={1}>
			<SelectEnum
				size='small'
				id="location"
				value={shiftLocation.get(value)}
				label={({eng: 'Location'})}
				items={shiftLocationTransList}
				update={modifyUpdater(shiftLocation, update)}
			/>
		</Box>
	</>;
};

export default ShiftEdit;
