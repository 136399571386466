/**
 * Optics for pure manipulation of Quote
 *
 * @since 0.1.0
 */
import { Lens } from 'monocle-ts';
import { Quote } from '~/src/pricing/quote';
import { Charge, ChargeGroup, ChargeItem, normalizeCharge } from '~/src/pricing/quote/change';
import { normalizeQuote } from '~/src/pricing/quote';
import { Path } from '~/src/pages/bulk-design';
import { NamedItem } from '~/src/edit/TreeView/TemplateTreeView';


export const chargeItemNameLens: Lens<ChargeItem, string> = Lens.fromProp<ChargeItem>()('Name');


/**
 * Focus on a ChargeItem in Quote, given it's path in array of indexes.
 *
 * @since 0.1.0
 */
export const chargeItemQuote = (indices: Path): Lens<Quote, ChargeItem | undefined> =>
	new Lens<Quote, ChargeItem | undefined>(
		(quote) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			let currentNode: any = quote;
			for (const index of indices) {
				if (currentNode && 'Charges' in currentNode && index >= 0 && index < currentNode.Charges.length) {
					currentNode = currentNode.Charges[index] as Charge[];
				} else if (currentNode && 'Items' in currentNode && index >= 0 && index < currentNode.Items.length) {
					currentNode = currentNode.Items[index] as ChargeGroup;
				} else {
					return undefined;
				}
			}
			if ('Name' in currentNode && 'Count' in currentNode && 'Price' in currentNode) {
				return currentNode as ChargeItem;
			}
			return undefined;
		},
		() => (quote) => quote
	);


/**
 * View and edit Name field inside ChargeItem
 *
 * @since 0.1.0
 */
export const updateChargeItemName = (chargeItem: ChargeItem, name: string): ChargeItem => chargeItemNameLens.set(name)(chargeItem);


/**
 * Add an item inside the `Charges` or `Items` array in Quote, just after its own index.
 *
 * @since 0.1.0
 */
export const addItemToQuote = (
	path: Path,
	itemToAdd: ChargeItem | ChargeGroup
): ((quote: Quote | undefined) => Quote | undefined) => {
	return (quote: Quote | undefined): Quote | undefined => {
		if (!quote) {
			return undefined;
		}

		let currentNode: Quote | ChargeGroup | ChargeItem = quote;

		// Copy the path array so that it's not mutated
		const pathCopy = [...path];

		while (pathCopy.length > 0) {
			const index = pathCopy.shift();

			if (
				currentNode &&
				'Charges' in currentNode &&
				index !== undefined &&
				index >= 0 &&
				index < currentNode.Charges.length
			) {
				// If we reach the last index, add the new item just after it
				if (pathCopy.length === 0) {
					currentNode.Charges.splice(index + 1, 0, { ...itemToAdd });
				}
				currentNode = currentNode.Charges[index] as ChargeItem;
			} else if (
				currentNode &&
				'Charges' in currentNode &&
				index == 0 &&
				currentNode.Charges.length == 0
			) {
				// If we reach the last index, add the new item just after it
				if (pathCopy.length === 0) {
					currentNode.Charges.splice(index, 0, { ...itemToAdd });
				}
			} else if (
				currentNode &&
				'Items' in currentNode &&
				index !== undefined &&
				index >= 0 &&
				index < (currentNode as ChargeGroup).Items.length
			) {
				// If we reach the last index, add the new item just after it
				if (pathCopy.length === 0) {
					(currentNode as ChargeGroup).Items.splice(index + 1, 0, { ...itemToAdd });
				}
				currentNode = (currentNode as ChargeGroup).Items[index] as ChargeGroup;
			} else {
				// Invalid path, return unchanged data
				return quote;
			}
		}

		// Ensure currentNode is defined
		if (currentNode) {
			return quote; // Return the modified data
		}

		// Invalid path, return unchanged data
		return quote;
	};
};

/**
 * Remove an item from the `Charges` or `Items` array in Quote.
 */
export const removeItemFromQuote = (
	path: Path,
): ((quote: Quote | undefined) => Quote | undefined) => {
	return (quote: Quote | undefined): Quote | undefined => {
		if (!quote) {
			return undefined;
		}

		let currentNode: Quote | ChargeGroup | ChargeItem = quote;

		// Copy the path array so that it's not mutated
		const pathCopy = [...path];

		while (pathCopy.length > 0) {
			const index = pathCopy.shift();

			if (
				currentNode &&
				'Charges' in currentNode &&
				index !== undefined &&
				index >= 0 &&
				index < currentNode.Charges.length
			) {
				// If we reach the last index, remove the item.
				if (pathCopy.length === 0) {
					currentNode.Charges.splice(index, 1);
				} else {
					currentNode = currentNode.Charges[index] as ChargeItem;
				}
			} else if (
				currentNode &&
				'Items' in currentNode &&
				index !== undefined &&
				index >= 0 &&
				index < (currentNode as ChargeGroup).Items.length
			) {
				// If we reach the last index, remove the item.
				if (pathCopy.length === 0) {
					(currentNode as ChargeGroup).Items.splice(index, 1);
				} else {
					currentNode = (currentNode as ChargeGroup).Items[index] as ChargeGroup;
				}
			} else {
				// Invalid path, return unchanged data
				return quote;
			}
		}

		// Ensure currentNode is defined
		if (currentNode) {
			return quote; // Return the modified data
		}

		// Invalid path, return unchanged data
		return quote;
	};
};

/**
 * Update Count and Name inside the Quote and recalculate the price.
 */
export const updateChargeField = (
	path: Path,
	field: keyof NamedItem<undefined>,
	value: string | number
): ((quote: Quote | undefined) => Quote | undefined) => {
	return (quote: Quote | undefined): Quote | undefined => {
		if (!quote) {
			return undefined;
		}

		// Copy the path array so that it's not mutated
		const pathCopy = [...path];
		if (pathCopy.length === 0) {
			return quote;
		}

		let index = 0;
		let parent: Charge[] | undefined = undefined;

		index = pathCopy.shift() as number;
		let currentNode: ChargeItem | undefined = quote.Charges[index];

		while (pathCopy.length > 0) {
			index = pathCopy.shift() as number;

			if (
				currentNode &&
				'Items' in currentNode &&
				index !== undefined &&
				index >= 0 &&
				index < (currentNode as ChargeGroup).Items.length
			) {
				parent = (currentNode as ChargeGroup).Items;
				currentNode = (currentNode as ChargeGroup).Items[index] as ChargeGroup;
			} else {
				// Invalid path, return unchanged data
				return quote;
			}
		}

		// Ensure currentNode is defined
		if (currentNode) {
			currentNode = {
				...currentNode,
				[field]: value,
			};
			if (parent && index >= 0) {
				parent.splice(index, 1, currentNode);
			} else if (index >= 0) {
				quote.Charges.splice(index, 1, currentNode);
			}
			// Recalculate the price if Count has been changed.
			if (field === 'Count') {
				quote.Charges = quote.Charges.map((charge: ChargeItem) => normalizeCharge(charge));
				quote = normalizeQuote(quote);
			}
			return quote; // Return the modified data
		}

		// Invalid path, return unchanged data
		return quote;
	};
};
