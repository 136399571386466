/**
 * Panels.
 *
 * Front-end related panel drawings, checks for updated props and appends XML to the current react reference.
 *
 * @since 0.2.0
 */
import { Opening } from '~/src/design/opening';
import { Projection } from '~/src/design/opening/projection';
import { Requestor } from '~/src/api/Requestor';
import * as React from 'react';

import { Space } from '~/src/ui/SpaceControl';
import { LengthConverter, AngleConverter } from '~/src/util/Units';
import { hash } from '~/src/util/Deep';
import { DRAWING_DIV_ID } from '~/src/design/opening/walls/edit';
import useDrawingInteractions from '~/src/hooks/useDrawingInteractions';
import { Box } from '@mui/material';
import { orange } from '@mui/material/colors';
import { closePie, PaneledState } from '~/src/design/opening/panel/edit';
import { Update } from '~/src/base/Function';
import { convertPanelTextElements, mapTextElements } from '~/src/util/Dom';
import useDrawingViewer from '~/src/hooks/useDrawingViewer';
import useTouchAngle from '~/src/hooks/useTouchAngle';

/**
 * PanelViewer Props.
 *
 * @since 0.2.0
 */
export interface PaneledProps {
	req: Requestor;
	visible: boolean;
	space: Space,
	value: Opening;
	projection: Projection;
	lengthConverter: LengthConverter;
	angleConverter: AngleConverter;
	updatePanel: Update<PaneledState>
	onDown: (e: React.MouseEvent | React.TouchEvent) => void
	activeElementId: string
	angle: number | null
	updateAngle: Update<number | null>
	trackingAngle: number | null
	updateTrackingAngle: Update<number | null>
}

/**
 * Angle and length unit conversion.
 */

const convertAngleUnits = (ac: AngleConverter) => (el: number) => (id: string): string | null => {
	if (id.endsWith('-Angle-lbl')) {
		return ac.build(el);
	}
	return null;
};

const convertLengthUnits = (lc: LengthConverter) => (el: number) => (id: string): string | null => {
	if (!id.includes('Angle') && id.endsWith('-lbl')) {
		return lc.build(el);
	}

	return null;
};

const PanelViewer: React.FC<PaneledProps> = ({
	lengthConverter,
	angleConverter,
	req,
	value,
	visible,
	projection,
	onDown,
	activeElementId,
	updatePanel,
	angle,
	updateAngle,
	trackingAngle,
	updateTrackingAngle,
	...props
}) => {
	const { ref } = useTouchAngle(updateAngle, angle, updateTrackingAngle, trackingAngle);
	const { viewBox, containerRef, zooming, ...mouseHandlers } = useDrawingInteractions(onDown, activeElementId);
	const { svgInner, hashKey } = useDrawingViewer(req, value, projection, [{
		type: 'Panels',
		Projection: projection,
	}]);

	React.useEffect(() => {
		if (zooming) {
			updatePanel(closePie);
		}
	}, [zooming, updatePanel]);

	React.useEffect(() => {
		if (containerRef?.current) {
			mapTextElements(convertPanelTextElements(convertLengthUnits(lengthConverter)))(containerRef?.current);
		}
	}, [lengthConverter, svgInner, value, containerRef]);

	React.useEffect(() => {
		if (containerRef?.current) {
			mapTextElements(convertPanelTextElements(convertAngleUnits(angleConverter)))(containerRef?.current);
		}
	}, [angleConverter, svgInner, value, containerRef]);

	const propHash = hash(hashKey)({
		req,
		value,
		projection,
		visible,
		onDown,
		...props
	});

	return (
		<Box
			id={DRAWING_DIV_ID}
			ref={containerRef}
			className={`hash-${propHash} drawing`}
			sx={{ width: '100%', flexGrow: 1 }}
			{...mouseHandlers}
		>
			<Box
				ref={ref}
				component='svg'
				dangerouslySetInnerHTML={{
					__html: svgInner
				}}
				sx={{
					[`& #${activeElementId}`]: {
						stroke: orange[300],
						strokeOpacity: 0.5,
						fill: orange[300]
					}
				}}
				viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`}
			/>
		</Box>
	);
};

export default PanelViewer;
