import { Box, BoxProps } from '@mui/material';
import * as React from 'react';
import QDrawerActions from './QDrawerActions';

interface QDrawerContainerProps {
		children: React.ReactNode
		actions?: React.ReactNode
		ContainerProps?: BoxProps
		FormProps?: BoxProps<'form'>
}

const QDrawerContainer: React.FC<QDrawerContainerProps> = ({
	children,
	actions,
	ContainerProps,
	FormProps
}) => {
	/*
	 * The main content form needs a defined height
	 * for the overflow scroll to work properly
	 */
	const [contentHeight, setContentHeight] = React.useState<number | null>(null);
	const actionsRef = React.createRef<HTMLDivElement | null>();
	const containerRef = React.useRef<HTMLDivElement | null>(null);

	React.useLayoutEffect(() => {
		function updateHeight() {
			if (actionsRef?.current && containerRef?.current) {
				const elHeight = actionsRef.current.getBoundingClientRect().height;
				const containerHeight = containerRef.current.getBoundingClientRect().height;

				setContentHeight(containerHeight - elHeight - 16);
			}
		}

		// Run on initial render
		updateHeight();

		window.addEventListener('resize', updateHeight);

		return () => window.removeEventListener('resize', updateHeight);

	}, [actionsRef, containerRef]);

	return (
		<Box display='flex' flexDirection='column' flexGrow={1} ml='15px' ref={containerRef} {...ContainerProps}>
			<Box maxHeight={`${contentHeight || 0}px`} height={`${contentHeight || 0}px`} flex={'1 1 auto'} sx={{ overflowY: 'auto'}} display='flex' flexDirection='column' component='form' {...FormProps}>
				{children}
			</Box>
			{actions && (
				<QDrawerActions ref={actionsRef}>
					{actions}
				</QDrawerActions>
			)}
		</Box>
	);
};

export default QDrawerContainer;
