import { interface as iface , string } from 'io-ts';


export type B64 = string;

export const B64 = string;


export type MimeType = string;

export const MimeType = string;


export interface MimeFile {
	Name : string;
	Mime : MimeType;
	Data : B64;
}

export const MimeFile = iface({
	Name : string,
	Mime : MimeType,
	Data : B64,
});

export const b64decode = ( b64 : B64 , mime ?: MimeType ) : Blob => {
	const byteChars = atob( b64 );
	const byteArray = [];

	for ( let i = 0 ; i < byteChars.length ; i++ ) {
		byteArray.push(byteChars.charCodeAt(i));
	}

	return new Blob( [new Uint8Array(byteArray)], { type : mime ?? '' } );
};
