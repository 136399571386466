import React, { useState } from 'react';
import { DragProps, HandleSelectFunction, NavigatedItem, TreeDataItem, TreePath, UpdatingFieldProps, selectionModelLeaf, selectionModelTree } from './TemplateTreeView';
import { Quote } from '~/src/pricing/quote';
import { Charge } from '~/src/pricing/quote/change';
import { TreeNode } from './TreeNode';
import { List } from '@mui/material';
import { Path } from '~/src/pages/bulk-design';
import { deepEqual, replaceItemsInNamedTree, swapItemsInTreeData } from '~/src/optics/TreeView/TreeView';
import { NamedTree } from '~/src/model/NamedTree';


interface TreeProps {
    treeData: (TreeDataItem)[];
    quoteData: Quote | Charge[] | undefined;
    updateSelectedPath: TreePath;
    path: Path | undefined;
    editMode: boolean;
    onSelect: HandleSelectFunction;
    selectedItemsData: (selectionModelTree<unknown> | selectionModelLeaf<unknown>)[] | undefined;
    selectedPath: Path | undefined;
    setFieldBasedOnPath: UpdatingFieldProps;
    navigatedItem: NavigatedItem;
    onDragStart: DragProps;
    onDrop: DragProps;
    onDragOver: (e: React.DragEvent) => void;
	topParentData: NamedTree<unknown>;
	setTreeData: (e: NamedTree<unknown>) => void;
	resetNavigatedItem: () => void;
}

export function Tree({
	treeData,
	navigatedItem,
	updateSelectedPath,
	quoteData,
	path,
	editMode,
	onSelect,
	selectedItemsData,
	selectedPath,
	setFieldBasedOnPath,
	topParentData,
	onDragOver,
	setTreeData,
	resetNavigatedItem
}: TreeProps) {
	/*
	 * 'Charges' in item means data type is Quote
	 * 'Discount' in item means data type is Charge
	 * else data type is BulkDesign or BulkDesignLeaf
	 */

	// Drag and Drop
	const [draggedIndex, setDraggedIndex] = useState<number | undefined>(undefined);
	const [draggedPath, setDraggedPath] = useState<Path | undefined>(undefined);

	const onDragStart = (index: number, draggedPathToSet: Path): void => {
		setDraggedPath(draggedPathToSet);
		setDraggedIndex(index);
	};

	const onDrop = (indexToReplace: number, index: number | undefined, pathToReplace: Path | undefined): void => {
		if (index || index === 0) {
			const newTreeDataItems = swapItemsInTreeData(index, indexToReplace)(treeData);
			if (newTreeDataItems){
				const newTreeData = replaceItemsInNamedTree(pathToReplace || [], newTreeDataItems)(topParentData);
				if (newTreeData){
					if (!deepEqual(newTreeData, topParentData)){
						setTreeData(newTreeData as NamedTree<unknown>);
					}
				}
			}
		}
	};


	return (
		<List className='tree-list'>
			{treeData.map((item: TreeDataItem, index: number) => (
				<TreeNode
					navigatedItem={navigatedItem}
					selectedPath={selectedPath}
					setFieldBasedOnPath={setFieldBasedOnPath}
					onSelect={onSelect}
					editMode={editMode}
					path={path ? [...path, index] : [index]}
					selectedItemsData={selectedItemsData ? selectedItemsData[index] : undefined}
					quoteData={quoteData ? 'Charges' in quoteData ? quoteData : quoteData[index] : undefined}
					updateSelectedPath={updateSelectedPath}
					node={item}
					key={index}
					onDragStart={() => onDragStart(index, path || [])}
					onDrop={() => onDrop(index, draggedIndex, draggedPath)}
					onDragOver={onDragOver}
					topParentData={topParentData}
					setTreeData={setTreeData}
					resetNavigatedItem={resetNavigatedItem}
				/>
			))}
		</List>
	);
}
