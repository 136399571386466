import React from 'react';

import { Requestor } from '~/src/api/Requestor';
import { list } from '~/src/design/template/api/list';
import { list as listRaw } from '~/src/admin/template/mod/api/raw-list';
import { listMods, applyMod, saveMod } from '~/src/admin/template/mod/api';

import { Template } from '~/src/design/template';
import { TemplateViewer } from '~/src/design/template/view';

import { ClientTemplateMod } from '~/src/admin/template/mod';
import { ClientTemplateModEditor } from '~/src/admin/template/mod/edit';

import { Import } from '~/src/ui/input/Import';
import { Export } from '~/src/ui/input/Export';
import { ToolBar } from '~/src/ui/toolbar/ToolBar';

import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { Optional } from 'monocle-ts';

import { Mod } from '~/src/base/Function';
import * as css from './template-mod.module.css';

export interface ModProps {
	req: Requestor;
}

export interface ModState {
	mod?: ClientTemplateMod;
	mods: ClientTemplateMod[];
	template?: Template;
	templates: Template[];
	modTemplates: Template[];
}

const updateClientTemplateMod = (page: ModPage) => (f: Mod<ClientTemplateMod>) => {
	page.setState(Optional.fromNullableProp<ModState>()('mod').modify(f));
};
const setClientTemplateMod = (page: ModPage) => (mod: ClientTemplateMod|undefined) => {
	page.setState(() => ({mod}));
};
const setServerMod = (page: ModPage) => (id: string) => {
	page.setState(() => ({mod: page.state.mods.find((t) => t.Id === id)}));
};

const fetchMods = (page: ModPage) => () => {
	listMods(page.props.req).then((mods) => page.setState({mods})).catch(console.warn);
	list(page.props.req)().then((modTemplates) => page.setState({modTemplates})).catch(console.warn);
};

const fetchTemplates = (page: ModPage) => () => {
	listRaw(page.props.req).then((templates) => page.setState({templates})).catch(console.warn);
};

const runMod = (page: ModPage) => () => {
	if (page.state.mod === undefined) return;
	applyMod(page.props.req)(page.state.mod).then((template) => {
		page.setState({template});
	}).catch(() => {
		alert('Applying mod failed.');
	});
};

const selectionValue = (ev: SelectChangeEvent<string>): string => ev.target.value;

export default class ModPage extends React.Component<ModProps, ModState> {
	constructor(props: ModProps) {
		super(props);
		this.state = {
			mods: [],
			templates: [],
			modTemplates: [],
		};

		fetchTemplates(this)();
		fetchMods(this)();
	}

	componentDidUpdate (_prevProps: ModProps, prevState: ModState) {
		if (prevState.mod !== this.state.mod) runMod(this)();
	}

	render() {
		const { mod, template, templates, modTemplates } = this.state;

		return (
			<div className={ `screen ${css['template-mod']}` }>
				<ToolBar>
					<Import codec={ClientTemplateMod} name="ClientTemplateMod" success={setClientTemplateMod(this)}
						failure={(e) => {
							alert('Import failed, check console for details.');
							console.warn(e);
						}}
					/>

					{ mod === undefined
						? null
						: <Export name="ClientTemplateMod" getContent={() => mod}
							onError={(e) => {
								alert('Export failed, check console for details.');
								console.warn(e);
							}}
						/> }

					<Select size="small" value="_load-from-server"
						onChange={(ev) => setServerMod(this)(selectionValue(ev))}>
						<MenuItem value="_load-from-server">Load</MenuItem>
						{ this.state.mods.map((m) => <MenuItem key={m.Id} value={m.Id}>{m.NamesChange.eng ?? m.Id}</MenuItem>) }
					</Select>

					{ mod == undefined ? null : <Button variant='outlined' onClick={() => saveMod(this.props.req)(mod).then(fetchMods(this)).catch(console.warn)}>Save</Button> }
				</ToolBar>

				<div className={ 'scroll' }>
					<div className={ css.wrap }>
						{ template ? <TemplateViewer req={this.props.req} template={template}/> : null }
						{ template && mod ? <Divider flexItem style={{ margin: '1em' }} /> : null }
						{ mod ? <ClientTemplateModEditor templates={templates} mod={mod} modTemplates={modTemplates} update={updateClientTemplateMod(this)} /> : null }
					</div>
				</div>
			</div>
		);
	}
}
