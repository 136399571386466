/**
 * DOM Utilities.
 *
 * @since 0.2.0
 */

import { Curb, Opening, SectionStep, SectionTree, Shift, Stretch, Wall } from '~/src/design/opening';
import { angleAngle, curbEdge, curbMeasure, curbOffset, curbOuterHeight, openingLeft, openingSection, opticCeiling, opticFloor, opticRight, restRightAngle, sectionRest, sectionTree, shiftOffset, stretchCurb, stretchEdge, stretchOffset, stretchOuterHeight } from '~/src/design/opening/optics';
import * as A from 'fp-ts/lib/Array';
import * as O from 'fp-ts/lib/Option';
import * as B from 'fp-ts/lib/boolean';
import * as E from 'fp-ts/lib/Either';
import { constant, flow, pipe } from 'fp-ts/lib/function';
import { Lens, Optional } from 'monocle-ts';
import { parseCurbs, parseSectionPath, sequenceTOption } from '~/src/edit/util';
import { major, minorMeasure, majorMeasure, definedMinorMeasurement, measureMajor, majorValue } from '~/src/design/opening/measurement/optics';
import { Directed, Measurement } from '~/src/design/opening/measurement';
import { one } from '~/src/optics/Boxes';
import { Path } from '~/src/edit/types';

/**
 * Walk down the DOM tree.
 *
 * @example
 * Print nodes
 * walkDOM((e) => { console.log(div); })(div);
 *
 * @since 0.2.0
 */
export const walkDOM = (f: ((e: Element) => void)) => (ele: Element) => {
	Array.from(ele.querySelectorAll('text')).forEach(f);
};

/**
 * Walk down the DOM tree and map to a text node.
 *
 * @example
 * Set text nodes to empty string
 * mapTextWithParent((el: HTMLElement) => (_str: string) => '')(div)
 *
 * @since 0.2.0
 */
export const mapTextWithParent = (f: ((e: HTMLElement) => (t: string) => string)) => walkDOM(e => {
	if (e.tagName !== 'text') return;
	const p = e.parentElement;
	if (p === null) return;
	const textNode = e.firstChild;
	if (!(textNode instanceof Text)) return;
	const newText = f(p)(textNode.data);
	if (newText === textNode.data) return;
	textNode.data = newText;
});

const measurementPathLength: (s: string[] | null) => O.Option<number> = (s: string[] | null) => pipe(
	s,
	O.fromNullable,
	O.chain((s) => sequenceTOption(A.findLastIndex((a) => a === 'Measure')(s), O.some(s))),
	O.map(([idx, path]) => path.slice(idx)),
	O.map((a) => a.length)
);

const optionalOpeningTextValue = <S extends keyof Directed>(shortenedPath: string[], opening: Opening, stretchOptic: Optional<Opening, Stretch<S>>) => {
	// Build curb optic
	const curbOptic = pipe(
		shortenedPath,
		parseCurbs,
		(cp) => stretchOptic.compose(stretchCurb(cp)),
	);

	// Get the path length
	const existingPathLength = measurementPathLength(shortenedPath);

	// Try to retrieve data
	return pipe(
		sequenceTOption(O.some(curbOptic), existingPathLength),
		O.map(([optic, len]) => {
			return pipe(
				len > 1,
				B.fold(
					() => optic.composeLens(curbMeasure<S>()).composeLens(major<S>()).composeLens(majorMeasure),
					() => optic.composeLens(curbMeasure<S>()).composeLens(definedMinorMeasurement<S>()).composeLens(minorMeasure)
				)
			);
		}),
		O.fold(() => O.some(0), (lens) => lens.getOption(opening))
	);
};

const POSSIBLE_SECTION_PARAMS = ['Left', 'Angle', 'Ceiling', 'Floor', 'Rest', 'Info'];

const getPathFromSectionTree: (pathId: string) => string[] | null = (pathId: string) => pipe(
	pathId.replace('-lbl', '').split('-'),
	A.findLastIndex((s: string) => POSSIBLE_SECTION_PARAMS.includes(s)),
	O.map((idx) => pathId.replace('-lbl', '').split('-').slice(idx)),
	O.foldW(constant(null), (a) => a)
);

const getSectionStepFromId: (pathId: string) => SectionStep[] = flow(
	(x) => x.split('-'),
	parseSectionPath,
);

type FloorPlanFloorOptic = Optional<Opening, Stretch<'Bottom'>>
type FloorPlanCurbOptic = Optional<Opening, Curb<'Bottom'>>

const createFloorOptic: (path: Path) => FloorPlanFloorOptic = flow(parseSectionPath, opticFloor);

export const createPossibleFloorPlanOptics: (pathId: string) => E.Either<FloorPlanCurbOptic, FloorPlanFloorOptic> = (pathId: string) => pipe(
	pathId,
	(x) => x.split('-'),
	(b) => {
		const floorOptic = createFloorOptic(b);
		const curbOptic = pipe(b, parseCurbs, (a) => floorOptic.composeOptional(stretchCurb<'Bottom'>(a)));

		return includesCurbs(pathId) ? E.left(curbOptic) : E.right(floorOptic);
	});

export const isEdgeOuterHeight = (s: string) => s.split('-').includes('EdgeOuterHeight');
export const isEdgeBredth = (s: string) => s.split('-').includes('EdgeBredth');
export const isEdge = (s: string) => s.split('-').includes('Edge');
export const getFloorPlanFocus = (s: string) => isEdgeOuterHeight(s) ? 'OuterHeight' : isEdgeBredth(s) ? 'Offset' : 'Bredth';
export const includesCurbs = (s: string) => s.split('-').includes('Curbs');

const stretchOuterHeightOptic = (floorOptic: FloorPlanFloorOptic) => floorOptic.composeLens(stretchOuterHeight());
const curbOuterHeightOptic = (curbOptic: FloorPlanCurbOptic) => curbOptic.composeLens(curbOuterHeight());

const conditionalOuterHeightOptic = (a: E.Either<FloorPlanCurbOptic, FloorPlanFloorOptic>) => pipe(
	a,
	E.fold(curbOuterHeightOptic, stretchOuterHeightOptic)
);

const stretchOffsetOptic = (floorOptic: FloorPlanFloorOptic) => floorOptic.composeLens(stretchOffset());
const curbOffsetOptic = (curbOptic: FloorPlanCurbOptic) => curbOptic.composeLens(curbOffset());

const conditionalBredthOptic = (a: E.Either<FloorPlanCurbOptic, FloorPlanFloorOptic>) => pipe(
	a,
	E.fold(curbOffsetOptic, stretchOffsetOptic),
	composeShiftOffsetOptics
);

const stretchEdgeOptic = (floorOptic: FloorPlanFloorOptic) => floorOptic.composeLens(stretchEdge());
const curbEdgeOptic = (curbOptic: FloorPlanCurbOptic) => curbOptic.composeLens(curbEdge());

const conditionalEdgeOptic = (a: E.Either<FloorPlanCurbOptic, FloorPlanFloorOptic>) => pipe(
	a,
	E.fold(curbEdgeOptic, stretchEdgeOptic),
	composeMajorMeasurementValueOptics
);

const bredthOrEdgeOptics = (pathId: string) => (a: E.Either<FloorPlanCurbOptic, FloorPlanFloorOptic>) => pipe(
	pathId,
	(s) => isEdgeBredth(s) ? E.right(conditionalBredthOptic(a)) : E.left(conditionalEdgeOptic(a)),
	E.fold((e) => e, (a) => a)
);

const determineFloorPlanOptics = (pathId: string) => (a: E.Either<FloorPlanCurbOptic, FloorPlanFloorOptic>) => pipe(
	pathId,
	(s) => isEdgeOuterHeight(s) ? E.right(conditionalOuterHeightOptic(a)) : E.left(bredthOrEdgeOptics(s)(a)) ,
	E.fold((e) => e, (a) => a)
);

const accessFloorPlanOptic = (optic: Optional<Opening, number>) => (opening: Opening) => optic.getOption(opening);

export const opticFloorPlan = (pathId: string) => pipe(
	pathId,
	createPossibleFloorPlanOptics,
	determineFloorPlanOptics(pathId),
);

const composeMajorMeasurementValueOptics = (a: Optional<Opening, Measurement<'In'>>) => a.composeLens(measureMajor()).composeLens(majorValue);
const composeShiftOffsetOptics = (a: Optional<Opening, Shift>) => a.composeLens(shiftOffset);

export const oFloorPlanValue = flow(
	opticFloorPlan,
	accessFloorPlanOptic
);

/**
 * Tries to retrieve data value from opening using id from element
 */
export const idToOpeningValue = (pathId: string, opening: Opening) => {
	const sectionStep = getSectionStepFromId(pathId);
	const pathFromSectionTree = getPathFromSectionTree(pathId);
	const firstIdx = pipe(pathFromSectionTree, O.fromNullable, O.chain(A.head), O.toUndefined);

	if (pathId.includes('Edge')) {
		/*
		 * TODO: A curb bredth, offset, and outer height may be null when the stretch bredth,
		 * offset, and outer height are defined in the Opening design. The drawing will
		 * fill in the amount from the stretch but the converter function isn't currently
		 * able to get that other stretch value
		 */
		return oFloorPlanValue(pathId)(opening);
	}

	if (pathFromSectionTree) {
		switch (firstIdx) {
		case 'Left': {
			const pathNoLeft = pathFromSectionTree.slice(1); // Removed ['Left']
			const newLeftFirstIdx = pipe(pathNoLeft, A.head, O.toUndefined);

			if (newLeftFirstIdx) {
				if (newLeftFirstIdx === 'Corner') {
					return O.some(0);
				}
				return optionalOpeningTextValue(pathNoLeft, opening, openingLeft.asOptional());
			}
			return O.some(0);
		}
		case 'Angle': {
			/*
			 * The angle for the right wall is accessed differentely
			 * then all the other joint angles
			 */
			if (pathId.includes('Right')) {
				return openingSection
					.composeOptional(sectionTree(sectionStep))
					.composeLens(sectionRest)
					.composePrism(one<Wall,SectionTree>())
					.composeLens(restRightAngle)
					.composeLens(angleAngle)
					.getOption(opening);
			}
			return openingSection
				.composeOptional(sectionTree(sectionStep))
				.composeLens(Lens.fromPath<SectionTree>()(['Angle', 'Angle'])).getOption(opening);
		}
		case 'Ceiling': return optionalOpeningTextValue(pathFromSectionTree, opening, opticCeiling(sectionStep));
		case 'Floor': return optionalOpeningTextValue(pathFromSectionTree, opening, opticFloor(sectionStep));
		case 'Rest': {
			const pathNoSection = pathFromSectionTree.slice(2); // Removed ['Rest', 'Right']

			const newRestFirstIdx = pipe(pathNoSection, A.head, O.toUndefined);

			if (newRestFirstIdx) {
				if (newRestFirstIdx === 'Corner') {
					return O.some(0);
				}
				return optionalOpeningTextValue(pathNoSection, opening, opticRight(sectionStep));
			}
			return O.some(0);

		}
		default: {
			console.warn(`SectionTree parameter "${firstIdx}" not recognized.`);
			return O.some(0);
		}
		}
	}
	return O.some(0);
};

/**
 * Walk down the DOM tree and map to a text node.
 *
 * @example
 * Set text nodes to empty string
 * mapTextWithParent((el: HTMLElement) => (_str: string) => '')(div)
 *
 * @since 0.2.0
 */
export const mapTextElements = (f: (x: SVGTextElement) => void) => (div: HTMLElement) => {
	Array.from(div.querySelectorAll('text')).map(f);
};

export const convertOpeningTextElements = (opening: Opening) => (f: (el: number) => (str: string) => string | null) => (el: SVGTextElement) => {
	// Get the element that has the path id
	const p = el.parentElement;
	if (p === null) return;

	const pathId = p.id;

	if (pathId === null) return;

	const val = pipe(
		idToOpeningValue(pathId, opening),
		O.map((n) => f(n)(pathId)),
		O.foldW(constant(''), (s) => s)
	);

	const dataNode = el.firstChild;

	if (val && dataNode instanceof Text) {
		dataNode.data = val;
	}
};

export const convertPanelTextElements = (f: (el: number) => (str: string) => string | null) => (el: SVGTextElement) => {
	// Get the element that has the path id
	const p = el.parentElement;
	if (p === null) return;

	const pathId = p.id;

	if (pathId === null) return;

	const dataNode = el.firstChild;

	if (!(dataNode instanceof Text)) return;

	/*
	 * TODO: Unlike `convertOpeningTextElements` this value is using the
	 * text element data value. Therefore the conversion is incorrect if
	 * the units are switched while on the Panels tab
	 *
	 * Is there a way to get the value from the opening object given the
	 * differing structure of the element ID
	 */
	const val = pipe(
		dataNode.data,
		parseFloat,
		(n) => f(n)(pathId),
		O.fromNullable,
		O.foldW(constant(''), (s) => s)
	);

	if (val) {
		dataNode.data = val;
	}
};
