import { Opening } from '~/src/design/opening';
import { Projection } from '~/src/design/opening/projection';
import { Requestor } from '~/src/api/Requestor';

import * as React from 'react';

import { Controls } from '~/src/design/opening/edit-controls';
import { Import } from '~/src/ui/input/Import';
import { Export } from '~/src/ui/input/Export';
import { Space } from '~/src/ui/SpaceControl';
import { ToolBar } from '~/src/ui/toolbar/ToolBar';
import WallEditor from '~/src/design/opening/walls/edit';
import { LengthConverters, LengthUnit, AngleConverters, AngleLocation } from '~/src/util/Units';

import { keyof, Type } from 'io-ts';

import { Lens } from 'monocle-ts';

import { Mod } from '~/src/base/Function';

import * as css from './edit.module.css';
import PanelEditor from '~/src/design/opening/panel/edit';

export type OpeningMode = 'Wall' | 'Panel';
export const OpeningMode: Type<OpeningMode> = keyof({
	Wall: true,
	Panel: true,
});

export interface OpeningState {
	loading: boolean;
	mode: OpeningMode;
	space: Space,
	projection: Projection;
	unit: LengthUnit;
	angle: AngleLocation;
	opening: Opening;
}

export interface OpeningParams {
	req: Requestor;
	opening: Opening;
}

const updateOpening = (page: OpeningComponent, pushHistory = true) => (f: Mod<Opening>) => {
	if (pushHistory)
		page.history.push(page.state.opening);

	page.setState(Lens.fromProp<OpeningState>()('opening').modify(f));
};
const setOpening = (page: OpeningComponent) => (opening: Opening) => {
	page.setState(() => ({opening}));
};

export const DEFAULT_DRAWING_ROTATION = 0;

export default class OpeningComponent extends React.Component<OpeningParams, OpeningState> {
	history: Opening[] = [];

	constructor(props: OpeningParams) {
		super(props);

		this.state = {
			loading: false,
			mode: 'Wall',
			space: {
				x: 0,
				y: 0,
				width: 1024.00,
				height: 1024.00,
				scale: 1,
				click: false,
				scroll: false,
				drag: false
			},
			projection: {type: 'Isometric', Rotate: DEFAULT_DRAWING_ROTATION},
			unit: 'inch',
			angle: 'interior',
			opening: props.opening
		};
	}

	render() {
		return (
			<div className={ css.Opening }>
				<ToolBar>
					<Import codec={Opening} name="Opening" success={setOpening(this)}
						failure={(e) => {
							alert('Import failed, check console for details.');
							console.warn(e);
						}}
					/>

					<Export name="Opening" getContent={() => this.state.opening} />

					<Controls
						projection={this.state.projection}
						onProjectionChange={(p) => {
							this.setState(prevState => ({
								projection: p(prevState.projection)
							}));
						}}

						lengthUnit={this.state.unit}
						onLengthUnitChange={(u) => {
							this.setState(prevState => ({
								unit: u(prevState.unit)
							}));
						}}

						angleLocation={this.state.angle}
						onAngleLocationChange={(a) => {
							this.setState(prevState => ({
								angle: a(prevState.angle)
							}));
						}}

						mode={this.state.mode}
						onOpeningModeChange={(o) => {
							this.setState(prevState => ({
								mode: o(prevState.mode)
							}));
						}}

						onUndo={() => {
							const opening = this.history.pop();
							if (!opening) return;

							setOpening(this)(opening);
						}}
					/>
				</ToolBar>

				{this.state.mode === 'Panel' && (
					<PanelEditor req={this.props.req}
						visible={this.state.mode === 'Panel'}
						value={this.state.opening}
						space={this.state.space}
						projection={this.state.projection}
						lengthConverter={LengthConverters[this.state.unit]}
						angleConverter={AngleConverters[this.state.angle]}
						update={updateOpening(this)}
						spaceControl={(space) => { this.setState({ space }); }}
					></PanelEditor>
				)}
				{this.state.mode === 'Wall' && (
					<WallEditor req={this.props.req}
						visible={this.state.mode === 'Wall'}
						value={this.state.opening}
						space={this.state.space}
						projection={this.state.projection}
						curbEdges={false}
						lengthConverter={LengthConverters[this.state.unit]}
						angleConverter={AngleConverters[this.state.angle]}
						update={updateOpening(this)}
						spaceControl={(space) => { this.setState({ space }); }}
					></WallEditor>
				)}
			</div>
		);
	}
}
