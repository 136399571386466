import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Mod, comp } from '~/src/base/Function';
import { Optic, pdot } from '~/src/base/Optic';
import { Update } from '~/src/base/Function';

export type Queries = {[k in string]?: string};

export const queryKey: (x: string) => Optic<Queries,string|undefined> = pdot;

export const useQuery = (): [Queries, Update<Queries>] => {
	const { search } = useLocation();
	const current = React.useMemo(() => Object.fromEntries(new URLSearchParams(search).entries()), [search]);
	const nav = useNavigate();
	const update = React.useMemo(() => (f: Mod<Queries>) => nav(toQuery(f(current))), [nav, current]);
	return [ current, update ];
};

export const toQuery = (qs: Queries): string => '?' + Object.entries(qs).filter(
	(x): x is [string, string] => x[1] !== undefined
).map(
	([k,v]) => encodeURIComponent(k) + (v === '' ? '' : '=' + encodeURIComponent(v))
).join('&');

export const useQueryKey= (key: string): [string | undefined, Update<string|undefined>] => {
	const [ queries, updateQueries ] = useQuery();
	const current = queries[key];
	const update = comp(updateQueries,queryKey(key));
	return [ current, update ];
};
