/**
 * Optics for pure manipulation of concrete `Openings`.
 *
 * @since 0.1.0
 */
import { Lens, Optional } from 'monocle-ts';
import { indexArray } from 'monocle-ts/lib/Index/Array';

import { Shift, Opening, OpeningInfo, SectionTree, SectionInfo, Joint, JointInfo, Section, Stretch, Wall, Offset, Anomaly, Curb, SectionStep } from '~/src/design/opening';
import { OneOrUptoThree } from '~/src/model/Boxes';
import { Division } from '~/src/design/opening/division';
import { Directed, Measurement, Major, Minor, ShiftLocation } from '~/src/design/opening/measurement';
import { EdgeInfo } from '~/src/design/opening/panel';
import { strategyDivision, divisionDivision, DivisionStep } from '~/src/design/opening/division/optics';

import * as Poly from '~/src/design/opening/polymorphic/optics';
import { measureMinor, measureMajor } from '~/src/design/opening/measurement/optics';
import { one } from '~/src/optics/Boxes';
import { OffsetLocation } from '~/src/design/opening/polymorphic';
import { Distance } from '~/src/model/Numbers';
import { Projection, ProjectionTag } from '~/src/design/opening/projection';

export {
/**
 * Polymorphic versiosn of these optics.
 *
 * @since 0.1.0
 */
	Poly
};

/**
 * Focus on the `SectionTree` in an `Opening`.
 *
 * @since 0.1.0
 * @category Opening
 */
export const openingSection: Lens<Opening, SectionTree> =
	Poly.openingSection<EdgeInfo,JointInfo,SectionInfo,OpeningInfo>();

/**
 * Focus on the left `Stretch` in an `Opening`.
 *
 * @since 0.1.0
 * @category Opening
 */
export const openingLeft: Lens<Opening, Stretch<'Left'>> =
	Lens.fromProp<Opening>()('Left');

/**
 * Focus on a particular `Division` in an `Opening`.
 *
 * **Warning!** If the `Strategy` is not `'Manual'`, this will synthesize a
 * `Division`, which may not match the one calculated from the whole model.
 *
 * @since 0.1.0
 * @category Opening
 */
export const openingDivision = (section: SectionStep[], division: DivisionStep[]): Optional<Opening, Division> =>
	Poly.openingSection<EdgeInfo,JointInfo,SectionInfo,OpeningInfo>().asOptional()
		.compose(Poly.sectionSection<EdgeInfo,JointInfo,SectionInfo>(section))
		.compose(sectionDivision.asOptional())
		.compose(divisionDivision(division));

/**
 * Focus on the `Division` tree in a `Section`.
 *
 * **Warning!** If the `Strategy` is not `'Manual'`, this will synthesize a
 * `Division`, which may not match the one calculated from the whole model.
 *
 * @since 0.1.0
 * @category Section
 */
export const sectionDivision: Lens<Section, Division> =
	Poly.sectionInfo<EdgeInfo,JointInfo,SectionInfo>().compose(strategyDivision);

/**
 * Focus on the Joint in a `Section`.
 *
 * @since 0.2.0
 * @category Section
 */
export const sectionAngle: Lens<SectionTree, Joint> =
	Lens.fromProp<SectionTree>()('Angle');

/**
 * Focus on the `Ceiling` Stretch of a `Section`.
 *
 * @since 0.2.0
 * @category Section
 */
export const sectionCeiling: Lens<Section, Stretch<'Top'>> =
	Lens.fromProp<Section>()('Ceiling');

/**
 * Focus on the `Floor` Stretch of a `Section`.
 *
 * @since 0.2.0
 * @category Section
 */
export const sectionFloor: Lens<Section, Stretch<'Bottom'>> =
	Lens.fromProp<Section>()('Floor');

/**
 * Focus on the `Right` Stretch of a `Section`.
 *
 * @since 0.2.0
 * @category Section
 */
export const wallRight: Lens<Wall, Stretch<'Right'>> =
	Lens.fromProp<Wall>()('Right');

/**
 * Focus on the `Major` in a `Stretch`.
 *
 * @category Stretch
 * @since 0.4.0
 */
export const stretchMeasureMajor = <S extends keyof Directed>(i: number): Optional<Stretch<S>, Major> =>
	stretchMeasure<S>(i).composeLens(measureMajor());

/**
 * Focus on the minor offset in a `Stretch`.
 *
 * @category Stretch
 * @since 0.4.0
 */
export const stretchMeasureMinor = <S extends keyof Directed>(i: number): Optional<Stretch<S>, Minor> =>
	stretchMeasure<S>(i).composeLens(measureMinor());

/**
 * Focus on the `Measurement`in a `Stretch`.
 *
 * @category Stretch
 * @since 0.4.0
 */
export const stretchMeasure = <S extends keyof Directed>(i: number): Optional<Stretch<S>, Measurement<S>> =>
	stretchCurb<S>(i).composeLens(curbMeasure());

/**
 * Focus on the `Curb` of a `Section`.
 *
 * @category Curb
 * @since 0.4.0
 */
export const indexCurb = <S extends keyof Directed>(i: number): Optional<Curb<S>[], Curb<S>> =>
	indexArray<Curb<S>>().index(i);

/**
 * Focus on the Measure in a `Curb`.
 *
 * @category Section
 * @since 0.4.0
 */
export const curbMeasure = <S extends keyof Directed>(): Lens<Curb<S>, Measurement<S>> =>
	Lens.fromProp<Curb<S>>()('Measure');

/**
 * Focus on the Direction in a `Measurement`
 *
 * @category Section
 */
export const measurementDirection = <S extends keyof Directed>(): Lens<Measurement<S>, Directed[S]> =>
	Lens.fromProp<Measurement<S>>()('Direction');

/**
 * Focus on the Direction in a `Curb`
 *
 * @category Section
 */
export const curbDirection = <S extends keyof Directed>(): Lens<Curb<S>, Directed[S]> =>
	curbMeasure<S>().compose(measurementDirection<S>());

/**
 * Focus on the curbEdge in a `Curb`.
 *
 * @category Section
 * @since 0.4.0
 */
export const curbEdge = <S extends keyof Directed>(): Lens<Curb<S>, Measurement<'In'>> =>
	Lens.fromNullableProp<Curb<S>>()('Bredth', { Direction: 'in', Major: { Major: 0 }, Minor: { Minor: 0 } });

/**
 * Focus on a child `Section` of a `SectionTree`, given it's index.
 *
 * @since 0.2.0
 * @category Section
 */
export const sectionSection: ((is: SectionStep[]) => Optional<SectionTree, Section>) =
	Poly.sectionSection;

/**
 * Focus on a child `Section` of a `SectionTree`, given it's index.
 *
 * @since 0.2.0
 * @category Section
 */
export const sectionTree: ((is: SectionStep[]) => Optional<SectionTree, SectionTree>) =
	Poly.sectionTree;

/**
 * Focus on the immediate child `Section` of a `SectionTree`.
 *
 * @since 0.2.0
 * @category Section
 */
export const sectionTreeSection: Lens<SectionTree, Section> =
	Poly.sectionTreeSection();

/**
 * Focus on the `Rest` property of a `SectionTree`, to look at the downstream `Wall` or `Section`s.
 *
 * @since 0.2.0
 * @category Section
 */
export const sectionRest: Lens<SectionTree, OneOrUptoThree<Wall, SectionTree>> =
	Poly.sectionRest();

/**
 * Focus on a particular `Curb` in a `Stretch`
 *
 * @since 0.2.0
 * @category Stretch
 */
export const stretchCurb = <S extends keyof Directed>(i: number): Optional<Stretch<S>, Curb<S>> =>
	stretchCurbs<S>().composeOptional(indexCurb(i));

/**
 * Focus on the `Curbs` of a `Stretch`.
 *
 * @category Stretch
 * @since 0.4.0
 */
export const stretchCurbs = <S extends keyof Directed>(): Lens<Stretch<S>, Curb<S>[]> =>
	Lens.fromProp<Stretch<S>>()('Curbs');

/**
 * Focus on the `Edge` of a `Stretch`.
 *
 * @category Stretch
 * @since 0.4.0
 */
export const stretchEdge: <S extends keyof Directed>() => Lens<Stretch<S>, Measurement<'In'>> = <S extends keyof Directed>() => Lens.fromNullableProp<Stretch<S>>()('Bredth', { Direction: 'in', Major: { Major: 0 }, Minor: { Minor: 0 } });


/**
 * Focus on the `OuterHeight` of a `Stretch`.
 *
 * @category Stretch
 * @since 0.4.0
 */
export const stretchOuterHeight = <S extends keyof Directed>(): Lens<Stretch<S>, number> =>
	Lens.fromNullableProp<Stretch<S>>()('OuterHeight', 0);

/**
 * Focus on the `Offset` of a `Stretch`.
 *
 * @category Stretch
 * @since 0.4.0
 */
export const stretchOffset = <S extends keyof Directed>(): Lens<Stretch<S>, Shift> =>
	Lens.fromNullableProp<Stretch<S>>()('Offset', {'Location': 'center', Offset: 0});

/**
 * Focus on the `Offset` of a `Shift`.
 *
 * @category Stretch
 */
export const shiftOffset =Lens.fromNullableProp<Shift>()('Offset', 0);

/**
 * Focus on Location in Shift
 *
 * @category Curb
 */
export const shiftLocation: Lens<Shift, ShiftLocation> = Lens.fromNullableProp<Shift>()('Location', 'center');

/**
 * Focus on nullable Measures.
 *
 * @category Section
 * @since 0.4.0
 */
export const curbNullableMeasure = <S extends keyof Directed>(direction: Directed[keyof Directed]): Lens<Measurement<S>|null, Measurement<S>> =>
	new Lens<Measurement<S>|null, Measurement<S>>(
		(a) => {
			if (a === null) {
				return {
					Direction: direction,
				} as Measurement<S>;
			}
			return a;
		},
		(a) => (_s) => a
	);

/**
 * Focus on the OuterHeight in a `Curb`.
 *
 * @category Section
 * @since 0.4.0
 */
export const curbOuterHeight = <S extends keyof Directed>(): Lens<Curb<S>, number> =>
	Lens.fromNullableProp<Curb<S>>()('OuterHeight', 0);

/**
 * Focus on the Offset in a `Curb`.
 *
 * @category Section
 * @since 0.4.0
 */
export const curbOffset = <S extends keyof Directed>(): Lens<Curb<S>, Shift> =>
	Lens.fromNullableProp<Curb<S>>()('Offset', { Location: 'center', Offset: 0 });

/**
 * Focus on the Angle in a `Joint`.
 *
 * @category Section
 * @since 0.4.0
 */
export const angleAngle: Lens<Joint, number> =
	Lens.fromProp<Joint>()('Angle');

/**
 * Focus on the Joint in a `Wall`.
 *
 * @category Section
 * @since 0.4.0
 */
export const restRightAngle: Lens<Wall, Joint> =
	Lens.fromProp<Wall>()('Angle');

/**
 * Focus on Location in Offset
 *
 * @category Curb
 */
export const anomalyOffsetLocation: Lens<Offset, OffsetLocation> = Lens.fromProp<Offset>()('Location');

/**
 * Focus on Offset in Offset
 *
 * @category Curb
 */
export const anomalyOffsetOffset: Lens<Offset, number> = Lens.fromProp<Offset>()('Offset');

/**
 * Focus on Offset in Anomaly
 *
 * @category Curb
 */
export const anomalyOffset: Lens<Anomaly, Offset> = Lens.fromProp<Anomaly>()('Offset');

/**
 * Focus on Depth in Anomaly
 *
 * @category Curb
 */
export const anomalyDepth: Lens<Anomaly, number> = Lens.fromProp<Anomaly>()('Depth');

/**
 * Focus on Size in Anomaly
 *
 * @category Curb
 */
export const anomalySize: Lens<Anomaly, Distance> = Lens.fromProp<Anomaly>()('Size');

/**
 * Focus on Width in Anomaly
 *
 * @category Curb
 */
export const anomalyWidth: Lens<Anomaly, Distance> = Lens.fromNullableProp<Anomaly>()('Width', 1);

/**
 * Focus on Description in Anomaly
 *
 * @category Curb
 */
export const anomalyDescription: Lens<Anomaly, string> = Lens.fromNullableProp<Anomaly>()('Description', '');

/**
 * Focus on Anomalies in Curb
 *
 * @category Curb
 */
export const curbAnomalies: <S extends keyof Directed>() => Lens<Curb<S>, Anomaly[]> = <S extends keyof Directed>() => Lens.fromNullableProp<Curb<S>>()('Anomalies', []);

/**
 * Focus on left wall's major curb dimension
 *
 * @category Opening
 * @since 0.4.0
 */
export const leftWallMajorCurbDim = openingLeft.composeOptional(stretchMeasureMajor(0));

/**
 * Focus on a SectionTree in Opening using SectionStep
 *
 * @category Opening
 */
export const opticSection = (sectionPath: SectionStep[]): Optional<Opening,SectionTree> => openingSection
	.composeOptional(sectionTree(sectionPath));

/**
 * Focus on Rest in SectionTree of Opening using SectionStep
 *
 * @category Opening
 */
export const opticRest = (sectionPath: SectionStep[]): Optional<Opening,OneOrUptoThree<Wall,SectionTree>> =>
	opticSection(sectionPath).composeLens(sectionRest);

/**
 * Focus on Right stretch of a Section in SectionTree of Opening using SectionStep
 *
 * @category Opening
 */
export const opticRight = (sectionPath: SectionStep[]) =>
	opticRest(sectionPath).compose(one<Wall,SectionTree>().asOptional()).composeLens(wallRight);

/**
 * Focus on a right wall's major curb dimension
 * using SectionStep
 *
 * @category Opening
 * @since 0.4.0
 */
export const rightWallMajorCurbDim = (sectionPath: SectionStep[]) => opticRight(sectionPath).composeOptional(stretchMeasureMajor(0));

/**
 * Focus on a section's floor minor curb dimension
 * using SectionStep
 *
 * @category Opening
 * @since 0.4.0
 */
export const floorMinorCurbDim = (sectionPath: SectionStep[]) => openingSection.composeOptional(sectionSection(sectionPath)).composeLens(sectionFloor).composeOptional(stretchMeasureMinor(0));

/**
 * Focus on a section's ceiling minor curb dimension
 * using SectionStep
 *
 * @category Opening
 * @since 0.4.0
 */
export const ceilingMinorCurbDim = (sectionPath: SectionStep[]) => openingSection.composeOptional(sectionSection(sectionPath)).composeLens(sectionCeiling).composeOptional(stretchMeasureMinor(0));

/**
 * Focus on Top stretch of a Section in SectionTree of Opening using SectionStep
 *
 * @category Opening
 */
export const opticCeiling = (sectionPath: SectionStep[]) =>
	opticSection(sectionPath).composeLens(sectionTreeSection).composeLens(sectionCeiling);

/**
 * Focus on the Curbs for the Top stretch of a Section in SectionTree of Opening using SectionStep
 *
 * @category Opening
 */
export const ceilingCurbsOptic = (sectionPath: SectionStep[]) => opticCeiling(sectionPath).composeLens(stretchCurbs());

/**
 * Focus on Bottom stretch of a Section in SectionTree of Opening using SectionStep
 *
 * @category Opening
 */
export const opticFloor = (sectionPath: SectionStep[]) =>
	opticSection(sectionPath).composeLens(sectionTreeSection).composeLens(sectionFloor);

/**
 * Focus on the Curbs for the Bottom stretch of a Section in SectionTree of Opening using SectionStep
 *
 * @category Opening
 */
export const floorCurbsOptic = (sectionPath: SectionStep[]) => opticFloor(sectionPath).composeLens(stretchCurbs());

/**
 * Focus on ProjectionTag in Projection
 *
 * @category Projection
 */
export const projectionTag: Lens<Projection, ProjectionTag> = Lens.fromProp<Projection>()('type');
