import { constant, pipe } from 'fp-ts/lib/function';
import { Optional } from 'monocle-ts';
import { Opening, Stretch } from '~/src/design/opening';
import { shiftOffset, stretchEdge, stretchOffset, stretchOuterHeight } from '~/src/design/opening/optics';
import * as O from 'fp-ts/lib/Option';
import { MeasurementEdit } from '~/src/design/opening/measurement/edit';
import QDrawerError from '~/src/ui/drawer/QDrawerError';
import QAccordion, { QAccordionTitleValueSummary } from '~/src/ui/QAccordion';

import { modifyUpdater } from '~/src/edit/util';
import { FormProps } from '~/src/edit/types';
import { major, majorMeasure } from '~/src/design/opening/measurement/optics';
import NumberEdit from '~/src/edit/Number';
import ShiftEdit from '~/src/design/opening/shift/edit';

const FloorForm = (
	optic: Optional<Opening, Stretch<'Bottom'>>,
	focus: 'Bredth' | 'OuterHeight' | 'Offset',
) => ({
	value,
	lengthConverter,
	angleConverter,
	update,
}: FormProps<Opening>) =>
	O.fold(
		constant(<QDrawerError>Curb Not Found</QDrawerError>),
		(stretch: Stretch<'Bottom'>) => {
			const updateStretch = modifyUpdater(optic, update);
			const bredth = stretchEdge<'Bottom'>().get(stretch);
			const updateBredth = modifyUpdater(stretchEdge<'Bottom'>(), updateStretch);

			const outerHeight = stretchOuterHeight<'Bottom'>().get(stretch);
			const updateOuterHeight = modifyUpdater(stretchOuterHeight<'Bottom'>(), updateStretch);

			const offset = stretchOffset<'Bottom'>().get(stretch);
			const updateOffset = modifyUpdater(stretchOffset<'Bottom'>(), updateStretch);

			return <>
				<QAccordion
					initialExpanded={focus === 'Bredth'}
					panelName='bredth'
					summary={<QAccordionTitleValueSummary
						title='Bredth'
						value={pipe(
							value,
							(m) => pipe(optic.composeLens(stretchEdge<'Bottom'>()).composeLens(major<'In'>()).composeLens(majorMeasure).getOption(m), O.getOrElse(constant(0))),
							lengthConverter.build,
						)} />}
					details={<MeasurementEdit
						update={updateBredth}
						value={bredth}
					/>}
				/>
				<QAccordion
					initialExpanded={focus === 'OuterHeight'}
					panelName='OuterHeight'
					summary={<QAccordionTitleValueSummary
						title='Outer Height'
						value={pipe(
							value,
							(m) => pipe(optic.composeLens(stretchOuterHeight<'Bottom'>()).getOption(m), O.getOrElse(constant(0))),
							lengthConverter.build,
						)} />}
					details={<NumberEdit
						tlabel={{eng: 'Outer Height'}}
						unit='mm'
						update={updateOuterHeight}
						value={outerHeight}
					/>}
				/>
				<QAccordion
					initialExpanded={focus === 'Offset'}
					panelName='Offset'
					summary={<QAccordionTitleValueSummary
						title='Offset'
						value={pipe(
							value,
							(m) => pipe(optic.composeLens(stretchOffset<'Bottom'>()).composeLens(shiftOffset).getOption(m), O.getOrElse(constant(0))),
							lengthConverter.build,
						)} />}
					details={<ShiftEdit
						lengthConverter={lengthConverter}
						angleConverter={angleConverter}
						update={updateOffset}
						value={offset}
					/>}
				/>
			</>;
		})(optic.getOption(value));

export default FloorForm;
