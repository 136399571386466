/**
 * Building Templates and getting information about them.
 *
 * @since 0.4.0
 */
import { array } from 'io-ts';
import { Template } from '~/src/design/template';
import { Requestor } from '~/src/api/Requestor';

/**
 * Fetch a list of templates from the server.
 *
 * @since 0.4.0
 */
export const list = (req: Requestor) => (mode?: string): Promise<Template[]> =>
	req.request(array(Template))(`template/list${mode === undefined ? '' : '/' + mode}`, 'GET');
