/**
 * TemplatemMod.
 *
 */
import { Type, array, null as cnull, number, partial, record, string, undefined as undef, union } from 'io-ts';
import { Dependency } from '~/src/design/template/dependency';
import { Translations } from '~/src/model/Language';
import { Range } from '~/src/design/template/variable';

/**
 * Type for Specialization.
 *
 */
export type Specialization = Dependency;
/**
 * Codec for Specialization.
 *
 */
export const Specialization: Type<Specialization> = Dependency;

/**
 * Type for OptionSelection.
 *
 */
export type OptionSelection = { [k in string]?: string };
/**
 * Codec for OptionSelection.
 *
 */
export const OptionSelection: Type<OptionSelection> = record(string, union([string, undef]));

/**
 * Type for NameChange with Translations
 *
 */
export type NameChange = { [k in string]?: Translations }

/**
 * Type for OptionNamesChange.
 *
 */
export type OptionNamesChange = { [k in string]?: NameChange };

/**
 * Codec for OptionNamesChange.
 *
 */
export const OptionNamesChange: Type<OptionNamesChange> = record(string, union([record(string, union([Translations, undef])), undef]));

/**
 * Type for VariableSelection.
 *
 */
export type VariableSelection = { [k in string]?: number };
/**
 * Codec for VariableSelection.
 *
 */
export const VariableSelection: Type<VariableSelection> = record(string, union([number, undef]));

/**
 * Type for Collate field Changes.
 *
 */
export type CollateChange = { [k in string]?: string|null };
/**
 * Codec for Collate Changes.
 *
 */
export const CollateChange: Type<CollateChange> = record(string, union([string, cnull, undef]));
/**
 * Type for VariableName Changes.
 *
 */
export type VariableNames = NameChange;
/**
 * Codec for VariableName Changes.
 *
 */
export const VariableNames: Type<VariableNames> = record(string, union([Translations, undef]));
/**
 * Type for VariableName Changes.
 *
 */
export type SpaceNames = NameChange;
/**
 * Codec for SpaceNames Changes.
 *
 */
export const SpaceNames: Type<SpaceNames> = record(string, union([Translations, undef]));
/**
 * Type for SpaceRank Changes.
 *
 */
export type SpaceRank = { [k in string]?: number };

/**
 * Codec for Space Rank Changes.
 *
 */
export const SpaceRank: Type<SpaceRank> = record(string, union([number, undef]));

/**
 * Type for VariableRank Changes.
 *
 */
export type VariableRank = { [k in string]?: number };

/**
 * Codec for VariableRank Changes.
 *
 */
export const VariableRank: Type<VariableRank> = record(string, union([number, undef]));

/**
 * Type for VariableRank Changes.
 *
 */
export type VariableRange = { [k in string]?: Range };

/**
 * Codec for VariableRank Changes.
 *
 */
export const VariableRange: Type<VariableRange> = record(string, union([Range, undef]));

/**
 * Type for OptionRank Changes
 *
 */
export type OptionRank = { [k in string]?: { [k in string]?: number } }

/**
 * Codex from OptionRank Change
 *
 */
export const OptionRank: Type<OptionRank> = record(string, union([record(string, union([number, undef])), undef]));


/**
 * Type for TemplateMod.
 *
 */
export type TemplateMod = {
	Specialization?: Specialization;
	SpaceCollateChange?: CollateChange,
	SpaceRestrict?: string[],
	OptionSelection?: OptionSelection;
	OptionNamesChange?: OptionNamesChange,
	OptionRank?: OptionRank,
	VariableCollateChange?: CollateChange,
	VariableSelection?: VariableSelection;
	VariableNames?: VariableNames;
	SpaceNames?: SpaceNames;
	SpaceRank?: SpaceRank;
	VariableRank?: VariableRank;
	VariableRange?: VariableRange;
};
/**
 * Codec for TemplateMod.
 *
 */
export const TemplateMod: Type<TemplateMod> = partial({
	Specialization: Specialization,
	SpaceCollateChange: CollateChange,
	SpaceRestrict: array(string),
	OptionSelection: OptionSelection,
	OptionNamesChange: OptionNamesChange,
	OptionRank: OptionRank,
	VariableCollateChange: CollateChange,
	VariableSelection: VariableSelection,
	VariableNames: VariableNames,
	SpaceNames: SpaceNames,
	SpaceRank: SpaceRank,
	VariableRank: VariableRank,
	VariableRange: VariableRange,
});
