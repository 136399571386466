/**
 * Custom Container types used in our models.
 *
 * @since 0.1.0
 */
import { Type, tuple, union } from 'io-ts';

export { NonEmpty, refineNonEmpty } from '~/src/base/Array/NonEmpty';

/**
 * `OneOrUptoThree<A,B>` is designed for each level of a typed tree, where `A`
 * is the type of leaves and `B` is the type at each node (and contains a `OneOrUptoThree`).
 *
 * Either one `A`, or one, two, or three `B`s in an array.
 *
 * @since 0.1.0
 * @category OneOrUptoThree
 */
export type OneOrUptoThree<A,B> = A | [B] | [B,B] | [B,B,B];

/**
 * Codec for OneOrUptoThree.
 *
 * @since 0.1.0
 * @category OneOrUptoThree
 */
export const OneOrUptoThree = <A, B>(codecA: Type<A>, codecB: Type<B>): Type<OneOrUptoThree<A,B>> => union([
	codecA,
	tuple([codecB,codecB,codecB]),
	tuple([codecB,codecB]),
	tuple([codecB])
]);
