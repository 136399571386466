/**
 * Misc type helpers.
 *
 * @internal
 */

import { failure, identity, success, Type } from 'io-ts';

export type Guard<T> = (x: unknown) => x is T;

/**
 * Parse only `true`.
 */
export const boolTrue = new Type<true>(
	'true',
	(input: unknown): input is true => input === true,
	(input, context) => input === true ? success(input) : failure(input, context),
	identity
);

/**
 * A string matching a prefix.
 */
export type StartsWith<T extends string> = `${T}${string}`;

/**
 * Codec for `StartsWith`.
 */
export const StartsWith = <T extends string>(pre: T): Type<StartsWith<T>> => new Type<StartsWith<T>>(
	`Prefix<${pre}>`,
	startsWith(pre),
	(input, context) => startsWith(pre)(input) ? success(input) : failure(input, context),
	identity
);

export const startsWith = <T extends string>(pre: T) => (x: unknown): x is StartsWith<T> =>
	typeof x === 'string' && x.startsWith(pre);
